@if (filters && filterValues) {
  @if (filters.price && filters.price.minValue !== filters.price.maxValue) {
    <catalog-product-list-filter-price
      class="g-form-group"
      [filters]="filters"
      [filterValues]="filterValues"
      (changePrice)="changePrice($event)"
      (clearFilter)="clearPriceFilter()"
    ></catalog-product-list-filter-price>
  }

  @if (filters.hasCityStock || filters.hasAllStocks) {
    <catalog-product-list-filter-stock
      class="g-form-group"
      [filters]="filters"
      [filterValues]="filterValues"
      (changeApplyCityStock)="changeApplyCityStock($event)"
      (changeApplyAllStock)="changeApplyAllStock($event)"
      (clearFilter)="clearStock()"
    ></catalog-product-list-filter-stock>
  }

  @for (prop of filters.characteristics; track prop.id) {
    <div class="g-form-group">
      @if (
        prop.type === PROPERTY_TYPES.INPUT_NUMBER &&
        prop.minValue !== null && prop.maxValue !== null &&
        prop.minValue !== prop.maxValue
      ) {
        <catalog-product-list-filter-input
          [filters]="filters"
          [filterValues]="filterValues"
          [prop]="prop"
          (changePropertyFilter)="changePropertyFilter(prop, $event)"
          (clearFilter)="clearPropertyFilter(prop.id)"
        ></catalog-product-list-filter-input>
      }

      @if ((prop.typeElements || []).length && !prop.showSelectbox) {
        <catalog-product-list-filter-buttons
          [filters]="filters"
          [filterValues]="filterValues"
          [prop]="prop"
          (toggleType)="toggleType(prop.id, $event, filterValues)"
          (clearFilter)="clearTypes(prop.id)"
        ></catalog-product-list-filter-buttons>
      }

      @if ((prop.typeElements || []).length && prop.type === PROPERTY_TYPES.CHECKBOX && prop.showSelectbox) {
        <catalog-product-list-filter-checkbox
          [filters]="filters"
          [filterValues]="filterValues"
          [prop]="prop"
          [submitDisabled]="submitDisabled"
          (toggle)="toggleType(prop.id, $event, filterValues)"
          (selectAll)="selectTypes(prop.id)"
          (clearAll)="clearTypes(prop.id)"
          (clearFilter)="clearTypes(prop.id)"
          (applyFilterValues)="apply()"
        ></catalog-product-list-filter-checkbox>
      }
    </div>
  }

  @if (nomenclatureBase?.isPartner && !companyId) {
    @if (cities && cities.length > 0) {
      <catalog-product-list-filter-select-box
        class="g-form-group"
        [title]="'Город партнера'"
        [items]="cities"
        [selected]="selectedCities || []"
        [submitDisabled]="submitDisabled"
        (toggle)="toggleCity($event)"
        (selectAll)="selectCities()"
        (clearAll)="clearCities()"
        (clearFilter)="clearCities()"
        (applyFilterValues)="apply()"
      ></catalog-product-list-filter-select-box>
    }

    @if (companies && companies.length > 0) {
      <catalog-product-list-filter-select-box
        class="g-form-group"
        [title]="'Партнер'"
        [items]="companies"
        [selected]="selectedCompanies || []"
        [submitDisabled]="submitDisabled"
        (toggle)="toggleCompany($event)"
        (selectAll)="selectCompanies()"
        (clearAll)="clearCompanies()"
        (clearFilter)="clearCompanies()"
        (applyFilterValues)="apply()"
      ></catalog-product-list-filter-select-box>
    }
  }

  @if (paginationResponse) {
    <div class="g-form-group">
      Найдено {{ paginationResponse.totalItems | declOfNum: ['товар', 'товаров', 'товара'] }}
    </div>
  }

  @if ((filters.price && filters.price.minValue !== filters.price.maxValue) || filters.characteristics.length) {
    <div class="footer">
      @if (resetItem) {
        <button type="button" [disabled]="submitDisabled" [routerLink]="resetItem | catalogGroupLink">
          Сбросить
        </button>
      } @else {
        <button type="button" [disabled]="submitDisabled" (click)="resetFilterValues.emit()">
          Сбросить
        </button>
      }
      <button type="button" class="apply" [disabled]="submitDisabled" (click)="apply()">
        Применить
      </button>
    </div>
  }
}
