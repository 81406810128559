import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule as CommonPipesModule } from '@inaripro-nx/common-ui';
import { PipesModule } from '../../pipes/pipes.module';
import { EditorElementContentComponent } from '../editor/components/editor-element-content/editor-element-content.component';
import { EditorElementTransformComponent } from '../editor/components/editor-element-transform/editor-element-transform.component';
import { ExportComponent } from './export.component';
import { NgArrayPipesModule } from 'ngx-pipes';
import { EditorZonePaintComponent } from '../editor/components/editor-zone-paint/editor-zone-paint.component';

@NgModule({
  declarations: [ExportComponent],
  exports: [ExportComponent],
  imports: [
    CommonModule,
    CommonPipesModule,
    PipesModule,
    EditorElementTransformComponent,
    EditorElementContentComponent,
    NgArrayPipesModule,
    EditorZonePaintComponent,
  ],
})
export class ExportModule {}
