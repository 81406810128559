<div class="catalog-filter">
  <div class="catalog-filter__header">
    <div class="catalog-filter__title">
      @if (prop && prop.id && prop.showHelper && prop.helperText) {
        <div class="helper-wrapper helper-wrapper--dashed">
          <design-helper-window
            class="helper"
            [title]="prop.alias"
            [uid]="prop.id"
            [position]="'auto-bottom'"
          >
            <span class="state-popover__toggle">
              <ng-container *ngTemplateOutlet="filterTitle"></ng-container>&nbsp;(?)
            </span>
            <div class="state-popover-body__content" [innerHtml]="prop.helperText"></div>
          </design-helper-window>
          <ng-container *ngTemplateOutlet="filterReset"></ng-container>
        </div>
      } @else {
        <ng-container *ngTemplateOutlet="filterTitle"></ng-container>
        <ng-container *ngTemplateOutlet="filterReset"></ng-container>
      }
    </div>
  </div>
  <div class="catalog-filter__body">
    <ng-content></ng-content>
  </div>
</div>

<ng-template #filterTitle>
  {{title}}
</ng-template>

<ng-template #filterReset>
  <span class="filter-reset" [class.filter-reset--active]="isApply" (click)="clearFilter.emit()">
    Сбросить
  </span>
</ng-template>
