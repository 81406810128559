<ng-container *ngIf="{
  saveOptions: saveOptions$ | async,
  isSending: isSending$ | async
} as state">
  @if (state.saveOptions && state.saveOptions.length > 0) {
    @if (state.saveOptions.length > 1) {
      <div
        *ngIf="{saveSelectActive: false} as subState"
        designAdDropDown
        [dropDownAlignElement]="saveButtonWrapper"
        [dropDownItems]="state.saveOptions"
        [dropDownOpenTo]="'down'"
        [dropDownAlign]="'left'"
        [dropDownClass]="state.saveOptions.length === 3 ? 'drop-down-buttonshistory-save drop-down-buttonshistory-save--big' : 'drop-down-buttonshistory-save'"
        (selectItem)="!state.isSending && selectSaveOption($event.item)"
        (toggleDropDown)="subState.saveSelectActive = $event"
      >
        <div #saveButtonWrapper>
          <design-tool-button
            class="save-template-button"
            [selected]="subState.saveSelectActive"
          >
            <i class="icon icon-ic_save"></i>
            <span>Сохранить</span>
          </design-tool-button>
        </div>
      </div>
    } @else {
      <design-tool-button
        class="save-template-button"
        (click)="!state.isSending && selectSaveOption({type: ESaveType.new})"
      >
        <i class="icon icon-ic_save"></i>
        <span>Сохранить</span>
      </design-tool-button>
    }
  }
</ng-container>

<ng-container *ngIf="(isSaved$ | async) as isSaved">
  <div class="save-template-info">
    <i class="icon icon-baseline-check"></i>
    <span>Изменения успешно сохранены</span>
  </div>
</ng-container>
