<catalog-product-list-filter-wrapper
  [prop]="prop"
  [title]="prop.alias"
  [isApply]="filterValues.typeFilters[prop.id] | objectValues | someEqual: true"
  (clearFilter)="clearFilter.emit($event)"
>
  @for (el of prop.typeElements; track el.id) {
    @if (el.color) {
      <design-color-button
        [colorsTitles]="el.name"
        [colors]="el.color"
        [isActive]="filterValues.typeFilters[prop.id] && filterValues.typeFilters[prop.id][el.id]"
        (click)="toggleType.emit(el.id)"
      ></design-color-button>
    } @else {
      <button
        [class.g-btn-toggle--active]="filterValues.typeFilters[prop.id] && filterValues.typeFilters[prop.id][el.id]"
        class="g-btn-toggle"
        (click)="toggleType.emit(el.id)"
      >
        {{ el.name }}
      </button>
    }
  }
</catalog-product-list-filter-wrapper>
