import { CommonModule, PercentPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DropDownModule, ToolButtonComponent } from '@inaripro-nx/design-ui';
import {
  WorkareaStore,
  ZOOM_OPTIONS,
} from '../../../../state/workarea/workarea.store';
import { PercentNoSpacePipe, PipesModule } from '@inaripro-nx/common-ui';

interface ZoomOptionModel {
  dropDownLabel: string;
  value: number;
}

@Component({
  selector: 'painter-editor-zoom-controls',
  standalone: true,
  templateUrl: './editor-zoom-controls.component.html',
  styleUrls: ['./editor-zoom-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, DropDownModule, PipesModule, ToolButtonComponent],
  providers: [PercentPipe, PercentNoSpacePipe],
})
export class EditorZoomControlsComponent {
  readonly zoomOptions: ZoomOptionModel[] = ZOOM_OPTIONS.map((value) => ({
    dropDownLabel: this.percentNoSpacePipe.transform(value) || '',
    value,
  }));

  readonly zoom$ = this.workareaStore.zoom$;
  readonly zoomInDisabled$ = this.workareaStore.zoomInDisabled$;
  readonly zoomOutDisabled$ = this.workareaStore.zoomOutDisabled$;

  readonly isFocusActive$ = this.workareaStore.isFocusActive$;
  readonly isGrabActive$ = this.workareaStore.isGrabActive$;

  zoomSelectActive = false;

  constructor(
    private readonly workareaStore: WorkareaStore,
    private readonly percentNoSpacePipe: PercentNoSpacePipe
  ) {}

  zoomOut(): void {
    this.workareaStore.zoomOut();
  }

  zoomIn(): void {
    this.workareaStore.zoomIn();
  }

  selectZoom({ value }: ZoomOptionModel): void {
    this.workareaStore.setZoom(value);
  }

  toggleFocus(): void {
    this.workareaStore.toggleFocus();
  }

  toggleGrab(): void {
    this.workareaStore.toggleGrab();
  }
}
