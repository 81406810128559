<div
  class="popup-select-box"
  [class.popup-select-box--active]="activeOption?.label"
  [class.popup-select-box--disabled]="isDisabled"
  [class.popup-select-box--new]="isNew"
  designClickOutside
  (clickOutsideEvent)="isSelectListVisible && hideSelectList()"
>
  <div class="popup-select-box__input">
    <input type="text" [value]="activeOption?.label || emptyLabel" (click)="!isDisabled && showSelectList()" readonly>
    <i class="icon icon-ic_keyboard_arrow_right" (click)="!isDisabled && toggleSelectList(); $event.stopPropagation()"></i>
  </div>
  @if (isSelectListVisible) {
    <ul class="popup-select-box__list">
      @for (option of options; track option.value) {
        <li
          class="popup-select-box__list-item"
          [class.popup-select-box__list-item--active]="activeOption?.id === option.id"
          (mousedown)="onClickList(option)"
          (mouseup)="isNew && $event.stopPropagation()"
        >
          {{ option.label }}
        </li>
      }
    </ul>
  }
</div>
