import { createAction, props } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';

export interface IGo {
  path: unknown[];
  query?: object;
  extras?: NavigationExtras;
}

export const go = createAction('[Router] go', props<{ payload: IGo }>());
