import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle } from '@angular/platform-browser';
import { ESafeType } from '../../enums/safetype.enum';

@Pipe({
  name: 'sanitize',
  standalone: true,
})
export class SanitizePipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  transform(value: string, type: ESafeType): SafeHtml | SafeStyle {
    switch (type) {
      case ESafeType.HTML:
        return this.domSanitizer.bypassSecurityTrustHtml(value);
      case ESafeType.STYLE:
        return this.domSanitizer.bypassSecurityTrustStyle(value);
      default:
        return '';
    }
  }
}
