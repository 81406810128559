<ng-container
  *ngIf="{
    designerSource: productService.designerSource$ | async,
    designerProduct: productService.designerProduct$ | async,
    price: productService.price$ | async,
    updatingPrice: productService.updatingPrice$ | async,
    activeDesignProduct: productService.activeDesignProduct$ | async,
    orderCount: productService.orderCount$ | async,
    countInBasket: productService.countInBasket$ | async,
    myTemplates: myTemplatesStore.collection$ | async,
    isProductOwner: productTemplatesStore.isProductOwner$ | async,
    productTemplates: productTemplatesStore.collection$ | async,
    propertiesInfo: productService.propertiesInfo$ | async,
    filters: filtersService.filters$ | async,
    figures: figuresService.figures$ | async,
    pictures: picturesStore.collection$ | async,
    qrCodes: qrCodesStore.collection$ | async,
    appShellConfig: appShellConfig$ | async,
    loading: loading$ | async,
    linkId: productService.linkId$ | async,
    containerDictionaryId: productService.containerDictionaryId$ | async
  } as state"
>
  <painter-main
    [config]="state.appShellConfig"
    [designerSource]="state.designerSource"
    [product]="state.designerProduct"
    [price]="state.price"
    [updatingPrice]="state.updatingPrice"
    [activeDesignProduct]="state.activeDesignProduct"
    [orderCount]="state.orderCount || 0"
    [countInBasket]="state.countInBasket || 0"
    [propertiesInfo]="state.propertiesInfo"
    [filters]="state.filters"
    [figures]="state.figures"
    [pictures]="state.pictures"
    [picturesLibraries$]="picturesLibraryStore.libraries$"
    [picturesLibraryCurrentId$]="picturesLibraryStore.currentId$"
    [picturesLibraryCurrentLibrary$]="picturesLibraryStore.currentLibrary$"
    [picturesLibraryCurrentPictures$]="picturesLibraryStore.currentPictures$"
    [getLibraryPictures]="getLibraryPictures"
    [patternLibraries$]="patternsStore.libraries$"
    [patternCurrentId$]="patternsStore.currentId$"
    [patternCurrentLibrary$]="patternsStore.currentLibrary$"
    [patternCurrentPatterns$]="patternsStore.currentPatterns$"
    [getPatterns]="getPatterns"
    [qrCodes]="state.qrCodes"
    [myTemplates]="state.myTemplates"
    [isProductOwner]="state.isProductOwner"
    [productTemplates]="state.productTemplates"
    [newQRCode$]="qrCodesStore.newModel$"
    [savedTemplate$]="savedTemplate$"
    [uploadPicture]="uploadPicture"
    [deletePicture]="deletePicture"
    [addPicture]="addPicture"
    [createQRCode]="createQRCode"
    [deleteQRCode]="deleteQRCode"
    [createMyTemplate]="createMyTemplate"
    [updateMyTemplate]="updateMyTemplate"
    [removeMyTemplate]="removeMyTemplate"
    [createProductTemplate]="createProductTemplate"
    [updateProductTemplate]="updateProductTemplate"
    [removeProductTemplate]="removeProductTemplate"
    [createShareTemplate]="createShareTemplate"
    (selectProduct)="openSelectProductModal()"
    (changeParams)="changeParams($event)"
    (addToBasket)="productService.addToBasket($event)"
  >
    <shell-header
      data-header
      [appShellConfig]="state.appShellConfig"
    ></shell-header>
  </painter-main>

  <catalog-select-product
    #catalogSelectProduct
    [selectedProductId]="state.designerProduct?.id || null"
    [containerDictionaryId]="state.containerDictionaryId || null"
    [containers]="state.designerProduct?.containers || []"
    [linkId]="state.linkId || null"
    [productListRequest]="{ excludeCalculators: true, hasDesigner: true }"
    [filterCatalogNomenclatureTreeItem]="filterCatalogNomenclatureTreeItem"
    (selectProductListItem)="selectProduct($event)"
  ></catalog-select-product>

  <order-product-add-to-cart-modal
    *ngIf="state.appShellConfig && state.designerProduct"
    [publicUrl]="state.appShellConfig.publicUrl"
    [name]="state.designerProduct.name"
  ></order-product-add-to-cart-modal>

  <design-preloader *ngIf="state.loading"></design-preloader>
</ng-container>
