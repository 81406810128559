<catalog-product-list-filter-wrapper
  [title]="'Цена, Р'"
  [isApply]="!!filterValues.priceFilter"
  (clearFilter)="clearFilter.emit()"
>
  <design-range-inputs
    class="price"
    [range]="filters.price"
    [values]="filterValues.priceFilter"
    [step]="0.01"
    (changeModel)="changePrice.emit($event)"
  ></design-range-inputs>
</catalog-product-list-filter-wrapper>
