import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  IFiltersValues,
  IProductFilters,
  IPropertyTypeElement,
} from '../../interfaces';
import { CountSelectedPropertyPipe } from './pipes/count-selected-property.pipe';
import { DesignUiModule } from '@inaripro-nx/design-ui';

@Component({
  selector: 'catalog-product-list-applied-filter',
  standalone: true,
  imports: [CommonModule, DesignUiModule, CountSelectedPropertyPipe],
  templateUrl: './catalog-product-list-applied-filter.component.html',
  styleUrls: ['./catalog-product-list-applied-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogProductListAppliedFilterComponent {
  @Input() filters: IProductFilters | null = null;
  @Input() filterValues: IFiltersValues | null = null;

  @Output() removePriceFilter = new EventEmitter<void>();
  @Output() removeApplyAllStockFilter = new EventEmitter<void>();
  @Output() removeFilter = new EventEmitter<number>();
  @Output() toggleType = new EventEmitter<{
    toggleElement: IPropertyTypeElement;
    filterValues: IFiltersValues;
  }>();

  readonly MAX_COUNT_PROPERTY = 3;

  toggle(propertyId: number, elementId: number, filterValues: IFiltersValues) {
    this.toggleType.emit({
      toggleElement: { propertyId, elementId },
      filterValues,
    });
  }
}
