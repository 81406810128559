<div
  class="editor-draw"
  *ngIf="{
    elements: elements$ | async,
    filters: filters$ | async,
    activeDesignSideIndex: activeDesignSideIndex$ | async,
    fullColor: fullColor$ | async,
    isFillModalOpened: isFillModalOpened$ | async,
    zoneColors: zoneColors$ | async,
    zonePatterns: zonePatterns$ | async,
    zoneTranslates: zoneTranslates$ | async,
    activeDesignZonesWithColor: activeDesignZonesWithColor$ | async,
    elementsEditable: elementsEditable$ | async,
    zones: (activeDesignSide?.zones || []) | filterBy: ['isOnlyFilling']: false,
    colorZones: (activeDesignSide?.zones || []) | filterBy: ['isOnlyFilling']: true
  } as state"
  (touchmove)="$event.preventDefault()"
  (mousedown)="$event.stopPropagation()"
  (touchstart)="onEditorDrawTouchStart($event)"
>
  <svg
    *ngIf="activeDesignSide"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="width: 100%; height: 100%"
    #svgBase
    id="svgBase"
    preserveAspectRatio="xMidYMid meet"
    [attr.viewBox]="activeDesignSide.sizePX | viewbox"
  >
    <!-- Рабочая область редактора -->
    <g *ngIf="state.isFillModalOpened"></g>

    <defs>
      <mask id="mask1">
        <rect x="0" y="0" width="100%" height="100%" fill="black" />
        <rect
          *ngFor="let zone of state.zones"
          [attr.transform]="(updateZone?.id === zone.id && updateZone?._translate || state.zoneTranslates?.['' + zone.id]) | svgTranslate"
          [attr.x]="zone.start?.x"
          [attr.y]="zone.start?.y"
          [attr.width]="zone.size?.x"
          [attr.height]="zone.size?.y"
          fill="white"
        />
      </mask>
    </defs>

    <rect
      x="-50%"
      y="-50%"
      width="200%"
      height="200%"
      (mousedown)="clearSelect()"
      (touchstart)="clearSelect()"
      fill-opacity="0"
    />

    <!-- Область фоновой заливки -->
    <rect
      x="0"
      y="0"
      width="100%"
      height="100%"
      [attr.fill]="state.fullColor || BACKGROUND_COLOR_DEFAULT"
      style="pointer-events: none"
    />

    <!-- Мокап -->
    <image
      *ngIf="activeDesignSide.mockupType === EMockupType.down"
      [attr.xlink:href]="activeDesignSide.image.url"
      x="0"
      y="0"
      width="100%"
      height="100%"
      style="pointer-events: none"
    ></image>

    <!-- Фон области нанесения -->
    <g
      data-painter-editor-zone-paint
      *ngFor="let zone of state.zones"
      [attr.transform]="(updateZone?.id === zone.id && updateZone?._translate || state.zoneTranslates?.['' + zone.id]) | svgTranslate"
      [zone]="zone"
      [zoneColor]="state.zoneColors?.['' + zone.id]"
      [zonePattern]="state.zonePatterns?.['' + zone.id]"
      style="pointer-events: none"
    ></g>

    <g *ngIf="!activeDesignSide._isFullPrint">
      <rect
        *ngFor="let zone of activeDesignSide.zones"
        [attr.transform]="state.zoneTranslates?.['' + zone.id] | svgTranslate"
        [attr.x]="zone.start?.x"
        [attr.y]="zone.start?.y"
        [attr.width]="zone.size?.x"
        [attr.height]="zone.size?.y"
        fill="#000"
        fill-opacity="0"
        (click)="clearSelect(); selectZone(activeDesignSide, zone)"
      ></rect>
    </g>

    <!-- Прогрузка шрифтов -->
    <g class="fonts-loading">
      @for (el of state.elements; track el; let i = $index) {
        <g
          *ngIf="asTextElement(el) as textEl"
          [attr.font-style]="textEl.fontStyle"
          [attr.font-weight]="textEl.fontWeight"
          [attr.font-family]="textEl.fontFamily | fontFamily"
        >
          <text>{{textEl.text}}</text>
        </g>
      }
    </g>

    <!-- Объекты дизайна -->
    <g mask="url(#mask1)">
      <g [attr.transform]="zone0Center | svgTranslate">
        @for (el of state.elements; track el; let i = $index) {
          @if (el.sideIndex === state.activeDesignSideIndex) {
            <g
              *ngIf="
                i === updateElement?.index ? updateElement?.element : el as elem
              "
              data-painter-editor-element-transform
              [el]="elem"
              (mousedownEvent)="
                state.elementsEditable &&
                  startAction(
                    EActionType.move,
                    $event,
                    activeDesignSide,
                    i
                  )
              "
            >
              <rect
                [attr.x]="0"
                [attr.y]="0"
                [attr.width]="elem.size.x"
                [attr.height]="elem.size.y"
                [attr.transform]="el.scale | svgScale"
                fill-opacity="0"
                stroke="none"
              />

              <g
                data-painter-editor-element-content
                [el]="elem"
                [index]="i"
                [inAction]="updateElement?.index === i && updateElement?.inAction"
                [filter]="elem.filterId && state.filters?.[elem.filterId]"
              ></g>
            </g>
          }
        }

        <g
          *ngIf="updateElement?.element as el"
          data-painter-editor-element-transform
          [el]="el"
          (mousedownEvent)="
            state.elementsEditable &&
              startAction(
                EActionType.move,
                $event,
                activeDesignSide,
                updateElement?.index
              )
          "
        >
          <rect
            [attr.x]="0"
            [attr.y]="0"
            [attr.width]="el.size.x"
            [attr.height]="el.size.y"
            [attr.transform]="el.scale | svgScale"
            fill-opacity="0"
            stroke="none"
          />
        </g>
      </g>
    </g>

    <!-- Фон области заливки -->
    <g
      data-painter-editor-zone-paint
      *ngFor="let zone of state.colorZones"
      [zone]="zone"
      [zoneColor]="state.zoneColors?.['' + zone.id]"
      [zonePattern]="state.zonePatterns?.['' + zone.id]"
      (click)="clearSelect(); selectZone(activeDesignSide, zone)"
    ></g>

    <!-- Мокап -->
    <image
      *ngIf="activeDesignSide.mockupType === EMockupType.up"
      [attr.xlink:href]="activeDesignSide.image.url"
      x="0"
      y="0"
      width="100%"
      height="100%"
      style="pointer-events: none"
    ></image>

    <!-- Область затемнения -->
    <rect
      x="0"
      y="0"
      width="100%"
      height="100%"
      style="
        pointer-events: none;
        fill: rgba(0, 0, 0, 0.0352);
        stroke: #f6f6f6;
        stroke-width: 3px;
        vector-effect: non-scaling-stroke;
      "
    />

    <!-- Управление областями нанесения и заливки -->
    <g *ngIf="!activeDesignSide._isFullPrint">
      <g *ngFor="let zone of activeDesignSide.zones; let zoneIndex = index">
        <g data-painter-editor-rect-dashed
           *ngIf="zone.moveArea as moveArea"
           [area]="moveArea" [colors]="['#ededed', '#3D3D3D']">
          <text
            *ngIf="moveArea.start"
            x="0" y="-0.5em"
            text-anchor="start"
            [attr.transform]="moveArea.start | svgTranslate"
            [attr.font-size]="fontSize + 'px'"
            style="
              color: #3D3D3D;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            "
          >Границы перемещения</text>
        </g>
        <g [attr.transform]="(updateZone?.id === zone.id && updateZone?._translate || state.zoneTranslates?.['' + zone.id]) | svgTranslate">
          <g data-painter-editor-rect-dashed
             [area]="zone" [colors]="['#ededed', '#9e9e9e']"></g>
          <image
            *ngIf="zone.moveArea && zone.start && zone.size"
            [attr.transform]="{x: zone.start.x + zone.size.x, y: zone.start.y + zone.size.y} | svgTranslate"
            [attr.width]="sizeActions.x"
            [attr.height]="sizeActions.y"
            class="pointer zone-action"
            xlink:href="/assets/images/move-zone.jpg"
            (mousedown)="startAction(EActionType.move_zone, $event, activeDesignSide, zoneIndex)"
            (touchstart)="startAction(EActionType.move_zone, $event, activeDesignSide, zoneIndex)"
          ></image>
          <image
            *ngIf="
              (state.activeDesignZonesWithColor || []).length > 1 &&
              (zone.hasColorPalette || zone.colorProperty) &&
              zone.start
            "
            [attr.transform]="{x: zone.start.x - sizeActions.x, y: zone.start.y - sizeActions.y } | svgTranslate"
            [attr.width]="sizeActions.x"
            [attr.height]="sizeActions.y"
            class="pointer zone-action select-zone-icon"
            xlink:href="/assets/images/select-zone.svg"
            (click)="selectZone(activeDesignSide, zone)"
          ></image>
        </g>
      </g>
    </g>

    <!-- Элементы управления выделенным объектом дизайна -->
    <g
      *ngIf="
        state.elementsEditable && updateElement && updateElement?.element as el
      "
      [attr.transform]="zone0Center | svgTranslate"
    >
      <g
        [attr.transform]="
          (el.translate | svgTranslate) +
          ({
            angle: el.rotate,
            point: { size: el.size, scale: el.scale } | elementRotateCenter
          } | svgRotate) +
          (el.scale | svgScale)
        "
      >
        <rect
          [attr.x]="0"
          [attr.y]="0"
          [attr.width]="el.size.x"
          [attr.height]="el.size.y"
          stroke="#0091D9"
          stroke-width="1px"
          stroke-dasharray="5,5"
          vector-effect="non-scaling-stroke"
          fill="none"
        />

        <g [class.hidden]="updateElement ? updateElement.inAction : false">
          <image
            [attr.transform]="
              ({
                x: -sizeActions.x / el.scale.x,
                y: -sizeActions.y / el.scale.y
              } | svgTranslate) +
              ({ x: 1 / el.scale.x, y: 1 / el.scale.y } | svgScale)
            "
            [attr.width]="sizeActions.x"
            [attr.height]="sizeActions.y"
            class="pointer"
            xlink:href="/assets/images/box-close.svg"
            (click)="removeElement($event)"
          ></image>
          <image
            [attr.transform]="
              ({
                x: el.size.x,
                y: -sizeActions.y / el.scale.y
              } | svgTranslate) +
              ({ x: 1 / el.scale.x, y: 1 / el.scale.y } | svgScale)
            "
            [attr.width]="sizeActions.x"
            [attr.height]="sizeActions.y"
            class="pointer"
            xlink:href="/assets/images/box-rotate.svg"
            (dblclick)="rotateToDefault()"
            (mousedown)="
              startAction(EActionType.rotate, $event, activeDesignSide)
            "
            (touchstart)="
              startAction(EActionType.rotate, $event, activeDesignSide)
            "
          ></image>
          <image
            [attr.transform]="
              ({
                x: el.size.x,
                y: el.size.y
              } | svgTranslate) +
              ({ x: 1 / el.scale.x, y: 1 / el.scale.y } | svgScale)
            "
            [attr.width]="sizeActions.x"
            [attr.height]="sizeActions.y"
            class="pointer"
            xlink:href="/assets/images/box-resize.svg"
            (dblclick)="scaleToDefault()"
            (mousedown)="
              startAction(EActionType.scale, $event, activeDesignSide)
            "
            (touchstart)="
              startAction(EActionType.scale, $event, activeDesignSide)
            "
          ></image>
          @if (el.type === EElementType.image && (el | imageMask).mask) {
            <image
              [attr.transform]="
                ({
                  x: -sizeActions.x / el.scale.x,
                  y: el.size.y
                } | svgTranslate) +
                ({ x: 1 / el.scale.x, y: 1 / el.scale.y } | svgScale)
              "
              [attr.width]="sizeActions.x"
              [attr.height]="sizeActions.y"
              class="pointer"
              xlink:href="/assets/images/box-crop.svg"
              (click)="openCropModal()"
            ></image>
          }
        </g>
      </g>
    </g>
  </svg>
</div>
