export const MAX_FILE_IN_LIBRARY_AUTH_USER = 30;
export const MAX_FILE_IN_LIBRARY_NOAUTH_USER = 10;
export const MAX_SIMULTANEOUS_UPLOADS = 10; // ограничения на одномоментную загрузку

export const REACH_FILE_LIMIT_USER_TEXT =
  'Достигнуто максимальное количество файлов на одного пользователя, удалите ненужные файлы.';
export const REACH_FILE_LIMIT_NO_USER_TEXT =
  'Достигнуто максимальное количество файлов для неавторизованного пользователя. Авторизуйтесь или удалите ненужные файлы.';
export const REACH_FILE_LIMIT_NO_USER_SHORT_TEXT =
  'Достигнуто максимальное количество файлов для неавторизованного пользователя.';

export const DECIMAL_POINTS_SCALE = 0;
export const DECIMAL_POINTS_ROTATE = 0;
export const DECIMAL_POINTS_STROKE = 0;
