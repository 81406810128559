@if (response && pages.length > 1) {
  <div class="pager">
    <div class="actions">
      <div class="items">
        <div class="pager__nav prev">
          <a [class.disabled]="isFirstPage" [routerLink]="['./']" [queryParams]="previousPageQueryParams" queryParamsHandling="merge">
            <i class="icon icon-ic_keyboard_arrow_right"></i>
          </a>
        </div>

        @for (page of pages; track page.value; let i = $index) {
          <a
            class="pager__item"
            [class.current]="currentPage === page.value"
            [routerLink]="['./']" [queryParams]="pageQueryParams[i]" queryParamsHandling="merge">

            <span>{{ page.label }}</span>
          </a>
        }

        <div class="pager__nav next">
          <a [class.disabled]="isLastPage" [routerLink]="['./']" [queryParams]="nextPageQueryParams" queryParamsHandling="merge">
            <i class="icon icon-ic_keyboard_arrow_right"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
}
