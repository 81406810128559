import {
  EPropertyType,
  IDesignProduct,
  IPricesKit,
  IProductProperty,
  IProperty,
  IPublicProductWithPrice,
} from '@inaripro-nx/catalog';

import {
  EFilterPropertyKey,
  EFilterPropertyName,
  IDesignerElementKeys,
  IDesignerProduct,
  IDesignerProperty,
  IDesignerPropertyElement,
  IDesignerPropertyElementMap,
  IDesignerPropertyMap,
  IDesignerSource,
  IElementInfo,
  IPropertyInfo,
  IShareTemplate,
} from '@inaripro-nx/painter';

import { reduceToMap, sortAsNumber } from '@inaripro-nx/common-ui';

export function isProductError(product: IPublicProductWithPrice): boolean {
  const isProductError =
    !product.designProducts?.length ||
    !!product.designProducts.find(
      (dp) =>
        !dp.sides?.length ||
        !!dp.sides.find((s) => !s.isFullPrint && !s.zones?.length)
    );

  return isProductError;
}

export function getDesignerProduct(
  product: IPublicProductWithPrice | null,
  shareTemplate: IShareTemplate | null,
  autoDownload: boolean
): IDesignerProduct | null {
  if (!product) {
    return null;
  }

  const {
    id,
    name,
    productType,
    description,
    shortDescription,
    productTypeHelpText,
    designSeo,
    containerDictionaryId,
    containers,
    lotSize,
    minRange,
    maxRange,
    album,
    video,
    previews,
    price,
  } = product;

  return {
    id,
    name,
    productType,
    description,
    shortDescription,
    productTypeHelpText,
    designSeo,
    containerDictionaryId,
    containers,
    lotSize,
    minRange,
    maxRange,
    album,
    video,
    previews,
    price,
    shareTemplate,
    autoDownload,
  };
}

export function getPropertiesInfo(
  allProductProperties: IProperty[] | null,
  product: IPublicProductWithPrice | null
): IPropertyInfo[] {
  if (!allProductProperties || !product) {
    return [];
  }

  return (product.productProperties || [])
    .map((productProperty) => {
      const property = allProductProperties.find(
        (pp) => pp.id === productProperty.id
      );

      if (!property) {
        return null;
      }

      const { id, alias: name, type, showHelper, helperText } = property;
      const { val } = productProperty;

      const pi: IPropertyInfo = {
        id,
        name,
        val,
        type,
        showHelper,
        helperText,
      };

      if (productProperty.selectedElements) {
        pi.elements = (property.typeElements || [])
          .filter(
            (te) => (productProperty.selectedElements || []).indexOf(te.id) > -1
          )
          .map((te) => {
            const { id, name, color } = te;
            const ei: IElementInfo = { id, name, color };
            return ei;
          });
      }

      return pi;
    })
    .filter((v) => !!v)
    .sort(
      (a, b) =>
        +(a?.type !== EPropertyType.COLOR) - +(b?.type !== EPropertyType.COLOR)
    ) as IPropertyInfo[];
}

export function getDesignerElementKeys(
  product: IPublicProductWithPrice | null
): IDesignerElementKeys | null {
  if (!product) {
    return null;
  }

  return (product.designProducts || []).reduce((acc, dp) => {
    const keys = [
      ...(dp.kitUids || []),
      ...(dp.properties || []).reduce((acc2, p) => {
        acc2 = [...acc2, ...(p.selectedElements || []).map((id) => '' + id)];
        return acc2;
      }, [] as string[]),
    ];
    acc[dp.id] = keys.reduce((acc2, key) => {
      acc2[key] = true;
      return acc2;
    }, {} as { [id: string]: boolean });
    return acc;
  }, {} as IDesignerElementKeys);
}

export function getInitDesignerSource(
  allProductProperties: IProperty[] | null,
  product: IPublicProductWithPrice | null
): IDesignerSource | null {
  if (!allProductProperties || !product) {
    return null;
  }

  let properties: IDesignerProperty[] = getDesignerProperties(
    allProductProperties,
    product.productProperties || []
  );

  const propertiesSortedIds = [
    ...(product.price.properties || []).map((item) => '' + item.id),
    ...(product.price.propertiesOrder || []).map((id) => '' + id),
  ];

  properties = properties.sort((a, b) => {
    let aIndex = propertiesSortedIds.indexOf(a.id);
    aIndex = aIndex === -1 ? 99 : aIndex;

    let bIndex = propertiesSortedIds.indexOf(b.id);
    bIndex = bIndex === -1 ? 99 : bIndex;

    return aIndex - bIndex;
  });

  if (product.price.kits) {
    properties.unshift(getKitDesignerProperty(product.price.kits));
  }

  let propertiesMap = reduceToMap<IDesignerProperty, 'id'>(properties, 'id');
  updateDesignerPropertiesFlags(propertiesMap, product);

  properties = properties
    .filter((p) => p.inOrder || p.inPrice || p.inDesign)
    .map((p) => {
      const elements = p.elements.filter(
        (e) =>
          (!p.inOrder || e.inOrder) &&
          (!p.inPrice || e.inPrice) &&
          (!p.inDesign || e.inDesign)
      );
      const elementsMap: IDesignerPropertyElementMap = reduceToMap<
        IDesignerPropertyElement,
        'id'
      >(elements, 'id');

      return {
        ...p,
        elements,
        elementsMap,
      };
    });

  propertiesMap = reduceToMap<IDesignerProperty, 'id'>(properties, 'id');

  const allElementsMap: IDesignerPropertyElementMap = properties.reduce(
    (acc, prop) => {
      acc = { ...acc, ...prop.elementsMap };
      return acc;
    },
    {}
  );
  const selected = properties
    .filter((p) => p.elements.length === 1)
    .map((p) => p.elements[0].id);

  const source: IDesignerSource = {
    productId: product.id,
    properties,
    propertiesMap,
    allElementsMap,
    selected,
    disabled: [],
    fullColor: null,
  };

  return source;
}

export function getDesignerSource(data: {
  product: IPublicProductWithPrice | null;
  designerElementKeys: IDesignerElementKeys | null;
  initDesignerSource: IDesignerSource | null;
  queryElements: string[];
}): IDesignerSource | null {
  const { product, designerElementKeys, initDesignerSource, queryElements } =
    data;

  if (
    !product ||
    !designerElementKeys ||
    !initDesignerSource ||
    !queryElements.length
  ) {
    return initDesignerSource;
  }

  const disabledPriceUids = product.price.disabledPriceUids || [];
  const { propertiesMap, allElementsMap } = initDesignerSource;

  const uniqueQueryElements = getUniqueElementIdByProperty(
    allElementsMap,
    queryElements
  );

  const selected: string[] = uniqueQueryElements
    .filter((id) => initDesignerSource.selected.indexOf(id) < 0)
    .reduce(
      (selected, elementId) => {
        const trySelected = getUniqueElementIdByProperty(allElementsMap, [
          elementId,
          ...selected,
        ]);

        const isValid = isValidSelected({
          disabledPriceUids,
          designerElementKeys,
          allElementsMap,
          selected: trySelected,
        });

        return isValid ? trySelected : selected;
      },
      [...initDesignerSource.selected]
    );

  const disabled: string[] = [];
  const selectedElementIds: { [propertyId: string]: string } = selected.reduce(
    (map, id) => {
      const el = initDesignerSource.allElementsMap[id];
      map[el.propertyId] = el.id;
      return map;
    },
    {} as { [propertyId: string]: string }
  );

  initDesignerSource.properties.forEach((p) => {
    p.elements
      .filter((e) => selected.indexOf(e.id) < 0)
      .forEach((e) => {
        const trySelected = getUniqueElementIdByProperty(allElementsMap, [
          e.id,
          ...selected,
        ]);

        const canSelected = isValidSelected({
          disabledPriceUids,
          designerElementKeys,
          allElementsMap,
          selected: trySelected,
        });

        if (canSelected && !selectedElementIds[e.propertyId]) {
          selectedElementIds[e.propertyId] = e.id;
          selected.push(e.id);
        }
      });
  });

  initDesignerSource.properties.forEach((p) => {
    p.elements
      .filter((e) => selected.indexOf(e.id) < 0)
      .forEach((e) => {
        const trySelected = getUniqueElementIdByProperty(allElementsMap, [
          e.id,
          ...selected,
        ]);

        const canSelected = isValidSelected({
          disabledPriceUids,
          designerElementKeys,
          allElementsMap,
          selected: trySelected,
        });

        if (!canSelected) {
          disabled.push(e.id);
        }
      });
  });

  const fullColor = getFullColor(
    propertiesMap,
    selected,
    product.designFullColorPropertyId
  );

  const designerSource = {
    ...initDesignerSource,
    selected,
    disabled,
    fullColor,
  };

  return designerSource;
}

function getDesignerProperties(
  allProductProperties: IProperty[],
  productProperties: IProductProperty[]
): IDesignerProperty[] {
  if (allProductProperties && productProperties) {
    return productProperties
      .filter((pp) => !!(pp.selectedElements || []).length)
      .map((productProperty) => {
        const property = allProductProperties.find(
          (pp) => pp.id === productProperty.id
        );

        if (!property) {
          return null;
        }

        const { id, alias: name, type, isSeparateProductCount } = property;

        const elements: IDesignerPropertyElement[] = (
          property.typeElements || []
        )
          .filter(
            (te) => (productProperty.selectedElements || []).indexOf(te.id) > -1
          )
          .map((te) => {
            const { id, name, color } = te;

            const dPE: IDesignerPropertyElement = {
              id: '' + id,
              propertyId: '' + property.id,
              name,
              color,
              inPrice: false,
              inOrder: false,
              inDesign: false,
            };
            return dPE;
          });

        const elementsMap: IDesignerPropertyElementMap = reduceToMap<
          IDesignerPropertyElement,
          'id'
        >(elements, 'id');

        const dProp: IDesignerProperty = {
          id: '' + id,
          name,
          type,
          isSeparateProductCount,
          elements,
          elementsMap,
          inPrice: false,
          inOrder: false,
          inDesign: false,
        };

        return dProp;
      })
      .filter((val) => !!val) as IDesignerProperty[];
  } else {
    return [];
  }
}

function getKitDesignerProperty(kits: IPricesKit[]): IDesignerProperty {
  const elements: IDesignerPropertyElement[] =
    kits.map((kit) => {
      const { uid: id, name } = kit;

      const element: IDesignerPropertyElement = {
        id,
        propertyId: EFilterPropertyKey.kit,
        name,
        inPrice: false,
        inOrder: false,
        inDesign: false,
      };

      return element;
    }) || [];

  const elementsMap: IDesignerPropertyElementMap = reduceToMap<
    IDesignerPropertyElement,
    'id'
  >(elements, 'id');

  const kitProperty: IDesignerProperty = {
    id: EFilterPropertyKey.kit,
    name: EFilterPropertyName.kit,
    type: null,
    isSeparateProductCount: false,
    elements,
    elementsMap,
    inPrice: false,
    inOrder: false,
    inDesign: false,
  };

  return kitProperty;
}

function updateDesignerPropertiesFlags(
  propertiesMap: IDesignerPropertyMap,
  product: IPublicProductWithPrice | null
) {
  if (!product) {
    return;
  }

  const kitProperty = propertiesMap[EFilterPropertyKey.kit];

  if (kitProperty) {
    kitProperty.elements.forEach((el) => {
      el.inPrice = true;
      el.inDesign = !!product.designProducts?.find(
        (dp) => (dp.kitUids || []).indexOf(el.id) > -1
      );
    });

    kitProperty.inPrice = true;
    kitProperty.inDesign = !!kitProperty.elements.find((e) => e.inDesign);
  }

  product.price.properties
    ?.map((p) => p.id)
    .forEach((id) => {
      const p = propertiesMap['' + id];
      p.inPrice = true;
      p.elements.forEach((el) => (el.inPrice = true));
    });

  product.price.propertiesOrder?.forEach((id) => {
    const p = propertiesMap['' + id];
    p.inOrder = true;
    p.elements.forEach((el) => (el.inOrder = true));
  });

  product.designProducts?.forEach((dp) =>
    dp.properties?.forEach((dpp) => {
      const p = propertiesMap['' + dpp.id];
      p.inDesign = true;
      dpp.selectedElements?.forEach(
        (id) => (p.elementsMap['' + id].inDesign = true)
      );
    })
  );
}

function getUniqueElementIdByProperty(
  allElementsMap: IDesignerPropertyElementMap,
  elements: string[]
): string[] {
  const useQueryPropIds: { [propertyId: string]: boolean } = {};

  return [...new Set(elements)].filter((elementId) => {
    const element = allElementsMap[elementId];

    if (!element || useQueryPropIds[element.propertyId]) {
      return false;
    }

    useQueryPropIds[element.propertyId] = true;
    return true;
  });
}

function isValidSelected(data: {
  disabledPriceUids: string[];
  designerElementKeys: IDesignerElementKeys;
  allElementsMap: IDesignerPropertyElementMap;
  selected: string[];
}): boolean {
  const { disabledPriceUids, designerElementKeys, allElementsMap, selected } =
    data;

  const selectedInPrice = sortAsNumber(
    selected
      .map((id) => allElementsMap[id])
      .filter((e) => e.inPrice)
      .map((e) => e.id)
  );

  const canSelectedInPrice =
    (disabledPriceUids || []).indexOf(selectedInPrice.join('.')) < 0;

  const selectedInDesign = selected
    .map((id) => allElementsMap[id])
    .filter((e) => e.inDesign)
    .map((e) => e.id);

  const designerKeys = Object.keys(designerElementKeys) || [];
  const canSelectedInDesign =
    !designerKeys.length ||
    !!designerKeys.find((key) => {
      const elementKeys = designerElementKeys[key];
      return selectedInDesign.every((id) => elementKeys[id]);
    });

  return canSelectedInPrice && canSelectedInDesign;
}

function getFullColor(
  propertiesMap: IDesignerPropertyMap,
  selected: string[],
  designFullColorPropertyId?: number
): string | null {
  if (!designFullColorPropertyId) {
    return null;
  }

  const colorProperty = propertiesMap['' + designFullColorPropertyId];

  if (colorProperty?.inDesign) {
    const findColor = (colorProperty.elements || []).find(
      (e) => (selected || []).indexOf(e.id) > -1
    );

    if (findColor) {
      return findColor.color || null;
    }
  }

  return null;
}

export function getPriceUid(
  designerSource: IDesignerSource | null
): string | null {
  if (!designerSource) {
    return null;
  }

  const { properties, allElementsMap, selected } = designerSource;
  const selectedAll = properties.length === selected.length;

  if (!selectedAll) {
    return null;
  }

  return sortAsNumber(
    selected
      .map((id) => allElementsMap[id])
      .filter((e) => e.inPrice)
      .map((e) => e.id)
  ).join('.');
}

export function getActiveDesignProductIndex(data: {
  designProducts: IDesignProduct[];
  designerElementKeys: IDesignerElementKeys | null;
  designerSource: IDesignerSource | null;
}): number | null {
  const { designProducts, designerElementKeys, designerSource } = data;

  if (!designProducts.length || !designerElementKeys || !designerSource) {
    return null;
  }

  const { properties, selected } = designerSource;
  const selectedAll = properties.length === selected.length;

  if (!selectedAll) {
    return null;
  }

  if (designProducts.length === 1) {
    return 0;
  }

  const dpIndex = designProducts.findIndex((dp) => {
    const keys = designerElementKeys[dp.id];

    const selectedCount = (selected || []).filter((id) => keys[id]).length;

    const propsCount =
      (dp.kitUids?.length ? 1 : 0) + (dp.properties?.length || 0);

    return selectedCount === propsCount;
  });

  return dpIndex > -1 ? dpIndex : null;
}

export function getOrderParams(designerSource: IDesignerSource): {
  designElementsIds: number[];
  orderElementsIds: number[];
} {
  const designElementsIds = (designerSource.selected || [])
    .filter((id) => {
      const el = designerSource.allElementsMap[id];
      return el && el.propertyId !== EFilterPropertyKey.kit && el.inDesign;
    })
    .map((id) => +id);

  const orderElementsIds = (designerSource.selected || [])
    .filter((id) => {
      const el = designerSource.allElementsMap[id];
      return el && el.propertyId !== EFilterPropertyKey.kit && el.inOrder;
    })
    .map((id) => +id);

  return { designElementsIds, orderElementsIds };
}

export function getSumCountUid(designerSource: IDesignerSource | null): string {
  if (!designerSource) {
    return '';
  }

  return sortAsNumber(
    (designerSource.selected || []).filter((id) => {
      const el = designerSource.allElementsMap[id];
      const prop = el ? designerSource.propertiesMap[el.propertyId] : null;
      return prop?.isSeparateProductCount;
    })
  ).join('.');
}
