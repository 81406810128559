import { CommonModule, DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  clearNgAttributes,
  getTextFromUrl,
  stripHtmlComments,
} from '@inaripro-nx/common-ui';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@Component({
  standalone: true,
  imports: [CommonModule, LazyLoadImageModule],
  selector: 'design-card-picture',
  templateUrl: './card-picture.component.html',
  styleUrls: ['./card-picture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardPictureComponent implements OnDestroy {
  @Input() image!: {
    url: string;
    preview?: string;
    height?: number; // original. if preview
    width?: number; // original. if preview
  };
  @Input() canDelete = true;
  @Input() imageToSvg = false;
  @Input() isFigure = false;
  @Input() lazyLoad = true;

  @Output() selectObject: EventEmitter<{
    svg: string;
    bBox: { width: number; height: number };
  }> = new EventEmitter();
  @Output() cardCloseEmit: EventEmitter<void> = new EventEmitter();

  @ViewChild('svgGroup') svgGroup!: ElementRef<SVGGraphicsElement>;

  get imageExt() {
    return (this.image.url.split('.') || []).pop();
  }

  get pasteAsSvg() {
    return this.imageToSvg && this.imageExt === 'svg';
  }

  destroyed = false;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnDestroy(): void {
    this.destroyed = true;
  }

  public clickCardClose(event: MouseEvent): void {
    event.stopPropagation();
    this.cardCloseEmit.emit();
  }

  addObject() {
    if (this.image.preview && this.image.height && this.image.width) {
      const svg = `<g><image xlink:href="${this.image.url}"></image></g>`;
      const { height, width } = this.image;

      this.selectObject.emit({
        svg,
        bBox: {
          height,
          width,
        },
      });

      return;
    }

    const el = this.svgGroup.nativeElement;
    const bBox = el.getBBox();

    const clone = clearNgAttributes(el.cloneNode(true) as Element);
    const image = clone.querySelector('image');

    if (!image) {
      return;
    }

    if (this.pasteAsSvg) {
      getTextFromUrl(this.image.url, (text) => {
        if (!text || this.destroyed) {
          return;
        }

        const div = this.document.createElement('div');
        div.innerHTML = text;

        const svgFile = div.querySelector('svg');

        if (!svgFile) {
          return;
        }

        if (!svgFile.getAttribute('width')) {
          svgFile.setAttribute('width', `${bBox.width}`);
        }

        if (!svgFile.getAttribute('height')) {
          svgFile.setAttribute('height', `${bBox.height}`);
        }

        const svg = svgFile.outerHTML;

        this.selectObject.emit({
          svg,
          bBox,
        });
      });
    } else {
      const svg = stripHtmlComments(clone.innerHTML);

      this.selectObject.emit({
        svg,
        bBox,
      });
    }
  }
}
