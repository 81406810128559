<div
  class="content-fill"
  *ngIf="{
    activeDesignSide: activeDesignSide$ | async,
    activeDesignZone: activeDesignZone$ | async,
    activeDesignZonesWithColor: activeDesignZonesWithColor$ | async
  } as state"
>

  <ng-container
    *ngIf="
      state.activeDesignSide &&
      state.activeDesignZonesWithColor &&
      state.activeDesignZonesWithColor.length
    "
  >
    <ng-container
      *ngIf="{
        zone: state.activeDesignZone || state.activeDesignZonesWithColor[0]
      } as subState"
    >
      <design-select
        class="select"
        *ngIf="state.activeDesignZonesWithColor.length > 1"
        [options]="state.activeDesignZonesWithColor || []"
        [selectedOption]="subState.zone"
        (selectOption)="selectZone(state.activeDesignSide, $event)"
      ></design-select>

      <ng-container *ngIf="'' + subState.zone?.id as zoneKey">
        @if (subState.zone.colorProperty) {
          <painter-content-fill-property [zoneKey]="zoneKey"></painter-content-fill-property>
        } @else if (subState.zone.isOnlyFilling) {
          <painter-content-fill-color [zoneKey]="zoneKey"></painter-content-fill-color>
        } @else {
          <ng-container *ngTemplateOutlet="tabsTemplate"></ng-container>

          @if (activeTab.id === COLOR_TAB_ID) {
            <painter-content-fill-color [zoneKey]="zoneKey"></painter-content-fill-color>
          } @else if (activeTab.id === PATTERN_TAB_ID) {
            <painter-content-fill-pattern [zoneKey]="zoneKey"></painter-content-fill-pattern>
          }
        }
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #tabsTemplate>
  <design-tabs
    class="color-tabs"
    [tabs]="tabs"
    [selectedTabId]="activeTab.id"
    (tabChanged)="activeTab = $event"
  ></design-tabs>
</ng-template>
