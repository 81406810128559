<ng-container *ngIf="{ isDesktop: isDesktop$ | async } as state">
  <painter-main-desktop
    *ngIf="state.isDesktop; else mobileView"
  ></painter-main-desktop>
  <ng-template #mobileView>
    <painter-main-mobile></painter-main-mobile>
  </ng-template>
  <div [hidden]="true">
    <ng-container #export></ng-container>
  </div>

  <painter-modal-ai></painter-modal-ai>
  <painter-modal-reach-file-limit></painter-modal-reach-file-limit>

  <painter-modal-template></painter-modal-template>
  <painter-modal-download-pdf></painter-modal-download-pdf>
  <painter-modal-save-changes></painter-modal-save-changes>
</ng-container>
