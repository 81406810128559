import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { IDesignProduct } from '@inaripro-nx/catalog';
import { StorageService } from '@inaripro-nx/common-ui';
import { EditorProductSidesComponent } from '../editor-product-sides/editor-product-sides.component';
import { ToolButtonComponent } from '@inaripro-nx/design-ui';

const SIDES_GROUP_NAME = 'SIDES_GROUP';

@Component({
  selector: 'painter-editor-sides-control',
  standalone: true,
  templateUrl: './editor-sides-control.component.html',
  styleUrls: ['./editor-sides-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, EditorProductSidesComponent, ToolButtonComponent],
})
export class EditorSidesControlComponent implements OnInit {
  @Input() activeDesignProduct: IDesignProduct | null = null;
  @Input() activeDesignSideIndex: number | null = null;

  @Output() selectSide = new EventEmitter<number>();

  active = false;

  constructor(private readonly storageService: StorageService) {}

  ngOnInit(): void {
    if (!this.storageService.local.getItem(SIDES_GROUP_NAME)) {
      this.active = true;
    }
  }

  onClick() {
    this.active = !this.active;
    if (!this.storageService.local.getItem(SIDES_GROUP_NAME)) {
      this.storageService.local.setItem(SIDES_GROUP_NAME, 'true');
    }
  }
}
