import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DesignUiModule, ModalWindowStore } from '@inaripro-nx/design-ui';

import { AuthService } from '@inaripro-nx/auth';
import {
  IPictureLibrary,
  PipesModule as CommonPipesModule,
} from '@inaripro-nx/common-ui';

import { RouterModule } from '@angular/router';
import { combineLatest, map, Subscription } from 'rxjs';
import { SimplebarAngularModule } from 'simplebar-angular';
import {
  MAX_FILE_IN_LIBRARY_AUTH_USER,
  MAX_FILE_IN_LIBRARY_NOAUTH_USER,
  MAX_SIMULTANEOUS_UPLOADS,
  REACH_FILE_LIMIT_NO_USER_TEXT,
  REACH_FILE_LIMIT_USER_TEXT,
} from '../../constants';
import { MODAL_AI_UID, MODAL_REACH_FILE_LIMIT } from '../../components';
import { PicturesLibraryService, PicturesService } from '../../services';

@Component({
  selector: 'pictures-content-pictures',
  standalone: true,
  imports: [
    CommonModule,
    DesignUiModule,
    CommonPipesModule,
    SimplebarAngularModule,
    RouterModule,
  ],
  templateUrl: './content-pictures.component.html',
  styleUrls: ['./content-pictures.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentPicturesComponent implements OnInit, OnDestroy {
  @Input() isDesktop = true;

  @Output() selectObject: EventEmitter<{
    svg: string;
    url: string;
    bBox: { width: number; height: number };
  }> = new EventEmitter();

  readonly REACH_FILE_LIMIT_USER_TEXT = REACH_FILE_LIMIT_USER_TEXT;
  readonly REACH_FILE_LIMIT_NO_USER_TEXT = REACH_FILE_LIMIT_NO_USER_TEXT;

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  readonly userPictures$ = this.picturesService.userPictures$;
  readonly user$ = this.authService.user$;
  readonly isLimitFileReach$ = combineLatest([
    this.userPictures$,
    this.user$,
  ]).pipe(
    map(
      ([userPictures, user]) =>
        userPictures!.length >=
        (user ? MAX_FILE_IN_LIBRARY_AUTH_USER : MAX_FILE_IN_LIBRARY_NOAUTH_USER)
    )
  );

  maxFiles = 0;
  readonly maxFileSize = 30 * 1024 * 1024;
  readonly maxSimultaneousUploads = MAX_SIMULTANEOUS_UPLOADS;

  constructor(
    private readonly modalWindowStore: ModalWindowStore,
    public picturesService: PicturesService,
    public picturesLibraryService: PicturesLibraryService,
    public authService: AuthService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subs = combineLatest([this.user$, this.userPictures$])
      .pipe(
        map(([user, pictures]) => {
          let freeBufferPictures = 0;
          const picturesLength = (pictures || []).length;

          const maxFile = user
            ? MAX_FILE_IN_LIBRARY_AUTH_USER
            : MAX_FILE_IN_LIBRARY_NOAUTH_USER;

          freeBufferPictures = maxFile - picturesLength;

          freeBufferPictures = freeBufferPictures < 0 ? 0 : freeBufferPictures;

          return freeBufferPictures;
        })
      )
      .subscribe((value) => {
        this.maxFiles = value;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }

  onDeleteObject(id: number) {
    this.picturesService.deletePicture(id);
  }

  public selectPictureLibrary(item: unknown): void {
    this.picturesLibraryService.getPictures((item as IPictureLibrary).id);
  }

  openGenerateForm() {
    this.modalWindowStore.patch({ [MODAL_AI_UID]: true });
  }

  openLimitErrorModal() {
    if (this.isDesktop) {
      return;
    }

    this.modalWindowStore.patch({ [MODAL_REACH_FILE_LIMIT]: true });
  }
}
