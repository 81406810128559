<div class="card-template">
  <div class="card-template__card">
    <div
      class="card-template__card-close"
      (click)="remove.emit()"
      *ngIf="isShowRemove"
    >
      <i class="icon icon-ic_cross-close"></i>
    </div>
    <div class="card-template__card-checkbox" *ngIf="isShowActive">
      <input
        type="checkbox"
        [ngModel]="template.isActive"
        (change)="toggleActive.emit(!template.isActive)"
      />
    </div>
    <img [src]="template.cover?.url" (click)="selectTemplate.emit()" />
  </div>
  <div class="card-template__description">
    <p
      class="template-name"
      *ngIf="{ show: false, name: template.name } as edit"
    >
      <ng-container *ngIf="!edit.show; else inputEdit">
        <span>{{ edit.name }}</span>
        <i
          class="icon icon-ic_edit"
          *ngIf="isShowEdit"
          (click)="edit.show = true"
        ></i>
      </ng-container>
      <ng-template #inputEdit>
        <design-form-input-string
          [fControl]="templateForm.controls['name']"
          [classWrapper]="'short-view'"
          (keydownEnter)="
            edit.show = false;
            templateForm.valid && (edit.name = templateForm.value.name);
            templateForm.valid && editName.emit(templateForm.value.name)
          "
          (keydownEsc)="edit.show = false"
          (blurInputEvent)="
            edit.show = false;
            templateForm.valid && (edit.name = templateForm.value.name);
            templateForm.valid && editName.emit(templateForm.value.name)
          "
        ></design-form-input-string>
      </ng-template>
    </p>
    <p class="template-time">
      {{ template.updateTime | date : 'dd.MM.y, HH:mm' }}
    </p>
  </div>
</div>
