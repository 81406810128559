import { Pipe, PipeTransform } from '@angular/core';
import { PercentPipe } from '@angular/common';

@Pipe({
  name: 'percentNoSpace',
})
export class PercentNoSpacePipe implements PipeTransform {
  private percentPipe: PercentPipe;

  constructor() {
    this.percentPipe = new PercentPipe('en-US');
  }

  transform(value: number | null): string {
    if (value === null || value === undefined) {
      return '';
    }

    const formattedValue = this.percentPipe.transform(value, '1.0-0');
    return formattedValue
      ? formattedValue.replace(/\s/g, '').replace(/,/g, '')
      : '';
  }
}
