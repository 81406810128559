<svg:g>
  @if (el.textArc && el.textArc.angle) {
    <defs>
      <path
        [attr.id]="'textCircle' + index"
        [attr.d]="el.textArc | svgCirclePath"
      />
    </defs>
    <text
      *ngIf="inAction"
      x="0"
      y="0"
      [attr.font-style]="el.fontStyle"
      [attr.font-weight]="el.fontWeight"
      [attr.font-family]="el.fontFamily | fontFamily"
      [attr.font-size]="DEFAULT_TEXT_SIZE"
      [attr.letter-spacing]="el.letterSpacing"
      [attr.fill]="'inherit'"
      [attr.transform]="textTranslate | svgTranslate"
    >
      <textPath [attr.href]="'#textCircle' + index">
        {{ el.text }}
      </textPath>
    </text>
    <g
      *ngIf="!inAction && !!chars"
      [attr.font-style]="el.fontStyle"
      [attr.font-weight]="el.fontWeight"
      [attr.font-family]="el.fontFamily | fontFamily"
      [attr.font-size]="DEFAULT_TEXT_SIZE"
      [attr.letter-spacing]="el.letterSpacing"
      [attr.fill]="'inherit'"
      [attr.transform]="textTranslate | svgTranslate"
    >
      <text
        *ngFor="let char of chars"
        [attr.x]="char.point.x"
        [attr.y]="char.point.y"
        [attr.transform]="char | svgRotate"
      >
        <tspan>{{ char.letter }}</tspan>
      </text>
    </g>
  }
</svg:g>
