import { IDesignProduct } from '@inaripro-nx/catalog';
import { IFileResponse, IMapOfString, IPattern } from '@inaripro-nx/common-ui';
import {
  IElements,
  IMapXY,
} from '../components/main/interfaces/editor.interface';
import { IFile } from '@inaripro-nx/design-ui';

export const VERSION_START = '0.0002';
export const VERSION_REVERSE_FILL_OPACITY = '1.0';
export const VERSION_IMAGE_URL = '1.5';
export const VERSION_IMAGE_SIZES = '2.1';
export const VERSION_IMAGE_EXT = '2.2';
export const CURRENT_TEMPLATE_VERSION = VERSION_IMAGE_EXT;

export type ITemplateType = 'product' | 'my';

export interface IShareTemplate {
  uid: string;
  json: ITemplateJson;
  productId: number;
  version: string;
}

export interface IShareResponse {
  uid: string;
}

export interface ITemplate {
  id: number;
  name: string;
  json: ITemplateJson;
  cover: IFile | null;
  updateTime: string;
  productId: number;
  version: string;
  markForDelete?: boolean; // on front
}

export interface IProductTemplate extends ITemplate {
  isActive: boolean;
}

export interface ITemplateJson {
  activeDesignProduct: IDesignProduct | null;
  elements: IElements;
  zoneColors: IMapOfString | null;
  zonePatterns: { [key: string]: IPattern } | null;
  zoneTranslates: IMapXY | null | undefined;
}
