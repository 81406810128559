import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { Observable } from 'rxjs';
import { IElement } from '../../../../interfaces/editor.interface';
import { ELEMENT_FILL_MODAL_UID } from '../../../../interfaces/main.interface';
import { ActionsTextService } from '../../../../services/actions-text/actions-text.service';
import { EditorService } from '../../../../services/editor/editor.service';
import { EyeDropperService } from '../../../../services/eye-dropper/eye-dropper.service';
import { WindowToolsService } from '../../../../services/window-tools/window-tools.service';
import { EColorTitle } from '../../../../../../constants';
import { HistoryStore } from '../../../../state/history/history.store';
import { ELEMENT_COLOR_DEFAULT } from '@inaripro-nx/common-ui';

@Component({
  selector: 'painter-actions-element-fill',
  standalone: true,
  imports: [CommonModule, DesignUiModule],
  templateUrl: './actions-element-fill.component.html',
  styleUrls: ['./actions-element-fill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsElementFillComponent {
  @Input() element!: IElement;
  @Input() withModal = false;
  @Input() modalTitle = EColorTitle.COLOR_DEFAULT;
  @Input() maxWidthModal!: number;
  @Input() prefix!: string;

  readonly ELEMENT_FILL_MODAL_UID = ELEMENT_FILL_MODAL_UID;
  readonly ELEMENT_COLOR_DEFAULT = ELEMENT_COLOR_DEFAULT;

  isDesktop$: Observable<boolean> = this.windowToolsService.isDesktop$;
  readonly useColors$ = this.historyStore.useColors$;

  constructor(
    public editorService: EditorService,
    public eyeDropperService: EyeDropperService,
    private windowToolsService: WindowToolsService,
    public actionsTextService: ActionsTextService,
    private readonly historyStore: HistoryStore
  ) {}
}
