@for (figure of figureList; track figure.id) {
  <div
    class="list-item"
    [class.list-item--icon]="figure.icon"
    [class.list-item--rectangle]="figure.rectangle"
    [class.list-item--image]="figure.image"
    [class.list-item--active]="selectedFigureId === figure.id"
    (click)="onClick($event, figure)"
  >
    @if (figure.icon) {
      <ng-container [ngTemplateOutlet]="iconFigure" [ngTemplateOutletContext]="{ figure: figure }"></ng-container>
    }

    @if (figure.rectangle) {
      <ng-container [ngTemplateOutlet]="rectangleFigure" [ngTemplateOutletContext]="{ figure: figure }"></ng-container>
    }

    @if (figure.image) {
      <ng-container [ngTemplateOutlet]="imageFigure" [ngTemplateOutletContext]="{ figure: figure }"></ng-container>
    }
  </div>
}

<ng-template #iconFigure let-figure="figure">
  <i class="icon" [ngClass]="figure.icon"></i>
  <span class="list-item__name">{{ figure.name }}</span>
</ng-template>

<ng-template #rectangleFigure let-figure="figure">
    <div
      class="list-item__rectangle"
      [style.width.px]="figure.rectangle?.width"
      [style.height.px]="figure.rectangle?.height"
    ></div>
    <span class="list-item__name">{{ figure.name }}</span>
</ng-template>

<ng-template #imageFigure let-figure="figure">
  <img
    class="list-item__image"
    [ngSrc]="figure.image.url"
    [width]="74"
    [height]="74"
    [alt]="figure.image.name"
    loading="lazy"
  />
</ng-template>
