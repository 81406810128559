@if (!productList) {
  <div class="loading"><ng-content></ng-content></div>
} @else {
  @if (!productList.length) {
    <catalog-product-list-not-found
      [appliedFiltersTemplate]="appliedFiltersTemplate"
      (resetFilterValues)="resetFilterValues.emit()"
    ></catalog-product-list-not-found>
  } @else {
    <div class="grid">
      @for (product of productList; track product.id) {
        <catalog-product-card
          class="grid__cell"
          [product]="product"
          [user]="user$ | async"
          [publicBaseUrl]="publicBaseUrl"
          [isSelected]="product.id === selectedProductId"
          (click)="selectProduct.emit({product, filterValues, range})"
        ></catalog-product-card>
      }

      @if (banners) {
        @for (banner of banners; track banner.id; let i = $index) {
          <design-banner class="grid__banner" [banner]="banner" [attr.data-index]="i"></design-banner>
        }
      }
    </div>
  }
}
