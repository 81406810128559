<svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  preserveAspectRatio="xMidYMid meet"
  [attr.viewBox]="side.sizePX | viewbox"
  [attr.width]="side.sizeMM.x + 'mm'"
  [attr.height]="side.sizeMM.y + 'mm'"
  *ngIf="{
    zones: side.zones | filterBy: ['isOnlyFilling']: false,
    colorZones: side.zones | filterBy: ['isOnlyFilling']: true
  } as state"
>
    <!-- Рабочая область редактора -->
  <defs *ngIf="fontFaces.length">
    <style
      *ngFor="let ff of fontFaces"
      [innerHTML]="ff"
    ></style>
  </defs>

    <defs>
      <clipPath id="mask1">
        <rect
          *ngFor="let zone of state.zones"
          [attr.x]="zone.start?.x"
          [attr.y]="zone.start?.y"
          [attr.width]="zone.size?.x"
          [attr.height]="zone.size?.y"
          [attr.transform]="zoneTranslates['' + zone.id] | svgTranslate"
        />
      </clipPath>
    </defs>

    <!-- Область фоновой заливки -->
    <rect
      x="0"
      y="0"
      width="100%"
      height="100%"
      [attr.fill]="fullColor || BACKGROUND_COLOR_DEFAULT"
    />

    <!-- Мокап -->
    <image
      *ngIf="side.mockupType === EMockupType.down"
      [attr.xlink:href]="side.image.url"
      x="0"
      y="0"
      [attr.width]="side.sizePX.x"
      [attr.height]="side.sizePX.y"
    ></image>

    <!-- Фон области нанесения -->
    <g
      data-painter-editor-zone-paint
      *ngFor="let zone of state.zones"
      [attr.transform]="zoneTranslates['' + zone.id] | svgTranslate"
      [zone]="zone"
      [zoneColor]="zoneColors['' + zone.id]"
      [zonePattern]="zonePatterns['' + zone.id]"
    ></g>

    <!-- Объекты дизайна -->
    <g clip-path="url(#mask1)">
      <g [attr.transform]="zone0Center | svgTranslate">
        <ng-container *ngFor="let el of elements; let i = index">
          <ng-container *ngIf="el.sideIndex === sideIndex">
            <g data-painter-editor-element-transform [el]="el">
              <g data-painter-editor-element-content [el]="el" [index]="i" [filter]="el.filterId && filters[el.filterId]"></g>
            </g>
          </ng-container>
        </ng-container>
      </g>
    </g>

    <!-- Фон области заливки -->
    <g
      data-painter-editor-zone-paint
      *ngFor="let zone of state.colorZones"
      [zone]="zone"
      [zoneColor]="zoneColors['' + zone.id]"
      [zonePattern]="zonePatterns['' + zone.id]"
    ></g>

    <!-- Мокап -->
    <image
      *ngIf="side.mockupType === EMockupType.up"
      [attr.xlink:href]="side.image.url"
      x="0"
      y="0"
      [attr.width]="side.sizePX.x"
      [attr.height]="side.sizePX.y"
    ></image>

    <!-- Область нанесения -->
    <g *ngIf="!side._isFullPrint">
      <g *ngFor="let zone of state.zones"
         [attr.transform]="zoneTranslates['' + zone.id] | svgTranslate">
        <rect
          [attr.x]="zone.start?.x"
          [attr.y]="zone.start?.y"
          [attr.width]="zone.size?.x"
          [attr.height]="zone.size?.y"
          style="
            fill: none;
            stroke: #9e9e9e;
            stroke-width: 1px;
            vector-effect: non-scaling-stroke;
          "
        />
        <rect
          [attr.x]="zone.start?.x"
          [attr.y]="zone.start?.y"
          [attr.width]="zone.size?.x"
          [attr.height]="zone.size?.y"
          style="
            fill: none;
            stroke: #ededed;
            stroke-width: 1px;
            stroke-dasharray: 3 3;
            vector-effect: non-scaling-stroke;
          "
        />
      </g>
    </g>
</svg>
