<div class="actions-text">
  <ng-container *ngTemplateOutlet="textSVG"></ng-container>

  @if (isDesktop) {
  <div class="actions-text-desktop">
    <div class="actions-text__group actions-text__group--desktop">
      <ng-container
        *ngTemplateOutlet="modification; context: { mode: 'desktop' }"
      ></ng-container>
    </div>
    <div class="actions-text__group actions-text__group--desktop">
      <ng-container *ngTemplateOutlet="inputstext"></ng-container>
    </div>
    <div class="actions-text__group actions-text__group--desktop">
      <ng-container *ngTemplateOutlet="rangeSize"></ng-container>
      <ng-container *ngTemplateOutlet="rangeTurn"></ng-container>
    </div>
    <div class="actions-text__group actions-text__group--desktop">
      <ng-container *ngTemplateOutlet="fontSelector"></ng-container>
    </div>
    <div class="actions-text__group actions-text__group--desktop">
      <ng-container *ngTemplateOutlet="text"></ng-container>
    </div>
    <div class="actions-text__group actions-text__group--desktop">
      <ng-container
        *ngTemplateOutlet="colorBox; context: { withModal: false }"
      ></ng-container>
    </div>
    <div class="actions-text__group actions-text__group--desktop">
      <ng-container
        *ngTemplateOutlet="rangeStroke; context: { withModal: false }"
      ></ng-container>
    </div>
  </div>
  } @else {
  <div
    class="actions-text-mobile"
    [class.actions-text-mobile-move]="!isShowActionsSubmenu"
  >
    <ng-content></ng-content>
    <div
      class="tabs"
      [class.tabs-move]="!isShowActionsSubmenu"
      [class.tabs-text-active-trasformations]="
        subRow === ESubRow.trasformations
      "
      [class.tabs-text-active-text]="subRow === ESubRow.text"
      [class.tabs-text-active-font]="subRow === ESubRow.font"
    >
      <div
        class="tabs-subrow"
        [class.tabs-subrow-text-active-trasformations]="
          subRow === ESubRow.trasformations
        "
        [class.tabs-subrow-text-active-text]="subRow === ESubRow.text"
        [class.tabs-subrow-text-active-font]="subRow === ESubRow.font"
      >
        <div class="actions-forms" *ngIf="subRow === ESubRow.trasformations">
          <div class="actions-form-w50 actions-form-hide-mobile">
            <div class="actions-text__group actions-text__group--mobile">
              <ng-container *ngTemplateOutlet="rangeSize"></ng-container>
              <ng-container *ngTemplateOutlet="rangeTurn"></ng-container>
            </div>
          </div>
          <div class="actions-form-w50">
            <div class="actions-text__group actions-text__group--mobile">
              <ng-container
                *ngTemplateOutlet="modification; context: { mode: 'mobile' }"
              ></ng-container>
            </div>
          </div>
        </div>
        <div class="actions-forms" *ngIf="subRow === ESubRow.text">
          <div class="actions-text__group actions-text__group--mobile">
            <ng-container *ngTemplateOutlet="text"></ng-container>
          </div>
        </div>
        <div class="actions-forms" *ngIf="subRow === ESubRow.font">
          <div class="actions-text__group actions-text__group--mobile">
            <div class="wrapper-select-font-panel">
              <div class="wrapper-select-font-panel__select">
                <ng-container
                  *ngTemplateOutlet="
                    fontSelector;
                    context: { dropdownDirection: DropdownDirection.UP }
                  "
                ></ng-container>
              </div>
              <div class="wrapper-select-font-panel__colorbox">
                <ng-container
                  *ngTemplateOutlet="colorBox; context: { withModal: true }"
                ></ng-container>
              </div>
            </div>
            <div class="wrapper-select-font-panel wrapper-select-font-panel--stroke">
              <div class="wrapper-select-font-panel__select">
                <ng-container
                  *ngTemplateOutlet="rangeStroke"
                ></ng-container>
              </div>
              <div class="wrapper-select-font-panel__select">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tabs-row">
        <div
          class="tab"
          [class.tab-active]="subRow === ESubRow.trasformations"
          (click)="toggleSubRow(ESubRow.trasformations)"
        >
          <i class="icon icon-ic_transformation"></i>
          <span>Трансформация</span>
        </div>
        <div
          class="tab"
          [class.tab-active]="subRow === ESubRow.text"
          (click)="toggleSubRow(ESubRow.text)"
        >
          <i class="icon icon-ic_text"></i>
          <span>Текст</span>
        </div>
        <div
          class="tab"
          [class.tab-active]="subRow === ESubRow.font"
          (click)="toggleSubRow(ESubRow.font)"
        >
          <i class="icon icon-ic_brush"></i>
          <span>Шрифт</span>
        </div>
      </div>
    </div>
  </div>
  }
</div>

<ng-template #textSVG>
  <svg
    *ngIf="textChanges"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style="width: 0; height: 0; position: absolute"
  >
    <g #svgGroupMultiLine>
      <text
        x="0"
        y="0"
        dy="0"
        [attr.font-size]="DEFAULT_TEXT_SIZE"
        [attr.letter-spacing]="textChanges.letterSpacing"
        [attr.font-style]="textChanges.fontStyle"
        [attr.font-weight]="textChanges.fontWeight"
        [attr.font-family]="textChanges.fontFamily | fontFamily"
      >
        <tspan
          *ngFor="let line of textChanges.text.split('\n'); let i = index"
          x="0"
          [attr.dy]="(i === 0 ? 1 : textChanges.lineSpace) + 'em'"
          [innerHTML]="line || '.' | safeHtml"
          [attr.stroke-width]="line ? 'inherit' : 0"
          [attr.fill]="line ? 'inherit' : 'none'"
        ></tspan>
      </text>
    </g>
    <g #svgGroupSingleLine>
      <text
        x="0"
        y="0"
        dy="0"
        [attr.font-size]="DEFAULT_TEXT_SIZE"
        [attr.letter-spacing]="textChanges.letterSpacing"
        [attr.font-style]="textChanges.fontStyle"
        [attr.font-weight]="textChanges.fontWeight"
        [attr.font-family]="textChanges.fontFamily | fontFamily"
      >
        <tspan
          x="0"
          [innerHTML]="textChanges.text | safeHtml"
        ></tspan>
      </text>
    </g>
    @if (textChanges && textChanges.textArc && textChanges.textArc.angle) {
      <g
        #svgGroupTextArc
        data-painter-editor-element-text-arc
        [el]="textChanges"
        [index]="'action'"
        [inAction]="true"
      ></g>
    }
  </svg>
</ng-template>

<ng-template #modification let-mode="mode">
  <painter-actions-text-modification
    [textElement]="textElement"
    [mode]="mode"
    (toggleFontWeight)="toggleFontWeight()"
    (toggleFontStyle)="toggleFontStyle()"
    (centerElement)="centerElement.emit($event)"
    (changeTextAnchor)="changeTextAnchor($event)"
    (changeLineSpace)="changeLineSpace($event)"
    (changeLetterSpacing)="changeLetterSpacing($event)"
    (stopChangeLetterSpacing)="stopChangeLetterSpacing()"
    (changeTextArc)="changeTextArc($event)"
    (stopChangeTextArc)="stopChangeTextArc()"
  ></painter-actions-text-modification>
</ng-template>

<ng-template #inputstext>
  <painter-actions-element-translate
    [element]="element"
  ></painter-actions-element-translate>
</ng-template>

<ng-template #rangeSize>
  <painter-actions-text-scale
    [element]="element"
    [designSide]="designSide"
  ></painter-actions-text-scale>
</ng-template>

<ng-template #rangeTurn>
  <painter-actions-text-rotate
    [element]="element"
  ></painter-actions-text-rotate>
</ng-template>

<ng-template #text>
  <textarea
    class="g-textarea"
    autocomplete="off"
    maxlength="1000"
    [value]="element.text"
    (input)="inputEvent$.next($event)"
    (blur)="blurEmit()"
  ></textarea>
</ng-template>

<ng-template #fontSelector let-dropdownDirection="dropdownDirection">
  <painter-actions-text-font-select
    [textElement]="textElement"
    [dropdownDirection]="dropdownDirection"
    (changeFontFamily)="changeFontFamily($event)"
  ></painter-actions-text-font-select>
</ng-template>

<ng-template #colorBox let-withModal="withModal">
  <div class="wrapper-select-color-panel">
    <div class="wrapper-color-panel">
      <painter-actions-element-fill
        [element]="element"
        [withModal]="withModal"
        [modalTitle]="EColorTitle.COLOR_TEXT"
        [maxWidthModal]="302"
        [prefix]="'color-picker-text'"
      >
        <ng-container *ngTemplateOutlet="fillOpacity"></ng-container>
      </painter-actions-element-fill>
    </div>
  </div>
</ng-template>

<ng-template #rangeStroke>
  <painter-actions-element-stroke
    [element]="element"
    [minValue]="MIN_VALUE_TEXT_STROKE_WIDTH"
    [maxValue]="MAX_VALUE_TEXT_STROKE_WIDTH"
    [modalUid]="MODAL_STROKE_TEXT_UID"
    [modificator]="'stroke-text'"
    [prefix]="'modal-stroke-text'"
  >
      <ng-container *ngTemplateOutlet="strokeOpacity"></ng-container>
  </painter-actions-element-stroke>
</ng-template>

<ng-template #fillOpacity>
  <div class="wrapper-fillopacity">
    <div class="wrapper-fillopacity__range">
      <painter-actions-element-fill-opacity
        [element]="element"
      ></painter-actions-element-fill-opacity>
    </div>
  </div>
</ng-template>

<ng-template #strokeOpacity>
  <div class="wrapper-stroke-opacity">
      <painter-actions-element-stroke-opacity
        [element]="element"
      ></painter-actions-element-stroke-opacity>
  </div>
</ng-template>
