import { Injectable } from '@angular/core';
import { linkToGlobalState } from '@inaripro-nx/common-ui';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import {
  combineLatest,
  EMPTY,
  exhaustMap,
  filter,
  Observable,
  switchMap,
} from 'rxjs';
import {
  CatalogNomenclatureDictionaryRecords,
  ICatalogNomenclatureListItem,
  ICatalogNomenclatureSeoListItem,
  ICatalogNomenclatureTreeItem,
  ICategory,
  IMapOfTreeItem,
} from '../interfaces';
import { CatalogNomenclatureService } from '../services';
import { arrayToTree } from '../utils';

export const SEO_LINK_PREFIX = 1000000;
export const SEO_DICTIONARY_PREFIX = 2000000;

export interface CatalogNomenclatureState {
  dictionary: CatalogNomenclatureDictionaryRecords | null;
  listItems: ICatalogNomenclatureListItem[] | null;
  treeItems: ICatalogNomenclatureTreeItem[] | null;
  seoItems: ICatalogNomenclatureSeoListItem[] | null;
  listItemsNoLink: ICatalogNomenclatureListItem[] | null;
  treeItemsNoLink: ICatalogNomenclatureTreeItem[] | null;
  treeItem: ICategory | null;
  childParent: IMapOfTreeItem | null;
  childParentByDictionary: IMapOfTreeItem | null;
  childParentBySlug: IMapOfTreeItem | null;
}

const initialState: CatalogNomenclatureState = {
  dictionary: null,
  listItems: null,
  treeItems: null,
  seoItems: null,
  listItemsNoLink: null,
  treeItemsNoLink: null,
  treeItem: null,
  childParent: null,
  childParentByDictionary: null,
  childParentBySlug: null,
};

@Injectable({
  providedIn: 'root',
})
export class CatalogNomenclatureStore extends ComponentStore<CatalogNomenclatureState> {
  readonly dictionary$ = this.select((state) => state.dictionary);
  readonly listItems$ = this.select((state) => state.listItems);
  readonly treeItems$ = this.select((state) => state.treeItems);
  readonly seoItems$ = this.select((state) => state.seoItems);
  readonly listItemsNoLink$ = this.select((state) => state.listItemsNoLink);
  readonly treeItemsNoLink$ = this.select((state) => state.treeItemsNoLink);
  readonly treeItem$ = this.select((state) => state.treeItem);
  readonly childParent$ = this.select((state) => state.childParent);
  readonly childParentByDictionary$ = this.select(
    (state) => state.childParentByDictionary
  );
  readonly childParentBySlug$ = this.select((state) => state.childParentBySlug);

  private readonly loadAll = this.effect((trigger$) =>
    trigger$.pipe(
      exhaustMap(() => {
        return combineLatest([
          this.catalogNomenclatureService.getNomenclatureDictionary(),
          this.catalogNomenclatureService.getNomenclatureList(),
          this.catalogNomenclatureService.getNomenclatureSeoList(),
          this.catalogNomenclatureService.getNomenclatureNoLinkList(),
        ]).pipe(
          filter(
            ([dictionaryItems, listItems, seoItems, listItemsNoLink]) =>
              !!dictionaryItems &&
              !!listItems &&
              !!seoItems &&
              !!listItemsNoLink
          ),
          tapResponse(
            ([dictionaryItems, listItems, seoItems, listItemsNoLink]) => {
              const nomenclatureDictionary: CatalogNomenclatureDictionaryRecords =
                {};

              dictionaryItems.forEach((item) => {
                nomenclatureDictionary[item.id] = item;
              });

              const seoDictionary: CatalogNomenclatureDictionaryRecords = {};

              const seoListItems: ICatalogNomenclatureListItem[] = seoItems.map(
                (seoCategory) => {
                  seoDictionary[SEO_DICTIONARY_PREFIX + seoCategory.id] = {
                    id: SEO_DICTIONARY_PREFIX + seoCategory.id,
                    isActive: true,
                    isPublish: true,
                    name: seoCategory.name,
                    crashed: false,
                    isPartner: false,
                    logo: null,
                    headTitle: seoCategory.headTitle,
                  };

                  const listItem: ICatalogNomenclatureListItem | undefined =
                    listItems.find(
                      (item) => item.dictionaryId === seoCategory.parentId
                    );

                  const parentId = listItem
                    ? [...listItem.parentId, listItem.id]
                    : [seoCategory.parentId];

                  return {
                    id: SEO_LINK_PREFIX + seoCategory.id,
                    parentId: parentId,
                    dictionaryId: SEO_DICTIONARY_PREFIX + seoCategory.id,
                    editable: false,
                    useAmount: null,
                    dictionarySlug: seoCategory.slug,
                    level: parentId.length + 1,
                    position: 1,
                    canAddProduct: true,
                    amount: null,
                    hasProductDesign: false,
                  };
                }
              );

              const dictionary = {
                ...nomenclatureDictionary,
                ...seoDictionary,
              };
              this.setDictionary(dictionary);

              const listItemsWithSeo = [...listItems, ...seoListItems];

              const childParent: IMapOfTreeItem = {};
              const childParentByDictionary: IMapOfTreeItem = {};
              const childParentBySlug: IMapOfTreeItem = {};

              const treeItems = this.listToTreeItems(
                listItemsWithSeo,
                dictionary
              );

              const treeItemsNoLink = this.listToTreeItems(
                listItemsNoLink,
                dictionary,
                (tree) => {
                  childParent[tree.id.toString()] = tree;
                  childParentByDictionary[tree.dictionaryId.toString()] = tree;
                  childParentBySlug[tree.dictionarySlug] = tree;
                }
              );

              this.setTreeItems(treeItems);
              this.setListItems(listItems);
              this.setSeoItems(seoItems);
              this.setListItemsNoLink(listItemsNoLink);
              this.setTreeItemsNoLink(treeItemsNoLink);
              this.setChildParent(childParent);
              this.setChildParentByDictionary(childParentByDictionary);
              this.setChildParentBySlug(childParentBySlug);
            },
            () => EMPTY
          )
        );
      })
    )
  );

  readonly loadTreeItem = this.effect((trigger$: Observable<number>) =>
    trigger$.pipe(
      switchMap((id) => {
        this.setTreeItem(null);
        return this.catalogNomenclatureService.getNomenclature(id).pipe(
          tapResponse(
            (treeItem) => this.setTreeItem(treeItem),
            () => EMPTY
          )
        );
      })
    )
  );

  private readonly setDictionary = this.updater(
    (state, payload: CatalogNomenclatureDictionaryRecords) => ({
      ...state,
      dictionary: payload,
    })
  );

  private readonly setListItems = this.updater(
    (state, payload: ICatalogNomenclatureListItem[]) => ({
      ...state,
      listItems: payload,
    })
  );

  private readonly setTreeItems = this.updater(
    (state, payload: ICatalogNomenclatureTreeItem[]) => ({
      ...state,
      treeItems: payload,
    })
  );

  private readonly setSeoItems = this.updater(
    (state, payload: ICatalogNomenclatureSeoListItem[]) => ({
      ...state,
      seoItems: payload,
    })
  );

  private readonly setListItemsNoLink = this.updater(
    (state, payload: ICatalogNomenclatureListItem[]) => ({
      ...state,
      listItemsNoLink: payload,
    })
  );

  private readonly setTreeItemsNoLink = this.updater(
    (state, payload: ICatalogNomenclatureTreeItem[]) => ({
      ...state,
      treeItemsNoLink: payload,
    })
  );

  private readonly setTreeItem = this.updater(
    (state, payload: ICategory | null) => ({
      ...state,
      treeItem: payload,
    })
  );

  private readonly setChildParent = this.updater(
    (state, payload: IMapOfTreeItem) => ({
      ...state,
      childParent: payload,
    })
  );

  private readonly setChildParentByDictionary = this.updater(
    (state, payload: IMapOfTreeItem) => ({
      ...state,
      childParentByDictionary: payload,
    })
  );

  private readonly setChildParentBySlug = this.updater(
    (state, payload: IMapOfTreeItem) => ({
      ...state,
      childParentBySlug: payload,
    })
  );

  init() {
    this.loadAll();
  }

  private listToTreeItems(
    listItems: ICatalogNomenclatureListItem[],
    dictionary: CatalogNomenclatureDictionaryRecords,
    callback?: (child: ICatalogNomenclatureTreeItem) => void
  ): ICatalogNomenclatureTreeItem[] {
    return listItems
      .filter((item) => item.level === 1)
      .map((parent) => {
        const object: ICatalogNomenclatureTreeItem = {
          ...parent,
          children: [],
        };

        object.children = arrayToTree(
          listItems,
          parent.id,
          object,
          dictionary,
          callback
        );

        callback?.(object);

        return object;
      });
  }

  constructor(
    private readonly catalogNomenclatureService: CatalogNomenclatureService,
    private readonly globalStore: Store
  ) {
    super({ ...initialState });

    linkToGlobalState(
      this.state$,
      'libs/catalog/nomenclature',
      this.globalStore
    );

    this.init();
  }
}
