export enum ProductTypeEnum {
  BASE_PRODUCT = 'BASE_PRODUCT',
  DESIGN = 'DESIGN',
  EXAMPLE = 'EXAMPLE',
  ALL = 'ALL',
}

export enum ProductCardTypeEnum {
  PRODUCT = 'PRODUCT',
  CALCULATOR = 'CALCULATOR',
}

export enum ProductPriceTypeEnum {
  BY_COUNT = 'BY_COUNT',
  BY_PROPERTY = 'BY_PROPERTY',
  BY_KIT = 'BY_KIT',
}

export enum ProductCalcModeTypeEnum {
  NATURAL = 'NATURAL',
  PERCENT = 'PERCENT',
}
