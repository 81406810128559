import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  delay,
  fromEvent,
  interval,
  Observable,
  of,
  pairwise,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import { IWindowSize, WINDOW_SIZE } from '@inaripro-nx/common-ui';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

export enum EArrowAction {
  STEPNEXTSLIDER = 'stepNextSlider',
  STEPPREVSLIDER = 'stepPrevSlider',
}

@Component({
  selector: 'design-invisible-vertically-scroll',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './design-invisible-vertically-scroll.component.html',
  styleUrl: './design-invisible-vertically-scroll.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignInvisibleVerticallyScrollComponent
  implements AfterViewInit, OnDestroy
{
  @Input() deltaMouseMove = 5;
  @Input() deltaClick = 20;
  @Input() deltaWheel = 10;

  @ViewChild('invisibleVerticallyScroll', { static: false })
  invisibleVerticallyScroll?: ElementRef<HTMLDivElement>;
  @ViewChild('arrowUp', { static: false })
  arrowUp?: ElementRef<HTMLDivElement>;
  @ViewChild('arrowDown', { static: false })
  arrowDown?: ElementRef<HTMLDivElement>;

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  isActiveArrowDown = false;
  isActiveArrowUp = false;

  DELAY_LONG_CLICK = 200;

  constructor(
    private cdr: ChangeDetectorRef,
    @Inject(WINDOW_SIZE) private readonly windowSize$: Observable<IWindowSize>
  ) {}

  ngAfterViewInit() {
    if (this.invisibleVerticallyScroll) {
      this.initScrollActions(this.invisibleVerticallyScroll);

      this.initWheelActions(this.invisibleVerticallyScroll);
      this.initMouseActions(this.invisibleVerticallyScroll);

      this.initArrowActions(this.invisibleVerticallyScroll);
      this.initResizeActions(this.invisibleVerticallyScroll);
      this.initTouchActions(this.invisibleVerticallyScroll);
    }
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }

  private initScrollActions(elementRef: ElementRef) {
    this.subs = fromEvent<Event>(elementRef.nativeElement, 'scroll')
      .pipe(
        tap(() => {
          this.conditionsForArrows(elementRef);
        })
      )
      .subscribe();
  }

  private initWheelActions(elementRef: ElementRef) {
    this.subs = fromEvent<WheelEvent>(elementRef.nativeElement, 'wheel')
      .pipe(
        tap((event: WheelEvent) => {
          event.preventDefault();
          this.scrollElement(elementRef, 0, event.deltaY, this.deltaWheel);
        })
      )
      .subscribe();
  }

  private initMouseActions(elementRef: ElementRef) {
    const mouseDownEvent$: Observable<MouseEvent> = fromEvent<MouseEvent>(
      elementRef.nativeElement,
      'mousedown'
    );
    const mouseUpEvent$: Observable<MouseEvent> = fromEvent<MouseEvent>(
      elementRef.nativeElement,
      'mouseup'
    );
    const mouseMoveEvent$: Observable<MouseEvent> = fromEvent<MouseEvent>(
      elementRef.nativeElement,
      'mousemove'
    );
    const mouseLeaveEvent$: Observable<MouseEvent> = fromEvent<MouseEvent>(
      elementRef.nativeElement,
      'mouseleave'
    );

    this.subs = mouseDownEvent$
      .pipe(
        switchMap((mouseStartEvent) => {
          return mouseMoveEvent$.pipe(
            pairwise(),
            tap(([mouseMoveEventCurr, mouseMoveEventPrev]) => {
              this.scrollElement(
                elementRef,
                mouseMoveEventPrev.clientY,
                mouseMoveEventCurr.clientY,
                this.deltaMouseMove
              );
            }),
            takeUntil(mouseUpEvent$),
            takeUntil(mouseLeaveEvent$)
          );
        })
      )
      .subscribe();
  }

  private initArrowActions(elementRef: ElementRef) {
    if (this.arrowUp) {
      this.initTouchMethods(
        elementRef,
        this.arrowUp,
        EArrowAction.STEPPREVSLIDER
      );
      this.initClickMethods(
        elementRef,
        this.arrowUp,
        EArrowAction.STEPPREVSLIDER
      );
    }

    if (this.arrowDown) {
      this.initTouchMethods(
        elementRef,
        this.arrowDown,
        EArrowAction.STEPNEXTSLIDER
      );
      this.initClickMethods(
        elementRef,
        this.arrowDown,
        EArrowAction.STEPNEXTSLIDER
      );
    }
  }

  private initTouchMethods(
    elementRef: ElementRef,
    arrowElementRef: ElementRef,
    typeArrowAction: EArrowAction
  ) {
    const touchstart$ = fromEvent<TouchEvent>(
      arrowElementRef.nativeElement,
      'touchstart'
    );
    const touchend$ = fromEvent<TouchEvent>(
      arrowElementRef.nativeElement,
      'touchend'
    );

    const longTouch$ = touchstart$.pipe(
      switchMap((v) => {
        return of(v).pipe(delay(this.DELAY_LONG_CLICK), takeUntil(touchend$));
      })
    );

    this.subs = longTouch$
      .pipe(
        switchMap((event) => {
          return interval(200).pipe(
            tap(() => {
              switch (typeArrowAction) {
                case EArrowAction.STEPPREVSLIDER: {
                  this.deltaDecrease(elementRef, this.deltaClick);
                  this.conditionsForArrows(elementRef);
                  break;
                }
                case EArrowAction.STEPNEXTSLIDER: {
                  this.deltaIncrease(elementRef, this.deltaClick);
                  this.conditionsForArrows(elementRef);
                  break;
                }
              }
            }),
            takeUntil(touchend$)
          );
        })
      )
      .subscribe();
  }

  private initClickMethods(
    elementRef: ElementRef,
    arrowElementRef: ElementRef,
    typeArrowAction: EArrowAction
  ) {
    const click$ = fromEvent<MouseEvent>(
      arrowElementRef.nativeElement,
      'click'
    );
    const mouseleave$ = fromEvent<MouseEvent>(
      arrowElementRef.nativeElement,
      'mouseleave'
    );
    const mouseenter$ = fromEvent<MouseEvent>(
      arrowElementRef.nativeElement,
      'mouseenter'
    );

    this.subs = click$
      .pipe(
        tap((event: Event) => {
          event.stopPropagation();
          switch (typeArrowAction) {
            case EArrowAction.STEPPREVSLIDER: {
              this.deltaDecrease(elementRef, this.deltaClick);
              this.conditionsForArrows(elementRef);
              break;
            }
            case EArrowAction.STEPNEXTSLIDER: {
              this.deltaIncrease(elementRef, this.deltaClick);
              this.conditionsForArrows(elementRef);
              break;
            }
          }
        })
      )
      .subscribe();

    this.subs = mouseenter$
      .pipe(
        switchMap((event) => {
          event.stopPropagation();
          return interval(200).pipe(
            tap(() => {
              switch (typeArrowAction) {
                case EArrowAction.STEPPREVSLIDER: {
                  this.deltaDecrease(elementRef, this.deltaClick);
                  this.conditionsForArrows(elementRef);
                  break;
                }
                case EArrowAction.STEPNEXTSLIDER: {
                  this.deltaIncrease(elementRef, this.deltaClick);
                  this.conditionsForArrows(elementRef);
                  break;
                }
              }
            }),
            takeUntil(mouseleave$)
          );
        })
      )
      .subscribe();
  }

  private initResizeActions(elementRef: ElementRef) {
    this.subs = this.windowSize$
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        tap(() => {
          this.conditionsForArrows(elementRef);
        })
      )
      .subscribe();
  }

  private initTouchActions(elementRef: ElementRef) {
    this.subs = fromEvent<TouchEvent>(elementRef.nativeElement, 'touchmove')
      .pipe(
        tap((event: TouchEvent) => {
          event.stopPropagation();
          this.conditionsForArrows(elementRef);
        })
      )
      .subscribe();
  }

  private scrollElement(
    elementRef: ElementRef,
    startValue: number,
    endValue: number,
    delta: number
  ) {
    if (startValue > endValue) {
      this.deltaDecrease(elementRef, delta);
    }

    if (startValue < endValue) {
      this.deltaIncrease(elementRef, delta);
    }

    this.conditionsForArrows(elementRef);
  }

  private deltaDecrease(elementRef: ElementRef, delta: number) {
    elementRef.nativeElement.scrollTop =
      elementRef.nativeElement.scrollTop - delta < 0
        ? 0
        : elementRef.nativeElement.scrollTop - delta;
  }

  private deltaIncrease(elementRef: ElementRef, delta: number) {
    elementRef.nativeElement.scrollTop =
      elementRef.nativeElement.scrollTop + delta;
  }

  private conditionsForArrows(elementRef: ElementRef) {
    this.isActiveArrowUp = !(elementRef.nativeElement.scrollTop <= 0);
    this.isActiveArrowDown = !(
      elementRef.nativeElement.scrollTop +
        1 +
        elementRef.nativeElement.clientHeight >=
      elementRef.nativeElement.scrollHeight
    );

    this.cdr.detectChanges();
  }
}
