import { Pipe, PipeTransform } from '@angular/core';
import { IMapOfBoolean } from '@inaripro-nx/common-ui';

@Pipe({
  name: 'selectedItemsSize',
  standalone: true,
})
export class SelectedItemsSizePipe implements PipeTransform {
  transform(value: IMapOfBoolean): number {
    let size = 0;

    Object.keys(value).forEach((key) => {
      if (value[key]) {
        size++;
      }
    });

    return size;
  }
}
