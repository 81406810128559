import { Inject, Injectable } from '@angular/core';
import { HistoryStore } from '../../state/history/history.store';
import { ProductStore } from '../../state/product/product.store';
import {
  BehaviorSubject,
  combineLatest,
  delay,
  filter,
  of,
  Subscription,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  getStorageNameProductElements,
  LOCAL_STORAGE_ELEMENTS_TIME,
} from '../../interfaces/main.interface';
import {
  CURRENT_TEMPLATE_VERSION,
  ITemplateData,
  VERSION_START,
} from '../../../../interfaces/templates.interface';
import { IDesignerProduct } from '../../../../interfaces';
import { updateTemplateVersion } from '../../../../utils/update.utils';
import { isArray } from 'lodash';
import { AppShellConfig, StorageService, WINDOW } from '@inaripro-nx/common-ui';
import { AuthService } from '@inaripro-nx/auth';
import { DownloadService } from '../download/download.service';

@Injectable({
  providedIn: 'root',
})
export class MainService {
  private readonly saveToLocalStorage$: BehaviorSubject<null> =
    new BehaviorSubject(null);

  public config: AppShellConfig | null = null;

  constructor(
    private readonly historyStore: HistoryStore,
    private readonly productStore: ProductStore,
    private readonly storageService: StorageService,
    private readonly authService: AuthService,
    private readonly downloadService: DownloadService,
    @Inject(WINDOW) private window: Window
  ) {}

  public initService() {
    this.productSubscribe();
  }

  public download(): Subscription {
    return of(null)
      .pipe(
        withLatestFrom(
          this.authService.user$,
          this.productStore.isProductOwner$
        ),
        tap(([, user, isProductOwner]) => {
          if (user) {
            this.downloadService.downloadPdfOrSvg(isProductOwner);
          } else {
            this.saveToLocalStorage$.next(null);
          }
        }),
        delay(0),
        tap(([, user]) => {
          if (!user) {
            const url = new URL(this.window.location.href);
            url.searchParams.append('auto_download', '1');
            this.window.location.href = `${
              this.config?.publicUrl
            }/login?returnUrl=${encodeURIComponent(url.toString())}`;
          }
        })
      )
      .subscribe();
  }

  private productSubscribe() {
    this.productStore.product$
      .pipe(
        tap((product) => {
          this.historyStore.resetState();

          if (!product) {
            return;
          }

          if (product.shareTemplate) {
            const { productId, version, json } = product.shareTemplate;

            if (productId === product.id) {
              this.applyTemplate(version, json);
            }
          } else {
            this.loadTemplateFromStorage(product);
          }
        })
      )
      .subscribe();

    this.productStore.product$
      .pipe(
        filter((product) => !!product?.autoDownload),
        delay(1000),
        tap(() => this.download())
      )
      .subscribe();

    this.productStore.activeDesignProduct$
      .pipe(
        tap(() => {
          this.historyStore.setActiveElementIndex({
            activeElementIndex: null,
          });
        })
      )
      .subscribe();

    combineLatest([
      this.historyStore.elements$,
      this.productStore.zoneColors$,
      this.productStore.zonePatterns$,
      this.productStore.zoneTranslates$,
      this.saveToLocalStorage$,
    ])
      .pipe(
        withLatestFrom(this.productStore.product$),
        tap(
          ([[elements, zoneColors, zonePatterns, zoneTranslates], product]) => {
            if (product) {
              this.storageService.local.setItem(
                getStorageNameProductElements(product.id),
                JSON.stringify({
                  elements,
                  version: CURRENT_TEMPLATE_VERSION,
                  zoneColors,
                  zonePatterns,
                  zoneTranslates,
                }),
                LOCAL_STORAGE_ELEMENTS_TIME
              );
            }
          }
        )
      )
      .subscribe();
  }

  private loadTemplateFromStorage(product: IDesignerProduct) {
    try {
      const savedJSON = this.storageService.local.getItem(
        getStorageNameProductElements(product.id)
      );

      if (savedJSON) {
        const { elements, version, zoneColors, zonePatterns, zoneTranslates } =
          JSON.parse(savedJSON);

        this.applyTemplate(version, {
          elements,
          zoneColors,
          zonePatterns,
          zoneTranslates,
        });
      }
    } catch (e) {
      // TODO: error handler
    }
  }

  private applyTemplate(version: string, data: ITemplateData) {
    if (+version < +VERSION_START) {
      return;
    }

    const template = updateTemplateVersion({
      id: 0,
      name: '',
      cover: null,
      updateTime: '',
      productId: 0,
      version,
      json: {
        activeDesignProduct: null,
        ...data,
      },
    });

    const { elements, zoneColors, zonePatterns, zoneTranslates } =
      template.json;

    if (isArray(elements)) {
      this.historyStore.updateElements({
        elements: elements.filter((el) => !!el),
      });
    }

    this.productStore.setZoneColors(zoneColors || {});
    this.productStore.setZonePatterns(zonePatterns || {});
    this.productStore.setZoneTranslates(zoneTranslates || {});
  }
}
