import { IProductDeliveryParams } from '@inaripro-nx/catalog';
import { IFileRR, IFileResponse } from '@inaripro-nx/common-ui';

export interface IGetResponse {
  id: number | null;
  uid: string | null;
  products?: IProduct[];
  calculators?: ICalculator[];
  updatedAt: string;
}

export interface ICalculator {
  id: number;
  calcProcessId: string;
  calcId: number; // id калькулятора. В корзину можно добавить несколько расчетов по одному и тому же calcId.
  name: string;
  imageUrl: string;
  count: number;
  totalPrice: number; // цена за весь заказ
  perCountPrice: number; // цена за 1шт
  options: ICalculatorOption[]; // все опции и базовы и дополнительные
  companyId: number;
  companyName: string;
  productId?: number;
  files?: IFileRR[];
  selected: boolean;
  crashed: boolean;
}

export interface ICalculatorOption {
  name?: string;
  value?: string | string[]; // одна строка или массив строк
}

export interface IProductRequest {
  productId: number;
  priceUid: string;
  sumCountUid: string; // строка (через точку отсортированных по возрастанию) из тех elementId, которые входят в заказ и для которых properties имеют признак isSeparateProductCount === true. Для всех других случаев пустая строка
  count: number; // умножать на lotSize НЕ надо
  designElementsIds?: number[]; // id элементов из изделия, которых нет в прайсе
  orderElementsIds?: number[]; // id элементов из изделия, которых нет в прайсе и нет в дизайнере, но есть в заказе
  files?: IFileRR[];
  linkId?: number; // Знает только front
  design?: {
    fullColor: string | null;
    sides: IProductDesignSide[];
  };
}

export interface IProduct extends IProductRequest {
  id: number;
  name: string; // чистое название товара
  unitName: string; // или шт. если нет
  ranges: IBasketRangeAndPrice[]; // Итоговая цена с тиражом для расчета на фронте
  price: number; // Итоговая цена с учетом суммы тиража всех товаров с одинаковым productId
  article?: string; // пользовательский из прайса
  properties?: Array<{ alias: string; elementName: string }>; // Характеристики из прайса PRICE_TYPES.BY_PROPERTY + из дизайна + из заказа
  kitName?: string; // only for PRICE_TYPES.BY_KIT
  lotSize: number; // из товара
  maxRange: number; // из товара
  minRange: number; // из товара
  manufacturerId: number; // из товара
  manufacturerName: string; // из товара
  onlinePayment: boolean; // из товара
  deliveryParams: IProductDeliveryParams; // из товара
  image: IFileResponse; // Сначала из строки прайса, потом из альбома
  categoryId: number; // Нужен для расчета доставки, id номенклатуры
  selected: boolean;
  crashed: boolean;
}

export interface IBasketRangeAndPrice {
  rLower: number;
  rUpper: number;
  price: number;
}

export interface IDesignFile {
  svg?: IFileResponse; // Required for request
  logo?: IFileResponse; // Delay load in response, readonly
  preview?: IFileResponse; // Delay load in response, readonly
}

export interface IProductDesignZone extends IDesignFile {
  id: number; // zoneId
}

export interface IProductDesignSide extends IDesignFile {
  id: number; // sideId
  zones?: IProductDesignZone[];
}
