@if (breadcrumbs.length) {
<ol
  itemscope
  itemtype="https://schema.org/BreadcrumbList"
  class="breadcrumbs"
>
  @for (b of breadcrumbs; let i = $index; track b.name) {
  <li
    class="breadcrumbs__item"
    [class.breadcrumbs__item--mobile]="
      i === breadcrumbs.length - backItemIndex
    "
    itemscope
    itemtype="https://schema.org/ListItem"
    itemprop="itemListElement"
  >
    <a
      itemprop="item"
      [routerLink]="b.routerLink"
      [queryParams]="b.queryParams || {}"
    >
      <i class="icon icon-ic_arrow-breadcrumbs"></i>
      <span itemprop="name">{{ b.name }}</span>
      <meta itemprop="position" [content]="i + 1" />
    </a>
  </li>
  }
</ol>
}
