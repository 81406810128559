import { createReducer, on } from '@ngrx/store';
import { ISortConfig, ISortRequest } from '../../interfaces';
import * as SortActions from './sort.actions';

export interface SortState {
  request: ISortRequest | null;
  config: ISortConfig | null;
}

const initialState: SortState = {
  request: null,
  config: null,
};

export const sortReducer = createReducer(
  initialState,
  on(SortActions.setSortRequest, (state, { payload }) => ({
    ...state,
    request: payload,
  })),
  on(SortActions.setSortConfig, (state, { payload }) => ({
    ...state,
    config: payload,
  }))
);
