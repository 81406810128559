<catalog-product-list-filter-wrapper
  [title]="title"
  [isApply]="selected.length > 0"
  (clearFilter)="clearFilter.emit($event)"
>
  <design-simple-select-box
    [items]="items"
    [selectedList]="selected"
    [placeholder]="'Город'"
    [animatePlaceholder]="false"
    [submitDisabled]="submitDisabled"
    (toggle)="toggle.emit($event)"
    (selectAll)="selectAll.emit()"
    (clearAll)="clearAll.emit()"
    (changeModel)="applyFilterValues.emit()">
  </design-simple-select-box>
</catalog-product-list-filter-wrapper>
