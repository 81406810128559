<div class="crop-image">
  <img
    [src]="imageUrl"
    [width]="imageWidth$ | async"
    [height]="imageHeight$ | async"
    [hidden]="imageLoading$ | async"
    alt=""
    draggable="false"
  >
  @if (offset$ | async; as offset) {
    <div
      class="overlay"
      [ngStyle]="{
        'transform': 'translate(' + (-offset.x) + 'px, ' + (-offset.y) + 'px)'
      }"
    ></div>
  }
</div>
<div class="crop-image">
  <img
    [src]="imageUrl"
    [width]="imageWidth$ | async"
    [height]="imageHeight$ | async"
    [hidden]="imageLoading$ | async"
    (load)="onImageLoaded()"
    alt=""
    draggable="false"
    #image
  >
</div>

@if (imageUrl && (imageLoading$ | async)) {
  <design-preloader></design-preloader>
}
