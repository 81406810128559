import {
  CURRENT_TEMPLATE_VERSION,
  ITemplate,
  VERSION_IMAGE_EXT,
  VERSION_IMAGE_SIZES,
  VERSION_IMAGE_URL,
  VERSION_REVERSE_FILL_OPACITY,
} from '../interfaces/templates.interface';
import {
  EElementType,
  SVG_NS,
} from '../components/main/interfaces/editor.interface';
import { getUrlFromSvg } from '@inaripro-nx/common-ui';
import { getExtensionByUrl } from './calculate.utils';

export function updateTemplateVersion(
  document: Document,
  template: ITemplate
): ITemplate {
  const { version } = template;

  if (version === CURRENT_TEMPLATE_VERSION) {
    return template;
  }

  let updateTemplate = { ...template };

  updateTemplate = updateFillOpacity(updateTemplate);
  updateTemplate = updateImageUrl(document, updateTemplate);
  updateTemplate = updateImageSizes(document, updateTemplate);
  updateTemplate = updateImageExt(updateTemplate);
  // Add next updates here.

  return updateTemplate;
}

function updateFillOpacity(template: ITemplate): ITemplate {
  const { version } = template;

  if (+version < +VERSION_REVERSE_FILL_OPACITY) {
    const { json } = template;

    return {
      ...template,
      json: {
        ...json,
        elements: json.elements
          ? json.elements.map((e) => {
              let fillOpacity = e.fillOpacity;
              let fill = e.fill;

              if (e.fillOpacity !== null) {
                fillOpacity = 100 - e.fillOpacity;
              }

              if (
                e.type === EElementType.figure ||
                e.type === EElementType.text ||
                e.type === EElementType.qr
              ) {
                fill = fill || '#000000';
              }

              return {
                ...e,
                fillOpacity,
                fill,
              };
            })
          : json.elements,
      },
      version: VERSION_REVERSE_FILL_OPACITY,
    };
  }

  return template;
}

function updateImageUrl(document: Document, template: ITemplate): ITemplate {
  const { version } = template;

  if (+version < +VERSION_IMAGE_URL) {
    const { json } = template;

    return {
      ...template,
      json: {
        ...json,
        elements: json.elements
          ? json.elements.map((e) => {
              if (e.type === EElementType.image && !e.url && e.svg) {
                return {
                  ...e,
                  url: getUrlFromSvg(document, e.svg) || '',
                };
              }

              return e;
            })
          : json.elements,
      },
      version: VERSION_IMAGE_URL,
    };
  }

  return template;
}

function updateImageSizes(document: Document, template: ITemplate): ITemplate {
  const { version } = template;

  if (+version < +VERSION_IMAGE_SIZES) {
    const { json } = template;

    return {
      ...template,
      json: {
        ...json,
        elements: json.elements
          ? json.elements.map((e) => {
              if (
                e.type === EElementType.figure ||
                e.type === EElementType.qr
              ) {
                const { x: width, y: height } = e.size;

                const container = document.createElementNS(SVG_NS, 'svg');
                container.innerHTML = e.svg;
                const svg = container.querySelector('svg');

                if (!svg) {
                  // console.log('not found', e);
                  return e;
                }

                svg.setAttribute('width', `${width}`);
                svg.setAttribute('height', `${height}`);

                const outerHTML = svg.outerHTML;

                // console.log({
                //   'before': e.svg,
                //   'after': outerHTML,
                //   'equal': e.svg === outerHTML,
                // });

                return {
                  ...e,
                  svg: outerHTML,
                };
              } else if (
                e.type === EElementType.image &&
                e.svg.indexOf('data-crop-image') < 0
              ) {
                return {
                  ...e,
                  svg: '',
                };
              }

              return e;
            })
          : json.elements,
      },
      version: VERSION_IMAGE_SIZES,
    };
  }

  return template;
}

function updateImageExt(template: ITemplate): ITemplate {
  const { version } = template;

  if (+version < +VERSION_IMAGE_EXT) {
    const { json } = template;

    return {
      ...template,
      json: {
        ...json,
        elements: json.elements
          ? json.elements.map((e) => {
              if (e.type === EElementType.image && !e.ext && e.url) {
                return {
                  ...e,
                  ext: getExtensionByUrl(e.url),
                };
              }

              return e;
            })
          : json.elements,
      },
      version: VERSION_IMAGE_EXT,
    };
  }

  return template;
}
