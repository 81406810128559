<svg:g
  [attr.transform]="
    (el.translate | svgTranslate) +
    ({
      angle: el.rotate,
      point: { size: el.size, scale: el.scale } | elementRotateCenter
    } | svgRotate)"
  [attr.fill]="el.fill || 'transparent'"
  [attr.fill-opacity]="el.fillOpacity !== null ? 100 - el.fillOpacity + '%' : null"
  [attr.stroke]="el.strokeWidth ? el.stroke || null : null"
  [attr.stroke-width]="el.strokeWidth || null"
  [attr.stroke-opacity]="!el.strokeWidth || el.strokeOpacity === null || el.strokeOpacity === undefined ? null : 100 - el.strokeOpacity + '%'"
  stroke-linecap="round"
  stroke-linejoin="round"
  (mousedown)="onMouseDown($event)"
  (touchstart)="onTouchStart($event)"
>
  <ng-content></ng-content>
</svg:g>
