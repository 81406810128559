import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalWindowComponent, ModalWindowStore } from '@inaripro-nx/design-ui';
import { ImageAiComponent, ImageAiModule } from '@inaripro-nx/image-ai';
import { delay, distinctUntilChanged, filter, map, Subscription } from 'rxjs';
import { PicturesService, PicturesLibraryService } from '../../services';

export const MODAL_AI_UID = 'MODAL_AI_UID';

@Component({
  selector: 'pictures-modal-ai',
  standalone: true,
  imports: [CommonModule, ModalWindowComponent, ImageAiModule],
  templateUrl: './modal-ai.component.html',
  styleUrls: ['./modal-ai.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalAiComponent implements AfterViewInit, OnDestroy {
  @Output() save = new EventEmitter();

  readonly MODAL_AI_UID = MODAL_AI_UID;

  @ViewChild(ImageAiComponent) imageAiComponent?: ImageAiComponent;

  private _saveSub: Subscription | null = null;
  set saveSub(sub: Subscription | null) {
    if (this._saveSub) {
      this._saveSub.unsubscribe();
    }

    this._saveSub = sub;
  }

  private _openSub: Subscription | null = null;
  set openSub(sub: Subscription | null) {
    if (this._openSub) {
      this._openSub.unsubscribe();
    }

    this._openSub = sub;
  }

  constructor(
    private readonly modalWindowStore: ModalWindowStore,
    private readonly picturesService: PicturesService,
    private readonly picturesLibraryService: PicturesLibraryService
  ) {}

  ngAfterViewInit(): void {
    this.initTextAreaFocus();
  }

  ngOnDestroy() {
    this.saveSub = null;
    this.openSub = null;
  }

  private initTextAreaFocus(): void {
    this.openSub = this.modalWindowStore.modals$
      .pipe(
        map((modals) => modals[this.MODAL_AI_UID]),
        distinctUntilChanged(),
        filter((isOpen) => isOpen === true),
        delay(0)
      )
      .subscribe(() => {
        this.imageAiComponent?.textAreaFocus();
      });
  }

  closeModal() {
    this.modalWindowStore.patch({ [MODAL_AI_UID]: false });
  }

  onSave() {
    if (!this.imageAiComponent) {
      return;
    }

    this.saveSub = this.imageAiComponent.saveImage().subscribe((picture) => {
      if (picture) {
        this.picturesService.addPicture(picture);
        this.picturesLibraryService.getPictures(0); // Загруженные
        this.save.emit();
        this.closeModal();
      }
    });
  }
}
