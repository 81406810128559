import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { IFiltersValues, IProperty } from '../../../../interfaces';

@Component({
  selector: 'catalog-product-list-filter-wrapper',
  standalone: true,
  imports: [CommonModule, DesignUiModule],
  templateUrl: './catalog-product-list-filter-wrapper.component.html',
  styleUrls: ['./catalog-product-list-filter-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogProductListFilterWrapperComponent {
  @Input() prop: IProperty | null = null;
  @Input() title = '';
  @Input() filters!: IFiltersValues;
  @Input() isApply = false;
  @Input() isShowHelper = true;

  @Output() clearFilter = new EventEmitter<void>();
}
