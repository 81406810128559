import {
  PaginationOrderByType,
  PaginationOrderDirectionType,
} from './pagination.interface';

export interface ISortRequest {
  order_by: PaginationOrderByType;
  order_direction: PaginationOrderDirectionType;
}

export interface ISortConfig {
  defaultRequest: ISortRequest;
  orderByValues: PaginationOrderByType[];
}

export interface ISortOption {
  id: number;
  label: string;
  value: PaginationOrderByType;
  direction: PaginationOrderDirectionType;
}
