import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EPropertyType } from '@inaripro-nx/catalog';

import {
  EFilterPropertyKey,
  IDesignerSource,
} from '../../../../../../../../interfaces';

import { DesignUiModule } from '@inaripro-nx/design-ui';
import { ContentProductPropertyWrapperComponent } from '../content-product-property-wrapper/content-product-property-wrapper.component';

@Component({
  selector: 'painter-content-product-properties',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    DesignUiModule,
    ContentProductPropertyWrapperComponent,
  ],
  templateUrl: './content-product-properties.component.html',
  styleUrls: ['./content-product-properties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentProductPropertiesComponent {
  @Input() propertySource!: IDesignerSource;
  @Output() changeParams = new EventEmitter<string>();

  readonly EPropertyType = EPropertyType;
  readonly EFilterPropertyKey = EFilterPropertyKey;

  isElementSelected(id: string) {
    return this.propertySource.selected.indexOf(id) > -1;
  }

  isElementDisabled(id: string) {
    return this.propertySource.disabled.indexOf(id) > -1;
  }

  onClick(event: Event, id: string): void {
    event.preventDefault();
    event.stopPropagation();

    const propertyId = this.propertySource.allElementsMap[id].propertyId;

    const params = (
      this.isElementDisabled(id)
        ? [id]
        : [
            ...this.propertySource.selected.filter((sid) => {
              const propId = this.propertySource.allElementsMap[sid].propertyId;
              return propId !== propertyId;
            }),
            id,
          ]
    )
      .filter((v) => !!v)
      .join(',');

    this.changeParams.emit(params);
  }
}
