<design-tool-button class="button" [selected]="active" (click)="onClick()">
  <i class="icon icon-design-sides"></i>
  <span>Стороны изделия</span>
</design-tool-button>

<painter-editor-product-sides
  class="dropdown"
  [class.dropdown--active]="active"
  [designProduct]="activeDesignProduct"
  [activeDesignSideIndex]="activeDesignSideIndex"
  (selectSide)="selectSide.emit($event)"
></painter-editor-product-sides>
