import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CatalogNomenclatureDictionaryRecords,
  ICatalogNomenclatureTreeItem,
  IMapOfTreeItem,
} from '../../interfaces/catalog.interface';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import {
  CatalogGroupLinkPipe,
  CatalogSortByIsActivePipe,
  CatalogTreeItemHasDesignPipe,
} from '../../pipes';

@Component({
  selector: 'catalog-group-sections',
  standalone: true,
  imports: [
    CommonModule,
    LazyLoadImageModule,
    RouterModule,
    CatalogGroupLinkPipe,
    CatalogSortByIsActivePipe,
    CatalogTreeItemHasDesignPipe,
  ],
  templateUrl: './catalog-group-sections.component.html',
  styleUrl: './catalog-group-sections.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogGroupSectionsComponent implements OnChanges {
  @Input() treeItem: ICatalogNomenclatureTreeItem | null = null;
  @Input() childParent: IMapOfTreeItem | null = null;
  @Input() dictionary: CatalogNomenclatureDictionaryRecords | null = null;
  @Input() companyId!: number;
  @Input() rootModule!: string;
  @Input() hasProductDesign = false;

  queryParams = {};

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['companyId']) {
      this.queryParams = this.companyId ? this.companyId : {};
    }
  }
}
