import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, tap } from 'rxjs';
import { IBannerImage, IBannersMap } from '@inaripro-nx/common-ui';

@Injectable({
  providedIn: 'root',
})
export class CatalogBannerService {
  private readonly bannersMapSubject$ = new BehaviorSubject<IBannersMap>({});
  readonly bannersMap$ = this.bannersMapSubject$.asObservable();

  constructor(private readonly httpClient: HttpClient) {}

  getBanners(id: number, cache = true): Observable<IBannerImage[]> {
    const bannersMap = this.bannersMapSubject$.value;

    if (cache && bannersMap[id]) {
      return of(bannersMap[id]);
    }

    return this.httpClient
      .get<{ data: IBannerImage[] }>(
        `/catalog/public/nomenclature_banners/${id}/`
      )
      .pipe(
        map(({ data }) => data),
        tap((data) => {
          if (data) {
            bannersMap[id] = data;
            this.bannersMapSubject$.next(bannersMap);
          }
        })
      );
  }
}
