import { ProductTypeEnum } from '../enums/catalog-product.enum';

export function getProductTypeName(productType: ProductTypeEnum): string {
  switch (productType) {
    case ProductTypeEnum.BASE_PRODUCT: {
      return 'Базовый товар';
    }

    case ProductTypeEnum.DESIGN: {
      return 'Цветовая гамма';
    }

    case ProductTypeEnum.EXAMPLE: {
      return 'Примеры работы';
    }

    case ProductTypeEnum.ALL: {
      return 'Все';
    }

    default:
      return '';
  }
}
