import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ProductTypeEnum } from '../../enums/catalog-product.enum';
import { ICategory, IFiltersValues } from '../../interfaces';
import { CatalogProductListContentFilterSortComponent } from './components/catalog-product-list-content-filter-sort/catalog-product-list-content-filter-sort.component';
import { CatalogProductListContentFilterRangeComponent } from './components/catalog-product-list-content-filter-range/catalog-product-list-content-filter-range.component';
import { CatalogProductListContentFilterTypeComponent } from './components/catalog-product-list-content-filter-type/catalog-product-list-content-filter-type.component';
import { ISortOption, ISortRequest } from '@inaripro-nx/common-ui';

@Component({
  selector: 'catalog-product-list-content-filter',
  standalone: true,
  imports: [
    CommonModule,
    CatalogProductListContentFilterRangeComponent,
    CatalogProductListContentFilterSortComponent,
    CatalogProductListContentFilterTypeComponent,
  ],
  templateUrl: './catalog-product-list-content-filter.component.html',
  styleUrls: ['./catalog-product-list-content-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogProductListContentFilterComponent {
  @Input() filterValues: IFiltersValues | null = null;
  @Input() productType: ProductTypeEnum | null = null;
  @Input() existProductTypes: ProductTypeEnum[] | null = null;
  @Input() nomenclatureBase: ICategory | null = null;
  @Input() range: number | null = null;
  @Input() sortRequest: ISortRequest | null = null;
  @Input() isMobileTypeButtons = false;

  @Output() changeTypeOption = new EventEmitter<IFiltersValues>();
  @Output() changeRangeOption = new EventEmitter<number>();
  @Output() changeSortOption = new EventEmitter<ISortOption>();

  readonly ProductTypeEnum = ProductTypeEnum;

  selectType(productType: ProductTypeEnum) {
    if (this.filterValues) {
      this.changeTypeOption.emit({
        ...this.filterValues,
        productType,
      });
    }
  }
}
