import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, ViewContainerRef } from '@angular/core';
import {
  getTime,
  IFileResponse,
  stripHtmlComments,
  svgToXml,
} from '@inaripro-nx/common-ui';
import {
  IProductDesignSide,
  IProductDesignZone,
  OrderCartStore,
} from '@inaripro-nx/order';
import {
  BehaviorSubject,
  delay,
  finalize,
  from,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  tap,
  toArray,
  withLatestFrom,
} from 'rxjs';
import { ExportComponent } from '../../components/export/export.component';
import {
  IExportRequest,
  IExportSideXML,
  IExportZoneXML,
  IGetSVGSide,
  IGetSVGZone,
} from './export.interface';
import { HistoryStore } from '../../state/history/history.store';
import { ProductStore } from '../../state/product/product.store';
import { ExportZoneComponent } from '../../components/export-zone/export-zone.component';

@Injectable()
export class ExportService {
  private viewContrainerRef!: ViewContainerRef;

  isProduction$: BehaviorSubject<boolean | null | undefined> =
    new BehaviorSubject<boolean | null | undefined>(null);

  constructor(
    private http: HttpClient,
    private readonly orderCartStore: OrderCartStore,
    private readonly historyStore: HistoryStore,
    private readonly productStore: ProductStore,
    @Inject(DOCUMENT) private document: Document
  ) {}

  init(viewContrainerRef: ViewContainerRef) {
    this.viewContrainerRef = viewContrainerRef;
  }

  public getTempPDF(request: IExportRequest): Observable<unknown> {
    if (this.isProduction$.value) {
      throw new Error('Valid token not returned');
    }

    const svgSides = this.getSvgSides(request);
    const xmlArray: string[] = svgSides.reduce((acc, side) => {
      acc.push(side.xml);

      if (side.zones) {
        acc = acc.concat(...side.zones.map((z) => z.xml));
      }

      return acc;
    }, [] as string[]);

    let delayTime = 0;

    return from(xmlArray).pipe(
      mergeMap((xml) => {
        delayTime += 1000;

        const file = new Blob([xml], { type: 'image/svg+xml' });

        const formData: FormData = new FormData();
        formData.append('file', file, `export_${new Date().getTime()}.svg`);

        return of(null).pipe(
          delay(delayTime),
          switchMap(() =>
            this.http.post<{ url: string }>(
              '/catalog/public/design/temp_pdf_result_chrome',
              formData
            )
          ),
          tap((response) => {
            //set url value to a element's href attribute.
            const a = this.document.createElement('a');
            a.href = response.url;
            a.target = '_blank';
            a.click();
            a.parentNode?.removeChild(a);
          })
        );
      })
    );
  }

  public getXmlSides(): Observable<IExportSideXML[] | null> {
    return of(null).pipe(
      withLatestFrom(
        this.historyStore.elements$,
        this.productStore.filtersMap$,
        this.productStore.activeDesignProduct$,
        this.productStore.fullColor$,
        this.productStore.zoneColors$,
        this.productStore.zonePatterns$,
        this.productStore.zoneTranslates$
      ),
      map(
        ([
          ,
          elements,
          filters,
          designProduct,
          fullColor,
          zoneColors,
          zonePatterns,
          zoneTranslates,
        ]) => {
          if (!designProduct) {
            return null;
          }

          return this.getSvgSides({
            elements,
            filters,
            designProduct,
            fullColor,
            zoneColors,
            zonePatterns,
            zoneTranslates,
          });
        }
      )
    );
  }

  public getProductSides(): Observable<IProductDesignSide[] | null> {
    this.orderCartStore.setGetProductSidesStatus(true);

    return this.getXmlSides().pipe(
      switchMap((xmlSides) => {
        if (xmlSides === null) {
          return of(null);
        }

        return from(xmlSides).pipe(
          mergeMap((side) => this.getProductDesignSide(side)),
          toArray()
        );
      }),
      finalize(() => this.orderCartStore.setGetProductSidesStatus(false))
    );
  }

  private xmlToFile(xml: string): Observable<IFileResponse> {
    const file = new Blob([xml || ''], {
      type: 'image/svg+xml',
    });

    const formData: FormData = new FormData();
    formData.append('file', file, `export_${getTime()}.svg`);

    return this.http.post<IFileResponse>(
      '/catalog/public/design/product_result_svg',
      formData
    );
  }

  private getProductDesignZone(
    zone: IExportZoneXML
  ): Observable<IProductDesignZone> {
    return this.xmlToFile(zone.xml).pipe(
      map((file) => {
        return {
          id: zone.id,
          svg: file,
        };
      })
    );
  }

  private getProductDesignSide(
    side: IExportSideXML
  ): Observable<IProductDesignSide> {
    return this.xmlToFile(side.xml).pipe(
      switchMap((file) => {
        const svgSide: IProductDesignSide = {
          id: side.id,
          svg: file,
        };

        if (!side.zones) {
          return of(svgSide);
        } else {
          return from(side.zones).pipe(
            mergeMap((zone) => this.getProductDesignZone(zone)),
            toArray(),
            map((zones) => {
              svgSide.zones = zones;
              return svgSide;
            })
          );
        }
      })
    );
  }

  private getSvgSides(request: IExportRequest): IExportSideXML[] {
    const { designProduct } = request;

    return designProduct.sides
      .filter((side) => side.id)
      .map((side, sideIndex) => {
        const sideXML: IExportSideXML = {
          id: side.id || 0,
          xml: svgToXml(
            this.getSvgSide({
              ...request,
              sideIndex,
              side,
            })
          ),
        };

        if (!side.isFullPrint) {
          const zonesXML: IExportZoneXML[] = side.zones
            .filter((z) => !z.isOnlyFilling)
            .map((zone) => {
              const elements = request.elements.filter(
                (e) => e.sideIndex === sideIndex
              );
              return { zone, elements };
            })
            .filter(({ zone, elements }) => !!elements.length)
            .map(({ zone, elements }) => {
              return {
                id: zone.id || 0,
                xml: svgToXml(
                  this.getSvgZone({
                    ...request,
                    sideIndex,
                    side,
                    zone,
                    elements,
                  })
                ),
              };
            });

          sideXML.zones = zonesXML;
        }

        return sideXML;
      });
  }

  private getSvgSide(request: IGetSVGSide): string {
    this.viewContrainerRef.clear();

    const componentRef =
      this.viewContrainerRef.createComponent<ExportComponent>(ExportComponent);

    const {
      side,
      sideIndex,
      filters,
      elements,
      fullColor,
      zoneColors,
      zonePatterns,
      zoneTranslates,
    } = request;

    componentRef.instance.side = side;
    componentRef.instance.sideIndex = sideIndex;
    componentRef.instance.filters = filters;
    componentRef.instance.elements = elements;
    componentRef.instance.fullColor = fullColor;
    componentRef.instance.zoneColors = zoneColors;
    componentRef.instance.zonePatterns = zonePatterns;
    componentRef.instance.zoneTranslates = zoneTranslates;
    componentRef.changeDetectorRef.detectChanges();

    const innerHTML = (
      componentRef.instance.elementRef.nativeElement as HTMLElement
    ).innerHTML;

    this.viewContrainerRef.clear();

    return this.clearSVG(innerHTML);
  }

  private getSvgZone(request: IGetSVGZone): string {
    this.viewContrainerRef.clear();

    const componentRef =
      this.viewContrainerRef.createComponent<ExportZoneComponent>(
        ExportZoneComponent
      );

    const {
      side,
      sideIndex,
      filters,
      elements,
      zoneColors,
      zonePatterns,
      zoneTranslates,
      zone,
    } = request;

    componentRef.instance.side = side;
    componentRef.instance.zone = zone;
    componentRef.instance.sideIndex = sideIndex;
    componentRef.instance.filters = filters;
    componentRef.instance.elements = elements;

    if (zoneColors['' + zone.id]) {
      componentRef.instance.zoneColor = zoneColors['' + zone.id];
    }

    if (zonePatterns['' + zone.id]) {
      componentRef.instance.zonePattern = zonePatterns['' + zone.id];
    }

    componentRef.instance.zoneTranslates = zoneTranslates;

    componentRef.changeDetectorRef.detectChanges();

    const innerHTML = (
      componentRef.instance.elementRef.nativeElement as HTMLElement
    ).innerHTML;

    this.viewContrainerRef.clear();

    return this.clearSVG(innerHTML);
  }

  private clearSVG(value: string): string {
    value = value.replace(/\sdata-painter-editor-element-transform=""/g, '');
    value = value.replace(/\sdata-painter-editor-element-content=""/g, '');
    value = value.replace(/\sng-reflect-el="\[object\sObject\]"/g, '');
    value = stripHtmlComments(value);
    return value;
  }
}
