<div class="main" *ngIf="{ isDesktop: isDesktop$ | async } as state">
  <div class="main-header">
    <ng-content select="[data-header]"></ng-content>
  </div>
  <div class="main-desktop" *ngIf="state.isDesktop; else mobileView">
    <painter-main-desktop></painter-main-desktop>
  </div>
  <ng-template #mobileView>
    <div class="main-mobile">
      <painter-main-mobile></painter-main-mobile>
    </div>
  </ng-template>
  <div [hidden]="true">
    <ng-container #export></ng-container>
  </div>

  <painter-modal-ai></painter-modal-ai>
  <painter-modal-reach-file-limit></painter-modal-reach-file-limit>

  <painter-modal-template></painter-modal-template>
  <painter-modal-download-pdf></painter-modal-download-pdf>
  <painter-modal-save-changes></painter-modal-save-changes>

</div>
