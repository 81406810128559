import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'someEqual',
})
export class SomeEqualPipe implements PipeTransform {
  transform(value: unknown[], compare?: unknown): boolean {
    return value && value.some((v) => v === compare);
  }
}
