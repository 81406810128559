import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DesignUiModule, ModalWindowStore } from '@inaripro-nx/design-ui';
import {
  FigureTypeEnum,
  ICropData,
  IFigure,
  IFigureListItem,
} from '../../interfaces';
import { CropFigureListComponent } from '../crop-figure-list/crop-figure-list.component';
import { CropContainerComponent } from '../crop-container/crop-container.component';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  Subject,
  Subscription,
  withLatestFrom,
} from 'rxjs';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DEFAULT_CROP_FIGURES } from '../../constants';
import { CropStore } from '../../store/crop';
import { getResizedInlineSvg } from '../../utils/crop.utils';
import { ContentPicturesComponent } from '@inaripro-nx/pictures';
import { ImageXlinkPipe } from '@inaripro-nx/common-ui';

const bufferRows = 1;
const widthFigurePx = 85;
const heightFigurePx = 85;

export const MODAL_CROP_UID = 'MODAL_CROP_UID';

@Component({
  selector: 'crop-modal',
  standalone: true,
  templateUrl: './crop-modal.component.html',
  styleUrls: ['./crop-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DesignUiModule,
    CropFigureListComponent,
    CropContainerComponent,
    ContentPicturesComponent,
  ],
  providers: [CropStore, ImageXlinkPipe],
})
export class CropModalComponent implements OnChanges, OnInit, OnDestroy {
  @Input() image: string | null = null;
  @Input() imageWidth = 0;
  @Input() imageHeight = 0;

  @Input() mask: string | null = null;
  @Input() maskX = 0;
  @Input() maskY = 0;
  @Input() maskWidth = 0;
  @Input() maskHeight = 0;

  @Input() figures: IFigure[] | null = null;
  @Input() selectedFigureId: number | null = null;

  @Input() isDesktop = true;

  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<ICropData>();

  @ViewChild('scrollContainer') private readonly scrollContainer?: ElementRef;

  beforeHeight = 0;
  afterHeight = 0;

  figureList: IFigureListItem[] = [...DEFAULT_CROP_FIGURES];
  selectedFigureForList: IFigureListItem = DEFAULT_CROP_FIGURES[0];
  selectedFigureForMask: IFigureListItem = DEFAULT_CROP_FIGURES[0];

  selectedImage: string | null = this.image;
  selectedImageWidth = this.imageWidth;
  selectedImageHeight = this.imageHeight;

  isMobilePicturesActive = false;

  readonly modalUid = MODAL_CROP_UID;
  readonly modalOpened$ = this.modalWindowStore.modals$.pipe(
    debounceTime(0),
    map((modals) => modals[this.modalUid])
  );

  readonly saveProportionsControl = new FormControl(false);

  readonly scrollEvent$ = new Subject<{
    event: Event | null;
    scrollContainer: HTMLElement;
  }>();

  readonly figures$ = this.scrollEvent$.pipe(
    startWith(null),
    debounceTime(200),
    map((scrollEvent) =>
      scrollEvent?.event === null || !scrollEvent?.scrollContainer
        ? null
        : scrollEvent?.scrollContainer?.scrollTop || 0
    ),
    map(() => this.getFigures())
  );

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  private readonly saveSubject$ = new Subject<void>();

  constructor(
    private readonly modalWindowStore: ModalWindowStore,
    private readonly cropStore: CropStore,
    private readonly cdr: ChangeDetectorRef,
    private readonly imageXlinkPipe: ImageXlinkPipe
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('image' in changes) {
      this.setOriginalImage(this.image);
    }

    if ('imageWidth' in changes || 'imageHeight' in changes) {
      this.setOriginalImageSize(this.imageWidth, this.imageHeight);
    }

    if ('figures' in changes) {
      this.figureList = [
        ...DEFAULT_CROP_FIGURES,
        ...(this.figures || []).map((f) => ({
          ...f,
          type: FigureTypeEnum.Mask,
        })),
      ];
    }

    if ('selectedFigureId' in changes) {
      this.setSelectedFigureById(this.selectedFigureId);
    }

    if ('maskX' in changes || 'maskY' in changes) {
      this.setCropPosition(this.maskX, this.maskY);
    }

    if ('maskWidth' in changes || 'maskHeight' in changes) {
      this.setCropSize(this.maskWidth, this.maskHeight);
    }
  }

  ngOnInit() {
    this.initCheckboxSubscription();
    this.initResizeSubscription();
    this.initSaveSubscription();
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }

  open() {
    this.modalWindowStore.patch({ [this.modalUid]: true });
  }

  close() {
    this.modalWindowStore.patch({ [this.modalUid]: false });
    this.reset();
  }

  onCancel() {
    this.cancel.emit();
    this.close();
  }

  onSave() {
    this.saveSubject$.next();
  }

  reset() {
    this.setOriginalImage(this.image);
    this.setOriginalImageSize(this.imageWidth, this.imageHeight);
    this.setSelectedFigureById(this.selectedFigureId);
    this.setCropPosition(this.maskX, this.maskY);
    this.setCropSize(this.maskWidth, this.maskHeight);
  }

  onSelectPicture(data: {
    svg: string;
    bBox: { width: number; height: number };
  }) {
    this.selectedImage = this.imageXlinkPipe.transform(data);
    this.setOriginalImage(this.selectedImage);

    this.selectedImageWidth = data.bBox.width;
    this.selectedImageHeight = data.bBox.height;
    this.setOriginalImageSize(
      this.selectedImageWidth,
      this.selectedImageHeight
    );

    if (this.isMobilePicturesActive) {
      this.isMobilePicturesActive = false;
    }
  }

  onSelectFigure(selectedFigure: IFigureListItem) {
    this.selectedFigureForList = { ...selectedFigure };
    this.selectedFigureForMask = { ...selectedFigure };
    this.saveProportionsControl.setValue(!!selectedFigure.rectangle, {
      emitEvent: false,
    });

    this.cropStore.loadMask(this.selectedFigureForMask.image?.url || null);
    this.cropStore.setSelectedFigureId(this.selectedFigureForList.id);
  }

  toggleMobilePictures() {
    this.isMobilePicturesActive = !this.isMobilePicturesActive;
  }

  private initCheckboxSubscription() {
    this.subs = this.saveProportionsControl.valueChanges.subscribe((value) => {
      if (value === false && !!this.selectedFigureForList?.rectangle) {
        this.selectedFigureForList = DEFAULT_CROP_FIGURES[0];
      }
    });
  }

  private initResizeSubscription() {
    this.subs = this.cropStore.resizing$
      .pipe(
        distinctUntilChanged(),
        filter((resizing) => !!resizing),
        filter(
          () => this.selectedFigureForList.type === FigureTypeEnum.Original
        )
      )
      .subscribe(() => {
        this.selectedFigureForList = DEFAULT_CROP_FIGURES[0];
        this.cdr.detectChanges();
      });
  }

  private initSaveSubscription() {
    this.subs = this.saveSubject$
      .asObservable()
      .pipe(
        withLatestFrom(
          this.cropStore.imageScale$,
          this.cropStore.imageX$,
          this.cropStore.imageY$,
          this.cropStore.cropWidth$,
          this.cropStore.cropHeight$,
          this.cropStore.cropX$,
          this.cropStore.cropY$,
          this.cropStore.inlineSvgMask$,
          this.cropStore.selectedFigureId$
        ),
        map(
          ([
            ,
            imageScale,
            imageX,
            imageY,
            cropWidth,
            cropHeight,
            cropX,
            cropY,
            inlineSvgMask,
            selectedFigureId,
          ]) => {
            const x = (imageX - cropX) / imageScale;
            const y = (imageY - cropY) / imageScale;
            const width = cropWidth / imageScale;
            const height = cropHeight / imageScale;

            const cropData: ICropData = {
              x,
              y,
              width,
              height,
              originalImage: this.selectedImage,
              originalImageWidth: this.selectedImageWidth,
              originalImageHeight: this.selectedImageHeight,
              inlineSvgMask: getResizedInlineSvg(inlineSvgMask, width, height),
              selectedFigureId,
            };

            return cropData;
          }
        )
      )
      .subscribe((cropData) => {
        this.save.emit(cropData);
        this.close();
      });
  }

  private setOriginalImage(image: string | null) {
    this.selectedImage = image;
    this.cropStore.setOriginalImage(image);
  }

  private setOriginalImageSize(width: number, height: number) {
    this.selectedImageWidth = width;
    this.selectedImageHeight = height;
    this.cropStore.setOriginalImageSize({ width, height });
  }

  private setSelectedFigureById(id: number | null) {
    const selectedFigure =
      this.figureList.find((f) => f.id === id) || DEFAULT_CROP_FIGURES[0];
    this.onSelectFigure(selectedFigure);
  }

  private setCropPosition(x: number, y: number) {
    this.cropStore.setCropPosition({ x, y });
  }

  private setCropSize(width: number, height: number) {
    this.cropStore.setCropSize({ width, height });
  }

  private getFigures() {
    const { offsetHeight, offsetWidth, scrollTop } = this.scrollContainer
      ?.nativeElement || {
      offsetHeight: 0,
      offsetWidth: 0,
      scrollTop: 0,
    };

    const columns = Math.floor(offsetWidth / widthFigurePx);
    const rows = Math.ceil((this.figureList || []).length / columns);

    const showRows = Math.ceil(offsetHeight / heightFigurePx);
    const scrolledRow = Math.floor(scrollTop / heightFigurePx);

    let startRow = scrolledRow - bufferRows;
    startRow = startRow < 0 ? 0 : startRow;

    const endRow = scrolledRow + showRows + bufferRows;

    this.beforeHeight = startRow > 0 ? startRow * heightFigurePx : 0;
    this.afterHeight = endRow < rows ? (rows - endRow) * heightFigurePx : 0;

    const startIndex = startRow * columns;
    const endIndex = endRow * columns;

    return (this.figureList || []).slice(startIndex, endIndex);
  }
}
