import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { IOrderProductSource } from '@inaripro-nx/order';
import {
  CatalogProductPriceComponent,
  CatalogProductPriceTableComponent,
} from '@inaripro-nx/catalog';
import { DropDownModule } from '@inaripro-nx/design-ui';
import { BehaviorSubject, filter, pairwise, Subscription } from 'rxjs';
import { HeaderLinkComponent } from '../header-link/header-link.component';

@Component({
  selector: 'shell-header-order-control',
  standalone: true,
  imports: [
    CommonModule,
    CatalogProductPriceComponent,
    CatalogProductPriceTableComponent,
    DropDownModule,
    HeaderLinkComponent,
  ],
  templateUrl: './header-order-control.component.html',
  styleUrls: ['./header-order-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderOrderControlComponent implements OnInit, OnDestroy {
  @Input() source!: IOrderProductSource;
  @Input() basketCounter: number | null = null;

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  private readonly isOrderButtonSubject$ = new BehaviorSubject<boolean>(true);
  readonly isOrderButton$ = this.isOrderButtonSubject$.asObservable();

  ngOnInit() {
    this.subs = this.source.updatingProduct$.subscribe(() => {
      this.isOrderButtonSubject$.next(true);
    });

    this.subs = this.source.mergeProductStatus$
      .pipe(
        pairwise(),
        filter(
          ([prevStatus, currStatus]) =>
            prevStatus === true && currStatus === false
        )
      )
      .subscribe(() => {
        this.isOrderButtonSubject$.next(false);
      });
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  addProductToCart() {
    this.source.addProductToCart();
  }
}
