export interface AppShellConfig {
  designerRoot: string;
  publicUrl: string;
  publicLinkType: AppShellLinkTypeEnum;
  isProduction: boolean;
}

export enum AppShellLinkTypeEnum {
  External,
  Relative,
}
