import {
  CURRENT_TEMPLATE_VERSION,
  ITemplate,
  VERSION_REVERSE_FILL_OPACITY,
} from '../interfaces/templates.interface';

export function updateTemplateVersion(template: ITemplate): ITemplate {
  const { version } = template;

  if (version === CURRENT_TEMPLATE_VERSION) {
    return template;
  }

  let updateTemplate = { ...template };

  updateTemplate = updateFillOpacity(updateTemplate);
  // Add next updates here.

  return {
    ...updateTemplate,
    version: CURRENT_TEMPLATE_VERSION,
  };
}

function updateFillOpacity(template: ITemplate): ITemplate {
  const { version } = template;

  if (+version < +VERSION_REVERSE_FILL_OPACITY) {
    const { json } = template;

    return {
      ...template,
      json: {
        ...json,
        elements: json.elements
          ? json.elements.map((e) => {
              if (e.fillOpacity !== null) {
                return {
                  ...e,
                  fillOpacity: 100 - e.fillOpacity,
                };
              }

              return e;
            })
          : json.elements,
      },
      version: VERSION_REVERSE_FILL_OPACITY,
    };
  }

  return template;
}
