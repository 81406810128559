import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AuthService } from '@inaripro-nx/auth';
import {
  CatalogCompareStore,
  CatalogFavoriteStore,
  CatalogGroupLinkPipe,
  CatalogNomenclatureStore,
} from '@inaripro-nx/catalog';
import { AppShellConfig, WINDOW } from '@inaripro-nx/common-ui';
import { GeoStore, ICity } from '@inaripro-nx/geo';
import { LinksStore } from '@inaripro-nx/links';
import { OrderCartStore } from '@inaripro-nx/order';
import { Subscription, tap } from 'rxjs';
import { HeaderCatalogSearchComponent } from './components/header-catalog-search/header-catalog-search.component';
import { HeaderCitySelectModalComponent } from './components/header-city-select-modal/header-city-select-modal.component';
import { HeaderLogoComponent } from './components/header-logo/header-logo.component';
import { HeaderGoBackComponent } from './components/header-menu-go-back/header-order-go-back.component';
import { HeaderMenuMobileComponent } from './components/header-menu-mobile/header-menu-mobile.component';
import { HeaderMenuServiceComponent } from './components/header-menu-service/header-menu-service.component';
import { HeaderMenuUserComponent } from './components/header-menu-user/header-menu-user.component';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { HeaderOrderControlComponent } from './components/header-order-control/header-order-control.component';
import { HeaderStore } from './state/header.store';
import { HeaderService } from './services/header.service';

@Component({
  selector: 'shell-header',
  standalone: true,
  imports: [
    CommonModule,
    HeaderLogoComponent,
    HeaderMenuComponent,
    HeaderMenuMobileComponent,
    CatalogGroupLinkPipe,
    HeaderMenuServiceComponent,
    HeaderMenuUserComponent,
    HeaderCatalogSearchComponent,
    HeaderCitySelectModalComponent,
    HeaderOrderControlComponent,
    HeaderGoBackComponent,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HeaderStore, GeoStore, LinksStore],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input()
  set appShellConfig(config: AppShellConfig | null) {
    this.config = config;
    this.headerStore.setConfig(config);
  }

  @Input() scrollFlag = true;

  @ViewChild('catalogFixedSearch', { static: false })
  catalogFixedSearch: HeaderCatalogSearchComponent | undefined;

  config: AppShellConfig | null = null;
  catalogFixedSearchVisible = false;

  orderProductSource$ = this.headerService.orderProductSource$;
  selectedProductSource$ = this.headerService.selectedProductSource$;

  readonly user$ = this.authService.user$.pipe(
    tap((user) => {
      this.geoStore.setCurrentLocation(user?.city || null);
    })
  );

  readonly dictionary$ = this.catalogNomenclatureStore.dictionary$;
  readonly treeItems$ = this.catalogNomenclatureStore.treeItems$;
  readonly links$ = this.linksStore.links$;

  readonly cities$ = this.geoStore.cities$;
  readonly currentLocation$ = this.geoStore.currentLocation$;

  readonly basketCounter$ = this.orderCartStore.productsCount$;

  private focusSearchInputTimeout: number | undefined;

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  constructor(
    private readonly catalogNomenclatureStore: CatalogNomenclatureStore,
    private readonly headerStore: HeaderStore,
    private readonly authService: AuthService,
    private readonly geoStore: GeoStore,
    private readonly linksStore: LinksStore,
    @Inject(WINDOW) private window: Window,
    private readonly catalogCompareStore: CatalogCompareStore,
    private readonly catalogFavoriteStore: CatalogFavoriteStore,
    private readonly orderCartStore: OrderCartStore,
    private readonly headerService: HeaderService
  ) {}

  ngOnInit(): void {
    this.geoStore.init();
    this.linksStore.init();
    this.catalogCompareStore.init();
    this.catalogFavoriteStore.init();
    this.orderCartStore.init();
  }

  ngOnDestroy(): void {
    if (this.focusSearchInputTimeout) {
      clearTimeout(this.focusSearchInputTimeout);
    }

    this._subs.forEach((s) => s.unsubscribe());
  }

  updateCurrentLocation(city: ICity) {
    this.geoStore.setCurrentLocation(city);
  }

  onSignout() {
    this.window.location.href = `${
      this.config?.publicUrl
    }/logout?returnUrl=${encodeURIComponent(this.window.location.href)}`;
  }
}
