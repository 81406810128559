<div
  class="selectbox-dropdown"
  [class.selectbox-dropdown--active]="isActive"
  (wheel)="preventScroll($event)"
  (touchmove)="preventScroll($event)"
  (touchstart)="preventScroll($event)"
>
  <div class="selectbox-dropdown__header">
    <div>Выбрано: ({{selectedItems | selectedItemsSize }})</div>
    <div class="btn-reset">
      @if (showSelectAll) {
        <a href="javascript:;" (click)="onSelectAll()">Все</a>
        <a href="javascript:;" (click)="!submitDisabled && onReset()">Сбросить</a>
      }
    </div>
  </div>
  <ngx-simplebar
    [class.ngx-simplebar__simple-selectbox]="modificatorSimplebar === 'simple-selectbox'"
  >
    <div class="selectbox-dropdown__content">
      <design-simple-selectbox-list
        [items]="listItems"
        [filterItems]="filterItems"
        [selected]="selectedItems"
        (toggleSelect)="selectItem($event)">
      </design-simple-selectbox-list>
    </div>
  </ngx-simplebar>
  <div class="selectbox-dropdown__footer g-form-button g-form-button--left">
    <button
      class="g-btn-link g-btn-link--red"
      [disabled]="submitDisabled"
      (click)="onApply()"
    >Применить</button>
  </div>
</div>
