import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { PipesModule as CommonPipesModule } from '@inaripro-nx/common-ui';
import {
  DEFAULT_TEXT_SIZE,
  ITextChanges,
  ITextElement,
  IXY,
  SVG_NS,
} from '../../../../interfaces/editor.interface';
import { PipesModule } from '../../../../pipes/pipes.module';
import { DOCUMENT, NgForOf, NgIf } from '@angular/common';
import { getTextStyledGroup } from '../../../../../../utils/svg.utils';
import { getInlineText } from '../../../../../../utils/calculate.utils';

interface IChar {
  letter: string;
  angle: number;
  point: IXY;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[data-painter-editor-element-text-arc]',
  standalone: true,
  imports: [CommonPipesModule, PipesModule, NgForOf, NgIf],
  templateUrl: './editor-element-text-arc.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorElementTextArcComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Input() el!: ITextElement | ITextChanges;
  @Input() index!: string | number;
  @Input() inAction?: boolean;

  readonly DEFAULT_TEXT_SIZE = DEFAULT_TEXT_SIZE;

  chars: IChar[] | null = null;
  dotLetter = '_';
  dotIndex = 'dot';

  private destroyed = false;

  get textTranslate(): IXY | null {
    return (this.el as ITextElement).textTranslate || null;
  }

  constructor(
    public readonly elRef: ElementRef,
    public readonly cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngOnInit(): void {
    this.document.fonts.ready.then(() => {
      if (this.destroyed) {
        return;
      }
      this.ngOnChanges();
      this.cdr.detectChanges();
    });
  }

  ngOnChanges() {
    this.chars = null;

    if (!this.inAction) {
      this.cdr.detectChanges();
      this.chars = this.getChars();
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.destroyed = true;
  }

  private getChars() {
    const pathEl = this.elRef.nativeElement.querySelector('path');

    if (!pathEl) {
      return null;
    }

    const { svgG, destroy } = getTextStyledGroup(this.document, this.el);
    const text = getInlineText(this.el.text);

    svgG.innerHTML = `<text><tspan>${text}</tspan></text>`;
    const svgText = svgG.querySelector('text');

    const letters = text.split('');

    const offsets = letters.map((letter, i) => {
      return svgText?.getEndPositionOfChar(i).x || 0;
    });

    destroy();

    return letters.map((letter: string, index: number) => {
      const offset = index > 0 ? offsets[index - 1] : 0;

      const point = pathEl.getPointAtLength(offset);
      const angle = this.getAngleAtLength(pathEl, offset, offsets[index]);

      return {
        point,
        angle,
        letter,
      };
    });
  }

  private getAngleAtLength(
    path: SVGGeometryElement,
    length1: number,
    length2: number
  ): number {
    const point1 = path.getPointAtLength(length1);
    const point2 = path.getPointAtLength(length2);
    const dx = point2.x - point1.x;
    const dy = point2.y - point1.y;
    return (Math.atan2(dy, dx) * 180) / Math.PI;
  }
}
