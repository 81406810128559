import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '../../providers/window.providers';
import { DOCUMENT } from '@angular/common';

export interface IShare extends IConfig {
  type: EShareType;
}

export enum EShareType {
  vkontakte = 'vkontakte',
  odnoklassniki = 'odnoklassniki',
  telegram = 'telegram',
}

interface IConfig {
  url: string;
  icon: string;
  name: string;
}

interface IHrefParams {
  templateUid: string;
}

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  private readonly config: { [key in EShareType]: IConfig } = {
    [EShareType.vkontakte]: {
      url: 'https://vk.com/share.php',
      icon: 'icon-vk-logo-share',
      name: 'Вконтакте',
    },
    [EShareType.odnoklassniki]: {
      url: 'https://connect.ok.ru/dk',
      icon: 'icon-odnoklassniki-logo-share',
      name: 'Одноклассники',
    },
    [EShareType.telegram]: {
      url: 'https://telegram.me/share/url',
      icon: 'icon-telegramm-logo-share',
      name: 'Телеграм',
    },
  };

  private get windowParams() {
    return {
      width: 600,
      height: 400,
      left: this.window.screen.width / 2 - 300,
      top: this.window.screen.height / 2 - 200,
    };
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public getShares(params: IHrefParams): IShare[] {
    return Object.keys(this.config).map((key: string) => {
      const type = key as EShareType;
      const { icon, name } = this.config[type];
      return {
        type,
        name,
        url: this.getUrl(type, params),
        icon,
      };
    });
  }

  public getHref(params: IHrefParams, encode = true) {
    const url = new URL(this.window.location.href);

    if (params.templateUid) {
      url.searchParams.set('template', params.templateUid);
    }

    const href = url.toString();

    return encode ? encodeURIComponent(href) : href;
  }

  private getUrl(type: EShareType, params: IHrefParams): string {
    const href = this.getHref(params);
    const title = encodeURIComponent(this.document.title);
    const { url } = this.config[type];

    switch (type) {
      case EShareType.vkontakte:
        return `${url}?url=${href}&title=${title}&utm_source=share2&noparse=true`;
      case EShareType.telegram:
        return `${url}?url=${href}&text=${title}&utm_source=share2`;
      case EShareType.odnoklassniki:
        return `${url}?st.cmd=WidgetSharePreview&st.shareUrl=${href}&st.title=${title}&st.imageUrl=0&utm_source=share2`;
      default:
        return this.window.location.href;
    }
  }

  public shareOpen(type: EShareType, params: IHrefParams): void {
    const url = this.getUrl(type, params);
    const windowParams = this.windowParams as Record<string, number>;
    const features = Object.keys(windowParams)
      .map((key) => `${key}=${windowParams[key]}`)
      .join(',');
    this.window.open(url, '_blank', features);
  }

  public copyUrl(params: IHrefParams): void {
    if (typeof navigator !== 'undefined') {
      const href = this.getHref(params, false);
      navigator.clipboard.writeText(href).then();
    }
  }
}
