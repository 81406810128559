import {
  EDeviceOrientation,
  TDeviceOrientation,
} from '../interfaces/devices.interface';

export function isTouchDevice() {
  return (
    !!(
      typeof window !== 'undefined' &&
      ('ontouchstart' in window ||
        ((window as any).DocumentTouch &&
          typeof document !== 'undefined' &&
          document instanceof (window as any).DocumentTouch))
    ) ||
    !!(
      typeof navigator !== 'undefined' &&
      (navigator.maxTouchPoints || (navigator as any).msMaxTouchPoints)
    )
  );
}

export function isMobile(): boolean {
  const isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
      return (
        isMobile.Android() ||
        isMobile.BlackBerry() ||
        isMobile.iOS() ||
        isMobile.Opera() ||
        isMobile.Windows()
      );
    },
  };
  return Boolean(isMobile.any());
}

export function isTouchDeviceUseMatchMedia(): boolean {
  let result = false;
  try {
    result = matchMedia('(pointer: coarse)').matches;
  } catch (err) {
    result = false;
  }
  return result;
}

export function getDeviceOrientation(): TDeviceOrientation {
  let result = EDeviceOrientation.landscape;
  try {
    if (matchMedia('(orientation: portrait)').matches) {
      result = EDeviceOrientation.portrait;
    } else {
      result = EDeviceOrientation.landscape;
    }
  } catch (err) {
    result = EDeviceOrientation.landscape;
  }

  return result;
}
