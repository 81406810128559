import { InjectionToken } from '@angular/core';
import { IPaginationConfig } from '../../interfaces';

export interface PaginationStoreConfig {
  storeUid: string;
  moduleUid: string;
  config: IPaginationConfig;
}

export const PAGINATION_STORE_CONFIG =
  new InjectionToken<PaginationStoreConfig>('pagination.store.config');
