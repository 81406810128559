import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IDropdownOption } from '../../interfaces';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from '../../directives';

@Component({
  selector: 'design-popup-select-box',
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective],
  templateUrl: './design-popup-select-box.component.html',
  styleUrls: ['./design-popup-select-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignPopupSelectBoxComponent {
  @Input() isNew = false;
  @Input() options: IDropdownOption[] = [];
  @Input() activeOption?: IDropdownOption;
  @Input() isDisabled = false;
  @Input() emptyLabel = '-';

  @Output() changeOption = new EventEmitter<IDropdownOption>();

  isSelectListVisible = false;

  onClickList(option: IDropdownOption) {
    this.changeOption.emit(option);
    this.hideSelectList();
  }

  toggleSelectList() {
    this.isSelectListVisible = !this.isSelectListVisible;
  }

  hideSelectList() {
    this.isSelectListVisible = false;
  }

  showSelectList() {
    this.isSelectListVisible = true;
  }
}
