<div
  #cropContainer
  cropModalWorkArea
  class="crop-container"
  [class.grab]="!isGrabbing && (isGrabActive$ | async)"
  [class.grabbing]="isGrabbing"
  [cropModalWorkAreaContainer]="cropImage"
  (dragStart)="onDragStart()"
  (dragEnd)="onDragEnd()"
>
  <div class="crop-container__image">
    <div class="crop-image" #cropImage>
      <crop-image [imageUrl]="imageUrl"></crop-image>
    </div>
  </div>
  <div class="crop-box" #cropBox>
     <crop-mask [mask]="mask" *ngIf="mask$ | async as mask"></crop-mask>
  </div>
</div>
