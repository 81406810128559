@if (product$ | async; as product) {
<div class="content-product">
  <h1 class="content-product__name">
    {{ product.name }}
    <i class="icon icon-ic_pencil" (click)="selectProduct()"></i>
  </h1>

  <painter-content-product-navigation
    class="content-product__nav"
  ></painter-content-product-navigation>

  @if (designerPropertySource$ | async; as designerPropertySource) {
  <painter-content-product-properties
    class="content-product__properties"
    [propertySource]="designerPropertySource"
    (changeParams)="changeParams($event)"
  ></painter-content-product-properties>
  }

  <painter-content-product-order
    class="content-product__order"
  ></painter-content-product-order>
</div>
}
