<div class="range-slider">
  <form [formGroup]="formGroup" novalidate>
    <div class="range-slider__range">
      <div class="range-slider__form-element">
        <span>от</span>
        <input
          type="number" class="range-slider__input"
          formControlName="minValue"
          autocomplete="off"
          [min]="(range || DEFAULT_FILTER).minValue"
          [max]="(values || DEFAULT_FILTER).maxValue"
          [step]="step"
          (keydown.enter)="updateFormValues(formGroup.value)"
          (blur)="updateFormValues(formGroup.value)"
        >
      </div>
      <div class="range-slider__form-element">
        <span>до</span>
        <input type="number" class="range-slider__input"
          formControlName="maxValue"
          autocomplete="off"
          [min]="(values || DEFAULT_FILTER).minValue"
          [max]="(range || DEFAULT_FILTER).maxValue"
          [step]="step"
          (keydown.enter)="updateFormValues(formGroup.value)"
          (blur)="updateFormValues(formGroup.value)"
        >
      </div>
    </div>
  </form>
</div>

