import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IBannerImage, PipesModule } from '@inaripro-nx/common-ui';

@Component({
  selector: 'design-banner',
  standalone: true,
  imports: [CommonModule, RouterModule, PipesModule],
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [NO_ERRORS_SCHEMA],
})
export class BannerComponent {
  @Input() banner: IBannerImage | null = null;
}
