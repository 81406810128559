import {
  PaginationOrderByType,
  PaginationOrderDirectionType,
} from '../interfaces';

export const PAGINATION_ORDER_BY = {
  DEFAULT: 'default' as PaginationOrderByType,
  NAME: 'name' as PaginationOrderByType,
  NAME_SORT: 'name_sort' as PaginationOrderByType,
  DATE: 'date' as PaginationOrderByType,
  USE_TOTAL: 'useTotal' as PaginationOrderByType,
  PRICE: 'price' as PaginationOrderByType,
  POPULARITY: 'popularity' as PaginationOrderByType,
  CREATE_TIME: 'create_time' as PaginationOrderByType,
};

export const PAGINATION_ORDER_DIRECTION = {
  ASC: 'asc' as PaginationOrderDirectionType,
  DESC: 'desc' as PaginationOrderDirectionType,
};
