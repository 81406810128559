import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IArea, IDesignZone } from '@inaripro-nx/catalog';
import { IPattern } from '@inaripro-nx/common-ui';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[data-painter-editor-zone-paint]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './editor-zone-paint.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorZonePaintComponent {
  @Input() zone!: IDesignZone;
  @Input() zoneColor?: string;
  @Input() zonePattern?: IPattern;
  @Input() isZoneExport = false;
}
