import { IFileResponse } from '@inaripro-nx/common-ui';
import {
  ProductTypeEnum,
  ICategoryIdentify,
  EPropertyType,
  IPublicAlbumThumbs,
  IPublicPrice,
  IPublicProductDesignSeo,
} from '@inaripro-nx/catalog';
import { IShareTemplate } from './templates.interface';

export enum EFilterPropertyKey {
  kit = 'k',
}

export enum EFilterPropertyName {
  kit = 'Уточнение',
}
export interface IDesignerElementKeys {
  [designerProductId: string]: { [key: string]: boolean };
}

export interface IDesignerProduct {
  id: number;
  name: string;
  productType: ProductTypeEnum;
  description: string;
  containerDictionaryId: number;
  containers: ICategoryIdentify[];
  price: IPublicPrice;
  album: IFileResponse[];
  previews: IPublicAlbumThumbs[];
  video?: string[];
  shortDescription?: string;
  productTypeHelpText?: string;
  designSeo?: IPublicProductDesignSeo;
  lotSize?: number;
  maxRange?: number;
  minRange?: number;
  shareTemplate: IShareTemplate | null;
  autoDownload: boolean;
}

export interface IElementInfo {
  id: number;
  name: string;
  color?: string;
}

export interface IPropertyInfo {
  id: number;
  name: string; // alias
  type: EPropertyType | null;
  showHelper: boolean;
  helperText: string;
  elements?: IElementInfo[];
  val?: string | number;
}

export interface IDesignerPropertyElement {
  id: string;
  propertyId: string;
  name: string;
  color?: string;
  inPrice: boolean;
  inOrder: boolean;
  inDesign: boolean;
}

export interface IDesignerPropertyElementMap {
  [id: string]: IDesignerPropertyElement;
}

export interface IDesignerProperty {
  id: string | EFilterPropertyKey.kit;
  name: string | EFilterPropertyName.kit;
  type: EPropertyType | null;
  isSeparateProductCount?: boolean;
  elements: IDesignerPropertyElement[];
  elementsMap: IDesignerPropertyElementMap; // links to elements
  inPrice: boolean;
  inOrder: boolean;
  inDesign: boolean;
}

export interface IDesignerPropertyMap {
  [id: string]: IDesignerProperty;
}

export interface IDesignerSource {
  productId: number;
  properties: IDesignerProperty[];
  propertiesMap: IDesignerPropertyMap; // links to properties
  allElementsMap: IDesignerPropertyElementMap; // links to elements
  selected: string[];
  disabled: string[];
  fullColor: string | null;
}
