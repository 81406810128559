import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { getUrlFromSvg } from '../../utils';

@Pipe({
  name: 'imageXLink',
})
export class ImageXlinkPipe implements PipeTransform {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  transform<T extends { svg?: string }>(element?: T): string | null {
    if (!element?.svg) {
      return null;
    }

    return getUrlFromSvg(this.document, element.svg);
  }
}
