export const APP_ICONS = [
  { name: 'attach-file', unicode: [''], color: '#F44336' },
  { name: 'attachment', unicode: [''] },
  { name: 'baseline-check', unicode: [''] },
  { name: 'calculator', unicode: [''], color: '#F44336' },
  { name: 'card_red', unicode: [''], color: '#F44336' },
  { name: 'cart', unicode: [''], color: '#9E9E9E' },
  { name: 'crop', unicode: [''], color: '#3D3D3D' },
  { name: 'design-sides', unicode: [''], color: '#3D3D3D' },
  { name: 'discount-box', unicode: [''], color: '#ee3e36' },
  { name: 'equalizer', unicode: [''] },
  { name: 'facebook-with-circle', unicode: [''] },
  { name: 'facebook', unicode: [''], color: '#9E9E9E' },
  { name: 'fill', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_access_time', unicode: [''] },
  { name: 'ic_account_circle', unicode: [''] },
  { name: 'ic_add', unicode: [''] },
  { name: 'ic_add_circle', unicode: [''] },
  { name: 'ic_arrow-back', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_arrow-breadcrumbs', unicode: [''], color: '#F44336' },
  { name: 'ic_arrow-down', unicode: [''], color: '#9E9E9E' },
  { name: 'ic_arrow-forward', unicode: [''], color: '#D9D9D9' },
  { name: 'ic_arrow-up', unicode: [''], color: '#D9D9D9' },
  { name: 'ic_arrow', unicode: [''] },
  { name: 'ic_arrow_left_slider_preview', unicode: [''] },
  { name: 'ic_arrow_right_circle', unicode: [''], color: 'white' },
  { name: 'ic_arrow_rigth_slider_preview', unicode: [''] },
  { name: 'ic_attach_file', unicode: [''] },
  { name: 'ic_attach_file_tilted', unicode: [''], color: '#0091D9' },
  { name: 'ic_attach_money', unicode: [''] },
  { name: 'ic_basket', unicode: [''], color: 'white' },
  { name: 'ic_block', unicode: [''] },
  { name: 'ic_box', unicode: [''] },
  { name: 'ic_brush', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_business', unicode: [''] },
  { name: 'ic_calc', unicode: [''] },
  { name: 'ic_call', unicode: [''] },
  { name: 'ic_cancel', unicode: [''] },
  { name: 'ic_center-horizontally', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_center-vertically', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_check-mark', unicode: [''], color: 'white' },
  { name: 'ic_check_box', unicode: [''] },
  { name: 'ic_check_box_outline', unicode: [''] },
  { name: 'ic_check_circle', unicode: [''], color: 'white' },
  { name: 'ic_check_offline', unicode: [''], color: '#D9D9D9' },
  { name: 'ic_check_online', unicode: [''], color: '#0FB834' },
  { name: 'ic_chrome_reader_mode', unicode: [''] },
  { name: 'ic_circle-plus', unicode: [''], color: '#F44336' },
  { name: 'ic_close', unicode: [''] },
  { name: 'ic_code', unicode: [''] },
  { name: 'ic_color-picker', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_color', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_comparison', unicode: [''], color: '#F44336' },
  { name: 'ic_contact_calendar', unicode: [''] },
  { name: 'ic_copy', unicode: [''], color: '#C4C4C4' },
  { name: 'ic_copyright', unicode: [''] },
  { name: 'ic_cross-close', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_cross-plus', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_date_range', unicode: [''] },
  { name: 'ic_delete_forever', unicode: [''] },
  { name: 'ic_done', unicode: [''] },
  { name: 'ic_done_circle', unicode: [''] },
  { name: 'ic_dots', unicode: [''], color: '#9E9E9E' },
  { name: 'ic_edit', unicode: [''] },
  { name: 'ic_exit_to_app', unicode: [''] },
  { name: 'ic_eye_passw', unicode: [''] },
  { name: 'ic_favorite', unicode: [''] },
  { name: 'ic_favorite_border', unicode: [''] },
  { name: 'ic_fiber_new', unicode: [''] },
  { name: 'ic_figure-arrow-rigth-big', unicode: [''], color: '#C4C4C4' },
  { name: 'ic_figure-arrow-rigth', unicode: [''], color: '#C4C4C4' },
  { name: 'ic_figure-circle-talk', unicode: [''], color: '#C4C4C4' },
  { name: 'ic_figure-circle', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_figure-crown', unicode: [''], color: '#C4C4C4' },
  { name: 'ic_figure-daimond', unicode: [''], color: '#C4C4C4' },
  { name: 'ic_figure-eight-angle', unicode: [''], color: '#C4C4C4' },
  { name: 'ic_figure-five-angle', unicode: [''], color: '#C4C4C4' },
  { name: 'ic_figure-plant-leaf', unicode: [''], color: '#C4C4C4' },
  { name: 'ic_figure-rectangle-talk', unicode: [''], color: '#C4C4C4' },
  { name: 'ic_figure-square', unicode: [''], color: '#C4C4C4' },
  {
    name: 'ic_figure-squre-border-radius-10',
    unicode: [''],
    color: '#C4C4C4',
  },
  { name: 'ic_figure-star', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_figure-stars', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_figure-triangle', unicode: [''], color: '#C4C4C4' },
  { name: 'ic_figures', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_filters', unicode: [''] },
  { name: 'ic_filter_list', unicode: [''] },
  { name: 'ic_filter_list2', unicode: [''] },
  { name: 'ic_flag', unicode: [''] },
  { name: 'ic_flip-horizontally', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_flip-vertically', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_fullscreen', unicode: [''] },
  { name: 'ic_fullscreen_exit', unicode: [''] },
  { name: 'ic_grab', unicode: [''] },
  { name: 'ic_group', unicode: [''] },
  { name: 'ic_help_fill', unicode: [''] },
  { name: 'ic_help_filled', unicode: [''], color: '#9E9E9E' },
  { name: 'ic_help_outline_24', unicode: [''] },
  { name: 'ic_inari_logo_min', unicode: [''] },
  { name: 'ic_indeterminate_check_box', unicode: [''] },
  { name: 'ic_info-cursive', unicode: [''], color: '#EDEDED' },
  { name: 'ic_info', unicode: [''] },
  { name: 'ic_info_outline', unicode: [''] },
  { name: 'ic_insert_drive_file', unicode: [''] },
  { name: 'ic_keyboard_arrow_down', unicode: [''] },
  { name: 'ic_keyboard_arrow_left_18', unicode: [''] },
  { name: 'ic_keyboard_arrow_right', unicode: [''] },
  { name: 'ic_keyboard_arrow_up', unicode: [''] },
  { name: 'ic_label_location', unicode: [''], color: '#F44336' },
  { name: 'ic_label_rectangle_location', unicode: [''], color: 'white' },
  { name: 'ic_layers', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_letter-t', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_link', unicode: [''] },
  { name: 'ic_link_2', unicode: [''], color: '#9E9E9E' },
  { name: 'ic_local_offer', unicode: [''] },
  { name: 'ic_mail_outline', unicode: [''] },
  { name: 'ic_map_location', unicode: [''], color: '#F44336' },
  { name: 'ic_map_location_empty', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_menu_24', unicode: [''] },
  { name: 'ic_minus', unicode: [''] },
  { name: 'ic_monetization', unicode: [''] },
  { name: 'ic_more_horiz', unicode: [''] },
  { name: 'ic_move', unicode: [''] },
  { name: 'ic_my_location', unicode: [''] },
  { name: 'ic_nature_people', unicode: [''] },
  { name: 'ic_paper', unicode: [''], color: '#9E9E9E' },
  { name: 'ic_pencil', unicode: [''], color: '#F44336' },
  { name: 'ic_perm_data_setting', unicode: [''] },
  { name: 'ic_phonelink_lock', unicode: [''] },
  { name: 'ic_photo', unicode: [''] },
  { name: 'ic_picture', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_play', unicode: [''], color: '#F44336' },
  { name: 'ic_play_circle_outline', unicode: [''] },
  { name: 'ic_plus', unicode: [''] },
  { name: 'ic_power', unicode: [''] },
  { name: 'ic_product', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_qr-code', unicode: [''] },
  { name: 'ic_question', unicode: [''], color: '#0091D9' },
  { name: 'ic_refresh_black', unicode: [''], color: '#000000' },
  { name: 'ic_remove', unicode: [''] },
  { name: 'ic_revert-close', unicode: [''], color: '#F44336' },
  { name: 'ic_rotate-phone', unicode: [''] },
  { name: 'ic_rotate', unicode: [''], color: 'white' },
  { name: 'ic_save', unicode: [''] },
  { name: 'ic_save_2', unicode: [''] },
  { name: 'ic_school', unicode: [''] },
  { name: 'ic_search', unicode: [''] },
  { name: 'ic_settings', unicode: [''] },
  { name: 'ic_share_link', unicode: [''], color: '#9E9E9E' },
  { name: 'ic_shield', unicode: [''] },
  { name: 'ic_shopping_cart', unicode: [''], color: 'white' },
  { name: 'ic_size', unicode: [''], color: 'white' },
  { name: 'ic_sort', unicode: [''] },
  { name: 'ic_star', unicode: [''] },
  { name: 'ic_store', unicode: [''] },
  { name: 'ic_stretch', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_subject', unicode: [''] },
  { name: 'ic_support', unicode: [''] },
  { name: 'ic_text-align-center', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_text-align-justify', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_text-align-left', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_text-align-right', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_text-bold', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_text-italic', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_text', unicode: [''], color: '#3D3D3D' },
  { name: 'ic_thumb', unicode: [''] },
  { name: 'ic_transformation', unicode: [''], color: '#0091D9' },
  { name: 'ic_trash', unicode: [''], color: '#F44336' },
  { name: 'ic_view_list', unicode: [''] },
  { name: 'ic_warning', unicode: [''] },
  { name: 'if_category', unicode: [''] },
  { name: 'instagram-with-circle', unicode: [''] },
  { name: 'instagram', unicode: [''], color: '#9E9E9E' },
  { name: 'line-space', unicode: [''], color: '#0091D9' },
  { name: 'lock', unicode: [''] },
  { name: 'magic-wand', unicode: [''] },
  { name: 'marker', unicode: [''], color: '#9E9E9E' },
  { name: 'more', unicode: [''] },
  { name: 'odnoklassniki-logo-share', unicode: [''], color: 'white' },
  { name: 'odnoklassniki-logo', unicode: [''] },
  { name: 'ok', unicode: [''], color: '#9E9E9E' },
  { name: 'partners', unicode: [''], color: '#87B301' },
  { name: 'pdf', unicode: [''] },
  { name: 'star', unicode: [''], color: '#F44336' },
  { name: 'telegramm-logo-share', unicode: [''], color: '#f44336' },
  { name: 'text-arc', unicode: [''], color: '#3D3D3D' },
  { name: 'text-letter-spacing', unicode: [''], color: '#3D3D3D' },
  { name: 'tt', unicode: [''], color: '#3D3D3D' },
  { name: 'twitter-with-circle', unicode: [''] },
  { name: 'viber', unicode: [''], color: '#9E9E9E' },
  { name: 'vimeo-with-circle', unicode: [''] },
  { name: 'vk-logo-share', unicode: [''] },
  { name: 'vk-logo', unicode: [''] },
  { name: 'vk-with-circle', unicode: [''] },
  { name: 'vk', unicode: [''], color: '#9E9E9E' },
  { name: 'yookassa', unicode: [''], color: 'url(#paint1_linear)' },
  { name: 'youtube-logo', unicode: [''] },
  { name: 'youtube', unicode: [''] },
  { name: 'zoom_in', unicode: [''] },
];
