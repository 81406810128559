import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'design-tool-button',
  templateUrl: './tool-button.component.html',
  styleUrls: ['./tool-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ToolButtonComponent {
  @Input() active: boolean | null = null;
  @Input() selected: boolean | null = null;
  @Input() disabled: boolean | null = null;
  @Input() title?: string = '';
}
