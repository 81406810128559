@if (product) {
  <div
    [attr.id]="'product-card-' + product.id"
    class="catalog-preview"
    [class.catalog-preview--calc]="productCardType === PRODUCT_CARD_TYPES.CALCULATOR"
    [class.catalog-preview--selected]="isSelected"
  >
    @if (isSelected) {
      <div class="catalog-preview__selected">
        <i class="icon icon-ic_check_circle"></i>
      </div>
    }
    <div class="catalog-preview__photo">
      <div #imageContainer>
        <ng-container [ngTemplateOutlet]="isLazyLoadImage ? lazyImage : image"></ng-container>
      </div>
      @if (user && canEditProduct) {
        <a
          [href]="cabinetHrefLink"
          target="_blank"
          class="catalog-preview__link-settings"
          [class.catalog-preview__link-settings--moderation]="user.role === USER_ROLES.Administrator"
          (click)="$event.stopPropagation()"
        >
          <i class="icon icon-ic_account_circle"></i>
        </a>
        <catalog-online-payment
          class="catalog-preview__online-payment"
          [isOn]="product.onlinePayment"
          [isTitle]="true"
        ></catalog-online-payment>
      }
      <div class="fogging-block"></div>
    </div>
    <div class="catalog-preview__description">
      <div class="catalog-preview__title">{{ product.name }}</div>
      <div class="catalog-preview__colors">
        <design-color-button-circle
          class="catalog-preview__color-item"
          *ngFor="let color of (product.colors || []).slice(0, 20)"
          [color]="color"
        ></design-color-button-circle>
      </div>

      <catalog-product-card-prices
        *ngIf="productCardType === PRODUCT_CARD_TYPES.PRODUCT && !hideOptions"
        [product]="product"
        [unitName]="unitName"
      >
      </catalog-product-card-prices>

      <div
        *ngIf="productCardType === PRODUCT_CARD_TYPES.PRODUCT && !hideOptions"
        class="catalog-preview__product-id"
      >
        <p>Артикул: {{ product.id }}</p>
        <p *ngIf="product?.brand">Бренд: {{ product.brand }}</p>
        <ng-container *ngIf="product.stockCount as stockCount">
          <p *ngIf="userCityName && !(stockCount.city | isUndefined)">
            <ng-container
              >{{ userCityName }}: {{ stockCount.city | number: '1.0-0' }}
              {{ unitName || 'шт.' }}</ng-container
            >
          </p>
          <p>
            Всего: {{ stockCount.total | number: '1.0-0' }}
            {{ unitName || 'шт.' }}
          </p>
        </ng-container>
        <ng-template *ngIf="!product.brand">
          <p>&nbsp;</p>
        </ng-template>
      </div>

      <ng-container *ngIf="productCardType === PRODUCT_CARD_TYPES.CALCULATOR">
        <button type="button" class="catalog-preview__calc-btn">
          Рассчитать
        </button>
        <i class="icon icon-calculator"></i>
      </ng-container>
    </div>
  </div>
}

<ng-template #image>
  <div
    #hoverSectors
    class="hvr__sectors"
    [class.hvr__sectors--active]="swipedIndex !== null"
  >
    <div class="hvr__sector" *ngFor="let img of product.logo; let i = index">
      <img
        [alt]="product.name"
        [src]="getLogo(img).url"
        [class.swiped-active]="i === swipedIndex"
      />
      <div class="hvr__dot" [class.swiped-active]="i === swipedIndex"></div>

      <div class="catalog-preview__badges" *ngIf="i === 0">
        <catalog-product-card-badges
          [product]="product"
        ></catalog-product-card-badges>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #lazyImage>
  <div
    #hoverSectors
    class="hvr__sectors"
    [class.hvr__sectors--active]="swipedIndex !== null"
    [class.hvr__sectors--swiped]="swipedIndex !== null"
  >
    <div class="hvr__sector" *ngFor="let img of product.logo; let i = index">
      <img
        [alt]="product.name"
        [defaultImage]="defaultImage"
        [lazyLoad]="getLogo(img).url"
        [class.swiped-active]="i === swipedIndex"
      />
      <div class="hvr__dot" [class.swiped-active]="i === swipedIndex"></div>

      <div
        class="catalog-preview__badges"
        *ngIf="(swipedIndex === null && i === 0) || swipedIndex === 0"
      >
        <catalog-product-card-badges
          [product]="product"
        ></catalog-product-card-badges>
      </div>
    </div>
  </div>
</ng-template>
