<svg:text
  x="0"
  y="0"
  dy="0"
  [attr.font-size]="DEFAULT_TEXT_SIZE"
  [attr.letter-spacing]="el.letterSpacing"
  [attr.font-style]="el.fontStyle"
  [attr.font-weight]="el.fontWeight"
  [attr.font-family]="el.fontFamily | fontFamily"
>
  @for (line of el.text.split('\n'); track $index; let i = $index) {
    <tspan
      x="0"
      [attr.dx]="linesDx[i] || 0"
      [attr.dy]="(i === 0 ? 1 : el.lineSpace) * DEFAULT_TEXT_SIZE"
      [innerHTML]="line || '.' | safeHtml"
      [attr.stroke-width]="line ? 'inherit' : 0"
      [attr.fill]="line ? 'inherit' : 'none'"
    ></tspan>
  }
</svg:text>
