import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IBreadcrumb } from '../../interfaces/breadcrumbs.interface';

@Component({
  selector: 'design-breadcrumbs',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
  @Input() breadcrumbs: IBreadcrumb[] = [];
  @Input() backItemIndex = 2;
  @Input() oftenSearchId: number | null = null;
}
