import { Injectable } from '@angular/core';
import {
  CatalogCharacteristicsService,
  CatalogNomenclatureStore,
  CatalogProductService,
  IDesignProduct,
  IProperty,
  IPublicPrice,
  IPublicProductWithPrice,
  getOrderCount,
} from '@inaripro-nx/catalog';

import {
  getSumCountUid,
  IDesignerElementKeys,
  IDesignerProduct,
  IDesignerSource,
  IPropertyInfo,
} from '@inaripro-nx/painter';

import {
  blockRobots,
  setDescription,
  setKeywords,
  setTitle,
  sumArray,
} from '@inaripro-nx/common-ui';
import { OrderCartStore } from '@inaripro-nx/order';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  EMPTY,
  MonoTypeOperatorFunction,
  Observable,
  Subject,
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  of,
  shareReplay,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  getActiveDesignProductIndex,
  getDesignerElementKeys,
  getDesignerProduct,
  getDesignerSource,
  getInitDesignerSource,
  getPriceUid,
  getPropertiesInfo,
  isProductError,
} from './product.utils';
import { ShareTemplatesService } from '../share-templates/share-templates.service';

const consoleLevel = 0; // чем выше, тем больше данных выводится в console
const log = <T>(name: string, level = 1): MonoTypeOperatorFunction<T> =>
  tap((obj) => consoleLevel >= level && console.log({ [name]: obj }));

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  readonly loadingProduct$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  readonly updatingPrice$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  private readonly productProperties$: Observable<IProperty[]> =
    this.catalogCharacteristicsService.getCatalogCharacteristics().pipe(
      map((response) => response.data),
      shareReplay({ refCount: false, bufferSize: 1 })
    );

  private readonly productId$: Subject<number | null> = new Subject();

  private readonly templateUidSubject$ = new BehaviorSubject<string | null>(
    null
  );

  private readonly autoDownloadSubject$ = new BehaviorSubject<boolean>(false);

  readonly queryElements$: Subject<string[]> = new Subject();
  readonly draftCount$: Subject<number> = new Subject();

  readonly product$: Observable<IPublicProductWithPrice | null> =
    this.productId$.pipe(
      distinctUntilChanged(),
      switchMap((productId) => {
        if (productId) {
          this.loadingProduct$.next(true);

          return this.catalogProductService.getProduct(productId).pipe(
            map((product) => (isProductError(product) ? null : product)),
            tap((product) => {
              if (product) {
                this.updateMeta(product);
                this.catalogNomenclatureStore.loadTreeItem(
                  product.containerDictionaryId
                );
              }

              this.loadingProduct$.next(false);
            }),
            catchError(() => {
              this.loadingProduct$.next(false);
              return EMPTY;
            })
          );
        } else {
          return of(null);
        }
      }),
      shareReplay({ refCount: false, bufferSize: 1 })
    );

  readonly orderCount$: Observable<number> = combineLatest([
    this.product$,
    this.draftCount$,
  ]).pipe(
    map(([product, draftCount]) => {
      if (!product) {
        return 1;
      }

      return getOrderCount(draftCount, product);
    }),
    distinctUntilChanged(),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly designerProduct$: Observable<IDesignerProduct | null> =
    this.product$.pipe(
      withLatestFrom(this.templateUidSubject$, this.autoDownloadSubject$),
      switchMap(([product, templateUid, autoDownload]) => {
        if (templateUid) {
          return this.shareTemplatesService
            .getTemplate(templateUid)
            .pipe(
              map((shareTemplate) =>
                getDesignerProduct(product, shareTemplate, autoDownload)
              )
            );
        } else {
          return of(getDesignerProduct(product, null, autoDownload));
        }
      }),
      shareReplay({ refCount: false, bufferSize: 1 })
    );

  readonly propertiesInfo$: Observable<IPropertyInfo[]> = combineLatest([
    this.productProperties$,
    this.product$,
  ]).pipe(
    map(([allProductProperties, product]) =>
      getPropertiesInfo(allProductProperties, product)
    ),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  private readonly designerElementKeys$: Observable<IDesignerElementKeys | null> =
    this.product$.pipe(
      map(getDesignerElementKeys),
      log('designerElementKeys'),
      shareReplay({ refCount: false, bufferSize: 1 })
    );

  private readonly initDesignerSource$: Observable<IDesignerSource | null> =
    combineLatest([this.productProperties$, this.product$]).pipe(
      map(([allProductProperties, product]) =>
        getInitDesignerSource(allProductProperties, product)
      ),
      log('initDesignerSource'),
      shareReplay({ refCount: false, bufferSize: 1 })
    );

  readonly designerSource$: Observable<IDesignerSource | null> = combineLatest([
    this.product$,
    this.designerElementKeys$,
    this.initDesignerSource$,
    this.queryElements$,
  ]).pipe(
    debounceTime(0),
    map(([product, designerElementKeys, initDesignerSource, queryElements]) => {
      const ds = getDesignerSource({
        product,
        designerElementKeys,
        initDesignerSource,
        queryElements,
      });
      return ds;
    }),
    log('designerSource'),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  private readonly activeDesignProductIndex$: Observable<number | null> =
    combineLatest([
      this.product$,
      this.designerElementKeys$,
      this.designerSource$,
    ]).pipe(
      debounceTime(0),
      map(([product, designerElementKeys, designerSource]) =>
        getActiveDesignProductIndex({
          designProducts: product?.designProducts || [],
          designerElementKeys,
          designerSource,
        })
      ),
      distinctUntilChanged(),
      log('activeDesignProductIndex', 2),
      shareReplay({ refCount: false, bufferSize: 1 })
    );

  readonly activeDesignProduct$: Observable<IDesignProduct | null> =
    combineLatest([this.product$, this.activeDesignProductIndex$]).pipe(
      debounceTime(0),
      shareReplay({ refCount: false, bufferSize: 1 }),
      map(([product, activeDesignProductIndex]) => {
        if (!product || activeDesignProductIndex === null) {
          return null;
        }

        return (product.designProducts || [])[activeDesignProductIndex] || null;
      }),
      log('activeDesignProduct'),
      shareReplay({ refCount: false, bufferSize: 1 })
    );

  private readonly priceUid$: Observable<string | null> =
    this.designerSource$.pipe(
      map((designerSource) => getPriceUid(designerSource)),
      distinctUntilChanged(),
      log('priceUid'),
      shareReplay({ refCount: false, bufferSize: 1 })
    );

  private readonly findPrice$: Observable<IPublicPrice | null> = combineLatest([
    this.product$,
    this.priceUid$,
  ]).pipe(
    debounceTime(0),
    switchMap(([product, priceUid]) => {
      if (!product || priceUid === null) {
        return of(null);
      }

      if (priceUid === product.price.uid) {
        return of({ ...product.price });
      }

      this.updatingPrice$.next(true);

      return this.catalogProductService
        .getProductPriceByUID(product.id, priceUid)
        .pipe(
          map((priceByUid) => ({ ...product.price, ...priceByUid })),
          tap(() => this.updatingPrice$.next(false)),
          catchError(() => {
            this.updatingPrice$.next(false);
            return EMPTY;
          })
        );
    }),
    log('findPrice'),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly price$: Observable<IPublicPrice | null> = combineLatest([
    this.activeDesignProduct$.pipe(
      map((dp) => !!dp),
      distinctUntilChanged()
    ),
    this.findPrice$,
  ]).pipe(
    debounceTime(0),
    map(([hasDP, findPrice]) => (hasDP ? findPrice : null)),
    log('price', 2),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  private readonly sumCountUid$: Observable<string> = this.designerSource$.pipe(
    map((designerSource) => getSumCountUid(designerSource)),
    distinctUntilChanged(),
    log('sumCountUid', 2),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly countInBasket$: Observable<number> = combineLatest([
    this.product$.pipe(
      map((p) => p?.id || null),
      distinctUntilChanged()
    ),
    this.orderCartStore.products$,
    this.sumCountUid$,
  ]).pipe(
    map(([productId, products, sumCountUid]) => {
      if (!productId) {
        return 0;
      }

      const countInBasket =
        sumArray(
          (products || [])
            .filter(
              (bp) =>
                bp.selected &&
                bp.productId === productId &&
                bp.sumCountUid === sumCountUid
            )
            .map((bp) => bp.count)
        ) || 0;

      return countInBasket;
    }),
    log('countInBasket', 2),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  constructor(
    private readonly catalogProductService: CatalogProductService,
    private readonly catalogCharacteristicsService: CatalogCharacteristicsService,
    private readonly catalogNomenclatureStore: CatalogNomenclatureStore,
    private readonly orderCartStore: OrderCartStore,
    private readonly store: Store,
    private readonly shareTemplatesService: ShareTemplatesService
  ) {}

  public setProductId(id: number | null) {
    // clear product before it load
    this.productId$.next(null);
    this.queryElements$.next([]);
    this.draftCount$.next(1);
    // set for load product
    this.productId$.next(id);
  }

  public setTemplateUid(uid: string | null) {
    this.templateUidSubject$.next(uid);
  }

  public setAutoDownload(v: boolean) {
    this.autoDownloadSubject$.next(v);
  }

  private updateMeta(product: IPublicProductWithPrice) {
    let { title, description } = product?.designSeo || {};

    if (!title) {
      title = `Конструктор ${product.name}: дизайн принта бесплатно`;
    }
    this.store.dispatch(setTitle({ payload: title }));

    if (!description) {
      description = `Создать макет ${product.name} со своим принтом и надписью, печать изображений на футболках, толстовках и майках`;
    }
    this.store.dispatch(setDescription({ payload: description }));

    const { keywords } = product?.designSeo || {};

    if (keywords) {
      this.store.dispatch(setKeywords({ payload: keywords }));
    }

    if (!product?.designSeo?.index) {
      this.store.dispatch(blockRobots());
    }
  }
}
