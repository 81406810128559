import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppShellConfig } from '@inaripro-nx/common-ui';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { ProductStore } from '../../../../state/product/product.store';
import { ContentProductNavigationComponent } from './components/content-product-navigation/content-product-navigation.component';
import { ContentProductOrderComponent } from './components/content-product-order/content-product-order.component';
import { ContentProductPropertiesComponent } from './components/content-product-properties/content-product-properties.component';

@Component({
  selector: 'painter-content-product',
  standalone: true,
  imports: [
    AsyncPipe,
    DesignUiModule,
    ContentProductNavigationComponent,
    ContentProductOrderComponent,
    ContentProductPropertiesComponent,
  ],
  templateUrl: './content-product.component.html',
  styleUrls: ['./content-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentProductComponent {
  readonly product$ = this.productStore.product$;
  readonly activeDesignSide$ = this.productStore.activeDesignSide$;
  readonly activeDesignProduct$ = this.productStore.activeDesignProduct$;
  readonly designerPropertySource$ = this.productStore.designerSource$;

  constructor(private readonly productStore: ProductStore) {}

  selectProduct() {
    this.productStore.selectProduct$.next(false);
  }

  changeParams(params: string | null = null) {
    this.productStore.changeParams$.next(params);
  }
}
