<ng-container
  [ngTemplateOutlet]="sliderTemplate || defaultSliderTemplate"
  [ngTemplateOutletContext]="{
    minValue: minValue,
    maxValue: maxValue,
    value: element.strokeWidth || DEFAULT_VALUE_STROKE_WIDTH,
    changeValue: changeValue.bind(this),
    stopChange: stopChange.bind(this),
    reset: reset.bind(this)
  }"
></ng-container>

<ng-template
  #defaultSliderTemplate
  let-minValue="minValue"
  let-maxValue="maxValue"
  let-value="value"
  let-changeValue="changeValue"
  let-stopChange="stopChange"
  let-reset="reset"
>
  <design-range-slider-wrapper
    [label]="'Обводка'"
    [unitmeasurement]="''"
    [minValue]="minValue"
    [maxValue]="maxValue"
    [value]="value"
    [decimalPoints]="DECIMAL_POINTS_STROKE"
    [isShowInputValue]="false"
    (changeValue)="changeValue($event)"
    (stopChange)="stopChange()"
    (resetValue)="reset()"
  ></design-range-slider-wrapper>
</ng-template>
<painter-modal-stroke
  [element]="element"
  [sliderTemplate]="sliderTemplate"
  [modalUid]="modalUid"
  [modificator]="modificator"
  [prefix]="prefix"
>
  <ng-content></ng-content>
</painter-modal-stroke>
