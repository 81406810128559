import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  from,
  map,
  mergeMap,
  Observable,
  shareReplay,
  tap,
  withLatestFrom,
  combineLatest,
} from 'rxjs';
import { CROP_BOX_DEFAULT_HEIGHT, CROP_BOX_DEFAULT_WIDTH } from '../constants';
import {
  getTextFromUrl,
  linkToGlobalState,
  normalizeFloat,
} from '@inaripro-nx/common-ui';
import { removePreserveAspectRatio } from '../utils/crop.utils';
import { Store } from '@ngrx/store';

export interface IXY {
  x: number;
  y: number;
}

export const MIN_ZOOM = 0.5;
export const MAX_ZOOM = 2;
export const ZOOM_STEP = 0.1;
export const ZOOM_OPTIONS: number[] = [0.5, 0.75, 1, 1.5, 2];

export const DEFAULT_ZOOM = 1;
export const DEFAULT_OFFSET: IXY = { x: 0, y: 0 };

export interface CropState {
  originalImageUrl: string | null;
  originalImageWidth: number;
  originalImageHeight: number;
  imageScale: number;
  imageX: number;
  imageY: number;
  cropWidth: number;
  cropHeight: number;
  cropX: number;
  cropY: number;
  maskUrl: string | null;
  inlineSvgMask: string | null;
  saveProportions: boolean;
  resizing: boolean;
  selectedFigureId: number | null;
  zoom: number;
  offset: IXY;
  prevZoom: number;
  prevOffset: IXY;
  focusZoom: number;
  focusOffset: IXY;
  isFocusActive: boolean;
  isGrabActive: boolean;
}

const initialState: CropState = {
  originalImageUrl: null,
  originalImageWidth: 0,
  originalImageHeight: 0,
  imageScale: 1,
  imageX: 0,
  imageY: 0,
  cropWidth: CROP_BOX_DEFAULT_WIDTH,
  cropHeight: CROP_BOX_DEFAULT_HEIGHT,
  cropX: 0,
  cropY: 0,
  maskUrl: null,
  inlineSvgMask: null,
  saveProportions: false,
  resizing: false,
  selectedFigureId: null,
  zoom: DEFAULT_ZOOM,
  offset: DEFAULT_OFFSET,
  prevZoom: DEFAULT_ZOOM,
  prevOffset: DEFAULT_OFFSET,
  focusZoom: DEFAULT_ZOOM,
  focusOffset: DEFAULT_OFFSET,
  isFocusActive: false,
  isGrabActive: false,
};

@Injectable()
export class CropStore extends ComponentStore<CropState> {
  private readonly localOffsetSubject$ = new BehaviorSubject<IXY>(
    DEFAULT_OFFSET
  );
  readonly localOffset$ = this.localOffsetSubject$.asObservable();

  readonly originalImageWidth$ = this.select(
    (state) => state.originalImageWidth
  );
  readonly originalImageHeight$ = this.select(
    (state) => state.originalImageHeight
  );
  readonly imageX$ = this.select((state) => state.imageX);
  readonly imageY$ = this.select((state) => state.imageY);
  readonly imageScale$ = this.select((state) => state.imageScale);
  readonly cropWidth$ = this.select((state) => state.cropWidth);
  readonly cropHeight$ = this.select((state) => state.cropHeight);
  readonly cropX$ = this.select((state) => state.cropX);
  readonly cropY$ = this.select((state) => state.cropY);
  readonly maskUrl$ = this.select((state) => state.maskUrl);
  readonly inlineSvgMask$ = this.select((state) => state.inlineSvgMask);
  readonly saveProportions$ = this.select((state) => state.saveProportions);
  readonly resizing$ = this.select((state) => state.resizing);
  readonly selectedFigureId$ = this.select((state) => state.selectedFigureId);
  readonly zoom$ = this.select((state) => state.zoom);
  readonly offset$ = this.select((state) => state.offset);
  readonly isFocusActive$ = this.select((state) => state.isFocusActive);
  readonly isGrabActive$ = this.select((state) => state.isGrabActive);

  readonly imageWidth$: Observable<number> = combineLatest([
    this.originalImageWidth$,
    this.imageScale$,
  ]).pipe(
    map(([originalImageWidth, imageScale]) => originalImageWidth * imageScale),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly imageHeight$: Observable<number> = combineLatest([
    this.originalImageHeight$,
    this.imageScale$,
  ]).pipe(
    map(
      ([originalImageHeight, imageScale]) => originalImageHeight * imageScale
    ),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly imageCenter$: Observable<IXY> = combineLatest([
    this.imageX$,
    this.imageY$,
    this.imageWidth$,
    this.imageHeight$,
  ]).pipe(
    map(([imageX, imageY, imageWidth, imageHeight]) => ({
      x: imageX + imageWidth / 2,
      y: imageY + imageHeight / 2,
    })),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly zoomOutDisabled$: Observable<boolean> = this.zoom$.pipe(
    map((zoom) => zoom <= MIN_ZOOM),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly zoomInDisabled$: Observable<boolean> = this.zoom$.pipe(
    map((zoom) => zoom >= MAX_ZOOM),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly setOriginalImage = this.updater(
    (state: CropState, payload: string | null) => {
      return {
        ...state,
        originalImageUrl: payload,
      };
    }
  );

  readonly setOriginalImageSize = this.updater(
    (state: CropState, payload: { width: number; height: number }) => {
      return {
        ...state,
        originalImageWidth: payload.width,
        originalImageHeight: payload.height,
      };
    }
  );

  readonly setImageScale = this.updater((state: CropState, payload: number) => {
    return {
      ...state,
      imageScale: payload,
    };
  });

  readonly setImageX = this.updater((state: CropState, payload: number) => {
    return {
      ...state,
      imageX: payload,
    };
  });

  readonly setImageY = this.updater((state: CropState, payload: number) => {
    return {
      ...state,
      imageY: payload,
    };
  });

  readonly setCropSize = this.updater(
    (state: CropState, payload: { width: number; height: number }) => {
      return {
        ...state,
        cropWidth: payload.width,
        cropHeight: payload.height,
      };
    }
  );

  readonly setCropPosition = this.updater(
    (state: CropState, payload: { x: number; y: number }) => {
      return {
        ...state,
        cropX: payload.x,
        cropY: payload.y,
      };
    }
  );

  readonly setMaskUrl = this.updater(
    (state: CropState, payload: string | null) => {
      return {
        ...state,
        maskUrl: payload,
      };
    }
  );

  readonly setInlineSvgMask = this.updater(
    (state: CropState, payload: string | null) => {
      return {
        ...state,
        inlineSvgMask: payload,
      };
    }
  );

  readonly setSaveProportions = this.updater(
    (state: CropState, payload: boolean) => {
      return {
        ...state,
        saveProportions: payload,
      };
    }
  );

  readonly setResizing = this.updater((state: CropState, payload: boolean) => {
    return {
      ...state,
      resizing: payload,
    };
  });

  readonly setSelectedFigureId = this.updater(
    (state: CropState, payload: number) => {
      return {
        ...state,
        selectedFigureId: payload,
      };
    }
  );

  readonly zoomIn = this.updater((state: CropState) => {
    return {
      ...state,
      zoom: normalizeFloat(Math.min(state.zoom + ZOOM_STEP, MAX_ZOOM)),
      isFocusActive: false,
    };
  });

  readonly zoomOut = this.updater((state: CropState) => {
    return {
      ...state,
      zoom: normalizeFloat(Math.max(state.zoom - ZOOM_STEP, MIN_ZOOM)),
      isFocusActive: false,
    };
  });

  readonly setZoom = this.updater((state: CropState, payload: number) => {
    return {
      ...state,
      zoom: payload,
      isFocusActive: false,
    };
  });

  readonly setOffset = this.updater((state: CropState, payload: IXY) => {
    return {
      ...state,
      offset: payload,
      isFocusActive: false,
    };
  });

  readonly toggleFocus = this.updater((state: CropState) => {
    if (!state.isFocusActive) {
      return {
        ...state,
        zoom: state.focusZoom,
        offset: state.focusOffset,
        prevZoom: state.zoom,
        prevOffset: { ...state.offset },
        isFocusActive: !state.isFocusActive,
      };
    } else {
      return {
        ...state,
        zoom: state.prevZoom,
        offset: { ...state.prevOffset },
        isFocusActive: !state.isFocusActive,
      };
    }
  });

  readonly setIsGrabActive = this.updater(
    (state: CropState, payload: boolean) => {
      return {
        ...state,
        isGrabActive: payload,
      };
    }
  );

  readonly reset = this.updater(() => {
    return {
      ...initialState,
    };
  });

  readonly removeGrab = this.effect((trigger$) =>
    trigger$.pipe(
      tap(() => {
        this.setIsGrabActive(false);
      })
    )
  );

  readonly loadMask = this.effect((svgUrl$: Observable<string | null>) =>
    svgUrl$.pipe(
      withLatestFrom(this.maskUrl$),
      mergeMap(([svgUrl, maskUrl]) => {
        if (svgUrl === null) {
          this.setInlineSvgMask(null);
          this.setMaskUrl(null);
          return EMPTY;
        }

        const svgResponseData: Promise<string> = new Promise(
          (resolve, reject) => {
            getTextFromUrl(svgUrl, (response) => {
              if (response) {
                resolve(response as string);
              } else {
                console.error('No SVG found in loaded contents');
                reject();
              }
            });
          }
        );

        return from(svgResponseData).pipe(
          tap((response) => {
            const inlineSvgMask = removePreserveAspectRatio(response);
            this.setInlineSvgMask(inlineSvgMask);

            if (maskUrl) {
              URL.revokeObjectURL(maskUrl);
            }

            const localMaskUrl =
              URL.createObjectURL(
                new Blob([inlineSvgMask], { type: 'image/svg+xml' })
              ) || svgUrl;

            this.setMaskUrl(localMaskUrl);
          }),
          catchError(() => {
            this.setInlineSvgMask(null);
            return EMPTY;
          })
        );
      })
    )
  );

  readonly toggleGrab = this.effect((trigger$) =>
    trigger$.pipe(
      withLatestFrom(this.isGrabActive$),
      tap(([, isGrabActive]) => {
        const active = !isGrabActive;
        this.setIsGrabActive(active);
      })
    )
  );

  constructor(private readonly globalStore: Store) {
    super({ ...initialState });
    linkToGlobalState(this.state$, 'libs/crop/CropStore', this.globalStore);
  }

  setLocalOffset(offset: IXY) {
    this.localOffsetSubject$.next(offset);
  }
}
