import { IFileResponse } from './form-file.interface';

export const BANNERS_LAYOUT = {
  PRODUCTS_LIST: 'PRODUCTS_LIST' as BannerLayoutType,
  PRODUCTS_FILTER: 'PRODUCTS_FILTER' as BannerLayoutType,
  CATALOG_GROUP: 'CATALOG_GROUP' as BannerLayoutType,
  CLIENTS_INFO: 'CLIENTS_INFO' as BannerLayoutType,
};

export type BannerLayoutType =
  | 'PRODUCTS_LIST'
  | 'PRODUCTS_FILTER'
  | 'CATALOG_GROUP'
  | 'CLIENTS_INFO';

export interface IBannerImage {
  id: number;
  type: BannerLayoutType;
  link: string;
  noindex: boolean;
  desktop: IFileResponse;
  mobile?: IFileResponse;
}

export interface IBannersMap {
  [id: number]: IBannerImage[];
}
