import { CommonModule, DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  AppShellConfig,
  AppShellLinkTypeEnum,
  WINDOW,
} from '@inaripro-nx/common-ui';
import { ISelectedProductSource } from '@inaripro-nx/catalog';

@Component({
  selector: 'shell-header-go-back',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './header-order-go-back.component.html',
  styleUrls: ['./header-order-go-back.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderGoBackComponent implements OnInit {
  @Input() config: AppShellConfig | null = null;
  @Input() selectedProductSource: ISelectedProductSource | null = null;

  link = '/';
  toBack = false;

  readonly linkTypeEnum = AppShellLinkTypeEnum;

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.initLink(this.document.referrer, this.document.URL);
  }

  private initLink(referrer: string, currentURL: string) {
    if (!referrer || referrer === currentURL) {
      return;
    }

    const hostnameReferrer = new URL(referrer).hostname || '';
    const hostnameCurrent = new URL(currentURL).hostname || '';

    const last2DomainReferrer = hostnameReferrer.split('.').slice(-2).join('.');
    const last2DomainCurrent = hostnameCurrent.split('.').slice(-2).join('.');

    if (last2DomainReferrer && last2DomainReferrer === last2DomainCurrent) {
      this.link = referrer;
      this.toBack = true;
    }
  }

  goBack(event: MouseEvent) {
    event.preventDefault();
    this.window.history.back();
  }

  goBackToProduct() {
    this.selectedProductSource?.setSelectedProductId(null);
  }
}
