@if (treeItem && dictionary && childParent) {
<div class="catalog-category-content__grid">
  @for (item of treeItem.children | catalogSortByIsActive : dictionary; track item.id) {
    @if (!hasProductDesign || (hasProductDesign && item.hasProductDesign)) {
      @if(dictionary[item.dictionaryId]; as dictItem) {
      <div
        class="catalog-category-content__grid-cell"
        (click)="openCategory($event, item)"
        (mousedown)="openCategory($event, item)"
      >
        <div class="category-item">
          <div class="category-item__img">
            @if (dictItem?.isPartner && dictItem?.isActive) {
              <div class="partner">
                <i class="icon icon-partners"></i>
              </div>
            }
            <img
              [src]="dictItem?.logo?.url"
              [alt]="dictItem?.name"
              [class.img-noactive]="!dictItem?.isActive"
            />
            <div class="fogging-block">
              <i class="icon icon-ic_eye_passw"></i>
            </div>
          </div>

          <div class="category-item__title">
            <a
              [routerLink]="item | catalogGroupLink: rootModule"
              [title]="dictItem?.name"
              [queryParams]="queryParams"
              class="category-item__title-content"
              [class.category-item__title-content-noactive]="!dictItem?.isActive"
            >
              @if (!dictItem?.isActive) {
                <i class="icon icon-ic_block"></i>
              }
              {{ dictItem?.name }}
              @if (item.amount && !hasProductDesign) {
                <span class="category-item__title-counter">({{ item.amount }})</span>
              }
            </a>
          </div>
        </div>
      </div>
    }
    }
  }
</div>
}
