@if (banner) {
  @if (banner.noindex) {
    <noindex>
      <ng-container
        [ngTemplateOutlet]="bannerImagesTemplate"
        [ngTemplateOutletContext]="{ item: banner }"
      ></ng-container>
    </noindex>
  } @else {
    <ng-container
      [ngTemplateOutlet]="bannerImagesTemplate"
      [ngTemplateOutletContext]="{ item: banner }"
    ></ng-container>
  }
}

<ng-template #bannerImagesTemplate let-item="item">
  @if (item.link) {
    @if (item.link | internalLink; as link) {
      <a
        [routerLink]="link.routerLink"
        [queryParams]="link.queryParams"
        fragment="{{link.fragment || ''}}"
        [rel]="item.noindex ? 'nofollow' : null"
      >
        <ng-container [ngTemplateOutlet]="images" [ngTemplateOutletContext]="{ item: banner }"></ng-container>
      </a>
    } @else {
      <a href="{{item.link}}" target="_blank" [rel]="item.noindex ? 'nofollow' : null">
        <ng-container [ngTemplateOutlet]="images" [ngTemplateOutletContext]="{ item: banner }"></ng-container>
      </a>
    }
  } @else {
    <ng-container *ngTemplateOutlet="images"></ng-container>
  }
</ng-template>

<ng-template #images let-item="item">
  <img [src]="item.desktop.url" class="banner__image hidden-mobile">
  <img *ngIf="item.mobile" [src]="item.mobile.url" class="banner__image visible-mobile">
</ng-template>
