import { IFilterMinMax } from '@inaripro-nx/common-ui';
import { ProductTypeEnum } from '../enums/catalog-product.enum';

export const CATALOG_ROOT_LINK = '/catalog';

export const CATALOG_PRICE_FILTER: IFilterMinMax = {
  minValue: 0.01,
  maxValue: 10000000,
};

export const CATALOG_PRODUCT_TYPE: { [key in ProductTypeEnum]: string } = {
  [ProductTypeEnum.BASE_PRODUCT]: ProductTypeEnum.BASE_PRODUCT,
  [ProductTypeEnum.DESIGN]: ProductTypeEnum.DESIGN,
  [ProductTypeEnum.READY_DESIGN]: ProductTypeEnum.READY_DESIGN,
  [ProductTypeEnum.EXAMPLE]: ProductTypeEnum.EXAMPLE,
  [ProductTypeEnum.ALL]: ProductTypeEnum.ALL,
};
