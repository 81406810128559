import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  EElementType,
  IElement,
  IXYb,
} from '../../interfaces/editor.interface';
import { ActionsService } from '../../services/actions/actions.service';
import { WindowToolsService } from '../../services/window-tools/window-tools.service';
import { HistoryStore } from '../../state/history/history.store';
import { MainStore } from '../../state/main/main.store';
import { ProductStore } from '../../state/product/product.store';
import { ActionsFiguresComponent } from './components/actions-figures/actions-figures.component';
import { ActionsPicturesComponent } from './components/actions-pictures/actions-pictures.component';
import { ActionsQrcodesComponent } from './components/actions-qrcodes/actions-qrcodes.component';
import { ActionsTextComponent } from './components/actions-text/actions-text.component';
import { ActionElementTitlePipe } from './pipes';

@Component({
  selector: 'painter-actions',
  standalone: true,
  imports: [
    CommonModule,
    ActionsFiguresComponent,
    ActionsPicturesComponent,
    ActionsTextComponent,
    ActionsQrcodesComponent,
    ActionElementTitlePipe,
    BrowserAnimationsModule,
  ],
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animateDestroyAndOnInit', [
      state('void', style({ opacity: '0', height: 0 })),
      transition(':leave', animate('200ms ease')),
      transition(':enter', [style({}), animate('200ms ease')]),
    ]),
  ],
})
export class ActionsComponent {
  filters$ = this.productStore.filters$;
  activeDesignSide$ = this.productStore.activeDesignSide$;
  figures$ = this.productStore.figures$;
  activeElementIndex$ = this.historyStore.activeElementIndex$;
  activeElement$ = this.historyStore.activeElement$;
  isDesktop$ = this.windowToolsService.isDesktop$;

  readonly EElementType = EElementType;

  startTouchCoordY: number | null = null;
  hideActionsSubmenu$ = this.mainStore.hideActionsSubmenu$;

  constructor(
    private historyStore: HistoryStore,
    private productStore: ProductStore,
    private actionsService: ActionsService,
    private mainStore: MainStore,
    private windowToolsService: WindowToolsService
  ) {}

  updateElement(data: { index: number; element: IElement }) {
    this.historyStore.updateElement(data);
  }

  centerElement(data: { index: number; element: IElement; center: IXYb }) {
    const element = this.actionsService.centerElement(data);

    if (!element) {
      return;
    }

    const { index } = data;

    this.updateElement({
      index,
      element,
    });
  }

  public headMobileMenuClick(): void {
    this.mainStore.toggleHideActionsSubmenu();
  }

  public headMobileMenuTouchStart(event: TouchEvent): void {
    if (!(window.TouchEvent && event instanceof TouchEvent)) {
      return;
    }

    this.startTouchCoordY = event.changedTouches[0].clientY;
  }

  public headMobileMenuTouchMove(event: TouchEvent): void {
    if (!(window.TouchEvent && event instanceof TouchEvent)) {
      return;
    }

    if (this.startTouchCoordY === null) {
      return;
    }

    const currentstartTouchCoordY = event.changedTouches[0].clientY;
    const deltaY = this.startTouchCoordY - currentstartTouchCoordY;

    if (deltaY > 50) {
      this.mainStore.setHideActionsSubmenu({ hideActionsSubmenu: false });
      this.startTouchCoordY = null;
    } else if (deltaY < -20) {
      this.mainStore.setHideActionsSubmenu({ hideActionsSubmenu: true });
      this.startTouchCoordY = null;
    }
  }

  headMobileMenuTouchEnd() {
    this.startTouchCoordY = null;
  }
}
