<ng-container
  *ngIf="{ page: page$ | async, hideActions: hideActions$ | async } as state"
>
  <div class="main-workspace">
    <div class="main-workspace__centerside">
      <painter-editor
        *ngIf="state.page === EPage.design; else content"
      ></painter-editor>
      <ng-template #content>
        <painter-content></painter-content>
      </ng-template>
    </div>
  </div>
  <div class="main-actions" *ngIf="state.page === EPage.design">
    <painter-actions [hidden]="state.hideActions"></painter-actions>
  </div>
  <div class="main-footer">
    <painter-mobile-tabs></painter-mobile-tabs>
  </div>
</ng-container>
