<div
  class="modification"
  [class.modification--qr-codes] = "modificator === 'qr-codes'"
  [class.modification--figures] = "modificator === 'figures'"
>
  <button
    (click)="flip({ x: !element.flip.x, y: element.flip.y })"
    [class.modification-active]="element.flip.x === true"
    title="Отразить по вертикали"
  >
    <span>
      <i class="icon icon-ic_flip-vertically"></i>
    </span>
  </button>
  <button
    (click)="flip({ x: element.flip.x, y: !element.flip.y })"
    [class.modification-active]="element.flip.y === true"
    title="Отразить по горизонтали"
  >
    <span>
      <i class="icon icon-ic_flip-horizontally"></i>
    </span>
  </button>
  <button
    (click)="centerElement({ x: true, y: false })"
    title="Центрировать по вертикали"
  >
    <span>
      <i class="icon icon-ic_center-vertically"></i>
    </span>
  </button>
  <button
    (click)="centerElement({ x: false, y: true })"
    title="Центрировать по горизонтали"
  >
    <span>
      <i class="icon icon-ic_center-horizontally"></i>
    </span>
  </button>
  <button (click)="stretchElement()" title="Растянуть на всю область печати">
    <span>
      <i class="icon icon-ic_stretch"></i>
    </span>
  </button>
</div>
