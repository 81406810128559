import { Injectable } from '@angular/core';
import { IOrderProductSource } from '@inaripro-nx/order';
import { ISelectedProductSource } from '@inaripro-nx/catalog';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private readonly orderProductSourceSubject$ =
    new Subject<IOrderProductSource>();

  readonly orderProductSource$ = this.orderProductSourceSubject$.asObservable();

  private readonly selectedProductSourceSubject$ =
    new Subject<ISelectedProductSource>();

  readonly selectedProductSource$ =
    this.selectedProductSourceSubject$.asObservable();

  set orderProductSource(source: IOrderProductSource) {
    this.orderProductSourceSubject$.next(source);
  }

  set selectedProductSource(source: ISelectedProductSource) {
    this.selectedProductSourceSubject$.next(source);
  }
}
