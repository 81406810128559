import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  map,
  shareReplay,
  Subscription,
} from 'rxjs';
import { CropStore } from '../../store/crop';
import { AsyncPipe, NgStyle } from '@angular/common';
import { DesignUiModule } from '@inaripro-nx/design-ui';

@Component({
  selector: 'crop-image',
  standalone: true,
  templateUrl: './crop-image.component.html',
  styleUrls: ['./crop-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, NgStyle, DesignUiModule],
})
export class CropImageComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() imageUrl: string | null = null;

  @ViewChild('image') readonly image?: ElementRef<HTMLImageElement>;

  readonly imageWidth$ = this.cropStore.imageWidth$;
  readonly imageHeight$ = this.cropStore.imageHeight$;

  readonly offset$ = this.cropStore.localOffset$;

  private readonly imageLoadingSubject$ = new BehaviorSubject(true);
  readonly imageLoading$ = this.imageLoadingSubject$
    .asObservable()
    .pipe(shareReplay({ refCount: false, bufferSize: 1 }));

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  constructor(
    private readonly renderer: Renderer2,
    private readonly cropStore: CropStore,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('imageUrl' in changes) {
      this.imageLoadingSubject$.next(true);
    }
  }

  ngAfterViewInit() {
    if (this.image) {
      const image = this.image.nativeElement;

      this.subs = combineLatest([
        this.cropStore.cropX$,
        this.cropStore.cropY$,
        this.cropStore.cropWidth$,
        this.cropStore.cropHeight$,
        this.cropStore.maskUrl$,
        this.cropStore.imageX$,
        this.cropStore.imageY$,
        this.cropStore.imageCenter$,
        this.cropStore.localOffset$,
        this.cropStore.zoom$,
      ])
        .pipe(
          debounceTime(0),
          map(
            ([
              cropX,
              cropY,
              cropWidth,
              cropHeight,
              mask,
              imageX,
              imageY,
              imageCenter,
              offset,
              zoom,
            ]) => {
              return {
                mask,
                maskX:
                  imageCenter.x +
                  (cropX - imageCenter.x) / zoom -
                  imageX -
                  offset.x,
                maskY:
                  imageCenter.y +
                  (cropY - imageCenter.y) / zoom -
                  imageY -
                  offset.y,
                maskWidth: cropWidth / zoom,
                maskHeight: cropHeight / zoom,
              };
            }
          )
        )
        .subscribe(({ mask, maskX, maskY, maskWidth, maskHeight }) => {
          if (mask) {
            this.clearCSSRectangleClip(image);
            this.setCSSImageMask(
              image,
              mask,
              maskX,
              maskY,
              maskWidth,
              maskHeight
            );
          } else {
            this.clearCSSImageMask(image);
            this.setCSSRectangleClip(
              image,
              maskX,
              maskY,
              maskWidth,
              maskHeight
            );
          }
          this.cdr.markForCheck();
        });
    }
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }

  onImageLoaded() {
    this.imageLoadingSubject$.next(false);
  }

  private setCSSImageMask(
    image: HTMLImageElement,
    mask: string,
    x: number,
    y: number,
    width: number,
    height: number
  ) {
    this.renderer.setStyle(image, 'mask', `url(${mask}) no-repeat`);
    this.renderer.setStyle(image, '-webkit-mask-image', `url(${mask})`);
    this.renderer.setStyle(image, '-webkit-mask-repeat', `no-repeat`);

    this.renderer.setStyle(image, 'mask-size', `${width}px ${height}px`);
    this.renderer.setStyle(
      image,
      '-webkit-mask-size',
      `${width}px ${height}px`
    );

    this.renderer.setStyle(image, 'mask-position', `${x}px ${y}px`);
    this.renderer.setStyle(image, '-webkit-mask-position', `${x}px ${y}px`);
  }

  private clearCSSImageMask(image: HTMLImageElement) {
    this.renderer.setStyle(image, 'mask', 'none');
    this.renderer.setStyle(image, '-webkit-mask-image', 'none');
  }

  private setCSSRectangleClip(
    image: HTMLImageElement,
    x: number,
    y: number,
    width: number,
    height: number
  ) {
    this.renderer.setStyle(
      image,
      'clip-path',
      `polygon(
                ${x}px ${y}px,
                ${x + width}px ${y}px,
                ${x + width}px ${y + height}px,
                ${x}px ${y + height}px
              )`
    );
  }

  private clearCSSRectangleClip(image: HTMLImageElement) {
    this.renderer.setStyle(image, 'clip-path', 'none');
  }
}
