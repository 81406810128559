import { Pipe, PipeTransform } from '@angular/core';
import {
  ICatalogNomenclatureListItem,
  ICatalogNomenclatureTreeItem,
  IMapOfCatalogNomenclatureCategoryForList,
} from '../../interfaces';

@Pipe({
  name: 'catalogSortByIsActive',
  standalone: true,
})
export class CatalogSortByIsActivePipe implements PipeTransform {
  transform(
    value: ICatalogNomenclatureTreeItem[],
    dict: IMapOfCatalogNomenclatureCategoryForList
  ): ICatalogNomenclatureTreeItem[] {
    return (value || []).sort(this.sortByFieldIsActive(dict));
  }

  private sortByFieldIsActive(dict: IMapOfCatalogNomenclatureCategoryForList) {
    return (
      itemA: ICatalogNomenclatureListItem,
      itemB: ICatalogNomenclatureListItem
    ): number => {
      const aField: number = itemA.dictionaryId;
      const bField: number = itemB.dictionaryId;
      const a: number = +dict[aField].isActive;
      const b: number = +dict[bField].isActive;

      return a === b ? itemA.position - itemB.position : b - a;
    };
  }
}
