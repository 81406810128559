import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  IFilter,
  PipesModule as CommonPipesModule,
  SvgFlipPipe,
} from '@inaripro-nx/common-ui';
import {
  EElementType,
  IElement,
  SVG_NS,
} from '../../../../interfaces/editor.interface';
import { EditorElementTextContentComponent } from '../editor-element-text-content/editor-element-text-content.component';

interface ICropData {
  mask: string;
  image: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[data-painter-editor-element-content]',
  standalone: true,
  imports: [CommonModule, CommonPipesModule, EditorElementTextContentComponent],
  providers: [SvgFlipPipe],
  templateUrl: './editor-element-content.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorElementContentComponent implements OnChanges {
  @Input() el!: IElement;
  @Input() index!: number;
  @Input() inAction?: boolean;
  @Input() filter?: 0 | IFilter | null;

  readonly EElementType = EElementType;

  cropData: ICropData | null = null;

  constructor(private readonly flipPipe: SvgFlipPipe) {}

  ngOnChanges(): void {
    this.cropData = this.getCropData();
  }

  private getCropData(): ICropData | null {
    if (
      this.el.type === EElementType.image &&
      this.el.svg.indexOf('data-crop-image') > -1
    ) {
      const container = document.createElementNS(SVG_NS, 'svg');
      container.innerHTML = this.el.svg;

      const mask = container.querySelector('mask');
      const image = container.querySelector('image');

      if (!mask || !image) {
        return null;
      }

      image.setAttribute('transform', this.flipPipe.transform(this.el));

      if (this.filter) {
        image.setAttribute('filter', 'url(#filter_' + this.filter.id + ')');
      }

      return {
        mask: mask.outerHTML,
        image: image.outerHTML,
      };
    }

    return null;
  }
}
