import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { IDesignSide, IDesignZone } from '@inaripro-nx/catalog';
import { IFilter, IPattern } from '@inaripro-nx/common-ui';
import { IElements, IMapXY, IXY } from '../../interfaces/editor.interface';
import { getFontFaces } from '../../../../utils/svg.utils';
import { MainService } from '../../services/main/main.service';

@Component({
  selector: 'painter-export-zone',
  templateUrl: './export-zone.component.html',
  styleUrl: './export-zone.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportZoneComponent {
  @Input() side!: IDesignSide;
  @Input() sideIndex: number | null = null;
  @Input() filters!: { [id: number]: IFilter };
  @Input() zone!: IDesignZone;
  @Input() zoneColor?: string;
  @Input() zonePattern?: IPattern;
  @Input() zoneTranslates!: IMapXY;
  @Input() withFonts!: boolean;

  private _elements!: IElements;
  fontFaces: string[] = [];

  get elements(): IElements {
    return this._elements;
  }

  @Input() set elements(elements: IElements) {
    this._elements = elements;

    if (this.withFonts) {
      this.fontFaces = getFontFaces(
        this.mainService.config?.designerRoot || '',
        elements,
        this.sideIndex
      );
    }
  }

  get zone0Center(): IXY {
    const zone0 = this.side?.zones[0];
    const zone0Center = {
      ...(zone0?.center || { x: 0, y: 0 }),
    };
    const zoneStart = {
      ...(this.zone?.start || { x: 0, y: 0 }),
    };

    zone0Center.x -= zoneStart.x;
    zone0Center.y -= zoneStart.y;

    const translate0 = this.zoneTranslates['' + zone0.id];

    if (translate0) {
      zone0Center.x += translate0.x;
      zone0Center.y += translate0.y;
    }

    const translate = this.zoneTranslates['' + this.zone.id];

    if (translate) {
      zone0Center.x -= translate.x;
      zone0Center.y -= translate.y;
    }

    return zone0Center;
  }

  constructor(
    private readonly mainService: MainService,
    public elementRef: ElementRef
  ) {}
}
