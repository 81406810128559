import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { PipesModule as CommonPipesModule } from '@inaripro-nx/common-ui';
import {
  IFiltersValues,
  IProductFilters,
  IPropertyForFilter,
} from '../../../../interfaces';
import { CatalogProductListFilterWrapperComponent } from '../catalog-product-list-filter-wrapper/catalog-product-list-filter-wrapper.component';

@Component({
  selector: 'catalog-product-list-filter-checkbox',
  standalone: true,
  imports: [
    CommonModule,
    DesignUiModule,
    CommonPipesModule,
    CatalogProductListFilterWrapperComponent,
  ],
  templateUrl: './catalog-product-list-filter-checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogProductListFilterCheckboxComponent {
  @Input() filters!: IProductFilters;
  @Input() filterValues!: IFiltersValues;
  @Input() prop!: IPropertyForFilter;
  @Input() submitDisabled = false;

  @Output() toggle = new EventEmitter<number>();
  @Output() selectAll = new EventEmitter<void>();
  @Output() clearAll = new EventEmitter<void>();
  @Output() clearFilter = new EventEmitter<void>();
  @Output() applyFilterValues = new EventEmitter<void>();
}
