<div class="header-go-back">
  @if (selectedProductSource?.selectedProductId$ | async) {
    <ng-container
      [ngTemplateOutlet]="goBackToProductTemplate"
      [ngTemplateOutletContext]="{
        id: selectedProductSource?.selectedProductId$ | async,
        params: selectedProductSource?.params$ | async
      }"
    ></ng-container>
  } @else if (config) {
    <ng-container
      [ngTemplateOutlet]="
        config.publicLinkType === linkTypeEnum.External
          ? externalLinkTemplate
          : relativeLinkTemplate
      "
      [ngTemplateOutletContext]="{
        link:
          config.publicLinkType === linkTypeEnum.External
            ? (toBack ? link : config.publicUrl + link)
            : link
      }"
    ></ng-container>
  } @else {
    <ng-container [ngTemplateOutlet]="relativeLinkTemplate"></ng-container>
  }
</div>

<ng-template #relativeLinkTemplate>
  <a [routerLink]="link">
    <ng-container
      [ngTemplateOutlet]="goBackTemplate"
      [ngTemplateOutletContext]="{text: 'Вернуться на сайт'}"
    ></ng-container>
  </a>
</ng-template>

<ng-template #externalLinkTemplate let-link="link">
  <a [attr.href]="link" (mousedown)="toBack && goBack($event)">
    <ng-container
      [ngTemplateOutlet]="goBackTemplate"
      [ngTemplateOutletContext]="{text: 'Вернуться на сайт'}"
    ></ng-container>
  </a>
</ng-template>

<ng-template #goBackTemplate let-text="text">
  <span class="text">
    <i class="icon icon-ic_arrow"></i>
    {{text}}
  </span>
</ng-template>

<ng-template #goBackToProductTemplate let-id="id" let-params="params">
  <a [routerLink]="['/product', id]" [queryParams]="params" (click)="goBackToProduct()">
    <ng-container
      [ngTemplateOutlet]="goBackTemplate"
      [ngTemplateOutletContext]="{text: 'Вернуться к редактированию'}"
    ></ng-container>
  </a>
</ng-template>
