import { ErrorHandler, isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppComponent } from './app.component';

import { registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { RouterModule } from '@angular/router';
import { AuthModule } from '@inaripro-nx/auth';
import { CatalogGroupLinkPipe } from '@inaripro-nx/catalog';
import {
  componentStateReducer,
  CustomRouterStateSerializer,
  DEFAULT_TITLE,
  MetaEffects,
  RouterEffects,
  StorageService,
  TitleEffects,
} from '@inaripro-nx/common-ui';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { OrderModule } from '@inaripro-nx/order';
import { PainterModule } from '@inaripro-nx/painter';
import { ShellModule } from '@inaripro-nx/shell';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ToastrModule } from 'ngx-toastr';
import * as Raven from 'raven-js';
import { environment } from '../environments/environment';
import { ProductComponent } from './components/product/product.component';
import { ApiInterceptor } from './interceptors/api/api.interceptor';
import { ErrorsInterceptor } from './interceptors/errors/errors.interceptor';
import { JwtInterceptor } from './interceptors/jwt/jwt.interceptor';
import { DesignerAppShellStore } from './state/designer-app-shell/designer-app-shell.store';
import { MyTemplatesStore } from './state/my-templates/my-templates.store';
import { PatternsStore } from './state/patterns/patterns.store';
import { PicturesLibraryStore } from './state/pictures-library/pictures-library.store';
import { PicturesStore } from './state/pictures/pictures.store';
import { ProductTemplatesStore } from './state/product-templates/product-templates.store';
import { QrCodesStore } from './state/qr-codes/qr-codes.store';
import { ROOT_LINKS } from './constants/app-links';
import { SelectivePreloadingStrategy } from './services/preloading-strategy/selective-preload-strategy';

registerLocaleData(localeRu, 'ru');

Raven.config(
  environment.sentry.DSN,
  environment.sentry.DSNRavenOptions
).install();

export class RavenErrorHandler implements ErrorHandler {
  handleError(error: unknown): void {
    // Send error to Sentry
    Raven.captureException(error);

    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    throw error;
  }
}

@NgModule({
  declarations: [AppComponent, ProductComponent],
  bootstrap: [AppComponent],
  imports: [
    DesignUiModule,
    BrowserModule,
    BrowserAnimationsModule,
    PainterModule,
    RouterModule.forRoot(
      [
        {
          path: '',
          redirectTo: `${ROOT_LINKS.product}/`,
          pathMatch: 'full',
        },
        {
          path: `${ROOT_LINKS.product}/:productId`,
          component: ProductComponent,
          data: {
            description:
              'Создать макет одежды, кепки, кружки и сувенирной продукции со своим принтом и надписью, печать изображений на футболках, толстовках и майках',
          },
        },
        {
          path: ROOT_LINKS.catalog,
          loadChildren: () =>
            import('./modules/catalog/catalog.module').then(
              (m) => m.CatalogModule
            ),
          data: {
            preload: false,
          },
        },
        {
          path: '**',
          redirectTo: `${ROOT_LINKS.product}/`,
        },
      ],
      {
        preloadingStrategy: SelectivePreloadingStrategy,
      }
    ),
    StoreModule.forRoot(
      {
        componentState: componentStateReducer,
        router: routerReducer,
      },
      {
        metaReducers: [],
        runtimeChecks: {
          strictStateSerializability: true,
          strictActionSerializability: true,
          strictActionImmutability: true,
          strictStateImmutability: true,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true,
        },
      }
    ),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouterStateSerializer,
    }),
    EffectsModule.forRoot([TitleEffects, MetaEffects, RouterEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 50, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: true, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: true,
    }),
    AuthModule,
    OrderModule,
    ShellModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ru' },
    {
      provide: DEFAULT_TITLE,
      useValue:
        'Конструктор одежды и сувениров онлайн: дизайн принта бесплатно',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorsInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: RavenErrorHandler,
    },
    StorageService,
    PicturesStore,
    PicturesLibraryStore,
    PatternsStore,
    QrCodesStore,
    DesignerAppShellStore,
    MyTemplatesStore,
    ProductTemplatesStore,
    CatalogGroupLinkPipe,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
