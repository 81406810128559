import { createReducer, on } from '@ngrx/store';
import {
  IPaginationConfig,
  IPaginationOnlyRequest,
  IPaginationResponse,
} from '../../interfaces';
import * as PaginationActions from './pagination.actions';

export interface PaginationState {
  request: IPaginationOnlyRequest | null;
  response: IPaginationResponse | null;
  config: IPaginationConfig | null;
}

const initialState: PaginationState = {
  request: null,
  response: null,
  config: null,
};

export const paginationReducer = createReducer(
  initialState,
  on(PaginationActions.setPaginationRequest, (state, { payload }) => ({
    ...state,
    request: payload,
  })),
  on(PaginationActions.setPaginationResponse, (state, { payload }) => ({
    ...state,
    response: payload,
  })),
  on(PaginationActions.setPaginationConfig, (state, { payload }) => ({
    ...state,
    config: payload,
  }))
);
