import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppShellConfig } from '@inaripro-nx/common-ui';
import { ActionsComponent } from '../actions/actions.component';
import { ContentComponent } from '../content/content.component';
import { DesktopTabsComponent } from '../desktop-tabs/desktop-tabs.component';
import { EditorComponent } from '../editor/editor.component';

@Component({
  selector: 'painter-main-desktop',
  standalone: true,
  imports: [
    CommonModule,
    EditorComponent,
    DesktopTabsComponent,
    ContentComponent,
    ActionsComponent,
  ],
  templateUrl: './main-desktop.component.html',
  styleUrls: ['./main-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainDesktopComponent {}
