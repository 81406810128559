import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IFiltersValues,
  IProductFilters,
  IProductFiltersRequest,
} from '../../interfaces/catalog-filter.interface';

@Injectable({
  providedIn: 'root',
})
export class CatalogFilterService {
  constructor(private readonly httpClient: HttpClient) {}

  getFilters(id: number, hasDesigner = false): Observable<IProductFilters> {
    let url = `/catalog/public/filters_values_characteristics/${id}/`;

    if (hasDesigner) {
      url += `?hasDesigner=1`;
    }

    return this.httpClient.get<IProductFilters>(url);
  }

  getFilterValues(data: IProductFiltersRequest): Observable<IFiltersValues> {
    return this.httpClient.post<IFiltersValues>(
      `/catalog/public/filters_values_price_and_stock/`,
      data
    );
  }
}
