import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { IUser, UserRoleEnum } from '@inaripro-nx/auth';
import {
  BLANK_IMAGE_SRC,
  IFileResponse,
  ImageSizeEnum,
  PipesModule,
  isTouchDevice,
} from '@inaripro-nx/common-ui';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { Observable, Subscription, fromEvent, map, zip } from 'rxjs';
import { ProductCardTypeEnum } from '../../enums/catalog-product.enum';
import { IPublicProductForList } from '../../interfaces';
import { CatalogProductCardBadgesComponent } from '../catalog-product-card-badges/catalog-product-card-badges.component';
import { CatalogProductCardPricesComponent } from '../catalog-product-card-prices/catalog-product-card-prices.component';
import { CatalogOnlinePaymentComponent } from '../catalog-online-payment/catalog-online-payment.component';

@Component({
  selector: 'catalog-product-card',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    CatalogOnlinePaymentComponent,
    CatalogProductCardPricesComponent,
    CatalogProductCardBadgesComponent,
    LazyLoadImageModule,
    PipesModule,
    DesignUiModule,
  ],
  templateUrl: './catalog-product-card.component.html',
  styleUrls: ['./catalog-product-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogProductCardComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private _product!: IPublicProductForList;

  get product(): IPublicProductForList {
    return this._product;
  }

  @Input() set product(productData: IPublicProductForList) {
    this._product = productData;
    this.logo = productData.logo ? this.getLogo(productData.logo) : undefined;
  }

  @Input() isSelected = false;

  @Input() user: IUser | null = null;
  @Input() unitName = '';
  @Input() hideOptions = false;
  @Input() lazyLoadPreview = false;
  @Input() userCityName = '';
  @Input() publicBaseUrl = '';

  @ViewChild('imageContainer', { static: false, read: ElementRef })
  imageContainer: ElementRef | undefined;

  @ViewChild('hoverSectors', { static: false }) hoverSectors:
    | ElementRef
    | undefined;

  logo: IFileResponse | undefined;
  swipedIndex: number | null = null;

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  get productCardType(): string {
    return this.product && this.product.cardType
      ? this.product.cardType
      : ProductCardTypeEnum.PRODUCT;
  }

  get defaultImage(): string {
    return BLANK_IMAGE_SRC;
  }

  get canEditProduct(): boolean {
    const cardType =
      this.product && this.product.cardType
        ? this.product.cardType
        : ProductCardTypeEnum.PRODUCT;

    return (
      cardType === ProductCardTypeEnum.PRODUCT &&
      !!this.user &&
      !!this.user.companyId &&
      (+this.user.companyId === this.product.company?.id ||
        this.user.role === UserRoleEnum.Administrator)
    );
  }

  get isLazyLoadImage(): boolean {
    return isPlatformBrowser(this.platformId) && this.lazyLoadPreview;
  }

  get cabinetHrefLink(): string {
    const product = this.product as IPublicProductForList;

    const productId =
      product.autoCreatePropertyId && product.baseProductId
        ? product.baseProductId
        : product.id;

    return `${this.publicBaseUrl}/cabinet/catalog/product/${productId}`;
  }

  readonly USER_ROLES = UserRoleEnum;
  readonly PRODUCT_CARD_TYPES = ProductCardTypeEnum;

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (
      ((this.product && this.product.logo) || []).length > 1 &&
      isPlatformBrowser(this.platformId) &&
      isTouchDevice()
    ) {
      this.swipedIndex = 0;
    }
  }

  ngAfterViewInit() {
    this.addImageEvents();
  }

  private addImageEvents() {
    if (!this.product || (this.product.logo || []).length <= 1) {
      return;
    }

    this.subs = fromEvent<MouseEvent>(
      this.imageContainer?.nativeElement,
      'mouseenter'
    ).subscribe(() => {
      this.hoverSectors?.nativeElement.classList.add('hvr__sectors--active');

      if (this.swipedIndex !== null) {
        this.swipedIndex = null;
        this.cdr.detectChanges();
      }
    });

    this.subs = fromEvent<MouseEvent>(
      this.imageContainer?.nativeElement,
      'mouseleave'
    ).subscribe(() => {
      this.hoverSectors?.nativeElement.classList.remove('hvr__sectors--active');
    });

    const touchStart$ = fromEvent<TouchEvent>(
      this.imageContainer?.nativeElement,
      'touchstart'
    );
    const touchEnd$ = fromEvent<TouchEvent>(
      this.imageContainer?.nativeElement,
      'touchend'
    );

    const countImages = (this.product.logo || []).length;

    const source1$: Observable<number> = touchStart$.pipe(
      map((event: TouchEvent) => event.changedTouches[0].clientX)
    );

    const source2$: Observable<number> = touchEnd$.pipe(
      map((event: TouchEvent) => event.changedTouches[0].clientX)
    );

    this.subs = zip(source1$, source2$).subscribe(
      ([clientXStart, clientXEnd]: number[]) => {
        if (clientXStart - clientXEnd > 0) {
          this.swipedIndex = (this.swipedIndex || 0) + 1;
          this.swipedIndex =
            this.swipedIndex > countImages - 1 ? 0 : this.swipedIndex;
        } else {
          this.swipedIndex = (this.swipedIndex || 0) - 1;
          this.swipedIndex =
            this.swipedIndex < 0 ? countImages - 1 : this.swipedIndex;
        }

        this.cdr.detectChanges();
      }
    );
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }

  getLogo(logo: IFileResponse[] | IFileResponse): IFileResponse {
    let foundLogo;

    if (Array.isArray(logo)) {
      foundLogo = logo[0].sizes?.find(
        (item) => item.size === ImageSizeEnum.VERTICAL
      );
      if (!foundLogo) {
        foundLogo = logo[0];
      }
    } else {
      foundLogo = logo.sizes?.find(
        (item) => item.size === ImageSizeEnum.VERTICAL
      );
      if (!foundLogo) {
        foundLogo = logo;
      }
    }

    return foundLogo;
  }

  isWhite(color: string): boolean {
    const regex = /^#f{3,6}$/gim;
    return regex.test(color);
  }
}
