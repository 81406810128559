<div
  #arrowUp
  class="arrow-up"
  [class.arrow-up--active]="isActiveArrowUp"
>
  <ng-content select="[arrow-up]"></ng-content>
</div>
<div
  #invisibleVerticallyScroll
  class="invisible-vertically-scroll"
>
  <ng-content select="[slider]"></ng-content>
</div>

<div
  #arrowDown
  class="arrow-down"
  [class.arrow-down--active]="isActiveArrowDown"
>
  <ng-content select="[arrow-down]"></ng-content>
</div>

