import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sumPoints',
})
export class SumPointsPipe implements PipeTransform {
  transform(points: { x: number; y: number }[]): { x: number; y: number } {
    return points.reduce(
      (sum, point) => {
        sum.x += point.x;
        sum.y += point.y;
        return sum;
      },
      { x: 0, y: 0 }
    );
  }
}
