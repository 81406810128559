import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileSizePipe } from './file-size/file-size.pipe';
import { FontFamilyPipe } from './font-family/font-family.pipe';
import { FormControlErrorPipe } from './form-control-error/form-control-error.pipe';
import { ImageBase64Pipe } from './image-base64/image-base64.pipe';
import { ImageBase64Service } from './image-base64/service/image-base64.service';
import { IsUndefinedPipe } from './is-undefined/is-undefined.pipe';
import { NumberCharacterPipe } from './number-character/number-character.pipe';
import { ObjectKeysPipe } from './object-keys/object-keys.pipe';
import { RoundNumberPipe } from './round-number/round-number.pipe';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { SortByPositionPipe } from './sort-by-position/sort-by-position.pipe';
import { SvgCirclePathPipe } from './svg-circle-path/svg-circle-path.pipe';
import { SvgRotatePipe } from './svg-rotate/svg-rotate.pipe';
import { SvgScalePipe } from './svg-scale/svg-scale.pipe';
import { SvgTranslatePipe } from './svg-translate/svg-translate.pipe';
import { ViewboxPipe } from './viewbox/viewbox.pipe';
import { IsNullOrUndefinedPipe } from './is-null-or-undefined/is-null-or-undefined.pipe';
import { SumPointsPipe } from './sum-points/sum-points.pipe';
import { ObjectValuesPipe } from './object-values/object-values.pipe';
import { SomeEqualPipe } from './some-equal/some-equal.pipe';
import { DeclOfNumPipe } from './decl-of-num/decl-of-num.pipe';
import { InternalLinkPipe } from './internal-link/internal-link.pipe';
import { PercentNoSpacePipe } from './percent-no-space/percent-no-space.pipe';
import { ImageMaskPipe } from './image-mask/image-mask.pipe';
import { ImageXlinkPipe } from './image-xlink/image-xlink.pipe';
import { SvgFlipPipe } from './svg-flip/svg-flip.pipe';

const pipes = [
  SafeHtmlPipe,
  SvgFlipPipe,
  SvgTranslatePipe,
  SvgRotatePipe,
  SvgScalePipe,
  ViewboxPipe,
  ImageBase64Pipe,
  SortByPositionPipe,
  FileSizePipe,
  ObjectKeysPipe,
  IsUndefinedPipe,
  FontFamilyPipe,
  NumberCharacterPipe,
  RoundNumberPipe,
  FormControlErrorPipe,
  SvgCirclePathPipe,
  IsNullOrUndefinedPipe,
  SumPointsPipe,
  ObjectValuesPipe,
  SomeEqualPipe,
  DeclOfNumPipe,
  InternalLinkPipe,
  PercentNoSpacePipe,
  ImageMaskPipe,
  ImageXlinkPipe,
];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes],
  imports: [CommonModule],
  providers: [ImageBase64Service, FileSizePipe],
})
export class PipesModule {}
