import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CropBoxTransformService } from '../../services/crop-box-transform.service';
import { CropMaskComponent } from '../crop-mask/crop-mask.component';
import { CropImageComponent } from '../crop-image/crop-image.component';
import { IFigureListItem } from '../../interfaces';
import { CropBoxSizeService } from '../../services/crop-box-size.service';
import { getFitScale } from '../../utils/crop.utils';
import { CropStore } from '../../store/crop';

@Component({
  selector: 'crop-container',
  standalone: true,
  templateUrl: './crop-container.component.html',
  styleUrls: ['./crop-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, CropMaskComponent, CropImageComponent],
  providers: [CropBoxTransformService, CropBoxSizeService],
})
export class CropContainerComponent
  implements OnChanges, AfterViewInit, OnDestroy
{
  @Input() imageUrl: string | null = null;
  @Input() imageWidth = 0;
  @Input() imageHeight = 0;
  @Input() maskX = 0;
  @Input() maskY = 0;
  @Input() maskWidth = 0;
  @Input() maskHeight = 0;
  @Input() saveProportions = false;
  @Input() selectedFigure?: IFigureListItem;

  @ViewChild('cropContainer') readonly cropContainer?: ElementRef<HTMLElement>;
  @ViewChild('cropBox') readonly cropBox?: ElementRef<HTMLElement>;

  readonly mask$ = this.cropStore.inlineSvgMask$;

  constructor(
    private readonly cropStore: CropStore,
    private readonly cropBoxTransformService: CropBoxTransformService,
    private readonly cropBoxSizeService: CropBoxSizeService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      'imageUrl' in changes ||
      'imageWidth' in changes ||
      'imageHeight' in changes
    ) {
      this.updateImage();
    }

    if ('selectedFigure' in changes) {
      this.updateCropBox();
    }

    if ('saveProportions' in changes) {
      this.cropStore.setSaveProportions(this.saveProportions);
    }
  }

  ngAfterViewInit() {
    if (this.cropContainer && this.cropBox) {
      this.cropBoxTransformService.init(
        this.cropContainer.nativeElement,
        this.cropBox.nativeElement
      );

      this.updateImage();

      if (!this.maskX && !this.maskX && !this.maskWidth && !this.maskHeight) {
        this.updateCropBox();
      } else {
        const { clientWidth: containerWidth, clientHeight: containerHeight } =
          this.cropContainer?.nativeElement || {
            clientWidth: 0,
            clientHeight: 0,
          };

        const imageScale = getFitScale(
          containerWidth,
          containerHeight,
          this.imageWidth,
          this.imageHeight
        );

        const imageX = (containerWidth - this.imageWidth * imageScale) / 2;
        const imageY = (containerHeight - this.imageHeight * imageScale) / 2;

        this.cropStore.setCropPosition({
          x: imageX - this.maskX * imageScale,
          y: imageY - this.maskY * imageScale,
        });

        this.cropStore.setCropSize({
          width: this.maskWidth * imageScale,
          height: this.maskHeight * imageScale,
        });
      }
    }
  }

  ngOnDestroy() {
    this.cropBoxTransformService.destroy();
  }

  private updateImage() {
    if (!this.cropContainer) {
      return;
    }

    const { clientWidth: containerWidth, clientHeight: containerHeight } =
      this.cropContainer.nativeElement;

    const imageScale = getFitScale(
      containerWidth,
      containerHeight,
      this.imageWidth,
      this.imageHeight
    );

    const imageX = (containerWidth - this.imageWidth * imageScale) / 2;
    const imageY = (containerHeight - this.imageHeight * imageScale) / 2;

    this.cropStore.setImageScale(imageScale);
    this.cropStore.setImageX(imageX);
    this.cropStore.setImageY(imageY);
  }

  private updateCropBox() {
    if (!this.cropContainer) {
      return;
    }

    const { width, height } = this.cropBoxSizeService.getSize(
      this.cropContainer,
      this.imageWidth,
      this.imageHeight,
      this.selectedFigure
    );

    this.cropStore.setCropSize({ width, height });
    this.cropStore.setCropPosition({
      x: (this.cropContainer.nativeElement.clientWidth - width) / 2,
      y: (this.cropContainer.nativeElement.clientHeight - height) / 2,
    });
  }
}
