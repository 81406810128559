import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { IFiltersValues, IProductFilters } from '../../../../interfaces';
import { IFilterMinMax } from '@inaripro-nx/common-ui';
import { CatalogProductListFilterWrapperComponent } from '../catalog-product-list-filter-wrapper/catalog-product-list-filter-wrapper.component';

@Component({
  selector: 'catalog-product-list-filter-price',
  standalone: true,
  imports: [
    CommonModule,
    DesignUiModule,
    CatalogProductListFilterWrapperComponent,
  ],
  templateUrl: './catalog-product-list-filter-price.component.html',
  styleUrl: './catalog-product-list-filter-price.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogProductListFilterPriceComponent {
  @Input() filters!: IProductFilters;
  @Input() filterValues!: IFiltersValues;

  @Output() changePrice = new EventEmitter<IFilterMinMax>();
  @Output() clearFilter = new EventEmitter<void>();
}
