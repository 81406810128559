@if (treeItem && treeItem.hasProductDesign && dictionary && childParent) {
<div class="sections">
  <ul class="category-list">
    @for (item of treeItem.children | catalogSortByIsActive : dictionary; track item.id) {
      @if (!hasProductDesign || (hasProductDesign && item.hasProductDesign)) {
        @if (dictionary[item.dictionaryId]; as dictItem) {
          @if ((item.editable && dictItem.isActive) || (!item.editable && dictItem.isActive) || (!item.editable && !dictItem.isActive)) {
            <li class="category-list__item">
              <div
                class="wrapper"
                [routerLink]="item | catalogGroupLink: rootModule" [queryParams]="queryParams"
              >
                <div>
                  @if (dictItem.logo && dictItem.logo.url) {
                    <img
                      [class.img-noactive]="!dictItem.isActive"
                      [lazyLoad]="dictItem.logo.url"
                      class="logo__desktop"
                      alt=""
                    />
                  }
                  @if (dictItem.logoMobile && dictItem.logoMobile.url) {
                    <img
                      [class.img-noactive]="!dictItem.isActive"
                      [lazyLoad]="dictItem.logoMobile.url"
                      class="logo__mobile"
                      alt=""
                    />
                  }
                </div>
                <a
                  class="categoryname"
                  [class.categoryname-noactive]="!dictItem.isActive"
                  [routerLink]="item | catalogGroupLink: rootModule" [queryParams]="queryParams"
                >
                  @if (!dictItem.isActive) { <i class="icon icon-ic_block"></i> }
                  {{ dictItem.name }}
                  @if (item.amount && !hasProductDesign) { <span class="amount">({{ item.amount }})</span> }
                </a>
              </div>

              <ul class="subcategory-list">
                @for (subItem of item.children | catalogSortByIsActive : dictionary; track subItem.id) {
                  @if (!hasProductDesign || (hasProductDesign && subItem.hasProductDesign)) {
                    <li
                      class="subcategory-list__item"
                      [class.subcategory-list__item-noactive]="!dictionary[subItem.dictionaryId].isActive"
                    >
                      <a [routerLink]="subItem | catalogGroupLink: rootModule" [queryParams]="queryParams">
                        {{ dictionary[subItem.dictionaryId].name }}
                      </a>
                      @if (subItem.amount && !hasProductDesign) {
                        <span class="amount">({{ subItem.amount }})</span>
                      }
                    </li>
                  }
                }
              </ul>
            </li>
          }
        }
      }
    }
  </ul>
</div>
}
