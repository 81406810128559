import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IMapOfBoolean } from '@inaripro-nx/common-ui';
import { IDesignSimpleSelectboxListItem } from '../../interfaces';
import { ClickOutsideDirective } from '../../directives';
import { DesignSimpleSelectboxInputComponent } from './components/design-simple-selectbox-input/design-simple-selectbox-input.component';
import { DesignSimpleSelectboxListComponent } from './components/design-simple-selectbox-list/design-simple-selectbox-list.component';
import { DesignSimpleSelectboxSelectedComponent } from './components/design-simple-selectbox-selected/design-simple-selectbox-selected.component';
import { SimplebarAngularModule } from 'simplebar-angular';
import { SelectedItemsSizePipe } from './pipes/selected-items-size/selected-items-size.pipe';
import { DesignSimpleSelectboxDropdownComponent } from './components/design-simple-selectbox-dropdown/design-simple-selectbox-dropdown.component';

@Component({
  selector: 'design-simple-select-box',
  standalone: true,
  imports: [
    CommonModule,
    ClickOutsideDirective,
    SimplebarAngularModule,
    DesignSimpleSelectboxInputComponent,
    DesignSimpleSelectboxListComponent,
    DesignSimpleSelectboxSelectedComponent,
    DesignSimpleSelectboxDropdownComponent,
    SimplebarAngularModule,
    SelectedItemsSizePipe,
  ],
  templateUrl: './design-simple-select-box.component.html',
  styleUrl: './design-simple-select-box.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignSimpleSelectBoxComponent implements OnChanges {
  @Input() items!: IDesignSimpleSelectboxListItem[];
  @Input() selectedList!: number[];
  @Input() selected!: IMapOfBoolean;
  @Input() placeholder!: string;
  @Input() animatePlaceholder = true;
  @Input() submitDisabled!: boolean;
  @Input() isUseWrapperForSelectedList = true;
  @Input() withChangeModel = true;
  @Input() showSelectAll = false;
  @Input() modificatorSimplebar = 'simple-selectbox';

  @Output() toggle: EventEmitter<number> = new EventEmitter<number>();
  @Output() selectAll: EventEmitter<void> = new EventEmitter<void>();
  @Output() clearAll: EventEmitter<void> = new EventEmitter<void>();
  @Output() changeModel: EventEmitter<IMapOfBoolean> =
    new EventEmitter<IMapOfBoolean>();

  @ViewChild('selectBoxInput', { static: false })
  selectBoxInput?: DesignSimpleSelectboxInputComponent;

  isActive = false;
  listItems!: IDesignSimpleSelectboxListItem[];
  filterItems!: IDesignSimpleSelectboxListItem[];
  selectedItems: IMapOfBoolean = {};

  constructor() {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['items']) {
      this.listItems = (this.items || []).slice();
    }

    if (simpleChanges['selected']) {
      this.selectedItems = { ...this.selected };
    }

    if (simpleChanges['selectedList']) {
      this.selectedItems = (this.selectedList || []).reduce((map, id) => {
        map[id] = true;
        return map;
      }, {} as IMapOfBoolean);
    }
  }

  public showDropdown(event: MouseEvent): void {
    if (event.target === this.selectBoxInput?.icon?.nativeElement) {
      return;
    }

    this.isActive = true;
  }

  public hideDropdown(): void {
    this.isActive = false;
  }

  public toggleDropdown(): void {
    this.isActive = !this.isActive;
  }

  onSearch(query: string) {
    this.filterItems = (this.items || []).slice();

    if (query) {
      this.filterItems = this.filterItems.filter(
        (item) => item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
      );
    }
  }

  onSelectAll() {
    this.onSearch('');
    if (this.selectBoxInput) {
      this.selectBoxInput.clear();
    }

    this.selectAll.emit();
  }

  onReset() {
    this.clearAll.emit();
    this.hideDropdown();
  }

  onApply(item: IMapOfBoolean) {
    this.hideDropdown();
    this.changeModel.emit(item);
  }

  selectItem(id: number) {
    this.toggle.emit(id);
  }

  unselected(item: number) {
    this.toggle.emit(item);
    if (this.withChangeModel) {
      this.changeModel.emit(this.selectedItems);
    }
  }
}
