export function getFitScale(
  containerWidth: number,
  containerHeight: number,
  originalWidth: number,
  originalHeight: number
): number {
  let scale = 1;

  if (originalWidth > containerWidth || originalHeight > containerHeight) {
    if (originalWidth / originalHeight < containerWidth / containerHeight) {
      scale = containerHeight / originalHeight;
    } else {
      scale = containerWidth / originalWidth;
    }
  }

  return scale;
}

export function getValueInInterval(
  value: number,
  interval: { min: number; max: number }
) {
  return Math.max(interval.min, Math.min(value, interval.max));
}

export function getResizedInlineSvg(
  inlineSvg: string | null,
  width: number,
  height: number,
  defaultInlineSvg = `
    <svg width="50" height="50" viewBox="0,0,50,50" preserveAspectRatio="none">
      <polygon points="0 0, 50 0, 50 50, 0 50"></polygon>
    </svg>
  `
): string {
  const svgWrapper = document.createElement('div');
  svgWrapper.innerHTML = inlineSvg || defaultInlineSvg;

  const innerSvg = svgWrapper.getElementsByTagName('svg')[0];
  innerSvg.setAttribute('width', `${width}`);
  innerSvg.setAttribute('height', `${height}`);

  return innerSvg.outerHTML;
}

export function removePreserveAspectRatio(inlineSvg: string): string {
  const svgWrapper = document.createElement('div');
  svgWrapper.innerHTML = inlineSvg;

  const innerSvg = svgWrapper.getElementsByTagName('svg')[0];
  innerSvg.setAttribute('preserveAspectRatio', 'none');

  return innerSvg.outerHTML;
}
