<div
  class="content-pictures"
  *ngIf="{
    user: user$ | async,
    userPictures: userPictures$ | async,
    libraries: picturesLibraryService.libraries$ | async,
    currentLibraryId: picturesLibraryService.currentId$ | async,
    currentLibrary: picturesLibraryService.currentLibrary$ | async,
    libraryPictures: picturesLibraryService.currentPictures$ | async,
    isLimitFileReach: isLimitFileReach$ | async
  } as state"
>
  <design-file-uploader
    #fileUploader
    [uid]="'content_pictures_file_uploader'"
    [uploadFile]="picturesService.uploadPicture"
    [accept]="'.png,.jpg,.jpeg,.svg'"
    [maxFiles]="maxFiles"
    [maxFileSize]="maxFileSize"
    [maxSimultaneousUploads]="maxSimultaneousUploads"
  ></design-file-uploader>
  <div class="content-pictures-buttons">
    <ng-container *ngTemplateOutlet="buttonGenerateImage"></ng-container>
    <ng-container *ngTemplateOutlet="buttonAddImage"></ng-container>
  </div>

  <ng-container
    *ngIf="{
      pictures: state.currentLibraryId
        ? state.libraryPictures
        : state.userPictures
    } as subState"
  >
    <div class="picture-library-select">
      <design-select
        [optionTemplate]="picturesLibraryTemplate"
        [options]="state.libraries || []"
        [selectedOption]="state.currentLibrary"
        (selectOption)="selectPictureLibrary($event)"
      ></design-select>
      <ng-template #picturesLibraryTemplate let-option="option">
        <span [class.is-active]="option?.id === state.currentLibraryId"
        >{{ option?.name }}
        </span>
      </ng-template>
    </div>
    <div class="wrapper-preloader" *ngIf="subState.pictures === null">
      <design-preloader></design-preloader>
    </div>
    <div
      class="simplebar-pictures"
      (drop)="!state.isLimitFileReach && fileUploader.fuFilesDrop($event)"
      (dragover)="!state.isLimitFileReach && fileUploader.fuFilesDropOver($event)"
    >
      <div
        class="wrapperimagesscroll"
        [class.wrapperimagesscroll-noclosecard]="!!state.currentLibraryId"
      >
        <div
          class="wrapper"
          *ngFor="let img of subState.pictures || []; let i = index"
        >
          <design-card-picture
            [image]="img"
            (selectObject)="selectObject.emit($event)"
            (cardCloseEmit)="state.currentLibraryId === 0 && onDeleteObject(img.id)"
          ></design-card-picture>
        </div>
      </div>
    </div>
    <div
      *ngIf="!state.isLimitFileReach"
      class="dropzone-comment"
      (drop)="fileUploader.fuFilesDrop($event)"
      (dragover)="fileUploader.fuFilesDropOver($event)"
    >
      <span>Для загрузки перенесите файлы сюда</span>
    </div>
  </ng-container>

  <div class="button-add">
    <ng-container *ngTemplateOutlet="buttonGenerateImage"></ng-container>
    <ng-container *ngTemplateOutlet="buttonAddImage"></ng-container>
  </div>

  <ng-template #buttonAddImage>
    <button
      type="button"
      class="g-btn-enter g-btn-enter__add"
      [class.btn--noactive]="state.isLimitFileReach"
      [title]="state.isLimitFileReach ? (state.user ? REACH_FILE_LIMIT_USER_TEXT : REACH_FILE_LIMIT_NO_USER_TEXT): null"
      (click)="state.isLimitFileReach ? openLimitErrorModal() : fileUploader.fuSelectFiles()"
    >
      <span>
        <i class="icon icon-ic_cross-plus"></i>
        Загрузить изображение
      </span>
    </button>
  </ng-template>

  <ng-template #buttonGenerateImage>
    <button
      type="button"
      class="btn-generate-image"
      [class.btn--noactive]="state.isLimitFileReach"
      [title]="state.isLimitFileReach ? (state.user ? REACH_FILE_LIMIT_USER_TEXT : REACH_FILE_LIMIT_NO_USER_TEXT): null"
      (click)="state.isLimitFileReach ? openLimitErrorModal() : openGenerateForm()"
    >
      <i class="icon icon-magic-wand"></i>
      <span>Создать изображение с помощью нейросети</span>
    </button>
  </ng-template>
</div>
