<div class="actions-figures" *ngIf="element">
  <ng-template #rangeSize>
    <div class="range-size">
      <painter-actions-element-scale
        [element]="element"
        [designSide]="designSide"
      ></painter-actions-element-scale>
    </div>
  </ng-template>

  <ng-template #rangeTurn>
    <div class="range-turn">
      <painter-actions-element-rotate
        [element]="element"
      ></painter-actions-element-rotate>
    </div>
  </ng-template>

  <ng-template #crop>
    @if (url) {
      <painter-actions-element-crop
        class="crop"
        [element]="element"
        [title]="'Вставить изображение'"
        (openCropModal)="openCropModal()"
      ></painter-actions-element-crop>
    }
  </ng-template>

  <ng-template #modification>
    <painter-actions-element-flip
      *ngIf="designSide"
      [element]="element"
      [designSide]="designSide"
      (updateElement)="updateElement.emit($event)"
      [modificator]="'figures'"
    ></painter-actions-element-flip>
  </ng-template>

  <ng-template #inputstext>
    <painter-actions-element-translate
      [element]="element"
    ></painter-actions-element-translate>
  </ng-template>

  <ng-template #colorpanel let-withModal="withModal">
    <div class="color-panel">
      <p> {{ EColorTitle.COLOR_FIGURES }}</p>
      <painter-actions-element-fill
        [element]="element"
        [withModal]="withModal"
        [modalTitle]= "EColorTitle.COLOR_FIGURES"
        [maxWidthModal]="302"
        [prefix]="'color-picker-figures'"
      >
        <ng-container *ngTemplateOutlet="fillopacity"></ng-container>
      </painter-actions-element-fill>
    </div>
  </ng-template>

  <ng-template #fillopacity>
    <div class="range-fillopacity">
      <div class="range-fillopacity__opacity">
        <painter-actions-element-fill-opacity
          [element]="element"
        ></painter-actions-element-fill-opacity>
      </div>
    </div>
  </ng-template>

  <ng-template #rangeStroke>
    <div class="range-size">
      <painter-actions-element-stroke
        [element]="element"
        [minValue]="MIN_VALUE_FIGURE_STROKE_WIDTH"
        [maxValue]="MAX_VALUE_FIGURE_STROKE_WIDTH"
        [modalUid]="MODAL_STROKE_FIGURE_UID"
        [modificator]="'stroke-figure'"
        [prefix]="'modal-stroke-figure'"
      >
        <ng-container *ngTemplateOutlet="strokeOpacity"></ng-container>
      </painter-actions-element-stroke>
    </div>
  </ng-template>

  <ng-template #strokeOpacity>
    <div class="wrapper-stroke-opacity">
      <painter-actions-element-stroke-opacity
        [element]="element"
      ></painter-actions-element-stroke-opacity>
    </div>
  </ng-template>

  @if(isDesktop) {
    <div class="actions-figures-desktop">
      <ng-container *ngTemplateOutlet="modification"></ng-container>
      <ng-container *ngTemplateOutlet="inputstext"></ng-container>
      <ng-container *ngTemplateOutlet="rangeSize"></ng-container>
      <ng-container *ngTemplateOutlet="rangeTurn"></ng-container>
      <ng-container *ngTemplateOutlet="crop"></ng-container>
      <ng-container *ngTemplateOutlet="colorpanel"></ng-container>
      <ng-container *ngTemplateOutlet="rangeStroke"></ng-container>
    </div>
  } @else {
    <div
      class="actions-figures-mobile"
      [class.actions-figures-mobile-move]="!isShowActionsSubmenu"
    >
      <ng-content></ng-content>
      <div
        class="tabs"
        [class.tabs-move]="!isShowActionsSubmenu"
        [class.tabs-figures-active-trasformations]="
        subRow === ESubRow.trasformations
      "
        [class.tabs-figures-active-color]="subRow === ESubRow.color"
      >
        <div
          class="tabs-subrow"
          [class.tabs-subrow-figures-active-trasformations]="
          subRow === ESubRow.trasformations
        "
          [class.tabs-subrow-figures-active-color]="subRow === ESubRow.color"
        >
          <div class="actions-forms" *ngIf="subRow === ESubRow.trasformations">
            <div class="actions-form actions-form-w50 actions-form-hide-mobile">
              <ng-container *ngTemplateOutlet="rangeSize"></ng-container>
              <ng-container *ngTemplateOutlet="rangeTurn"></ng-container>
            </div>
            <div class="actions-form actions-form-w50">
              <ng-container *ngTemplateOutlet="modification"></ng-container>
              <ng-container *ngTemplateOutlet="crop"></ng-container>
            </div>
          </div>
          <div class="actions-forms-block" *ngIf="subRow === ESubRow.color">
            <div class="actions-form actions-form-block">
              <div class="actions-forms-flex">
                <ng-container
                  *ngTemplateOutlet="colorpanel; context: { withModal: 'true' }"
                ></ng-container>
              </div>
              <div class="actions-forms-flex">
                <ng-container
                  *ngTemplateOutlet="rangeStroke"
                ></ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="tabs-row">
          <div
            class="tab"
            [class.tab-active]="subRow === ESubRow.trasformations"
            (click)="toggleSubRow(ESubRow.trasformations)"
          >
            <i class="icon icon-ic_transformation"></i>
            <span>Трансформация</span>
          </div>
          <div
            class="tab"
            [class.tab-active]="subRow === ESubRow.color"
            (click)="toggleSubRow(ESubRow.color)"
          >
            <i class="icon icon-ic_color"></i>
            <span>Цвет</span>
          </div>
        </div>
      </div>
    </div>
  }
</div>
