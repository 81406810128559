import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Params, RouterModule } from '@angular/router';
import {
  IPaginationOnlyRequest,
  IPaginationPages,
  IPaginationResponse,
  paginationCreatePageArray,
} from '@inaripro-nx/common-ui';

@Component({
  selector: 'design-pagination',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnChanges {
  @Input() storeUid = '';
  @Input() request: IPaginationOnlyRequest | null = null;
  @Input() response: IPaginationResponse | null = null;
  @Input() queryParams: Params = {};

  isFirstPage = false;
  isLastPage = false;

  previousPageQueryParams: Params | null = null;
  nextPageQueryParams: Params | null = null;

  currentPage: number | null = null;
  pages: IPaginationPages[] = [];
  pageQueryParams: Params[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if ('request' in changes && this.request) {
      try {
        this.currentPage =
          Math.ceil(this.request.offset / this.request.limit) + 1;
      } catch (e) {
        this.currentPage = null;
      }
    }

    if ('response' in changes && this.response) {
      this.isFirstPage = !this.response.hasPrevPage;
      this.isLastPage = !this.response.hasNextPage;

      if (this.response.hasPrevPage) {
        this.previousPageQueryParams = {
          ...this.queryParams,
          offset: Math.max(0, this.response.offset - this.response.limit),
          limit: this.response.limit,
        };
      } else {
        this.previousPageQueryParams = null;
      }

      if (this.response.hasNextPage) {
        this.nextPageQueryParams = {
          ...this.queryParams,
          offset: this.response.offset + this.response.limit,
          limit: this.response.limit,
        };
      } else {
        this.nextPageQueryParams = null;
      }

      try {
        this.currentPage =
          Math.ceil(this.response.offset / this.response.limit) + 1;
      } catch (e) {
        this.currentPage = null;
      }

      if (this.currentPage) {
        this.pages = paginationCreatePageArray(
          this.response,
          this.currentPage,
          7
        );

        this.pageQueryParams = this.pages.map((page) => {
          return {
            ...this.queryParams,
            offset:
              (this.response as IPaginationResponse).limit * (page.value - 1),
            limit: (this.response as IPaginationResponse).limit,
          };
        });
      }
    }
  }
}
