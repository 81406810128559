import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PipesModule } from '../../pipes/pipes.module';
import { HistoryStore } from '../../state/history/history.store';
import { ProductStore } from '../../state/product/product.store';
import { WorkareaStore } from '../../state/workarea/workarea.store';
import { EditorControlsComponent } from './components/editor-controls/editor-controls.component';
import { EditorDrawComponent } from './components/editor-draw/editor-draw.component';
import { EditorWorkareaDirective } from './directives/editor-workarea/editor-workarea.directive';
import { AppShellConfig } from '@inaripro-nx/common-ui';
import { ContentComponent } from '../content/content.component';

@Component({
  selector: 'painter-editor',
  standalone: true,
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    EditorDrawComponent,
    EditorControlsComponent,
    EditorWorkareaDirective,
    PipesModule,
    ContentComponent,
  ],
})
export class EditorComponent {
  isGrabbing = false;

  readonly isGrabActive$ = this.workareaStore.isGrabActive$;

  constructor(
    private readonly productStore: ProductStore,
    private readonly historyStore: HistoryStore,
    private readonly workareaStore: WorkareaStore
  ) {}

  selectSide(index: number) {
    this.workareaStore.reset();
    this.historyStore.setActiveElementIndex({ activeElementIndex: null });
    this.productStore.setActiveDesignSideIndex(index);
  }

  onDragStart(): void {
    this.isGrabbing = true;
  }

  onDragEnd(): void {
    this.isGrabbing = false;
  }

  clearSelect() {
    this.historyStore.setActiveElementIndex({
      activeElementIndex: null,
    });
  }
}
