import { IEnvironment } from './interfaces/environment.interface';

export const environment: IEnvironment = {
  production: false,
  api: 'https://api.dev.inari24.ru/v3',
  designerRoot: 'https://designer.inari24.ru',
  publicBaseUrl: 'https://dev.inari24.ru',
  sentry: {
    DSN: 'https://3314da35ac55ea4d65deca533cb83dd9@o4504168085848064.ingest.sentry.io/4505758888427520',
    options: {
      environment: 'dev',
      tracePropagationTargets: [/^https:\/\/api\.dev\.inari24\.ru/],
    },
    DSNRavenOptions: {
      environment: 'dev',
      sampleRate: 1,
    },
  },
  release: '0.0.1',
};
