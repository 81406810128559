import { Pipe, PipeTransform } from '@angular/core';

interface IInternalLinkPipeResult {
  routerLink: any[] | string;
  queryParams: {
    [k: string]: any;
  } | null;
  fragment: string | null;
}

@Pipe({
  name: 'internalLink',
})
export class InternalLinkPipe implements PipeTransform {
  transform(value: string): IInternalLinkPipeResult | null {
    try {
      let url;
      try {
        url = new URL(value);
      } catch (e) {
        return null;
      }

      if (value.indexOf('//') > -1) {
        const routerLink = url.pathname;
        const fragment = url.hash;
        const searchParams = url.searchParams;

        const queryParams: IInternalLinkPipeResult['queryParams'] = {};

        searchParams.forEach((val, key) => {
          queryParams[key] = val;
        });

        return {
          routerLink,
          queryParams,
          fragment: fragment === '' ? null : fragment.replace(/#/gi, ''),
        };
      }

      return null;
    } catch (e) {
      return null;
    }
  }
}
