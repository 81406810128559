import { Canvg, presets } from 'canvg';
import { Observable, catchError, from, of, switchMap } from 'rxjs';
import { decimalRound } from './math.utils';

export function getSvgXYFromEvent(
  svg: SVGGraphicsElement,
  event: MouseEvent | TouchEvent
) {
  return getSvgXY(
    svg,
    window.TouchEvent && event instanceof TouchEvent
      ? event.changedTouches[0]
      : (event as MouseEvent)
  );
}

export function getSvgXY(
  svg: SVGGraphicsElement,
  event: { clientX: number; clientY: number }
): { x: number; y: number } {
  const { a = 1, d = 1, e = 0, f = 0 } = svg.getScreenCTM() || {};

  return {
    x: (event.clientX - e) / a,
    y: (event.clientY - f) / d,
  };
}

export function getSvgLength(
  svg: SVGGraphicsElement,
  event: { x: number; y: number },
  decimalPlaces?: number
): { x: number; y: number } {
  const { a = 1, d = 1 } = svg.getScreenCTM() || {};

  return {
    x: decimalRound(event.x / a, decimalPlaces),
    y: decimalRound(event.y / d, decimalPlaces),
  };
}

export function getSvgSize(
  svg: SVGGraphicsElement,
  size: number,
  decimalPlaces?: number
): number {
  const { a = 1, d = 1 } = svg.getScreenCTM() || {};
  return decimalRound(size / a, decimalPlaces);
}

export function svgToXml(svg: SVGElement | string) {
  let source: string;

  if (svg instanceof SVGElement) {
    //get svg source.
    const serializer = new XMLSerializer();
    source = serializer.serializeToString(svg);
  } else {
    source = svg;
  }

  //add name spaces.
  if (!source.match(/^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/)) {
    source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
  }

  if (!source.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)) {
    source = source.replace(
      /^<svg/,
      '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
    );
  }

  //add xml declaration
  source = '<?xml version="1.1" encoding="UTF-8" ?>\r\n' + source;
  return source;
}

export function svgToPng(data: {
  svg: SVGElement;
  width?: number;
  height?: number;
}): Observable<Blob | null> {
  const { width, height, svg } = data;
  const widthC = width || svg.clientWidth;
  const heightC = height || svg.clientHeight;
  const source = svgToXml(data.svg);

  if (HTMLCanvasElement.prototype.transferControlToOffscreen === undefined) {
    return of(null);
  }

  const canvas = new OffscreenCanvas(widthC, heightC);
  const ctx = canvas.getContext('2d');

  const preset = presets.offscreen();

  return ctx
    ? from(Canvg.from(ctx, source, preset)).pipe(
        switchMap((v) => from(v.render())),
        switchMap(() => from(canvas.convertToBlob())),
        catchError(() => of(null))
      )
    : of(null);
}

export function getUrlFromSvg(document: Document, svg: string) {
  const svgElement = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'svg'
  );
  svgElement.innerHTML = svg;

  const image = svgElement.getElementsByTagName('image')[0];

  return image
    ? image.getAttributeNS('http://www.w3.org/1999/xlink', 'href')
    : null;
}
