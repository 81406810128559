<svg:g>
  @if (el.textArc && el.textArc.angle) {
    <g
      data-painter-editor-element-text-arc
      [el]="el"
      [index]="index"
      [inAction]="inAction"
    ></g>
  } @else {
    <g
      data-painter-editor-element-text-multiline
      [el]="el"
    ></g>
  }
</svg:g>
