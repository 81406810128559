import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CatalogNomenclatureDictionaryRecords,
  ICatalogNomenclatureTreeItem,
} from '../../interfaces';
import { CatalogGroupLinkPipe } from '../../pipes/catalog-group-link/catalog-group-link.pipe';
import { CatalogSortByIsActivePipe } from '../../pipes/catalog-sort-by-is-active/catalog-sort-by-is-active.pipe';

@Component({
  selector: 'catalog-section-list-item',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    CatalogGroupLinkPipe,
    CatalogSortByIsActivePipe,
  ],
  templateUrl: './catalog-section-list-item.component.html',
  styleUrl: './catalog-section-list-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogSectionListItemComponent {
  @Input() item!: ICatalogNomenclatureTreeItem;
  @Input() dictionary!: CatalogNomenclatureDictionaryRecords;
  @Input() rootModule!: string;
  @Input() queryParams = {};
  @Input() hasProductDesign = false;
}
