export enum EFontFamily {
  Arial = 'Arial',
  OpenSans = 'Open Sans',
  Roboto = 'Roboto',
  Oswald = 'Oswald',
  Prata = 'Prata',
  Impact = 'Impact',
  Intro = 'Intro',
  Gilroy = 'Gilroy',
  Lobster = 'Lobster',
  Pacifico = 'Pacifico',
  Caveat = 'Caveat',
  Pribambas = 'Pribambas',
  Pangolin = 'Pangolin',
  Paper = 'Paper',
  Press_Start_2P = 'Press Start 2P',
  Play = 'Play',
  Times = 'Times',
  TimesNewRoman = 'Times New Roman',
  RockwellMT = 'Rockwell MT',
  SportsWorld = 'Sports World',
  Bebas_Neue_Cyrillic = 'Bebas Neue Cyrillic',
  Freeride = 'Freeride',
  PlayfairDisplay = 'Playfair Display',
  Borsok = 'Borsok',
  RuslanDisplay = 'Ruslan Display',
  Baloo = 'Baloo Cyrillic',
  Stencil = 'Stencil BT [Rus by me]',
  Colatemta = 'Colatemta',
  Balkara = 'Balkara Free Condensed - npoekmu.me',
  TsarevichOld = 'Tsarevich old',
  TabulaPeutingeriana = 'Tabula Peutingeriana',
  AnimeAce = 'Anime Ace v05',
  SolomonSans = 'Solomon Sans',
  MountainsOfChristmas = 'Mountains of Christmas',
  Journalism = 'JOURNALISM',
  GoodVibesPro = 'Good Vibes Pro',
  Yokawerad = 'Yokawerad',
  Pobeda = 'Pobeda',
  RedOctober = 'Red October',
  KZCooper = 'KZ Cooper',
  JournalSansNew = 'Journal Sans New',
  FunСityLevel3Basic = 'FunCity Level 3 Basic',
  Antarctic = 'Antarctic',
  BitterBlack = 'Bitter Black',
  SpriteGraffiti = 'Sprite Graffiti',
  DockerTwo = 'DOCKER TWO',
  Campusgrav = 'a_CampusGrav',
  CaesarDressing = 'Caesar Dressing Cyrillic',
  NipponcyrBold = 'NipponCyr-Bold',
  Fundamental3DBrigade = 'Fundamental 3D  Brigade',
  JonnyDecor = 'Jonny Decor',
}

export interface IFontFamily {
  key: EFontFamily;
  label: string;
  old?: boolean;
  fileName?: string;
  blockBold?: boolean;
  blockItalic?: boolean;
}

export const FONTS_FAMILIES: IFontFamily[] = [
  { key: EFontFamily.Arial, label: 'Arial' },
  {
    key: EFontFamily.OpenSans,
    label: 'Open Sans',
    fileName: 'OpenSans-Regular.woff',
  },
  {
    key: EFontFamily.Roboto,
    label: 'Roboto',
    fileName: 'Roboto-Regular.woff',
  },
  {
    key: EFontFamily.Oswald,
    label: 'Oswald',
    fileName: 'Oswald-Regular.woff',
    blockItalic: true,
  },
  {
    key: EFontFamily.Prata,
    label: 'Prata',
    fileName: 'Prata-Regular.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.Impact,
    label: 'Impact',
    fileName: 'impact.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.Intro,
    label: 'INTRO',
    fileName: 'Intro.woff',
  },
  {
    key: EFontFamily.Gilroy,
    label: 'Gilroy',
    fileName: 'Gilroy-ExtraBold.woff',
  },
  {
    key: EFontFamily.Lobster,
    label: 'Lobster',
    fileName: 'Lobster.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.Pacifico,
    label: 'Pacifico',
    fileName: 'Pacifico-Regular.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.Caveat,
    label: 'Caveat',
    fileName: 'Caveat-VariableFont_wght.woff',
    blockItalic: true,
  },
  {
    key: EFontFamily.Pribambas,
    label: 'PRIBAMBAS',
    fileName: 'Pribambas.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.Pangolin,
    label: 'Pangolin',
    fileName: 'Pangolin-Regular.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.Paper,
    label: 'PAPER',
    fileName: 'Paper.woff',
  },
  {
    key: EFontFamily.Press_Start_2P,
    label: 'Press Start 2P',
    fileName: 'PressStart2P-Regular.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.Play,
    label: 'Play',
    fileName: 'Play-Regular.woff',
    blockBold: true,
    blockItalic: true,
  },
  { key: EFontFamily.Times, label: 'Times', old: true },
  { key: EFontFamily.TimesNewRoman, label: 'Times New Roman' },
  {
    key: EFontFamily.RockwellMT,
    label: 'Rockwell MT',
    fileName: 'rockwellmt.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.SportsWorld,
    label: 'SPORTS WORLD',
    fileName: 'SportsWorld.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.Bebas_Neue_Cyrillic,
    label: 'BEBAS NEUE',
    fileName: 'bebasneuecyrillic.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.Freeride,
    label: 'Freeride',
    fileName: 'Freeride.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.PlayfairDisplay,
    label: 'Playfair Display',
    fileName: 'PlayfairDisplay.woff',
  },
  {
    key: EFontFamily.Borsok,
    label: 'BORSOK',
    fileName: 'borsok.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.RuslanDisplay,
    label: 'RUSLAN DISPLAY',
    fileName: 'RuslanDisplay.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.Baloo,
    label: 'Baloo',
    fileName: 'baloo-cyrillic.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.Stencil,
    label: 'STENCILL',
    fileName: 'stencil.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.Colatemta,
    label: 'COLATEMTA',
    fileName: 'Colatemta.woff',
  },
  {
    key: EFontFamily.Balkara,
    label: 'BALKARA',
    fileName: 'BalkaraFreeCondensed.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.TsarevichOld,
    label: 'TSAREVICH OLD',
    fileName: 'Tsarevich-old.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.TabulaPeutingeriana,
    label: 'Tabula Peutingeriana',
    fileName: 'tabulapeutingeriana.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.AnimeAce,
    label: 'ANIME ACE',
    fileName: 'anime-ace.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.SolomonSans,
    label: 'Solomon Sans',
    fileName: 'solomon-sans-10.woff',
  },
  {
    key: EFontFamily.MountainsOfChristmas,
    label: 'Mountains Of Christmas',
    fileName: 'mountains-of-christmas-cyrillic.woff',
  },
  {
    key: EFontFamily.Journalism,
    label: 'JOURNALISM',
    fileName: 'journalism.woff',
  },
  {
    key: EFontFamily.GoodVibesPro,
    label: 'Good Vibes Pro',
    fileName: 'good-vibes-pro.woff',
  },
  {
    key: EFontFamily.Yokawerad,
    label: 'Yokawerad',
    fileName: 'yokawerad-2.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.Pobeda,
    label: 'ПОБЕДА',
    fileName: 'pobeda-regular.woff',
    blockItalic: true,
  },
  {
    key: EFontFamily.RedOctober,
    label: 'RED OCTOBER',
    fileName: 'red-october.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.KZCooper,
    label: 'Cooper',
    fileName: 'kzcooper.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.JournalSansNew,
    label: 'Journal Sans New',
    fileName: 'journal-sans-new-3.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.FunСityLevel3Basic,
    label: 'FUNCITY LEVEL 3 BASIC',
    fileName: 'fun-city-level-3-basic.woff',
  },
  {
    key: EFontFamily.Antarctic,
    label: 'Antarctic',
    fileName: 'antarctic.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.BitterBlack,
    label: 'Bitter Black',
    fileName: 'bitter.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.SpriteGraffiti,
    label: 'SPRITE GRAFFITI',
    fileName: 'Sprite Graffiti.woff',
    blockBold: true,
    blockItalic: true,
    old: true,
  },
  {
    key: EFontFamily.DockerTwo,
    label: 'DOCKER TWO',
    fileName: 'docker-2.woff',
  },
  {
    key: EFontFamily.Campusgrav,
    label: 'CAMPUSGRAV',
    fileName: 'ACampusGrav.woff',
  },
  {
    key: EFontFamily.CaesarDressing,
    label: 'CAESAR DRESSING',
    fileName: 'caesardressingcyrillic.woff',
  },
  {
    key: EFontFamily.NipponcyrBold,
    label: 'NIPPON',
    fileName: 'nipponcyr_bold.woff',
    blockItalic: true,
  },
  {
    key: EFontFamily.Fundamental3DBrigade,
    label: 'Fundamental 3D Brigade',
    fileName: 'fundamental-3d-brigade.woff',
    blockBold: true,
    blockItalic: true,
  },
  {
    key: EFontFamily.JonnyDecor,
    label: 'JONNY DECOR',
    fileName: 'jonny.woff',
  },
];
