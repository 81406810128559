import {
  afterNextRender,
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { CatalogGroupLinkPipe, CatalogSortByIsActivePipe } from '../../pipes';
import {
  CatalogNomenclatureDictionaryRecords,
  ICatalogNomenclatureListItem,
  ICatalogNomenclatureTreeItem,
  IMapOfTreeItem,
} from '../../interfaces';
import { WINDOW } from '@inaripro-nx/common-ui';

@Component({
  selector: 'catalog-group-categories',
  standalone: true,
  imports: [
    CommonModule,
    CatalogSortByIsActivePipe,
    CatalogGroupLinkPipe,
    RouterModule,
  ],
  providers: [CatalogGroupLinkPipe],
  templateUrl: './catalog-group-categories.component.html',
  styleUrl: './catalog-group-categories.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogGroupCategoriesComponent implements OnChanges {
  @Input() treeItem: ICatalogNomenclatureTreeItem | null = null;
  @Input() childParent: IMapOfTreeItem | null = null;
  @Input() dictionary: CatalogNomenclatureDictionaryRecords | null = null;
  @Input() companyId!: number;
  @Input() rootModule!: string;
  @Input() hasProductDesign = false;

  queryParams = {};

  private window?: Window;

  constructor(
    private readonly router: Router,
    private readonly catalogGroupLinkPipe: CatalogGroupLinkPipe,
    private readonly injector: Injector
  ) {
    afterNextRender(() => {
      this.window = this.injector.get(WINDOW);
    });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['companyId']) {
      this.queryParams = this.companyId ? this.companyId : {};
    }
  }

  public openCategory(
    event: MouseEvent | TouchEvent,
    item: ICatalogNomenclatureListItem
  ): void {
    if ((event.target as HTMLLinkElement).href) {
      // prevent
      return;
    }

    const urlTree = this.router.createUrlTree(
      [
        ...this.catalogGroupLinkPipe
          .transform(item, this.rootModule)
          .split('/'),
      ],
      { queryParams: this.queryParams }
    );
    const url = urlTree.toString();

    if (event.ctrlKey || event.metaKey || (event as MouseEvent).button === 1) {
      this.window?.open(url, '_blank');
    } else {
      this.router.navigateByUrl(url);
    }
  }
}
