import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { IPublicPriceByUid, IPublicProductWithPrice } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class CatalogProductService {
  constructor(private readonly http: HttpClient) {}

  productCash: { [key: string]: IPublicProductWithPrice } = {};
  priceCash: { [key: string]: IPublicPriceByUid } = {};

  getProduct(
    productId: number,
    withMaxPrice = false,
    priceUid: string | null = null
  ): Observable<IPublicProductWithPrice> {
    let searchParams: {
      maxDesignerPrice: string;
      priceUid?: string;
    } = {
      maxDesignerPrice: withMaxPrice ? '1' : '0',
    };

    if (priceUid) {
      searchParams = { ...searchParams, priceUid };
    }

    const baseUrl = `/catalog/public/goods/${productId}`;
    const urlSearchParams = new URLSearchParams(searchParams);
    const searchString = urlSearchParams.toString();
    const url = searchString ? `${baseUrl}?${searchString}` : baseUrl;

    const cacheKey = `${productId}-${priceUid}`;
    const cache = this.productCash[cacheKey];

    return cache
      ? of(cache)
      : this.http
          .get<IPublicProductWithPrice>(url)
          .pipe(tap((res) => (this.productCash[cacheKey] = res)));
  }

  getProductPriceByUID(
    productId: number,
    priceUid: string
  ): Observable<IPublicPriceByUid> {
    const url = `/catalog/public/goods/${productId}/price-by-uid/?priceUid=${priceUid}`;

    const cacheKey = `${productId}-${priceUid}`;
    const cache = this.priceCash[cacheKey];

    return cache
      ? of(cache)
      : this.http
          .get<IPublicPriceByUid>(url)
          .pipe(tap((res) => (this.priceCash[cacheKey] = res)));
  }
}
