<div class="main-workspace">
  <div class="main-workspace__leftside">
    <div class="main-workspace__leftside-tabs">
      <painter-desktop-tabs></painter-desktop-tabs>
    </div>
    <div class="main-workspace__leftside-content">
      <painter-content></painter-content>
    </div>
  </div>
  <div class="main-workspace__centerside">
    <painter-editor></painter-editor>
  </div>
  <div class="main-workspace__rigthside">
    <painter-actions></painter-actions>
  </div>
</div>
