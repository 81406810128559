import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  TemplateRef,
} from '@angular/core';
import { IDesignSide } from '@inaripro-nx/catalog';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { getElementScale } from '../../../../../../utils/calculate.utils';
import {
  ELEMENT_MAX_SCALE,
  ELEMENT_MIN_SCALE,
  IElement,
  IXY,
} from '../../../../interfaces/editor.interface';
import { EditorService } from '../../../../services/editor/editor.service';
import { DECIMAL_POINTS_SCALE } from '../../../../../../constants';

@Component({
  selector: 'painter-actions-element-scale',
  standalone: true,
  imports: [CommonModule, DesignUiModule],
  templateUrl: './actions-element-scale.component.html',
  styleUrls: ['./actions-element-scale.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsElementScaleComponent implements OnChanges {
  @Input() element!: IElement;
  @Input() designSide: IDesignSide | null = null;
  @Input() minValue = ELEMENT_MIN_SCALE * 100;
  @Input() maxValue = ELEMENT_MAX_SCALE * 100;
  @Input() sliderTemplate: TemplateRef<unknown> | null = null;

  readonly DECIMAL_POINTS_SCALE = DECIMAL_POINTS_SCALE;

  elementScale = this.maxValue;

  constructor(private editorService: EditorService) {}

  ngOnChanges(): void {
    this.elementScale = this.getElementScale();
  }

  private getElementScale() {
    if (!this.element || !this.designSide) {
      return this.maxValue;
    }

    return getElementScale(this.element, this.designSide);
  }

  changeValue(percent: number): void {
    if (!this.element || !this.designSide) {
      return;
    }

    const { size } = this.element;
    const { sizePX } = this.designSide;

    const scaleXY: IXY = {
      x: ((percent / 100) * sizePX.x) / size.x,
      y: ((percent / 100) * sizePX.y) / size.y,
    };

    const scale = scaleXY.x < scaleXY.y ? scaleXY.x : scaleXY.y;
    this.elementScale = percent;

    this.editorService.scale(scale);
  }

  stopChange() {
    this.editorService.stopChange();
  }

  reset(): void {
    this.changeValue(this.maxValue);
    this.editorService.stopChange();
  }
}
