import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, filter, map, switchMap } from 'rxjs';
import { ProductStore } from '../../../../../../state/product/product.store';
import { IDesignProperty } from '@inaripro-nx/catalog';
import { ContentProductPropertyWrapperComponent } from '../../../content-product/components/content-product-property-wrapper/content-product-property-wrapper.component';
import { DesignUiModule } from '@inaripro-nx/design-ui';

@Component({
  selector: 'painter-content-fill-property',
  standalone: true,
  imports: [
    CommonModule,
    ContentProductPropertyWrapperComponent,
    DesignUiModule,
  ],
  templateUrl: './content-fill-property.component.html',
  styleUrl: './content-fill-property.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentFillPropertyComponent {
  @Input()
  set zoneKey(key: string) {
    this.zoneKeySubject$.next(key);
  }

  @Input() colorProperty!: IDesignProperty;

  private readonly zoneKeySubject$ = new BehaviorSubject<string | null>(null);

  readonly designerSource$ = this.productStore.designerSource$;

  readonly inColor$ = this.zoneKeySubject$.asObservable().pipe(
    filter(Boolean),
    switchMap((zoneKey) =>
      this.productStore.zoneColors$.pipe(
        filter(Boolean),
        map((zoneColors) => zoneColors[zoneKey] || null)
      )
    )
  );

  constructor(private readonly productStore: ProductStore) {}

  public setZoneColor(color: string | null): void {
    const key = this.zoneKeySubject$.getValue();
    if (key) {
      this.productStore.setZoneColor({
        key,
        color,
      });
    }
  }
}
