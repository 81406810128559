import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { filter, Subscription } from 'rxjs';
import { RangeSliderComponent } from '../range-slider/range-slider.component';
import { FormInputNumberComponent } from '../form-input-number/form-input-number.component';
import { getFormatNumber, isAndroid } from '@inaripro-nx/common-ui';
import { RangeGradientSliderComponent } from '../range-gradient-slider/range-gradient-slider.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RangeSliderComponent,
    RangeGradientSliderComponent,
    FormInputNumberComponent,
  ],
  selector: 'design-range-slider-wrapper',
  templateUrl: './range-slider-wrapper.component.html',
  styleUrls: ['./range-slider-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangeSliderWrapperComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Input() unitmeasurement = '%';
  @Input() label = '';
  @Input() value = 0;
  @Input() minValue = 0;
  @Input() maxValue = 100;
  @Input() decimalPoints = 0;
  @Input() isGradientRangeSlider = false;
  @Input() color = '#000';
  @Input() isShowInputValue = true;

  @Output() stopChange: EventEmitter<void> = new EventEmitter();
  @Output() changeValue: EventEmitter<number> = new EventEmitter();
  @Output() resetValue: EventEmitter<void> = new EventEmitter();

  @ViewChild('inputElement')
  private readonly inputElement!: ElementRef;

  public isFocused = false;
  private canEmitStop = false;
  private valueSub!: Subscription;

  readonly valueControl = new FormControl<number>(this.value);
  readonly isAndroid = isAndroid();

  get controlValue(): number | null {
    return this.valueControl.value;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('value' in changes) {
      const { value } = getFormatNumber({
        value: this.value,
        minValue: this.minValue,
        maxValue: this.maxValue,
        decimalPoints: this.decimalPoints,
      });

      if (value !== this.valueControl.value) {
        this.valueControl.setValue(value, { emitEvent: false });
        this.canEmitStop = false;
      }
    }
  }

  ngOnInit() {
    this.valueChangeSubscribe();
  }

  ngOnDestroy() {
    if (this.valueSub) {
      this.valueSub.unsubscribe();
    }
  }

  private valueChangeSubscribe(): void {
    this.valueSub = this.valueControl.valueChanges
      .pipe(filter((v) => v !== this.value))
      .subscribe((value: number | null) => {
        const format = getFormatNumber({
          value,
          minValue: this.minValue,
          maxValue: this.maxValue,
          decimalPoints: this.decimalPoints,
        });

        if (format.isChange) {
          this.valueControl.setValue(format.value);
          this.cdr.detectChanges();
        } else if (format.value !== null) {
          this.changeValue.emit(format.value);
          this.canEmitStop = true;
        }
      });
  }

  public onValueChange(value: number): void {
    this.valueControl.setValue(value);
  }

  public onStopChange(): void {
    if (this.canEmitStop) {
      this.stopChange.emit();
    }

    this.isFocused = false;
  }
}
