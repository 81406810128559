import {
  decimalRound,
  IFileResponse,
  ImageSizeEnum,
} from '@inaripro-nx/common-ui';
import { IGalleryImage, IGalleryImageMap } from '@inaripro-nx/design-ui';
import { ProductPriceTypeEnum } from '../enums/catalog-product.enum';
import { IPublicAlbumThumbs, IPublicPrice } from '../interfaces';
import {
  CatalogNomenclatureDictionaryRecords,
  ICatalogNomenclatureListItem,
  ICatalogNomenclatureTreeItem,
} from '../interfaces/catalog.interface';

const CATALOG_ROOT_LINK = '/catalog';

export function arrayToTree(
  arr: ICatalogNomenclatureListItem[],
  parent: number,
  parentObject: ICatalogNomenclatureTreeItem,
  dictionary: CatalogNomenclatureDictionaryRecords
): ICatalogNomenclatureTreeItem[] {
  return arr
    .filter(({ parentId, level }) => {
      return parentId[level - 2] === parent;
    })
    .map((child) => {
      const dictionaryItem = dictionary[child.dictionaryId];

      const object: ICatalogNomenclatureTreeItem = {
        ...child,
        children: [],
        hasProductDesign: dictionaryItem.hasProductDesign,
      };

      object.children = arrayToTree(arr, child.id, object, dictionary);

      if (!parentObject.hasProductDesign && object.hasProductDesign) {
        parentObject.hasProductDesign = true;
      }

      return object;
    });
}

export function getRoute(
  item: ICatalogNomenclatureListItem,
  rootUrl = CATALOG_ROOT_LINK
): (string | number)[] {
  return [
    rootUrl,
    item.canAddProduct ? 'products' : 'group',
    item.dictionarySlug || item.dictionaryId,
    item.id,
  ];
}

export function getOrderCount(
  inputCount: number | null,
  product: {
    lotSize?: number;
    minRange?: number;
    maxRange?: number;
  } | null
): number {
  if (!product) {
    return inputCount || 1;
  }

  let orderCount = inputCount || product.lotSize || product.minRange || 1;

  const updateWithLotSize = (val: number, isMax = false) => {
    if (product.lotSize) {
      const part = isMax
        ? Math.floor(val / product.lotSize)
        : Math.ceil(val / product.lotSize);
      return decimalRound(part * product.lotSize, 0);
    }

    return val;
  };

  if (product.minRange && orderCount < product.minRange) {
    orderCount = updateWithLotSize(product.minRange);
  } else if (product.maxRange && orderCount > product.maxRange) {
    orderCount = updateWithLotSize(product.maxRange, true);
  } else {
    orderCount = updateWithLotSize(orderCount);
  }

  return orderCount;
}

export function getPublicImages(
  product: {
    name: string;
    album: IFileResponse[];
    previews: IPublicAlbumThumbs[];
  } | null,
  publicPrice: IPublicPrice | null
): IGalleryImageMap | null {
  if (!product) {
    return null;
  }

  const images = (product.album || []).map((f) =>
    convertToGalleryImage(f, product.previews)
  );

  if (images.length > 0) {
    images[0].alt = product.name;
    images[0].title = product.name;
  }

  const imagesMap: IGalleryImageMap = {};
  imagesMap['album'] = images;

  if (publicPrice) {
    Object.keys(publicPrice.photos || {}).forEach((uid) => {
      const photo = publicPrice.photos[uid];

      imagesMap[uid] = (photo.files || [])
        .sort(
          (a, b) => +(b.id === photo.previewId) - +(a.id === photo.previewId)
        )
        .map((f) => convertToGalleryImage(f));
    });
  }

  return imagesMap;
}

export function convertToGalleryImage(
  file: IFileResponse,
  previews?: IPublicAlbumThumbs[]
) {
  const previewsMap: { [key: string]: IPublicAlbumThumbs } = (previews || [])
    .filter((p) => p.size === ImageSizeEnum.VERTICAL)
    .reduce((acc, p) => {
      acc[p.originId] = p as IPublicAlbumThumbs;
      return acc;
    }, {} as { [key: string]: IPublicAlbumThumbs });

  const size = findSizeImage(file);

  const image: IGalleryImage = {
    id: size.id,
    medium: size.url,
    description: file.comment,
  };

  const preview = previewsMap[size.id];
  image.small = preview ? preview.url : size.url;

  return image;
}

export function findSizeImage(file: IFileResponse): IFileResponse {
  return (
    (file.sizes || []).find((s) => s.size === ImageSizeEnum.VERTICAL) || file
  );
}

export function getCurrentImage(
  priceUid: string | null,
  publicPrice: IPublicPrice | null,
  publicImages: IGalleryImageMap | null
) {
  let {
    currentImageUid,
    currentImageId,
  }: { currentImageUid: string | null; currentImageId: number | null } = {
    currentImageUid: null,
    currentImageId: null,
  };

  if (priceUid && publicPrice && publicImages) {
    const searchUid = filterUidForImages(priceUid, publicPrice);
    const imageUid = getImageUidByUid(searchUid, publicImages);

    if (imageUid) {
      currentImageUid = imageUid;
      currentImageId = imageUid
        ? (publicImages[imageUid][0].id as number)
        : null;
    }
  }

  return { currentImageUid, currentImageId };
}

export function filterUidForImages(
  priceUid: string,
  publicPrice: IPublicPrice
): string {
  if (publicPrice.type !== ProductPriceTypeEnum.BY_PROPERTY) {
    return priceUid;
  }

  const uids = (priceUid || '').split('.').filter((uid) => {
    const property = (publicPrice.properties || []).find(
      (p) => (p.elementIds || []).indexOf(+uid) > -1
    );
    return property && !property.isPlusPrice;
  });

  return uids.join('.');
}

export function getImageUidByUid(
  priceUid: string,
  publicImages: IGalleryImageMap
): string | null {
  let imageUid: string | null = null;

  const images = publicImages[priceUid];

  if (images && images.length) {
    imageUid = priceUid;
  }

  if (imageUid === null) {
    const uids = (priceUid || '').split('.');

    if (uids.length > 1) {
      uids.pop();
      return getImageUidByUid(uids.join('.'), publicImages);
    }
  }

  return imageUid;
}
