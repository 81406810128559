import { Inject, Injectable, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  combineLatest,
  exhaustMap,
  finalize,
  fromEvent,
  map,
  merge,
  of,
  Subscription,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  CROP_BOX_MIN_HEIGHT as MIN_HEIGHT,
  CROP_BOX_MIN_WIDTH as MIN_WIDTH,
} from '../constants';
import { getValueInInterval } from '../utils/crop.utils';
import { CropStore } from '../store/crop';

@Injectable()
export class CropBoxTransformService {
  private container!: HTMLElement;
  private box!: HTMLElement;

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  private readonly dragStop$ = fromEvent<PointerEvent>(
    this.document,
    'pointerup'
  );
  private readonly move$ = fromEvent<PointerEvent>(
    this.document,
    'pointermove'
  );

  constructor(
    private readonly cropStore: CropStore,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  init(container: HTMLElement, box: HTMLElement) {
    this.container = container;
    this.box = box;

    this.initSizeSubscription();
    this.initPositionSubscription();
    this.initDragSubscription();
    this.initResizeSubscription();
  }

  destroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }

  private initSizeSubscription() {
    this.subs = combineLatest([
      this.cropStore.cropWidth$,
      this.cropStore.cropHeight$,
    ]).subscribe(([width, height]) => {
      this.renderer.setStyle(this.box, 'width', `${width}px`);
      this.renderer.setStyle(this.box, 'height', `${height}px`);
    });
  }

  private initPositionSubscription() {
    this.subs = combineLatest([
      this.cropStore.cropX$,
      this.cropStore.cropY$,
    ]).subscribe(([x, y]) => {
      this.renderer.setStyle(
        this.box,
        'transform',
        `translate3d(${x}px, ${y}px, 0)`
      );
    });
  }

  private initDragSubscription() {
    this.subs = this.cropStore.isGrabActive$
      .pipe(
        switchMap((isGrabActive) => {
          return !isGrabActive
            ? fromEvent<PointerEvent>(this.box, 'pointerdown').pipe(
                withLatestFrom(
                  this.cropStore.cropX$,
                  this.cropStore.cropY$,
                  this.cropStore.cropWidth$,
                  this.cropStore.cropHeight$
                ),
                exhaustMap(
                  ([
                    { clientX: startX, clientY: startY },
                    x,
                    y,
                    width,
                    height,
                  ]) => {
                    return this.move$.pipe(
                      map(({ clientX: moveX, clientY: moveY }) => {
                        return {
                          newX: getValueInInterval(x + moveX - startX, {
                            min: this.container.offsetLeft,
                            max:
                              this.container.offsetLeft +
                              this.container.clientWidth -
                              width,
                          }),
                          newY: getValueInInterval(y + moveY - startY, {
                            min: this.container.offsetTop,
                            max:
                              this.container.offsetTop +
                              this.container.clientHeight -
                              height,
                          }),
                        };
                      }),
                      tap(({ newX, newY }) => {
                        this.cropStore.setCropPosition({ x: newX, y: newY });
                      }),
                      takeUntil(this.dragStop$)
                    );
                  }
                )
              )
            : of(null);
        })
      )
      .subscribe();
  }

  private initResizeSubscription() {
    const leftTop = this.createResizeElement('left-top');
    const leftBottom = this.createResizeElement('left-bottom');
    const rightTop = this.createResizeElement('right-top');
    const rightBottom = this.createResizeElement('right-bottom');

    this.subs = this.cropStore.isGrabActive$
      .pipe(
        switchMap((isGrabActive) => {
          return !isGrabActive
            ? merge(
                fromEvent<PointerEvent>(leftTop, 'pointerdown').pipe(
                  tap((e) => e.stopPropagation()),
                  map(() => ({ left: true, top: true }))
                ),
                fromEvent<PointerEvent>(leftBottom, 'pointerdown').pipe(
                  tap((e) => e.stopPropagation()),
                  map(() => ({ left: true, top: false }))
                ),
                fromEvent<PointerEvent>(rightTop, 'pointerdown').pipe(
                  tap((e) => e.stopPropagation()),
                  map(() => ({ left: false, top: true }))
                ),
                fromEvent<PointerEvent>(rightBottom, 'pointerdown').pipe(
                  tap((e) => e.stopPropagation()),
                  map(() => ({ left: false, top: false }))
                )
              ).pipe(
                withLatestFrom(
                  this.cropStore.cropX$,
                  this.cropStore.cropY$,
                  this.cropStore.cropWidth$,
                  this.cropStore.cropHeight$,
                  this.cropStore.saveProportions$
                ),
                exhaustMap(
                  ([{ left, top }, x, y, width, height, saveProportions]) => {
                    const {
                      top: containerTop,
                      right: containerRight,
                      bottom: containerBottom,
                      left: containerLeft,
                    } = this.container.getBoundingClientRect();

                    const startX = left
                      ? containerLeft + x
                      : containerLeft + x + width;
                    const startY = top
                      ? containerTop + y
                      : containerTop + y + height;

                    return this.move$.pipe(
                      tap(() => this.cropStore.setResizing(true)),
                      map(({ clientX: moveX, clientY: moveY }) => {
                        const dragX = left
                          ? getValueInInterval(moveX, {
                              min: containerLeft,
                              max: containerLeft + x + width - MIN_WIDTH,
                            })
                          : getValueInInterval(moveX, {
                              min: containerLeft + x + MIN_WIDTH,
                              max: containerRight,
                            });

                        const dragY = top
                          ? getValueInInterval(moveY, {
                              min: containerTop,
                              max: containerTop + y + height - MIN_HEIGHT,
                            })
                          : getValueInInterval(moveY, {
                              min: containerTop + y + MIN_HEIGHT,
                              max: containerBottom,
                            });

                        return {
                          x,
                          y,
                          width,
                          height,
                          wDiff: dragX - startX,
                          hDiff: dragY - startY,
                          saveProportions,
                        };
                      }),
                      map(
                        ({
                          x,
                          y,
                          width,
                          height,
                          wDiff,
                          hDiff,
                          saveProportions,
                        }) => {
                          if (!saveProportions) {
                            return {
                              newX: left ? x + wDiff : x,
                              newY: top ? y + hDiff : y,
                              newWidth: left ? width - wDiff : width + wDiff,
                              newHeight: top ? height - hDiff : height + hDiff,
                            };
                          }

                          let newX: number,
                            newY: number,
                            newWidth: number,
                            newHeight: number;

                          if (width > height) {
                            newWidth = left ? width - wDiff : width + wDiff;
                            newHeight = (newWidth / width) * height;
                            newX = left ? x + wDiff : x;
                            newY = top ? y + (height - newHeight) : y;
                          } else {
                            newHeight = top ? height - hDiff : height + hDiff;
                            newWidth = (newHeight / height) * width;
                            newX = left ? x + (width - newWidth) : x;
                            newY = top ? y + hDiff : y;
                          }

                          return { newX, newY, newWidth, newHeight };
                        }
                      ),
                      tap(({ newX, newY, newWidth, newHeight }) => {
                        this.cropStore.setCropPosition({ x: newX, y: newY });
                        this.cropStore.setCropSize({
                          width: newWidth,
                          height: newHeight,
                        });
                      }),
                      takeUntil(this.dragStop$),
                      finalize(() => this.cropStore.setResizing(false))
                    );
                  }
                )
              )
            : of(null);
        })
      )
      .subscribe();
  }

  private createResizeElement(
    position: 'left-top' | 'left-bottom' | 'right-top' | 'right-bottom'
  ): HTMLDivElement {
    const dot: HTMLDivElement = this.renderer.createElement('div');

    this.renderer.addClass(dot, 'dot');
    this.renderer.addClass(dot, position);
    this.renderer.appendChild(this.box, dot);

    return dot;
  }
}
