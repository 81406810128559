import { afterNextRender, Inject, Injectable, Injector } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  concatMap,
  delay,
  finalize,
  from,
  map,
  mergeMap,
  Observable,
  of,
  Subscription,
  switchMap,
  tap,
  toArray,
  withLatestFrom,
} from 'rxjs';
import { isArray } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { ExportService } from '../export/export.service';
import { ProductStore } from '../../state/product/product.store';
import { DOCUMENT } from '@angular/common';
import { getTime, WINDOW } from '@inaripro-nx/common-ui';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  private readonly _message$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  public readonly message$: Observable<string> = this._message$.asObservable();

  private _downloadSub: Subscription | null = null;
  private set downloadSub(sub: Subscription | null) {
    if (this._downloadSub) {
      this._downloadSub.unsubscribe();
    }

    this._downloadSub = sub;
  }
  private get downloadSub(): Subscription | null {
    return this._downloadSub;
  }

  private window?: Window;

  constructor(
    private readonly http: HttpClient,
    private readonly exportService: ExportService,
    private readonly productStore: ProductStore,
    private readonly injector: Injector,
    @Inject(DOCUMENT) private document: Document
  ) {
    afterNextRender(() => {
      this.window = this.injector.get(WINDOW);
    });
  }

  private setMessage(message: string) {
    this._message$.next(message);
  }

  public clearMessage() {
    this.setMessage('');
  }

  private downloadPdf() {
    if (this.downloadSub) {
      this.setMessage('Задача уже запущена');
      return;
    }

    this.clearMessage();

    this.downloadSub = this.exportService
      .getProductSides()
      .pipe(
        withLatestFrom(this.productStore.product$),
        map(([sides, product]) => ({
          design: { sides },
          productId: product?.id,
        })),
        switchMap((request) =>
          this.http.post<{ email: string }>(
            'catalog/public/design/pdf_result',
            request
          )
        ),
        tap((response) => {
          this.setMessage(
            `В течение 10 мин макет будет отправлен на ваш E-mail: ${response.email}.`
          );
        }),
        catchError((e) => {
          this.setMessage('Ошибка');

          if (!e || !e.error || !isArray(e.error) || !e.error[0]) {
            return of(null);
          }

          const data = e.error[0];

          if (data['design-user-uid']) {
            this.setMessage(
              'Заполните E-mail в настройках вашего профиля, для отправки макетов'
            );
          } else if (data['productId'] && isArray(data['productId'])) {
            const message = data['productId'][0];

            if (message === 'INVALID_VALUE') {
              this.setMessage('Доступ запрещен');
            } else if (message === 'TIMEOUT') {
              this.setMessage('Задача уже запущена');
            }
          }

          return of(null);
        }),
        finalize(() => {
          this.downloadSub = null;
        })
      )
      .subscribe();
  }

  private downloadSvg() {
    if (this.downloadSub) {
      this.setMessage('Задача уже запущена');
      return;
    }

    this.clearMessage();

    this.downloadSub = this.exportService
      .getXmlSides(true)
      .pipe(
        withLatestFrom(this.productStore.product$),
        map(([sides, product]) => ({
          design: { sides },
          productId: product?.id,
        })),
        switchMap((request) => {
          const getFileLink = (xml: string) => {
            const file = new Blob([xml], { type: 'image/svg+xml' });

            const a = this.document.createElement('a');
            a.href = URL.createObjectURL(file);
            a.target = '_blank';

            return a;
          };

          const time = getTime();

          const links = (request.design.sides || []).reduce(
            (acc, side, index) => {
              const a = getFileLink(side.xml);
              const sideName = `product_${request.productId}_side_${index}`;
              a.download = `${sideName}_${time}.svg`;
              acc.push(a);

              (side.zones || []).forEach((zone, zIndex) => {
                const aZ = getFileLink(zone.xml);
                const zoneName = `${sideName}_zone_${zIndex}`;
                aZ.download = `${zoneName}_${time}.svg`;
                acc.push(aZ);
              });

              return acc;
            },
            [] as HTMLAnchorElement[]
          );

          return from(links).pipe(
            concatMap((a) =>
              of(a).pipe(
                delay(100),
                tap((a) => a.click())
              )
            ),
            toArray(),
            tap(() => this.window?.alert('Скачивание завершено'))
          );
        }),
        catchError((e) => {
          this.setMessage('Ошибка');
          return of(null);
        }),
        finalize(() => {
          this.downloadSub = null;
        })
      )
      .subscribe();
  }

  public downloadPdfOrSvg(isProductOwner: boolean | null): void {
    return isProductOwner ? this.downloadPdf() : this.downloadSvg();
  }

  public downloadLayouts() {
    if (this.downloadSub) {
      this.window?.alert('Экспорт уже запущен');
      return;
    }

    this.downloadSub = this.exportService
      .getXmlSides()
      .pipe(
        switchMap((xmlSides) => {
          const xmlArray: string[] = (xmlSides || []).reduce((acc, side) => {
            acc.push(side.xml);

            if (side.zones) {
              acc = acc.concat(...side.zones.map((z) => z.xml));
            }

            return acc;
          }, [] as string[]);

          let delayTime = -1000;

          return from(xmlArray).pipe(
            mergeMap((xml) => {
              delayTime += 1000;

              const file = new Blob([xml], { type: 'image/svg+xml' });

              const formData: FormData = new FormData();
              formData.append(
                'file',
                file,
                `export_${new Date().getTime()}.svg`
              );

              return of(null).pipe(
                delay(delayTime),
                switchMap(() =>
                  this.http.post<{ url: string }>(
                    '/catalog/public/design/temp_svg_result_chrome',
                    formData
                  )
                ),
                tap((response) => {
                  //set url value to a element's href attribute.
                  const a = this.document.createElement('a');
                  a.href = response.url;
                  a.target = '_blank';
                  a.click();
                  a.parentNode?.removeChild(a);
                })
              );
            })
          );
        }),
        finalize(() => {
          this.downloadSub = null;
        })
      )
      .subscribe();
  }
}
