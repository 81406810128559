<ng-container *ngIf="{
  source: designerSource$ | async,
  inColor: inColor$ | async
} as state">
  <ng-container *ngIf="{
    property: state.source && state.source.propertiesMap[colorProperty.id]
  } as subState">
    <painter-content-product-property-wrapper
      *ngIf="subState.property"
      [name]="subState.property.name"
    >
      <div class="design-color-buttons">
        <design-color-button
          *ngFor="let el of subState.property.elements || []"
          [colors]="el.color || []"
          [colorsTitles]="el.name || []"
          [isActive]="el.color === state.inColor"
          [newSize]="true"
          (click)="setZoneColor(el.color || null)"
        ></design-color-button>
      </div>
    </painter-content-product-property-wrapper>
  </ng-container>
</ng-container>
