@if (existProductTypes && existProductTypes.length > 1) {
  <catalog-product-list-content-filter-type
    class="type"
    [productType]="productType || nomenclatureBase?.defaultProductType || ProductTypeEnum.BASE_PRODUCT"
    [existProductTypes]="existProductTypes"
    [isMobileTypeButtons]="isMobileTypeButtons"
    (selectType)="selectType($event)"
  ></catalog-product-list-content-filter-type>
}

<catalog-product-list-content-filter-range
  class="range"
  [nomenclatureBase]="nomenclatureBase"
  [range]="range || nomenclatureBase?.defaultRangeFilter || null"
  (changeOption)="changeRangeOption.emit($event)"
></catalog-product-list-content-filter-range>

<catalog-product-list-content-filter-sort
  class="sort"
  [sortRequest]="sortRequest"
  (changeOption)="changeSortOption.emit($event)"
></catalog-product-list-content-filter-sort>
