<design-invisible-vertically-scroll
  [deltaClick]="40"
  [deltaWheel]="100"
>
  <ng-container arrow-up>
    <div
      class="sides__slider-arrow"
      [hidden]="!isSliderActive"
    >
      <i class="icon icon-ic_arrow-up"></i>
    </div>
  </ng-container>
  <ng-container arrow-down>
    <div
      class="sides__slider-arrow"
      [hidden]="!isSliderActive"
    >
      <i class="icon icon-ic_arrow-down"></i>
    </div>
  </ng-container>
  <ng-container slider>
    <div
      class="sides"
      [class.sides--with-arrows]="isSliderActive"
    >
      <div class="sides__slider-container" #slider>
        <div
          #refSidesItem
          *ngFor="let side of designProduct?.sides; let sideIndex = index"
          class="sides__item"
          [class.sides__item--active]="sideIndex === activeDesignSideIndex"
          (click)="clickOnSide(sideIndex, refSidesItem)"
        >
          <img
            class="sides__item-img-mockup"
            [class.sides__item-img-mockup--up]="side.mockupType === 'up'"
            [src]="side.image.url"
          />
          <span class="sides__item-name">{{ sideIndex + 1 }}</span>
        </div>
      </div>
    </div>
  </ng-container>
</design-invisible-vertically-scroll>
