import { IXY } from '../store/crop';

export interface IFigure {
  id: number;
  image: IFigureImage;
  name: string;
}

export interface IFigureListItem {
  id: number;
  name: string;
  type: FigureTypeEnum;
  icon?: string;
  rectangle?: IFigureRectangle;
  image?: IFigureImage;
}

export interface IFigureImage {
  id: number;
  name: string;
  url: string;
}

export interface IFigureRectangle {
  width: number;
  height: number;
}

export enum FigureTypeEnum {
  Default,
  Original,
  Rectangle,
  Mask,
}

export interface ICropData {
  x: number;
  y: number;
  width: number;
  height: number;
  originalImage: string | null;
  originalImageWidth: number;
  originalImageHeight: number;
  originalImageX: number;
  originalImageY: number;
  inlineSvgMask: string | null;
  selectedFigureId: number | null;
  offset: IXY;
  zoom: number;
}
