<design-modal-window
  *ngIf="templateForm$ | async as templateForm"
  [uid]="'create-new-template'"
  [prefix]="'create-new-template'"
  (selfClose)="onCloseModal()"
  [maxWidth]="582"
  [fullMode]="true"
  [openOnCreate]="true"
  #modal
>
  <div modal-header>
    <span>
      {{
        (templateForm.value.id ? 'Сохранить в текущий шаблон' : 'Сохранить как новый шаблон')
      }}
    </span>
  </div>

  <div modal-body>
    <div class="g-form-group">
      <design-form-input-string
        [fControl]="templateForm.controls['name']"
        [label]="'Наименование шаблона*'"
      ></design-form-input-string>

      <ng-container
        [ngTemplateOutlet]="covers"
        [ngTemplateOutletContext]="{ templateForm: templateForm, sides: (sides$ | async) }"
      ></ng-container>
    </div>
  </div>

  <div modal-footer>
    <div class="g-form-button">
      <button type="button" class="g-btn-link" (click)="modal.closeModal(true)">
        Отмена
      </button>
      <button
        type="button"
        class="g-btn-link g-btn-link--red"
        [disabled]="templateForm.invalid || (isSending$ | async)"
        (click)="saveTemplate(templateForm)"
      >
        Ок
      </button>
    </div>
  </div>
</design-modal-window>

<ng-template #covers let-templateForm="templateForm" let-sides="sides">
  <ng-container *ngIf="sides?.length; else emptyCover">
    <div class="g-form-subtitle">Выберите обложку для шаблона:</div>

    <div class="upload-button" (click)="fileUploader.fuSelectFiles()">
      <design-file-uploader
        #fileUploader
        [uid]="'template_cover_file_uploader'"
        [accept]="'.png,.jpg,.jpeg'"
        [multiple]="false"
        (uploadedFile)="onFileUploaded(templateForm, $event)"
      ></design-file-uploader>

      <i class="icon icon-ic_cross-plus"></i>
      <span>Загрузить обложку</span>
    </div>

    <ngx-simplebar
      class="scroll-content"
      [class.scroll-content--large]="uploadedFile"
    >
      <div
        class="template-covers"
        [class.template-covers-one]="sides?.length === 1 && sides?.[0]?.zones?.length === 1"
      >
        <div
          class="template-covers__item template-covers__item--wide"
          *ngIf="uploadedFile"
        >
          <ng-container
            [ngTemplateOutlet]="uploadedFileTemplate"
            [ngTemplateOutletContext]="{
              uploadedFile: uploadedFile,
              templateForm: templateForm
            }"
          ></ng-container>
        </div>

        <ng-container *ngFor="let side of sides">
          <!-- First zone preview -->
          <painter-modal-template-cover-item
            class="template-covers__item"
            [cover]="templateForm.value.cover"
            (selectCover)="selectCover(templateForm, $event)"
          >
            <ng-container
              [ngTemplateOutlet]="firstZonePreview"
              [ngTemplateOutletContext]="{ side: side }"
            ></ng-container>
          </painter-modal-template-cover-item>

          <!-- Side preview -->
          <painter-modal-template-cover-item
            class="template-covers__item"
            [cover]="templateForm.value.cover"
            (selectCover)="selectCover(templateForm, $event)"
          >
            <ng-container
              [ngTemplateOutlet]="sidePreview"
              [ngTemplateOutletContext]="{ side: side }"
            ></ng-container>
          </painter-modal-template-cover-item>
        </ng-container>
      </div>
    </ngx-simplebar>
  </ng-container>
</ng-template>

<ng-template #emptyCover>
  <div class="g-alert g-alert-danger g-form-subtitle">
    Нет элементов для сохранения
  </div>
</ng-template>

<ng-template #firstZonePreview let-side="side">
  <svg
    class="svg-zone"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="xMidYMid meet"
    [attr.viewBox]="
      state.zone0.size || { x: 0, y: 0 }
        | viewbox: [[state.zone0.start || { x: 0, y: 0 }, state.zoneTranslates?.['' + state.zone0.id] || { x: 0, y: 0 }] | sumPoints]
    "
    *ngIf="{
      elements: elements$ | async,
      filters: filters$ | async,
      zoneTranslates: zoneTranslates$ | async,
      zone0: side.zones[0]
    } as state"
  >
    <defs>
      <!-- Маска в виде первой области нанесения -->
      <clipPath id="zoneMask-{{ side.id }}">
        <rect
          [attr.x]="state.zone0.start?.x"
          [attr.y]="state.zone0.start?.y"
          [attr.width]="state.zone0.size?.x"
          [attr.height]="state.zone0.size?.y"
          [attr.transform]="state.zoneTranslates?.['' + state.zone0.id] | svgTranslate"
        />
      </clipPath>
    </defs>

    <svg
      [attr.width]="side.sizePX.x"
      [attr.height]="side.sizePX.y"
      [attr.viewBox]="side.sizePX | viewbox"
      [attr.clip-path]="'url(#zoneMask-' + side.id + ')'"
      preserveAspectRatio="xMidYMid meet"
    >
      <!-- Объекты дизайна -->
      <g [attr.transform]="state.zone0.center || { x: 0, y: 0 } | svgTranslate">
        <g [attr.transform]="state.zoneTranslates?.['' + state.zone0.id] | svgTranslate">
          <ng-container *ngFor="let el of state.elements; let i = index">
            <ng-container *ngIf="el.sideIndex === side.sideIndex">
              <g data-painter-editor-element-transform [el]="el">
                <g data-painter-editor-element-content [el]="el" [index]="i" [filter]="el.filterId && state.filters?.[el.filterId]"></g>
              </g>
            </ng-container>
          </ng-container>
        </g>
      </g>
    </svg>
  </svg>
</ng-template>

<ng-template #sidePreview let-side="side">
  <svg
    class="svg-zone"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="xMidYMid meet"
    *ngIf="{
      elements: elements$ | async,
      filters: filters$ | async,
      zoneColors: zoneColors$ | async,
      zonePatterns: zonePatterns$ | async,
      zoneTranslates: zoneTranslates$ | async,
      zone0: side.zones[0],
      zones: side.zones | filterBy: ['isOnlyFilling']: false,
      colorZones: side.zones | filterBy: ['isOnlyFilling']: true
    } as state"
  >
    <svg
      [attr.viewBox]="side.sizePX | viewbox"
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <clipPath id="sideZonesMask-{{ side.id }}">
          <rect
            *ngFor="let zone of state.zones"
            [attr.x]="zone.start?.x"
            [attr.y]="zone.start?.y"
            [attr.width]="zone.size?.x"
            [attr.height]="zone.size?.y"
            [attr.transform]="state.zoneTranslates?.['' + zone.id] | svgTranslate"
          />
        </clipPath>
      </defs>

      <!-- Область фоновой заливки -->
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        [attr.fill]="BACKGROUND_COLOR_DEFAULT"
        style="pointer-events: none"
      />

      <!-- Мокап -->
      <image
        *ngIf="side.mockupType === EMockupType.down"
        [attr.xlink:href]="side.image.url"
        x="0"
        y="0"
        width="100%"
        height="100%"
      ></image>

      <!-- Фон области нанесения -->
      <g
        data-painter-editor-zone-paint
        *ngFor="let zone of state.zones"
        [attr.transform]="state.zoneTranslates?.['' + zone.id] | svgTranslate"
        [zone]="zone"
        [zoneColor]="state.zoneColors?.['' + zone.id]"
        [zonePattern]="state.zonePatterns?.['' + zone.id]"
      ></g>

      <!-- Объекты дизайна -->
      <g [attr.clip-path]="'url(#sideZonesMask-' + side.id + ')'">
        <g [attr.transform]="state.zone0.center || { x: 0, y: 0 } | svgTranslate">
          <g [attr.transform]="state.zoneTranslates?.['' + state.zone0.id] | svgTranslate">
            <ng-container *ngFor="let el of state.elements; let i = index">
              <ng-container *ngIf="el.sideIndex === side.sideIndex">
                <g data-painter-editor-element-transform [el]="el">
                  <g
                    data-painter-editor-element-content
                    [el]="el"
                    [index]="i"
                    [filter]="el.filterId && state.filters?.[el.filterId]"
                  ></g>
                </g>
              </ng-container>
            </ng-container>
          </g>
        </g>
      </g>

      <!-- Фон области заливки -->
      <g
        data-painter-editor-zone-paint
        *ngFor="let zone of state.colorZones"
        [zone]="zone"
        [zoneColor]="state.zoneColors?.['' + zone.id]"
        [zonePattern]="state.zonePatterns?.['' + zone.id]"
      ></g>

      <!-- Мокап -->
      <image
        *ngIf="side.mockupType === EMockupType.up"
        [attr.xlink:href]="side.image.url"
        x="0"
        y="0"
        width="100%"
        height="100%"
        style="pointer-events: none"
      ></image>
    </svg>
  </svg>
</ng-template>

<ng-template
  #uploadedFileTemplate
  let-uploadedFile="uploadedFile"
  let-templateForm="templateForm"
>
  <painter-modal-template-cover-item
    [cover]="templateForm.value.cover"
    [isActive]="templateForm.value.cover === uploadedFile"
    (selectCover)="selectUserCover(templateForm, uploadedFile)"
  >
    <svg
      class="svg-zone"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMidYMid meet"
    >
      <image
        [attr.xlink:href]="uploadedFile.url"
        x="0"
        y="0"
        width="100%"
        height="100%"
      ></image>
    </svg>
  </painter-modal-template-cover-item>
</ng-template>
