import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ElementNamePipe } from './element-name/element-name.pipe';
import { ElementRotateCenterPipe } from './element-rotate-center/element-rotate-center.pipe';
import { PropertyColorsPipe } from './property-colors/property-colors.pipe';

const pipes = [ElementNamePipe, ElementRotateCenterPipe, PropertyColorsPipe];

@NgModule({
  declarations: [...pipes],
  imports: [CommonModule],
  exports: [...pipes],
  providers: [...pipes],
})
export class PipesModule {}
