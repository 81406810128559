<svg:g *ngIf="zone.start && zone.size && (zoneColor || zonePattern)">
  <g *ngIf="zoneColor">
    <rect
      [attr.x]="isZoneExport ? 0 : zone.start.x"
      [attr.y]="isZoneExport ? 0 : zone.start.y"
      [attr.width]="zone.size.x"
      [attr.height]="zone.size.y"
      [attr.fill]="zoneColor"
    />
  </g>

  <g *ngIf="zonePattern">
    <image
      [attr.x]="isZoneExport ? 0 : zone.start.x"
      [attr.y]="isZoneExport ? 0 : zone.start.y"
      [attr.width]="zone.size.x"
      [attr.height]="zone.size.y"
      [attr.xlink:href]="zonePattern.url"
      preserveAspectRatio="xMidYMid slice"
    ></image>
  </g>
</svg:g>
