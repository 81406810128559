import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, ViewContainerRef } from '@angular/core';
import {
  getTime,
  IFileResponse,
  stripHtmlComments,
  svgToXml,
} from '@inaripro-nx/common-ui';
import {
  IProductDesignSide,
  IProductDesignZone,
  OrderCartStore,
} from '@inaripro-nx/order';
import {
  finalize,
  from,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  toArray,
  withLatestFrom,
} from 'rxjs';
import { ExportComponent } from '../../components/export/export.component';
import {
  IExportRequest,
  IExportSideXML,
  IExportZoneXML,
  IGetSVGSide,
  IGetSVGZone,
} from './export.interface';
import { HistoryStore } from '../../state/history/history.store';
import { ProductStore } from '../../state/product/product.store';
import { ExportZoneComponent } from '../../components/export-zone/export-zone.component';

@Injectable()
export class ExportService {
  private viewContainerRef!: ViewContainerRef;

  constructor(
    private http: HttpClient,
    private readonly orderCartStore: OrderCartStore,
    private readonly historyStore: HistoryStore,
    private readonly productStore: ProductStore,
    @Inject(DOCUMENT) private document: Document
  ) {}

  init(viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }

  public getXmlSides(withFonts = false): Observable<IExportSideXML[] | null> {
    return of(null).pipe(
      withLatestFrom(
        this.historyStore.elements$,
        this.productStore.filtersMap$,
        this.productStore.activeDesignProduct$,
        this.productStore.fullColor$,
        this.productStore.zoneColors$,
        this.productStore.zonePatterns$,
        this.productStore.zoneTranslates$
      ),
      map(
        ([
          ,
          elements,
          filters,
          designProduct,
          fullColor,
          zoneColors,
          zonePatterns,
          zoneTranslates,
        ]) => {
          if (!designProduct) {
            return null;
          }

          return this.getSvgSides(
            {
              elements,
              filters,
              designProduct,
              fullColor,
              zoneColors,
              zonePatterns,
              zoneTranslates,
            },
            withFonts
          );
        }
      )
    );
  }

  public getProductSides(): Observable<IProductDesignSide[] | null> {
    this.orderCartStore.setGetProductSidesStatus(true);

    return this.getXmlSides().pipe(
      switchMap((xmlSides) => {
        if (xmlSides === null) {
          return of(null);
        }

        return from(xmlSides).pipe(
          mergeMap((side) => this.getProductDesignSide(side)),
          toArray()
        );
      }),
      finalize(() => this.orderCartStore.setGetProductSidesStatus(false))
    );
  }

  private xmlToFile(xml: string): Observable<IFileResponse> {
    const file = new Blob([xml || ''], {
      type: 'image/svg+xml',
    });

    const formData: FormData = new FormData();
    formData.append('file', file, `export_${getTime()}.svg`);

    return this.http.post<IFileResponse>(
      '/catalog/public/design/product_result_svg',
      formData
    );
  }

  private getProductDesignZone(
    zone: IExportZoneXML
  ): Observable<IProductDesignZone> {
    return this.xmlToFile(zone.xml).pipe(
      map((file) => {
        return {
          id: zone.id,
          svg: file,
        };
      })
    );
  }

  private getProductDesignSide(
    side: IExportSideXML
  ): Observable<IProductDesignSide> {
    return this.xmlToFile(side.xml).pipe(
      switchMap((file) => {
        const svgSide: IProductDesignSide = {
          id: side.id,
          svg: file,
        };

        if (!side.zones) {
          return of(svgSide);
        } else {
          return from(side.zones).pipe(
            mergeMap((zone) => this.getProductDesignZone(zone)),
            toArray(),
            map((zones) => {
              svgSide.zones = zones;
              return svgSide;
            })
          );
        }
      })
    );
  }

  private getSvgSides(
    request: IExportRequest,
    withFonts: boolean
  ): IExportSideXML[] {
    const { designProduct } = request;

    return designProduct.sides
      .filter((side) => side.id)
      .map((side, sideIndex) => {
        const sideXML: IExportSideXML = {
          id: side.id || 0,
          xml: svgToXml(
            this.getSvgSide(
              {
                ...request,
                sideIndex,
                side,
              },
              withFonts
            )
          ),
        };

        if (!side._isFullPrint) {
          const zonesXML: IExportZoneXML[] = side.zones
            .filter((z) => !z.isOnlyFilling)
            .map((zone) => {
              const elements = request.elements.filter(
                (e) => e.sideIndex === sideIndex
              );
              return { zone, elements };
            })
            .filter(({ zone, elements }) => !!elements.length)
            .map(({ zone, elements }) => {
              return {
                id: zone.id || 0,
                xml: svgToXml(
                  this.getSvgZone(
                    {
                      ...request,
                      sideIndex,
                      side,
                      zone,
                      elements,
                    },
                    withFonts
                  )
                ),
              };
            });

          sideXML.zones = zonesXML;
        }

        return sideXML;
      });
  }

  private getSvgSide(request: IGetSVGSide, withFonts: boolean): string {
    this.viewContainerRef.clear();

    const componentRef =
      this.viewContainerRef.createComponent<ExportComponent>(ExportComponent);

    const {
      side,
      sideIndex,
      filters,
      elements,
      fullColor,
      zoneColors,
      zonePatterns,
      zoneTranslates,
    } = request;

    componentRef.instance.side = side;
    componentRef.instance.sideIndex = sideIndex;
    componentRef.instance.filters = filters;
    componentRef.instance.withFonts = withFonts;
    componentRef.instance.elements = elements;
    componentRef.instance.fullColor = fullColor;
    componentRef.instance.zoneColors = zoneColors;
    componentRef.instance.zonePatterns = zonePatterns;
    componentRef.instance.zoneTranslates = zoneTranslates;
    componentRef.changeDetectorRef.detectChanges();

    const innerHTML = (
      componentRef.instance.elementRef.nativeElement as HTMLElement
    ).innerHTML;

    this.viewContainerRef.clear();

    return this.clearSVG(innerHTML);
  }

  private getSvgZone(request: IGetSVGZone, withFonts: boolean): string {
    this.viewContainerRef.clear();

    const componentRef =
      this.viewContainerRef.createComponent<ExportZoneComponent>(
        ExportZoneComponent
      );

    const {
      side,
      sideIndex,
      filters,
      elements,
      zoneColors,
      zonePatterns,
      zoneTranslates,
      zone,
    } = request;

    componentRef.instance.side = side;
    componentRef.instance.zone = zone;
    componentRef.instance.sideIndex = sideIndex;
    componentRef.instance.filters = filters;
    componentRef.instance.withFonts = withFonts;
    componentRef.instance.elements = elements;

    if (zoneColors['' + zone.id]) {
      componentRef.instance.zoneColor = zoneColors['' + zone.id];
    }

    if (zonePatterns['' + zone.id]) {
      componentRef.instance.zonePattern = zonePatterns['' + zone.id];
    }

    componentRef.instance.zoneTranslates = zoneTranslates;

    componentRef.changeDetectorRef.detectChanges();

    const innerHTML = (
      componentRef.instance.elementRef.nativeElement as HTMLElement
    ).innerHTML;

    this.viewContainerRef.clear();

    return this.clearSVG(innerHTML);
  }

  private clearSVG(value: string): string {
    value = value.replace(/\sdata-painter-editor-zone-paint=""/g, '');
    value = value.replace(/\sdata-painter-editor-element-transform=""/g, '');
    value = value.replace(/\sdata-painter-editor-element-content=""/g, '');
    value = value.replace(/\sdata-painter-editor-element-text-content=""/g, '');
    value = value.replace(/\sdata-painter-editor-element-text-arc=""/g, '');
    value = value.replace(
      /\sdata-painter-editor-element-text-multiline=""/g,
      ''
    );
    value = value.replace(
      /\sng-reflect-zone-pattern="\[object\sObject\]"/g,
      ''
    );
    value = value.replace(/\sng-reflect-el="\[object\sObject\]"/g, '');
    value = value.replace(/\sng-reflect-zone="\[object\sObject\]"/g, '');
    value = value.replace(/\sng-reflect-filter="\[object\sObject\]"/g, '');
    value = value.replace(/\sng-reflect-index="\d+"/g, '');
    value = value.replace(/\sng-reflect-is-zone-export="true"/g, '');
    value = value.replace(
      /\sng-reflect-zone-color="#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})"/g,
      ''
    );
    value = value.replace(/\sclass="ng-star-inserted"/g, '');
    value = stripHtmlComments(value);
    return value;
  }
}
