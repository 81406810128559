import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { EMockupType, IDesignSide } from '@inaripro-nx/catalog';
import {
  BACKGROUND_COLOR_DEFAULT,
  IFilter,
  IMapOfString,
  IPattern,
} from '@inaripro-nx/common-ui';
import { IElements, IMapXY, IXY } from '../../interfaces/editor.interface';
import { MainService } from '../../services/main/main.service';
import { getFontFaces } from '../../../../utils/svg.utils';

@Component({
  selector: 'painter-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ExportComponent {
  readonly EMockupType = EMockupType;
  readonly BACKGROUND_COLOR_DEFAULT = BACKGROUND_COLOR_DEFAULT;

  @Input() side!: IDesignSide;
  @Input() sideIndex: number | null = null;
  @Input() filters!: { [id: number]: IFilter };
  @Input() zoneColors!: IMapOfString;
  @Input() zonePatterns!: { [key: string]: IPattern };
  @Input() zoneTranslates!: IMapXY;
  @Input() withFonts!: boolean;

  private _elements!: IElements;
  fontFaces: string[] = [];

  get elements(): IElements {
    return this._elements;
  }

  @Input() set elements(elements: IElements) {
    this._elements = elements;

    if (this.withFonts) {
      this.fontFaces = getFontFaces(
        this.mainService.config?.designerRoot || '',
        elements,
        this.sideIndex
      );
    }
  }

  @Input() fullColor: string | null = null;

  get zone0Center(): IXY {
    const zone = this.side?.zones[0];
    const zone0Center = {
      ...(zone?.center || { x: 0, y: 0 }),
    };

    const translate = this.zoneTranslates['' + zone.id];

    if (translate) {
      zone0Center.x += translate.x;
      zone0Center.y += translate.y;
    }

    return zone0Center;
  }

  constructor(
    private readonly mainService: MainService,
    public elementRef: ElementRef
  ) {}
}
