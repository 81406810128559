import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { PipesModule } from '@inaripro-nx/common-ui';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { CropModalComponent, ICropData } from '@inaripro-nx/crop';
import { HistoryStore } from '../../state/history/history.store';
import {
  combineLatest,
  map,
  Observable,
  Subject,
  Subscription,
  withLatestFrom,
} from 'rxjs';
import {
  EElementType,
  IElement,
  ISVGElement,
} from '../../interfaces/editor.interface';
import { ProductStore } from '../../state/product/product.store';
import { fitElementToActiveDesignSide } from '../../../../utils/calculate.utils';

@Component({
  selector: 'painter-modal-crop',
  standalone: true,
  imports: [CommonModule, PipesModule, DesignUiModule, CropModalComponent],
  templateUrl: './modal-crop.component.html',
  styleUrls: ['./modal-crop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalCropComponent implements OnInit, OnDestroy {
  @Input() isDesktop = true;

  readonly activeElement$: Observable<ISVGElement | null> =
    this.historyStore.activeElement$.pipe(
      map((el) => {
        return el?.type === EElementType.image ||
          el?.type === EElementType.figure
          ? el
          : null;
      })
    );

  readonly activeElementIndex$ = this.historyStore.activeElementIndex$;
  readonly activeDesignSide$ = this.productStore.activeDesignSide$;

  readonly figures$ = this.productStore.figures$;

  readonly selectedFigureId$ = combineLatest([
    this.figures$,
    this.activeElement$,
  ]).pipe(
    map(([figures, activeElement]) => {
      if (activeElement?.type !== EElementType.figure) {
        return null;
      }

      return (
        (figures || []).find((f) => f.image.url === activeElement.url)?.image
          .id || null
      );
    })
  );

  private readonly cropImageSubject$ = new Subject<{
    cropData: ICropData;
    elementData: { index: number; element: IElement };
  }>();

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  constructor(
    private readonly historyStore: HistoryStore,
    private readonly productStore: ProductStore
  ) {}

  ngOnInit() {
    this.initCropImageSubscription();
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }

  cropImage(
    cropData: ICropData,
    elementData: { index: number; element: IElement }
  ) {
    this.cropImageSubject$.next({ cropData, elementData });
  }

  private initCropImageSubscription() {
    this.subs = this.cropImageSubject$
      .asObservable()
      .pipe(withLatestFrom(this.activeDesignSide$))
      .subscribe(([{ cropData, elementData }, activeDesignSide]) => {
        const {
          width,
          height,
          x,
          y,
          originalImage,
          originalImageWidth,
          originalImageHeight,
          inlineSvgMask,
          selectedFigureId,
        } = cropData;

        const updateCenter = { x: 0, y: 0 };
        const maskId = Date.now().toString();

        const element: ISVGElement = {
          ...(elementData.element as ISVGElement),
          type: EElementType.image,
          svg: `
              <svg width="${width}" height="${height}">
                <defs>
                  <mask
                    id="myMask-${maskId}"
                    fill="white"
                    data-x="${x}"
                    data-y="${y}"
                    data-width="${width}"
                    data-height="${height}"
                    data-original-image-width="${originalImageWidth}"
                    data-original-image-height="${originalImageHeight}"
                    data-selected-figure-id="${selectedFigureId}"
                  >
                    ${inlineSvgMask}
                  </mask>
                </defs>
                <image
                  xlink:href="${originalImage}"
                  width="${originalImageWidth}"
                  height="${originalImageHeight}"
                  x="${x}"
                  y="${y}"
                  mask="url(#myMask-${maskId})"
                />
              </svg>
            `,
          size: {
            x: width,
            y: height,
          },
          translate: {
            x: updateCenter.x - width / 2,
            y: updateCenter.y - height / 2,
          },
          scale: { x: 1, y: 1 },
        };

        this.historyStore.updateElement({
          index: elementData.index,
          element: activeDesignSide
            ? fitElementToActiveDesignSide(
                element,
                activeDesignSide,
                updateCenter
              )
            : element,
        });
      });
  }
}
