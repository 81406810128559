import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  DesignUiModule,
  IDropdownOption,
  ModalWindowStore,
} from '@inaripro-nx/design-ui';
import { ICategory } from '../../../../interfaces';
import { NgTemplateOutlet } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

const DEFAULT_HELPER_TITLE = 'Тираж';
const DEFAULT_HELPER_TEXT = 'Стоимость товара может зависеть от тиража';

@Component({
  selector: 'catalog-product-list-content-filter-range',
  standalone: true,
  imports: [DesignUiModule, NgTemplateOutlet, ReactiveFormsModule],
  templateUrl: './catalog-product-list-content-filter-range.component.html',
  styleUrl: './catalog-product-list-content-filter-range.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogProductListContentFilterRangeComponent
  implements OnChanges
{
  @Input() nomenclatureBase: ICategory | null = null;

  @Input() range: number | null = null;
  @Input() uidStateHelper = 'typeHelp';

  @Output() changeOption = new EventEmitter<number>();

  options: IDropdownOption[] = [];
  selectedOption?: IDropdownOption;

  helperTitle = DEFAULT_HELPER_TITLE;
  helperText = DEFAULT_HELPER_TEXT;

  readonly modalUid = 'MODAL_RANGE_UID';

  readonly rangeControl = new FormControl(null);

  readonly minRange = 1;
  readonly maxRange = 1000000;

  constructor(private readonly modalWindowStore: ModalWindowStore) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('nomenclatureBase' in changes || 'range' in changes) {
      const ranges: IDropdownOption[] = (
        this.nomenclatureBase?.unit.ranges || []
      ).map(({ value }, index) => {
        return {
          id: index + 1,
          label: `${value} ${(this.nomenclatureBase as ICategory).unit.name}`,
          value,
        };
      });

      this.options = [
        ...ranges,
        {
          id: ranges.length + 1,
          label: 'Указать тираж',
          value: -1,
        },
      ];

      this.helperTitle =
        this.nomenclatureBase?.unit.alias || DEFAULT_HELPER_TITLE;

      this.helperText =
        this.nomenclatureBase?.unit.helperText || DEFAULT_HELPER_TEXT;

      this.selectedOption =
        this.options.find((o) => o.value === this.range) ||
        ({
          id: this.options.length + 2,
          label: `${this.range} ${
            (this.nomenclatureBase as ICategory)?.unit?.name || ''
          }`,
          value: this.range || 1,
        } as IDropdownOption);
    }
  }

  onChange(id: number) {
    const option = this.options.find((r) => r.id === id);

    if (!option) {
      return;
    }

    if (option.value === -1) {
      this.modalWindowStore.patch({ [this.modalUid]: true });
    } else {
      this.changeOption.emit(option.value as number);
    }
  }

  saveRangeModal() {
    const value = this.rangeControl.value;
    if (value) {
      this.selectedOption = {
        id: this.options.length + 1,
        label: `${value} ${(this.nomenclatureBase as ICategory).unit.name}`,
        value,
      };
      this.changeOption.emit(this.rangeControl.value as number);
    }
    this.modalWindowStore.patch({ [this.modalUid]: false });
  }

  closeRangeModal() {
    this.rangeControl.setValue(null);
    this.modalWindowStore.patch({ [this.modalUid]: false });
  }
}
