import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CatalogSelectProductComponent,
  ICatalogNomenclatureTreeItem,
} from '@inaripro-nx/catalog';
import { Subscription, of, switchMap, tap } from 'rxjs';
import { FiguresService } from '../../services/figures/figures.service';
import { FiltersService } from '../../services/filters/filters.service';
import { ProductService } from '../../services/product/product.service';
import { DesignerAppShellStore } from '../../state/designer-app-shell/designer-app-shell.store';
import { MyTemplatesStore } from '../../state/my-templates/my-templates.store';
import { PatternsStore } from '../../state/patterns/patterns.store';
import { PicturesLibraryStore } from '../../state/pictures-library/pictures-library.store';
import { PicturesStore } from '../../state/pictures/pictures.store';
import { ProductTemplatesStore } from '../../state/product-templates/product-templates.store';
import { QrCodesStore } from '../../state/qr-codes/qr-codes.store';
import { ShareTemplatesService } from '../../services/share-templates/share-templates.service';

@Component({
  selector: 'designer-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('catalogSelectProduct')
  readonly catalogSelectProduct?: CatalogSelectProductComponent;

  uploadPicture = this.picturesStore.uploadFile.bind(this.picturesStore);
  deletePicture = this.picturesStore.deleteModel.bind(this.picturesStore);
  addPicture = this.picturesStore.addModel.bind(this.picturesStore);
  createQRCode = this.qrCodesStore.addModel.bind(this.qrCodesStore);
  deleteQRCode = this.qrCodesStore.deleteModel.bind(this.qrCodesStore);
  createMyTemplate = this.myTemplatesStore.addModel.bind(this.myTemplatesStore);
  updateMyTemplate = this.myTemplatesStore.updateModel.bind(
    this.myTemplatesStore
  );
  removeMyTemplate = this.myTemplatesStore.deleteModel.bind(
    this.myTemplatesStore
  );
  createProductTemplate = this.productTemplatesStore.addModel.bind(
    this.productTemplatesStore
  );
  updateProductTemplate = this.productTemplatesStore.updateModel.bind(
    this.productTemplatesStore
  );
  removeProductTemplate = this.productTemplatesStore.deleteModel.bind(
    this.productTemplatesStore
  );
  getLibraryPictures = this.picturesLibraryStore.loadPictures.bind(
    this.picturesLibraryStore
  );
  getPatterns = this.patternsStore.loadPatterns.bind(this.patternsStore);

  createShareTemplate = this.shareTemplatesService.createTemplate.bind(
    this.shareTemplatesService
  );

  readonly appShellConfig$ = this.appShellStore.appShellConfig$;

  readonly loading$ = this.productService.loadingProduct$;

  readonly savedTemplate$ = this.productTemplatesStore.isProductOwner$.pipe(
    switchMap((isProductOwner) =>
      isProductOwner
        ? this.productTemplatesStore.savedTemplate$
        : this.myTemplatesStore.savedTemplate$
    )
  );

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  filterCatalogNomenclatureTreeItem = (item: ICatalogNomenclatureTreeItem) =>
    item.hasProductDesign || false;

  constructor(
    public productService: ProductService,
    public filtersService: FiltersService,
    public figuresService: FiguresService,
    public patternsStore: PatternsStore,
    public picturesStore: PicturesStore,
    public picturesLibraryStore: PicturesLibraryStore,
    public qrCodesStore: QrCodesStore,
    public myTemplatesStore: MyTemplatesStore,
    public productTemplatesStore: ProductTemplatesStore,
    public appShellStore: DesignerAppShellStore,
    private router: Router,
    private route: ActivatedRoute,
    private shareTemplatesService: ShareTemplatesService
  ) {}

  ngOnInit(): void {
    this.patternsStore.init();
    this.picturesStore.init();
    this.qrCodesStore.init();
    this.myTemplatesStore.init();
    this.productTemplatesStore.init();
    this.picturesLibraryStore.init();
  }

  ngAfterViewInit(): void {
    this.initRouterSubscription();
    this.initProductSubscription();
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  selectProduct({
    productId,
    linkId,
  }: {
    productId: number;
    linkId: number | null;
  }) {
    const navigation = linkId
      ? ['/product', productId, linkId]
      : ['/product', productId];
    this.router.navigate(navigation, {
      replaceUrl: true,
    });
  }

  changeParams(params: string | null) {
    // redefine params for correct work button reset for clear defaults selected
    const currentParams = this.route.snapshot.queryParams['params'];

    if (
      params === null &&
      (currentParams === null || currentParams === undefined)
    ) {
      params = '';
    } else if (params === '' && currentParams === '') {
      params = null;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { params },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  openSelectProductModal() {
    if (this.catalogSelectProduct) {
      this.catalogSelectProduct.toggleProductSelect(true);
    }
  }

  private initRouterSubscription() {
    this.subs = this.route.params.subscribe((p) => {
      const productId = +p['productId'] || null;
      const groupId = p['groupId'];
      const linkId = +p['linkId'] || null;
      const queryParams = { ...this.route.snapshot.queryParams };
      const templateKey = 'template';
      const templateUid = queryParams[templateKey] || null;
      const autoDownloadKey = 'auto_download';
      const autoDownload = !!queryParams[autoDownloadKey];

      this.productService.setLinkId(linkId);
      this.productService.setTemplateUid(templateUid);
      this.productService.setAutoDownload(autoDownload);

      if (templateUid || autoDownload) {
        delete queryParams[templateKey];
        delete queryParams[autoDownloadKey];

        this.router.navigate([], {
          replaceUrl: true,
          skipLocationChange: false,
          queryParams,
        });
      }

      if (productId) {
        this.productService.setProductId(productId);
      }

      if (groupId) {
        this.productService.setGroupId(groupId);
        this.openSelectProductModal();
      }
    });
  }

  private initProductSubscription() {
    this.subs = this.productService.product$
      .pipe(
        switchMap((p) =>
          p
            ? this.route.queryParamMap.pipe(
                tap((qp) => {
                  const params = (qp.get('params') || '').split(',');
                  this.productService.queryElements$.next(params);

                  const rangeQueryParam = qp.get('r');
                  const draftCount = rangeQueryParam
                    ? +rangeQueryParam || 1
                    : 1;
                  this.productService.draftCount$.next(draftCount);
                })
              )
            : of(null)
        )
      )
      .subscribe();
  }
}
