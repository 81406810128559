import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'declOfNum',
})
export class DeclOfNumPipe implements PipeTransform {
  transform(value: number, args: unknown[]): string {
    let number = Math.abs(value);
    number %= 100;
    if (number >= 5 && number <= 20) {
      return `${value} ${args[1]}`; // товар[ОВ]
    }
    number %= 10;
    if (number === 1) {
      return `${value} ${args[0]}`; // товар[]
    }
    if (number >= 2 && number <= 4) {
      return `${value} ${args[2]}`; // товар[А]
    }
    return `${value} ${args[1]}`;
  }
}
