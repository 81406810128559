import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'catalog-product-list-not-found',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './catalog-product-list-not-found.component.html',
  styleUrls: ['./catalog-product-list-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogProductListNotFoundComponent {
  @Input() appliedFiltersTemplate: TemplateRef<unknown> | null = null;

  @Output() resetFilterValues = new EventEmitter<void>();
}
