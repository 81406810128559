import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DropDownModule, ToolButtonComponent } from '@inaripro-nx/design-ui';
import { CropStore, ZOOM_OPTIONS } from '../../store/crop';
import { AsyncPipe } from '@angular/common';
import { PercentNoSpacePipe, PipesModule } from '@inaripro-nx/common-ui';

interface ZoomOptionModel {
  dropDownLabel: string;
  value: number;
}

@Component({
  selector: 'crop-zoom-controls',
  standalone: true,
  templateUrl: './crop-zoom-controls.component.html',
  styleUrls: ['./crop-zoom-controls.component.scss'],
  imports: [DropDownModule, ToolButtonComponent, AsyncPipe, PipesModule],
  providers: [PercentNoSpacePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CropZoomControlsComponent {
  zoomSelectActive = false;

  readonly zoomOptions: ZoomOptionModel[] = ZOOM_OPTIONS.map((value) => ({
    dropDownLabel: this.percentNoSpacePipe.transform(value) || '',
    value,
  }));

  readonly zoom$ = this.cropStore.zoom$;
  readonly zoomInDisabled$ = this.cropStore.zoomInDisabled$;
  readonly zoomOutDisabled$ = this.cropStore.zoomOutDisabled$;

  readonly isFocusActive$ = this.cropStore.isFocusActive$;
  readonly isGrabActive$ = this.cropStore.isGrabActive$;

  constructor(
    private readonly cropStore: CropStore,
    private readonly percentNoSpacePipe: PercentNoSpacePipe
  ) {}

  zoomOut(): void {
    this.cropStore.zoomOut();
  }

  zoomIn(): void {
    this.cropStore.zoomIn();
  }

  selectZoom({ value }: ZoomOptionModel): void {
    this.cropStore.setZoom(value);
  }

  toggleFocus(): void {
    this.cropStore.toggleFocus();
  }

  toggleGrab(): void {
    this.cropStore.toggleGrab();
  }
}
