<div class="card" (click)="addObject()">
  <div class="card-close" (click)="clickCardClose()">
    <i class="icon icon-ic_cross-close"></i>
  </div>
  <p class="text">{{ text }}</p>
  <svg *ngIf="needSVG" version="1.1" xmlns="http://www.w3.org/2000/svg" style="width: 0; height: 0">
    <g #svgGroup>
      <text x="0" y="0" dy="0" font-size="40">
        <tspan
          *ngFor="let line of text.split('\n'); let i = index" x="0" dy="1em"
          [innerHTML]="(line || '.') | safeHtml"
          [attr.stroke-width]="line ? 'inherit' : 0"
          [attr.fill]="line ? 'inherit' : 'none'"
        ></tspan>
      </text>
    </g>
  </svg>
</div>
