import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'svgTranslate',
})
export class SvgTranslatePipe implements PipeTransform {
  transform(
    value: { x: number | string; y: number | string } | undefined | null
  ): string {
    return value ? ` translate(${value.x} ${value.y})` : '';
  }
}
