<design-modal-window [uid]="modalUid" [maxWidth]="1286" (selfClose)="reset()" class="crop-modal">
  <div modal-header class="title">Обрезка изображения</div>

  <div modal-body class="body">
    <div class="image" [class.image--mobile-active]="!isDesktop && isMobilePicturesActive">
      <pictures-content-pictures class="content-pictures-modal" (selectObject)="onSelectPicture($event)"></pictures-content-pictures>
    </div>

    <div class="work-area">
      <div class="work-area__actions">
        <div class="g-checkboxblue g-checkboxblue--with-text">
          <input id="crop-proportions" type="checkbox" [formControl]="saveProportionsControl" />
          <label for="crop-proportions"><span>Сохранять пропорции</span></label>
        </div>
      </div>

      @if (modalOpened$ | async) {
        <crop-container
          class="work-area__container"
          [imageUrl]="selectedImage"
          [imageWidth]="selectedImageWidth"
          [imageHeight]="selectedImageHeight"
          [maskX]="maskX"
          [maskY]="maskY"
          [maskWidth]="maskWidth"
          [maskHeight]="maskHeight"
          [selectedFigure]="selectedFigureForMask"
          [saveProportions]="saveProportionsControl.value!"
        ></crop-container>
      }
    </div>

    <div class="mask">
      <div
        #scrollContainer
        class="mask__list-container"
        (scroll)="scrollEvent$.next({
          event: $event,
          scrollContainer: scrollContainer
        })"
      >
        <div
          [style.height.px]="scrollContainer.offsetHeight"
          [style.padding-top.px]="beforeHeight"
          [style.padding-bottom.px]="afterHeight"
        >
          @if (modalOpened$ | async) {
            <crop-crop-figure-list
              class="mask__list"
              [figureList]="(figures$ | async) || []"
              [selectedFigureId]="selectedFigureForList.id || null"
              (selectFigure)="onSelectFigure($event)"
            ></crop-crop-figure-list>
          }
        </div>
      </div>
    </div>

    @if (!isDesktop) {
      <button type="button" class="image-button" (click)="toggleMobilePictures()">
        <i class="icon icon-ic_picture"></i>
        <span>Открыть картинки</span>
      </button>
    }
  </div>

  <div modal-footer class="footer">
    @if (!isDesktop && isMobilePicturesActive) {
      <button type="button" class="g-btn-link" (click)="toggleMobilePictures()">Отмена</button>
    } @else {
      <button type="button" class="g-btn-link" (click)="onCancel()">Отмена</button>
      <button
        type="button"
        class="g-btn-link g-btn-link--red"
        [disabled]="!selectedImage"
        (click)="onSave()"
      >
        Сохранить
      </button>
    }
  </div>
</design-modal-window>
