import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { DesignUiModule, DropDownModule } from '@inaripro-nx/design-ui';
import { map, Observable, Subscription } from 'rxjs';
import {
  IProductTemplate,
  ITemplate,
} from '../../../../../../interfaces/templates.interface';
import { ProductStore } from '../../../../state/product/product.store';
import { TemplateFormService } from '../../../../services/template-form/template-form.service';
import { AuthService, User } from '@inaripro-nx/auth';
import { MainService } from '../../../../services/main/main.service';

enum ESaveType {
  current = 'current',
  new = 'new',
  download = 'download',
}

interface ISaveOption {
  type: ESaveType;
  dropDownLabel: string;
}

@Component({
  selector: 'painter-editor-template-control',
  standalone: true,
  imports: [CommonModule, DropDownModule, DesignUiModule],
  templateUrl: './editor-template-control.component.html',
  styleUrls: ['./editor-template-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorTemplateControlComponent implements OnDestroy {
  readonly ESaveType = ESaveType;

  saveOptions$: Observable<ISaveOption[]> =
    this.productStore.currentTemplate$.pipe(
      map((currentTemplate) => this.getSaveOptions(currentTemplate))
    );

  isSending$ = this.templateFormService.isSending$;
  isSaved$ = this.templateFormService.isSaved$;

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  constructor(
    private readonly productStore: ProductStore,
    private readonly templateFormService: TemplateFormService,
    private readonly mainService: MainService
  ) {}

  ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  private getSaveOptions(
    currentTemplate: ITemplate | IProductTemplate | null
  ): ISaveOption[] {
    const options: ISaveOption[] = currentTemplate
      ? [
          {
            dropDownLabel: 'Сохранить изменения в текущем шаблоне',
            type: ESaveType.current,
          },
          { dropDownLabel: 'Сохранить как новый шаблон', type: ESaveType.new },
        ]
      : [{ dropDownLabel: 'Сохранить шаблон', type: ESaveType.new }];

    options.push({
      type: ESaveType.download,
      dropDownLabel: 'Скачать макет',
    });

    return options;
  }

  selectSaveOption(saveOption: unknown) {
    const option = saveOption as { type: ESaveType };

    if (option.type === ESaveType.download) {
      this.subs = this.mainService.download();
    } else if (option.type === ESaveType.new) {
      this.templateFormService.createOrUpdate$.next('create');
    } else if (option.type === ESaveType.current) {
      this.templateFormService.createOrUpdate$.next('update');
    }
  }
}
