import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  fromEvent,
  Observable,
  pairwise,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { IWindowSize, WINDOW_SIZE } from '@inaripro-nx/common-ui';

@Component({
  selector: 'design-invisible-horizontally-scroll',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './design-invisible-horizontally-scroll.component.html',
  styleUrl: './design-invisible-horizontally-scroll.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignInvisibleHorizontallyScrollComponent
  implements AfterViewInit, OnDestroy
{
  @Input() deltaMouseMove = 5;
  @Input() deltaClick = 20;
  @Input() deltaWheel = 10;
  @Input() isShowArrows = false;

  @ViewChild('invisibleHorizontallyScroll', { static: false })
  invisibleHorizontallyScroll?: ElementRef<HTMLDivElement>;
  @ViewChild('leftArrow', { static: false })
  leftArrow?: ElementRef<HTMLDivElement>;
  @ViewChild('rightArrow', { static: false })
  rightArrow?: ElementRef<HTMLDivElement>;

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  isShowRightArrow = false;
  isShowLeftArrow = false;

  constructor(
    private cdr: ChangeDetectorRef,
    @Inject(WINDOW_SIZE) private readonly windowSize$: Observable<IWindowSize>
  ) {}

  ngAfterViewInit() {
    if (this.invisibleHorizontallyScroll) {
      this.initWheelActions(this.invisibleHorizontallyScroll);
      this.initMouseActions(this.invisibleHorizontallyScroll);

      if (this.isShowArrows) {
        this.initArrowActions(this.invisibleHorizontallyScroll);
        this.initResizeActions(this.invisibleHorizontallyScroll);
        this.initTouchActions(this.invisibleHorizontallyScroll);
      }
    }
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
  }

  private initWheelActions(elementRef: ElementRef) {
    this.subs = fromEvent<WheelEvent>(elementRef.nativeElement, 'wheel')
      .pipe(
        tap((event: WheelEvent) => {
          event.preventDefault();
          this.scrollElement(elementRef, event.deltaY, 0, this.deltaWheel);
        })
      )
      .subscribe();
  }

  private initMouseActions(elementRef: ElementRef) {
    const mouseStartEvent$: Observable<MouseEvent> = fromEvent(
      elementRef.nativeElement,
      'mousedown'
    );
    const mouseEndEvent$: Observable<MouseEvent> = fromEvent(
      elementRef.nativeElement,
      'mouseup'
    );
    const mouseMoveEvent$: Observable<MouseEvent> = fromEvent(
      elementRef.nativeElement,
      'mousemove'
    );
    const mouseLeaveEvent$: Observable<MouseEvent> = fromEvent(
      elementRef.nativeElement,
      'mouseleave'
    );

    this.subs = mouseStartEvent$
      .pipe(
        switchMap((mouseStartEvent) => {
          return mouseMoveEvent$.pipe(
            pairwise(),
            tap(([mouseMoveEventCurr, mouseMoveEventPrev]) => {
              this.scrollElement(
                elementRef,
                mouseMoveEventPrev.clientX,
                mouseMoveEventCurr.clientX,
                this.deltaMouseMove
              );
            }),
            takeUntil(mouseEndEvent$),
            takeUntil(mouseLeaveEvent$)
          );
        })
      )
      .subscribe();
  }

  private initArrowActions(elementRef: ElementRef) {
    if (this.leftArrow) {
      this.subs = fromEvent(this.leftArrow.nativeElement, 'click')
        .pipe(
          tap((event: Event) => {
            event.stopPropagation();
            elementRef.nativeElement.scrollLeft =
              elementRef.nativeElement.scrollLeft - this.deltaClick < 0
                ? 0
                : elementRef.nativeElement.scrollLeft - this.deltaClick;
            this.conditionsForArrows(elementRef);
          })
        )
        .subscribe();
    }

    if (this.rightArrow) {
      this.subs = fromEvent(this.rightArrow.nativeElement, 'click')
        .pipe(
          tap((event: Event) => {
            event.stopPropagation();
            elementRef.nativeElement.scrollLeft =
              elementRef.nativeElement.scrollLeft + this.deltaClick;
            this.conditionsForArrows(elementRef);
          })
        )
        .subscribe();
    }
  }

  private initResizeActions(elementRef: ElementRef) {
    this.subs = this.windowSize$
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        tap(() => {
          this.conditionsForArrows(elementRef);
        })
      )
      .subscribe();
  }

  private initTouchActions(elementRef: ElementRef) {
    this.subs = fromEvent<TouchEvent>(elementRef.nativeElement, 'touchmove')
      .pipe(
        tap((event: TouchEvent) => {
          event.stopPropagation();
          this.conditionsForArrows(elementRef);
        })
      )
      .subscribe();
  }

  private scrollElement(
    elementRef: ElementRef,
    startValue: number,
    endValue: number,
    delta: number
  ) {
    if (startValue > endValue) {
      elementRef.nativeElement.scrollLeft =
        elementRef.nativeElement.scrollLeft - delta < 0
          ? 0
          : elementRef.nativeElement.scrollLeft - delta;
    }

    if (startValue < endValue) {
      elementRef.nativeElement.scrollLeft =
        elementRef.nativeElement.scrollLeft + delta;
    }

    if (this.isShowArrows) {
      this.conditionsForArrows(elementRef);
    }
  }

  private conditionsForArrows(elementRef: ElementRef) {
    this.isShowLeftArrow = !(elementRef.nativeElement.scrollLeft === 0);
    this.isShowRightArrow = !(
      elementRef.nativeElement.scrollLeft +
        elementRef.nativeElement.clientWidth ===
      elementRef.nativeElement.scrollWidth
    );

    this.cdr.detectChanges();
  }
}
