import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@inaripro-nx/auth';
import { WINDOW } from '@inaripro-nx/common-ui';
import { ClickOutsideDirective } from '@inaripro-nx/design-ui';
import { ICity } from '@inaripro-nx/geo';
import { Subscription } from 'rxjs';
import { HeaderLinkComponent } from '../header-link/header-link.component';
import { HeaderMenuServiceComponent } from '../header-menu-service/header-menu-service.component';

@Component({
  selector: 'shell-header-menu-mobile',
  standalone: true,
  imports: [
    CommonModule,
    HeaderLinkComponent,
    ClickOutsideDirective,
    HeaderMenuServiceComponent,
  ],
  templateUrl: './header-menu-mobile.component.html',
  styleUrls: ['./header-menu-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMenuMobileComponent implements OnInit, OnDestroy {
  @Input() user: User | null = null;
  @Input() currentLocation: ICity | null = null;

  @Output() selectCity = new EventEmitter<void>();
  @Output() signout: EventEmitter<void> = new EventEmitter<void>();

  get userHasCabinet(): boolean {
    if (!this.user) {
      return false;
    }
    return this.user.isPartner || this.user.isCustomer;
  }

  mobileMenuState = false;

  readonly menuConfig: {
    name: string;
    link: string;
    relative?: boolean;
    exact?: boolean;
  }[] = [
    {
      name: 'Поставщики',
      link: '/catalog/company',
    },
    {
      name: 'Для клиентов',
      link: '/info',
    },
    {
      name: 'Новости',
      link: '/media',
    },
    {
      name: 'Контакты',
      link: '/contacts',
    },
  ];

  get loginUrl() {
    return `/login?returnUrl=${encodeURIComponent(window.location.href)}`;
  }

  _routeSub: Subscription | null = null;
  set routeSub(routeSub: Subscription | null) {
    if (this._routeSub) {
      this._routeSub.unsubscribe();
    }

    this._routeSub = routeSub;
  }

  constructor(
    @Inject(WINDOW) public readonly window: Window,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(() => this.cdr.detectChanges());
  }

  ngOnDestroy(): void {
    this.routeSub = null;
  }
}
