import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportZoneComponent } from './export-zone.component';
import {
  PipesModule as CommonPipesModule,
  PipesModule,
} from '@inaripro-nx/common-ui';
import { EditorElementTransformComponent } from '../editor/components/editor-element-transform/editor-element-transform.component';
import { EditorElementContentComponent } from '../editor/components/editor-element-content/editor-element-content.component';

@NgModule({
  declarations: [ExportZoneComponent],
  exports: [ExportZoneComponent],
  imports: [
    CommonModule,
    CommonPipesModule,
    PipesModule,
    EditorElementTransformComponent,
    EditorElementContentComponent,
  ],
})
export class ExportZoneModule {}
