import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  BACKGROUND_COLOR_DEFAULT,
  getContrastYIQ,
} from '@inaripro-nx/common-ui';
import { BehaviorSubject } from 'rxjs';
import { ModalWindowComponent } from '../modal-window/modal-window.component';
import { ModalWindowStore } from '../modal-window/state/modal-window/modal-window.store';
import { ColorBoxModule } from './components/color-box/color-box.module';
import { DesignInvisibleHorizontallyScrollComponent } from '../design-invisible-horizontally-scroll/design-invisible-horizontally-scroll.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ModalWindowComponent,
    ColorBoxModule,
    DesignInvisibleHorizontallyScrollComponent,
  ],
  selector: 'design-colorpicker',
  templateUrl: './colorpicker.component.html',
  styleUrls: ['./colorpicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorPickerComponent implements OnChanges {
  @Input() eyeColor?: string | null;
  @Input() inColor!: string | null; // hex
  @Input() debounceTime = 0;
  @Input() withModal = false;
  @Input() modalTitle = 'Цвет';
  @Input() isDesktop!: boolean | null;
  @Input() resizeSafari$: BehaviorSubject<boolean> | null = null;
  @Input() modalUid = 'color_picker';
  @Input() hasTransparent = true;
  @Input() useColors?: string[] | null;
  @Input() maxWidthModal = 290;
  @Input() prefix!: string;
  @Input() colorDefault!: string;
  @Input() modificator?: 'background-fill' | 'stroke-text' | 'stroke-figure';

  @Output() changeModel: EventEmitter<string | null> = new EventEmitter<
    string | null
  >();
  @Output() changeEnd: EventEmitter<void> = new EventEmitter<void>();
  @Output() startEye: EventEmitter<Event> = new EventEmitter<Event>();

  currentColor: string | null = this.inColor;

  readonly getContrastYIQ = getContrastYIQ;

  get defaultColor(): string | null {
    return this.hasTransparent ? null : this.colorDefault;
  }

  get isTransparent(): boolean {
    return !this.currentColor && this.hasTransparent;
  }

  constructor(private readonly modalWindowStore: ModalWindowStore) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inColor']) {
      this.currentColor = this.inColor;
    }
  }

  changeInput(event: Event) {
    const color = (event.target as HTMLInputElement).value;
    this.selectColor(color);
  }

  public openModalChangeColor(): void {
    if (this.withModal) {
      this.modalWindowStore.patch({ [this.modalUid]: true });
    }
  }

  changeColor(color: string) {
    this.currentColor = color;
    this.changeModel.emit(color);
  }

  selectColor(color: string | null) {
    this.currentColor = color || this.defaultColor;
    this.changeModel.emit(color);
    this.changeEnd.emit();
  }
}
