import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'catalog-online-payment',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './catalog-online-payment.component.html',
  styleUrls: ['./catalog-online-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogOnlinePaymentComponent {
  @Input() isOn = false;
  @Input() isTitle = false;
}
