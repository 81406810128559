import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RangeSliderWrapperComponent } from '../../../../../../../../../design-ui/src/lib/components/range-slider-wrapper/range-slider-wrapper.component';
import { DECIMAL_POINTS_STROKE } from '@inaripro-nx/painter';
import { IElement } from '../../../../interfaces/editor.interface';
import { ModalStrokeComponent } from '../../../modal-stroke/modal-stroke.component';
import { EditorService } from '../../../../services/editor/editor.service';
import { DEFAULT_VALUE_STROKE_WIDTH } from '../../../../../../constants/stroke.consts';

@Component({
  selector: 'painter-actions-element-stroke-width',
  standalone: true,
  imports: [CommonModule, RangeSliderWrapperComponent, ModalStrokeComponent],
  templateUrl: './actions-element-stroke-width.component.html',
  styleUrl: './actions-element-stroke-width.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsElementStrokeWidthComponent {
  @Input() element!: IElement;
  @Input() sliderTemplate: TemplateRef<unknown> | null = null;
  @Input() minValue!: number;
  @Input() maxValue!: number;
  @Input() modalUid!: string;
  @Input() modificator!: 'stroke-text' | 'stroke-figure';
  @Input() prefix!: string;

  readonly DECIMAL_POINTS_STROKE = DECIMAL_POINTS_STROKE;
  readonly DEFAULT_VALUE_STROKE_WIDTH = DEFAULT_VALUE_STROKE_WIDTH;

  constructor(public editorService: EditorService) {}

  changeValue(value: number) {
    this.editorService.strokeWidth(value);
  }

  stopChange() {
    this.editorService.stopChange();
  }

  reset() {
    this.changeValue(this.minValue);
  }
}
