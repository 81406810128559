import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ClickOutsideDirective,
  ModalWindowComponent,
  ModalWindowStore,
} from '@inaripro-nx/design-ui';
import { EColorTitle } from '../../../../constants';
import {
  ELEMENT_STROKE_COLOR_DEFAULT,
  IWindowSize,
  WINDOW_SIZE,
} from '@inaripro-nx/common-ui';
import { ColorPickerComponent } from '../../../../../../../design-ui/src/lib/components/colorpicker/colorpicker.component';
import { HistoryStore } from '../../state/history/history.store';
import { EyeDropperService } from '../../services/eye-dropper/eye-dropper.service';
import { map, Observable } from 'rxjs';
import { WindowToolsService } from '../../services/window-tools/window-tools.service';
import { IElement } from '../../interfaces/editor.interface';
import { EditorService } from '../../services/editor/editor.service';
import { ActionsTextService } from '../../services/actions-text/actions-text.service';

@Component({
  selector: 'painter-modal-stroke',
  standalone: true,
  imports: [
    CommonModule,
    ModalWindowComponent,
    ClickOutsideDirective,
    ColorPickerComponent,
  ],
  templateUrl: './modal-stroke.component.html',
  styleUrls: ['./modal-stroke.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalStrokeComponent {
  @Input() element!: IElement;
  @Input() sliderTemplate: TemplateRef<unknown> | null = null;
  @Input() modalUid!: string;
  @Input() modificator!: 'stroke-text' | 'stroke-figure';
  @Input() prefix!: string;

  readonly ELEMENT_STROKE_COLOR_DEFAULT = ELEMENT_STROKE_COLOR_DEFAULT;
  readonly EColorTitle = EColorTitle;

  readonly useColors$ = this.historyStore.useColors$;
  isDesktop$: Observable<boolean> = this.windowToolsService.isDesktop$;

  maxWidthModalStroke$ = this.windowSize$.pipe(
    map((windowSize) => {
      const { width: windowWidth } = windowSize;

      let maxWidthModalStroke = 242;

      if (windowWidth >= 1366) {
        maxWidthModalStroke = 314;
      }

      if (windowWidth <= 1024) {
        maxWidthModalStroke = 302;
      }

      return maxWidthModalStroke;
    })
  );

  constructor(
    public editorService: EditorService,
    private readonly historyStore: HistoryStore,
    public eyeDropperService: EyeDropperService,
    private windowToolsService: WindowToolsService,
    public actionsTextService: ActionsTextService,
    @Inject(WINDOW_SIZE) private readonly windowSize$: Observable<IWindowSize>
  ) {}

  changeModelStroke(event: any) {
    this.editorService.stroke(event);
  }

  changeEndStroke() {
    this.editorService.stopChange();
  }
}
