import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IShareResponse,
  IShareTemplate,
} from '../../../../interfaces/templates.interface';

@Injectable({
  providedIn: 'root',
})
export class ShareTemplatesService {
  createShareTemplate!: (model: IShareTemplate) => Observable<IShareResponse>;
  getShareTemplate!: (uid: string) => Observable<IShareTemplate>;
}
