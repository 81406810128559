<ng-container
  *ngIf="{
    updatingPrice: source.updatingPrice$ | async,
    publicPrice: source.publicPrice$ | async,
    priceBaseValue: (source.priceBaseValue$ | async) || 0,
    priceDiscountValue: (source.priceDiscountValue$ | async) || 0,
    orderCount: (source.orderCount$ | async) || 1,
    priceRange: source.priceRange$ | async,
    countWithBasket: source.countWithBasket$ | async,
    priceRangeIndex: source.priceRangeIndex$ | async,
    isAddButtonDisabled: source.isAddButtonDisabled$ | async,
    unit: source.unit$ | async
  } as state"
>
  <div class="order-control" *ngIf="state.publicPrice">
    <catalog-product-price
      [updatingPrice]="state.updatingPrice"
      [baseValue]="state.priceBaseValue * state.orderCount"
      [discountValue]="state.priceDiscountValue * state.orderCount"
      [range]="state.priceRange"
    ></catalog-product-price>

    <div
      class="order-control__info"
      designAdDropDown
      [dropDownAlignElement]="iconElementRef"
      [dropDownOpenTo]="'down'"
      [dropDownContentTemplateRef]="priceTableTemplate"
      [dropDownClass]="'header-order-price-table'"
    >
      <div class="icon-wrapper" #iconElementRef>
        <i class="icon icon-ic_help_fill"></i>
      </div>
    </div>

    <button
      *ngIf="isOrderButton$ | async; else goToBasketTemplate"
      [disabled]="state.isAddButtonDisabled || state.updatingPrice || false"
      class="g-btn"
      type="button"
      (click)="addProductToCart()"
    >
      <span class="icon-basket">
        <i class="icon icon-ic_basket"></i>
      </span>
      <span class="text">В корзину</span>
    </button>
  </div>

  <ng-template #priceTableTemplate>
    <catalog-product-price-table
      [publicPrice]="state.publicPrice"
      [sumOrderCount]="state.countWithBasket || 0"
      [activeRangeIndex]="state.priceRangeIndex"
      [unitName]="state.unit ? state.unit.name : 'шт.'"
    ></catalog-product-price-table>
  </ng-template>
</ng-container>

<ng-template #goToBasketTemplate>
  <shell-header-link [link]="'/order'" class="g-btn">
    <span class="icon-basket">
      <i class="icon icon-ic_shopping_cart"></i>
      <span class="product-count">{{ basketCounter }}</span>
    </span>
    <span class="text">Перейти</span>
  </shell-header-link>
</ng-template>
