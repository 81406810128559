import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { map, mergeMap } from 'rxjs';
import { RouterStateUrl } from '../../constants';
import {
  PAGINATION_STORE_CONFIG,
  PaginationStoreConfig,
} from './pagination.store.config';
import { filter } from 'rxjs/operators';
import {
  setPaginationConfig,
  setPaginationRequest,
  setPaginationResponse,
} from './pagination.actions';

@Injectable()
export class PaginationEffects {
  parseRouter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      map((action: RouterNavigationAction<RouterStateUrl>) => action.payload),
      filter(
        (payload) =>
          payload.routerState.data['module'] === this.storeConfig.moduleUid
      ),
      mergeMap((payload) => {
        const config = this.storeConfig.config;

        let offset: number = parseFloat(
          payload.routerState.queryParams['offset']
        );
        let limit: number = parseFloat(
          payload.routerState.queryParams['limit']
        );

        offset = !isNaN(offset) ? offset : config.defaultRequest.offset;
        limit = !isNaN(limit) ? limit : config.defaultRequest.limit;

        if (offset < 0) {
          offset = 0;
        } else if (limit && offset % limit !== 0) {
          // кратность нарушена
          offset = 0;
        }

        const limitValues = config.limitValues;

        if (limitValues && limitValues.length) {
          const find = limitValues.find((val) => val === limit);

          if (!find) {
            limit = limitValues[0];
          }
        }

        return [
          setPaginationConfig({
            uid: this.storeConfig.storeUid,
            payload: this.storeConfig.config,
          }),
          setPaginationRequest({
            uid: this.storeConfig.storeUid,
            payload: { offset, limit },
          }),
          setPaginationResponse({
            uid: this.storeConfig.storeUid,
            payload: null,
          }),
        ];
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    @Inject(PAGINATION_STORE_CONFIG)
    private readonly storeConfig: PaginationStoreConfig
  ) {}
}
