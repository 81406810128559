import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesignSimpleSelectboxListComponent } from '../design-simple-selectbox-list/design-simple-selectbox-list.component';
import { SelectedItemsSizePipe } from '../../pipes/selected-items-size/selected-items-size.pipe';
import { SimplebarAngularModule } from 'simplebar-angular';
import { IDesignSimpleSelectboxListItem } from '../../../../interfaces';
import { IMapOfBoolean, WINDOW } from '@inaripro-nx/common-ui';

@Component({
  selector: 'design-simple-selectbox-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    DesignSimpleSelectboxListComponent,
    SelectedItemsSizePipe,
    SimplebarAngularModule,
  ],
  templateUrl: './design-simple-selectbox-dropdown.component.html',
  styleUrl: './design-simple-selectbox-dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignSimpleSelectboxDropdownComponent {
  @Input() listItems!: IDesignSimpleSelectboxListItem[];
  @Input() filterItems!: IDesignSimpleSelectboxListItem[];
  @Input() selectedItems!: IMapOfBoolean;
  @Input() modificatorSimplebar!: string;
  @Input() submitDisabled!: boolean;
  @Input() showSelectAll = false;
  @Input() isActive!: boolean;

  @Output() toggleDropdown: EventEmitter<number> = new EventEmitter<number>();
  @Output() selectAllDropdown: EventEmitter<void> = new EventEmitter<void>();
  @Output() clearAllDropdown: EventEmitter<void> = new EventEmitter<void>();
  @Output() changeModelDropdown: EventEmitter<IMapOfBoolean> =
    new EventEmitter<IMapOfBoolean>();

  constructor(@Inject(WINDOW) private readonly window: Window) {}

  onSelectAll() {
    this.selectAllDropdown.emit();
  }

  onReset() {
    this.clearAllDropdown.emit();
  }

  onApply() {
    this.changeModelDropdown.emit(this.selectedItems);
  }

  selectItem(item: IDesignSimpleSelectboxListItem) {
    this.toggleDropdown.emit(item.id);
  }

  preventScroll(e: MouseEvent | TouchEvent) {
    const tagName = (e.target as HTMLInputElement).tagName;
    if (tagName !== 'DIV') {
      e.stopPropagation();
      return;
    }

    const area = e.target as HTMLElement;

    if (e instanceof MouseEvent) {
      const delta =
        (e as any).deltaY || (e as any).detail || (e as any).wheelDelta;
      if (delta < 0 && area.scrollTop === 0) {
        e.stopPropagation();
        e.preventDefault();
      }

      if (
        delta > 0 &&
        area.scrollHeight - area.clientHeight - area.scrollTop <= 1
      ) {
        e.stopPropagation();
        e.preventDefault();
      }
    }

    if ((this.window as any).TouchEvent && e instanceof TouchEvent) {
      e.stopPropagation();
      e.preventDefault();
    }
  }
}
