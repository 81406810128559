import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DesignUiModule, DropDownModule } from '@inaripro-nx/design-ui';
import {
  IFontStyle,
  IFontWeight,
  ITextAnchor,
  ITextElement,
  IXYb,
} from '../../../../../../interfaces/editor.interface';
import { FONTS_FAMILIES } from '../../../../../../interfaces/fonts.interface';

@Component({
  selector: 'painter-actions-text-modification',
  standalone: true,
  imports: [CommonModule, DesignUiModule, DropDownModule],
  templateUrl: './actions-text-modification.component.html',
  styleUrls: ['./actions-text-modification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsTextModificationComponent {
  private _textElement!: ITextElement;
  @Input() set textElement(textElement: ITextElement) {
    this._textElement = textElement;

    this.blockBold = true;
    this.blockItalic = true;

    // const ff = FONTS_FAMILIES.find(
    //   (ff) => ff.key === this._textElement.fontFamily
    // );
    //
    // if (ff) {
    //   this.blockBold = ff.blockBold || false;
    //   this.blockItalic = ff.blockItalic || false;
    // }
  }

  get textElement(): ITextElement {
    return this._textElement;
  }

  @Input() mode: 'desktop' | 'mobile' = 'desktop';

  @Output() toggleFontWeight = new EventEmitter<void>();
  @Output() toggleFontStyle = new EventEmitter<void>();
  @Output() centerElement: EventEmitter<IXYb> = new EventEmitter();
  @Output() changeTextAnchor: EventEmitter<ITextAnchor> = new EventEmitter();
  @Output() changeLineSpace: EventEmitter<{ lineSpace: number }> =
    new EventEmitter();
  @Output() changeLetterSpacing = new EventEmitter<number>();
  @Output() stopChangeLetterSpacing = new EventEmitter<void>();
  @Output() changeTextArc = new EventEmitter<number>();
  @Output() stopChangeTextArc = new EventEmitter<void>();

  readonly IFontWeight = IFontWeight;
  readonly IFontStyle = IFontStyle;
  readonly ITextAnchor = ITextAnchor;

  blockBold = false;
  blockItalic = false;

  readonly lineSpaces = [1.0, 1.5, 2.0].map((lineSpace) => ({
    lineSpace,
    dropDownLabel: lineSpace.toFixed(1).replace('.', ','),
  }));

  get elementLineSpaceLabel(): string {
    return parseFloat('' + this.textElement.lineSpace)
      .toFixed(1)
      .replace('.', ',');
  }

  get hasAngle(): boolean {
    return !!this.textElement.textArc?.angle;
  }
}
