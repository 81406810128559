import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppShellLinkTypeEnum } from '@inaripro-nx/common-ui';
import { HeaderStore } from '../../state/header.store';

@Component({
  selector: 'shell-header-link',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './header-link.component.html',
  styleUrls: ['./header-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLinkComponent {
  @Input() link: string | null = null;
  @Input() externalLink: string | null = null;
  @Input() name = '';
  @Input() shortNotification = '';
  @Input() exact = false;

  readonly config$ = this.headerStore.config$;
  readonly linkTypeEnum = AppShellLinkTypeEnum;

  constructor(private headerStore: HeaderStore) {}
}
