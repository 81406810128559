import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { EMockupType, IDesignSide } from '@inaripro-nx/catalog';
import {
  BACKGROUND_COLOR_DEFAULT,
  IFilter,
  IMapOfString,
  IPattern,
} from '@inaripro-nx/common-ui';
import { PREFIX_SIDE_FILL } from '../../../../constants';
import { IElements, IMapXY, IXY } from '../../interfaces/editor.interface';

@Component({
  selector: 'painter-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ExportComponent {
  readonly EMockupType = EMockupType;
  readonly BACKGROUND_COLOR_DEFAULT = BACKGROUND_COLOR_DEFAULT;
  readonly PREFIX_SIDE_FILL = PREFIX_SIDE_FILL;

  @Input() side!: IDesignSide;
  @Input() sideIndex: number | null = null;
  @Input() filters!: { [id: number]: IFilter };
  @Input() zoneColors!: IMapOfString;
  @Input() zonePatterns!: { [key: string]: IPattern };
  @Input() zoneTranslates!: IMapXY;

  private _elements!: IElements;

  get elements(): IElements {
    return this._elements;
  }

  @Input() set elements(elements: IElements) {
    this._elements = elements;

    this.elementsFiltersId = elements
      .filter((e) => !!e.filterId)
      .map((e) => e.filterId)
      .filter((value, index, array) => {
        return array.indexOf(value) === index;
      });
  }

  @Input() fullColor: string | null = null;

  get zone0Center(): IXY {
    const zone = this.side?.zones[0];
    const zone0Center = {
      ...(zone?.center || { x: 0, y: 0 }),
    };

    const translate = this.zoneTranslates['' + zone.id];

    if (translate) {
      zone0Center.x += translate.x;
      zone0Center.y += translate.y;
    }

    return zone0Center;
  }
  elementsFiltersId: (number | null)[] = [];

  constructor(public elementRef: ElementRef) {}
}
