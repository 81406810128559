<design-colorpicker
  [inColor]="(inColor$ | async) || null"
  (changeModel)="setZoneColor($event)"
  [eyeColor]="eyeDropperService.eyeColor$ | async"
  (startEye)="eyeDropperService.startEye($event)"
  [isDesktop]="isDesktop$ | async"
  [withModal]="false"
  [resizeSafari$]="actionsTextService.resizeSafari$"
  [useColors]="useColors$ | async"
  [colorDefault]="BACKGROUND_COLOR_DEFAULT"
  [modificator]="'background-fill'"
></design-colorpicker>
