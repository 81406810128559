@if (el.type === EElementType.text) {
  <svg:g
    data-painter-editor-element-text-content
    [el]="el"
    [index]="index"
    [inAction]="inAction"
    [attr.transform]="el.scale | svgScale"
  ></svg:g>
} @else if (el.type === EElementType.figure || el.type === EElementType.qr) {
  <svg:g
    [attr.transform]="el | svgFlip"
    [innerHTML]="el.svg | safeHtml"
  ></svg:g>
} @else if (el.type === EElementType.image) {
  @if (cropData) {
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      [attr.width]="el.size.x * el.scale.x"
      [attr.height]="el.size.y * el.scale.y"
      data-crop-image
    >
      <defs
        *ngIf="filter"
        [innerHTML]="filter.html | safeHtml"
      ></defs>
      <defs
        *ngIf="cropData.mask"
        [innerHTML]="cropData.mask | safeHtml"
      ></defs>
      <g [innerHTML]="cropData.image | safeHtml"></g>
    </svg>
  } @else if (el.url) {
    @if (filter || el.ext === 'svg') {
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        [attr.width]="el.size.x * el.scale.x"
        [attr.height]="el.size.y * el.scale.y"
        data-crop-image
      >
        <defs
          *ngIf="filter"
          [innerHTML]="filter.html | safeHtml"
        ></defs>
       <ng-container *ngTemplateOutlet="image"></ng-container>
      </svg>
    } @else {
      <ng-container *ngTemplateOutlet="image"></ng-container>
    }
  }
}

<ng-template #image>
  <svg:image
    *ngIf="el.type === EElementType.image"
    [attr.width]="el.size.x"
    [attr.height]="el.size.y"
    [attr.transform]="el | svgFlip"
    [attr.xlink:href]="el.url"
    [attr.filter]="filter ? 'url(#filter_' + filter.id + ')' : null"
  ></svg:image>
</ng-template>
