<div
  class="mobile-menu-btn"
  designClickOutside
  (clickOutsideEvent)="mobileMenuState = false"
  (click)="mobileMenuState = !mobileMenuState"
>
  <span class="mobile-menu-btn__text">Меню</span>

  <span
    class="mobile-menu-btn__icon burger-icon"
    [class.burger-icon--active]="mobileMenuState"
  >
    <span class="burger-icon__line"></span>
    <span class="burger-icon__line"></span>
    <span class="burger-icon__line"></span>
  </span>
</div>

<div class="mobile-menu" [class.mobile-menu--active]="mobileMenuState">
  <nav class="mobile-menu__nav" role="navigation" aria-label="Главное меню">
    <div class="mobile-menu__nav-go-back">
      <ng-content select="shell-header-go-back"></ng-content>
    </div>
    <ng-container *ngFor="let item of menuConfig">
      <ng-container *ngIf="item?.relative; else externalRef">
        <a class="mobile-menu__link mobile-menu__link--main" [attr.href]="'/'">
          {{ item.name }}
        </a>
      </ng-container>
      <ng-template #externalRef>
        <shell-header-link
          class="mobile-menu__link mobile-menu__link--main"
          [link]="item.link"
          [name]="item.name"
          [exact]="item?.exact || false"
        ></shell-header-link>
      </ng-template>
    </ng-container>
  </nav>
  <nav
    class="mobile-menu__nav"
    role="navigation"
    aria-label="Меню пользователя"
  >
    <shell-header-link
      [link]="loginUrl"
      *ngIf="!user"
      class="mobile-menu__link"
    >
      <i class="icon icon-ic_account_circle"></i>
      Войти в личный кабинет
    </shell-header-link>
    <ng-container *ngIf="user">
      <a class="mobile-menu__link mobile-menu__link--main" href="javascript:">
        <i class="icon icon-ic_account_circle"></i>
        <span class="ellipsis">{{ user.data.email || user.data.phone }}</span>
      </a>
      <a
        class="mobile-menu__link mobile-menu__link--main logout-link"
        href="javascript:"
        title="Выйти"
        (click)="signout.emit()"
      >
        Выйти
      </a>
      <ng-container *ngIf="userHasCabinet">
        <shell-header-link
          [link]="'/cabinet'"
          [name]="'Личный кабинет'"
          class="mobile-menu__link mobile-menu__link--main"
        ></shell-header-link>
        <shell-header-link
          [link]="'/cabinet/orders'"
          [name]="'История заказов'"
          class="mobile-menu__link mobile-menu__link--main"
        ></shell-header-link>
      </ng-container>
    </ng-container>
  </nav>
</div>
