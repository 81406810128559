import { Injectable } from '@angular/core';
import { linkToGlobalState } from '@inaripro-nx/common-ui';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  shareReplay,
  skip,
  startWith,
  switchMap,
} from 'rxjs';
import {
  EElementType,
  IElement,
  IElements,
  IUpdateElement,
} from '../../interfaces/editor.interface';
import { EPage } from '../../interfaces/main.interface';
import { ElementNamePipe } from '../../pipes/element-name/element-name.pipe';
import { WindowToolsService } from '../../services/window-tools/window-tools.service';
import { MainStore } from '../main/main.store';
import { ProductStore } from '../product/product.store';
import { v4 as uuidv4 } from 'uuid';

export interface HistoryState {
  history: IElements[];
  historyIndex: number;
  historyFreeze: boolean;
  freezeElements: IElements;
  activeElementIndex: number | null;
}

const initialState: HistoryState = {
  history: [],
  historyIndex: -1,
  historyFreeze: false,
  freezeElements: [],
  activeElementIndex: null,
};

@Injectable({
  providedIn: 'root',
})
export class HistoryStore extends ComponentStore<HistoryState> {
  readonly elements$: Observable<IElements> = this.select(
    (state: HistoryState) =>
      state.historyFreeze
        ? state.freezeElements
        : state.history[state.historyIndex] || []
  );

  readonly uuid$: Observable<string> = this.select(
    this.elements$,
    this.productStore.zoneColors$,
    this.productStore.zonePatterns$,
    this.productStore.zoneTranslates$,
    (elements, zoneColors, zonePatterns, zoneTranslates) => {
      const hasChanges =
        (elements || []).length ||
        (Object.keys(zoneColors) || []).length ||
        (Object.keys(zonePatterns) || []).length ||
        (Object.keys(zoneTranslates) || []).length;

      return hasChanges ? uuidv4() : '';
    }
  );

  readonly useColors$: Observable<string[]> = this.select(
    this.elements$,
    this.productStore.useColors$,
    (elements: IElements, useColors: string[]) => {
      const colors: string[] = [];
      elements.forEach((el) => el.fill && colors.push(el.fill));
      const accColors = [...colors, ...useColors];
      return [...new Set(accColors.map((c) => c.toLocaleLowerCase()))];
    }
  );

  readonly elementsFiltersId$: Observable<(number | null)[]> = this.select(
    this.elements$,
    (elements) =>
      elements
        .filter((e) => !!e.filterId)
        .map((e) => e.filterId)
        .filter((value, index, array) => {
          return array.indexOf(value) === index;
        })
  );

  readonly hasPrev$: Observable<boolean> = this.select(
    (state: HistoryState) => !state.historyFreeze && state.historyIndex > -1
  );

  readonly hasNext$: Observable<boolean> = this.select(
    (state: HistoryState) =>
      !state.historyFreeze && state.historyIndex < state.history.length - 1
  );

  readonly activeElementIndex$: Observable<number | null> = this.select(
    (state: HistoryState) => state.activeElementIndex
  );

  readonly activeElement$: Observable<IElement | null> = combineLatest([
    this.activeElementIndex$.pipe(distinctUntilChanged()),
    this.elements$,
  ]).pipe(
    debounceTime(0),
    map(
      ([activeElementIndex, elements]) =>
        (elements &&
          activeElementIndex !== null &&
          elements[activeElementIndex]) ||
        null
    ),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly hasUnsavedChanges$ = this.productStore.currentTemplate$.pipe(
    switchMap(() =>
      this.elements$.pipe(
        map((elements) => !!elements.length),
        skip(1),
        distinctUntilChanged(),
        startWith(false),
        shareReplay({ refCount: false, bufferSize: 1 })
      )
    )
  );

  readonly setHistoryFreeze = this.updater(
    (state: HistoryState, payload: boolean) => {
      if (state.historyFreeze === payload) {
        return state;
      } else {
        const freezeElements = payload
          ? (state.history[state.historyIndex] || []).slice()
          : [];

        return {
          ...state,
          historyFreeze: payload,
          freezeElements,
        };
      }
    }
  );

  readonly addElement = this.updater(
    (state: HistoryState, payload: { element: IElement }) => {
      return this.historyApplyChanges(state, (elements: IElements) => {
        const element = { ...payload.element };
        this.setElementName(state, element);

        elements.push(element);

        this.setActiveElementIndex({
          activeElementIndex: elements.length - 1,
          showActionsSubMenu: true,
        });
      });
    }
  );

  readonly copyElement = this.updater(
    (state: HistoryState, payload: { index: number; sideIndex: number }) => {
      const { index, sideIndex } = payload;

      return this.historyApplyChanges(state, (elements: IElements) => {
        if (elements[index]) {
          const element = { ...elements[index], sideIndex };

          element.typeIndex = null;
          if (
            element.type === EElementType.figure ||
            element.type === EElementType.image
          ) {
            element.text = null;
          }

          this.setElementName(state, element);

          elements.splice(index + 1, 0, element);
        }

        this.setActiveElementIndex({
          activeElementIndex: null,
        });
      });
    }
  );

  readonly removeElement = this.updater(
    (state: HistoryState, payload: { index: number }) => {
      return this.historyApplyChanges(state, (elements: IElements) => {
        if (elements[payload.index]) {
          elements.splice(payload.index, 1);
        }

        this.setActiveElementIndex({
          activeElementIndex: null,
        });
      });
    }
  );

  readonly updateElement = this.updater(
    (state: HistoryState, payload: IUpdateElement) => {
      return this.historyApplyChanges(state, (elements: IElements) => {
        if (elements[payload.index]) {
          elements.splice(payload.index, 1, payload.element);
        }

        this.setActiveElementIndex({
          activeElementIndex: payload.index,
        });
      });
    }
  );

  readonly updateElements = this.updater(
    (state: HistoryState, payload: { elements: IElements }) => {
      return this.historyApplyChanges(state, (elements: IElements) => {
        elements.splice(0, elements.length, ...payload.elements.slice());

        this.setActiveElementIndex({
          activeElementIndex: null,
        });
      });
    }
  );

  readonly prevHistory = this.updater((state: HistoryState) => {
    if (state.historyFreeze) {
      return state;
    }

    const historyIndex =
      state.historyIndex > -1 ? state.historyIndex - 1 : state.historyIndex;

    this.setActiveElementIndex({
      activeElementIndex: null,
    });

    return {
      ...state,
      historyIndex,
    };
  });

  readonly nextHistory = this.updater((state: HistoryState) => {
    if (state.historyFreeze) {
      return state;
    }

    const historyIndex =
      state.historyIndex < state.history.length - 1
        ? state.historyIndex + 1
        : state.historyIndex;

    this.setActiveElementIndex({
      activeElementIndex: null,
    });

    return {
      ...state,
      historyIndex,
    };
  });

  readonly setActiveElementIndex = this.updater(
    (
      state: HistoryState,
      payload: {
        activeElementIndex: number | null;
        showActionsSubMenu?: boolean;
      }
    ) => {
      const { activeElementIndex, showActionsSubMenu } = payload;

      const elements = state.history[state.historyIndex];
      const element =
        activeElementIndex !== null && elements[activeElementIndex];

      if (element) {
        this.productStore.setActiveDesignSideIndex(element.sideIndex);

        if (!this.windowToolsService.isDesktop$.value) {
          this.mainStore.setPage({ page: EPage.design });
        }
      }

      if (activeElementIndex !== state.activeElementIndex) {
        this.mainStore.setHideActionsSubmenu({
          hideActionsSubmenu: !showActionsSubMenu,
        });
      }

      return {
        ...state,
        activeElementIndex,
      };
    }
  );

  constructor(
    private globalStore: Store,
    private productStore: ProductStore,
    private mainStore: MainStore,
    private windowToolsService: WindowToolsService
  ) {
    super({ ...initialState });
    linkToGlobalState(
      this.state$,
      'libs/painter/HistoryStore',
      this.globalStore
    );
  }

  public resetState() {
    this.setState({ ...initialState });
  }

  private historyApplyChanges(
    state: HistoryState,
    applyChanges: (elements: IElements) => void
  ): HistoryState {
    if (state.historyFreeze) {
      const freezeElements = state.freezeElements.slice();
      applyChanges(freezeElements);

      return {
        ...state,
        freezeElements,
      };
    } else {
      const history = state.history.slice(0, state.historyIndex + 1);

      const elements = history.length
        ? history[history.length - 1].slice()
        : [];
      applyChanges(elements);

      history.push(elements);
      const historyIndex = history.length - 1;

      return {
        ...state,
        history,
        historyIndex,
      };
    }
  }

  private getElementTypesMaxIndex(state: HistoryState): {
    [key in EElementType]: number;
  } {
    return (state.history[state.historyIndex] || []).reduce((obj, element) => {
      if (
        element.typeIndex !== null &&
        (!obj[element.type] ||
          (obj[element.type] && obj[element.type] < element.typeIndex))
      ) {
        obj[element.type] = element.typeIndex;
      }
      return obj;
    }, {} as { [key in EElementType]: number });
  }

  private setElementName(state: HistoryState, element: IElement) {
    if (!element.typeIndex) {
      const indexes = this.getElementTypesMaxIndex(state);
      element.typeIndex =
        indexes[element.type] !== undefined ? indexes[element.type] + 1 : 1;
    }

    if (!element.text) {
      element.text = ElementNamePipe.getElementName(element);
    }
  }
}
