@if (options.length) {
  <div class="title">
    <div class="helper-wrapper helper-wrapper--dashed">
      <design-helper-window
        class="helper"
        [title]="'Тип предложения'"
        [uid]="'type'+uidStateHelper"
        [position]="'auto-bottom'"
        [size]="'xxs'"
      >
        <span class="state-popover__toggle">Тип предложения&nbsp;(?)</span>
        <div class="state-popover-body__content"
             [innerHtml]="'Товары отображаются с учетом указанного параметра. Вы можете выбрать любой тип предложения для отображения на странице или показать все.'"
        ></div>
      </design-helper-window>
    </div>
  </div>

  <div class="buttons" [class.buttons--mobile]="isMobileTypeButtons">
    @for (option of options; track option.id) {
      <button
        type="button"
        class="g-btn-toggle"
        [class.g-btn-toggle--active]="productType === option.value"
        (click)="onClickProductType(option)"
      >
        {{option.label}}
      </button>
    }
  </div>

  @if (!isMobileTypeButtons) {
    <design-popup-select-box
      class="select"
      [activeOption]="selectedOption"
      [options]="options"
      (changeOption)="onChange($event.id)">
    </design-popup-select-box>
  }
}
