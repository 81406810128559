import { CommonModule, DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { clearNgAttributes, getTextFromUrl } from '@inaripro-nx/common-ui';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'design-card-qr-code',
  templateUrl: './card-qr-code.component.html',
  styleUrls: ['./card-qr-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardQrCodeComponent implements OnDestroy {
  @Input() imgSrc!: string;
  @Input() text!: string;

  @Output() selectObject: EventEmitter<{
    text: string;
    url: string;
    svg: string;
    bBox: { width: number; height: number };
  }> = new EventEmitter();
  @Output() cardCloseEmit: EventEmitter<void> = new EventEmitter();
  @Output() imageLoaded: EventEmitter<CardQrCodeComponent> = new EventEmitter();

  @ViewChild('svgGroup') svgGroup!: ElementRef<SVGGraphicsElement>;

  destroyed = false;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnDestroy(): void {
    this.destroyed = true;
  }

  public clickCardClose(event: MouseEvent): void {
    event.stopPropagation();
    this.cardCloseEmit.emit();
  }

  addObject() {
    const el = this.svgGroup.nativeElement;
    const bBox = el.getBBox();

    const clone = clearNgAttributes(el.cloneNode(true) as Element);
    const image = clone.querySelector('image');

    if (!image) {
      return;
    }

    getTextFromUrl(image.getAttribute('xlink:href'), (text) => {
      if (!text || this.destroyed) {
        return;
      }

      const container = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'svg'
      );
      container.innerHTML = text;
      const svg = container.querySelector('svg');

      if (!svg) {
        return;
      }

      svg.setAttribute('width', `${bBox.width}`);
      svg.setAttribute('height', `${bBox.height}`);

      this.selectObject.emit({
        text: this.text,
        svg: svg.outerHTML,
        url: this.imgSrc,
        bBox,
      });
    });
  }
}
