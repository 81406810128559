@if (dictionary[item.dictionaryId]; as dictItem) {
  <div
    class="catalog-item"
    [class.catalog-item--noactive]="!dictItem.isActive"
    [style.background-image]="'url(' + dictItem.logo?.url + ')'"
    [routerLink]="item | catalogGroupLink: rootModule"
  >
    <div class="catalog-item__title">
      <ng-container
        [ngTemplateOutlet]="nameTemplate"
        [ngTemplateOutletContext]="{ item: item, dictItem: dictItem }"
      ></ng-container>
    </div>

    <div
      class="catalog-item__shadow"
      [class.catalog-item__shadow--noactive]="!dictItem.isActive"
    >
      <div
        class="catalog-item__shadow-title"
        [class.catalog-item__shadow-title--noactive]="!dictItem.isActive"
      >
        <ng-container
          [ngTemplateOutlet]="nameTemplate"
          [ngTemplateOutletContext]="{ item: item, dictItem: dictItem }"
        ></ng-container>
      </div>

      <div class="catalog-item__links">
        <ul class="category-list">
          @for (child of item.children | catalogSortByIsActive : dictionary; track child.id) {
            @if (!hasProductDesign || (hasProductDesign && child.hasProductDesign)) {
              <li class="category-list__item">
                <a
                  [routerLink]="child | catalogGroupLink: rootModule"
                  [class.category-list__item-link--noactive]="!dictionary[child.dictionaryId].isActive"
                  [queryParams]="queryParams"
                >
                  {{ dictionary[child.dictionaryId].name }}
                </a>
                @if (child.amount) {
                  <span class="category-list__counter">({{ child.amount }})</span>
                }
              </li>
            }
          }
        </ul>
      </div>
    </div>
  </div>
}

<ng-template #nameTemplate let-item="item" let-dictItem="dictItem">
  <a
    [routerLink]="item | catalogGroupLink: rootModule"
    [queryParams]="queryParams"
  >
    @if (!dictItem.isActive) {<i class="icon icon-ic_block"></i>} {{ dictItem.name }}
  </a>
</ng-template>
