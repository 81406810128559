@if (items && selectedItems.length) {
  <div class="selectbox-selected-list">
    <ng-container *ngTemplateOutlet="showSelectedListEl"></ng-container>
  </div>
}

<ng-template #showSelectedListEl>
  @for (select of selectedItems; track select.id) {
    <div class="selectbox-selected-list__el">
      <span class="title">{{ select.name }}</span>
      <span
        class="icon-wrapper"
        (click)="unSelectClick(select.id)"
      >
          <i class="icon icon-ic_close"></i>
      </span>
    </div>
  }
</ng-template>
