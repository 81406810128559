import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { PipesModule } from '@inaripro-nx/common-ui';
import {
  DEFAULT_TEXT_SIZE,
  ITextAnchor,
  ITextElement,
} from '../../../../interfaces/editor.interface';
import { getTextStyledGroup } from '../../../../../../utils/svg.utils';

@Component({
  selector: '[data-painter-editor-element-text-multiline]',
  standalone: true,
  imports: [CommonModule, PipesModule],
  templateUrl: './editor-element-text-multiline.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorElementTextMultilineComponent implements OnInit, OnDestroy {
  private _el!: ITextElement;
  @Input() set el(el: ITextElement) {
    this._el = el;
    this.linesDx = this.getLineDx(el);
  }
  get el() {
    return this._el;
  }

  linesDx: number[] = [];

  protected readonly DEFAULT_TEXT_SIZE = DEFAULT_TEXT_SIZE;

  private destroyed = false;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.document.fonts.ready.then(() => {
      if (this.destroyed) {
        return;
      }
      this.linesDx = this.getLineDx(this.el);
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroyed = true;
  }

  private getLineDx(el: ITextElement): number[] {
    const lines = el.text.split('\n');

    if (el.textAnchor === ITextAnchor.start) {
      return lines.map(() => 0);
    }

    const { svgG, destroy } = getTextStyledGroup(this.document, this.el);

    const linesLength = lines.map((line) => {
      svgG.innerHTML = `<text><tspan>${line}</tspan></text>`;
      return svgG.getBBox().width;
    });

    destroy();

    const maxLength = Math.max(...linesLength);

    return linesLength.map((length) => {
      if (el.textAnchor === ITextAnchor.middle) {
        return (maxLength - length) / 2;
      } else {
        return maxLength - length;
      }
    });
  }
}
