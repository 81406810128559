<div class="no-products">
  <div class="no-products__img">
    <img src="./assets/images/robot-with-buckets.jpg" alt="">
  </div>
  <div class="no-products__info">
    Нет товаров соответствующих установленным фильтрам.<br/>
    Попробуйте изменить критерии поиска.
  </div>
  <div class="no-products__exist-filters">
    <ng-container [ngTemplateOutlet]="appliedFiltersTemplate"></ng-container>
  </div>
  <div class="no-products__reset-filters">
    <button
      type="button"
      class="g-btn-clear"
      (click)="resetFilterValues.emit()"
    >
      <span>Сбросить все фильтры</span>
    </button>
  </div>
</div>
