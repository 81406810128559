<div
  class="range-gradient-slider"
  [attr.style]="'--bg:' + color | sanitize : ESafeType.STYLE"
>
  <input
    type="range"
    class="range-gradient-slider__range"
    [formControl]="sliderControl"
    [value]="currentValue"
    [min]="minValue"
    [max]="maxValue"
    (pointerup)="stopChangeEvent($event)"
    [ngStyle]="{
          'background-image': 'url(\'./assets/images/transparent3.svg\')'
        }"
  />
</div>
