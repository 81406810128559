<catalog-product-list-filter-wrapper
  [prop]="prop"
  [title]="prop.alias"
  [isApply]="filterValues.typeFilters[prop.id] | objectValues | someEqual: true"
  (clearFilter)="clearFilter.emit($event)"
>
  <design-simple-select-box
    [items]="prop.typeElements || []"
    [selected]="filterValues.typeFilters[prop.id]"
    [placeholder]="prop.alias"
    [animatePlaceholder]="false"
    [submitDisabled]="submitDisabled"
    (toggle)="toggle.emit($event)"
    (selectAll)="selectAll.emit()"
    (clearAll)="clearAll.emit()"
    (changeModel)="applyFilterValues.emit()"
    [withChangeModel]="false"
  ></design-simple-select-box>
</catalog-product-list-filter-wrapper>
