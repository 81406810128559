<span
  class="title"
  [class.title--animated]="animatePlaceholder"
>
    <span>{{ placeholder }}</span>
    <small>({{ count || 0 }})</small>
</span>

<input
  #searchInput
  type="text"
  class="search-input"
  [formControl]="searchControl "
  [hidden]="!search"
  (input)="onInput($event)"
>

<span
  class="icon-wrapper"
  (click)="toggle($event)"
>
    <i
      #icon
      class="icon icon-ic_keyboard_arrow_down"
    ></i>
</span>
