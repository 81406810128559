import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IMapOfBoolean } from '@inaripro-nx/common-ui';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { IDesignSimpleSelectboxListItem } from '../../../../interfaces';

@Component({
  selector: 'design-simple-selectbox-list',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './design-simple-selectbox-list.component.html',
  styleUrl: './design-simple-selectbox-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignSimpleSelectboxListComponent implements OnChanges {
  @Input() items!: IDesignSimpleSelectboxListItem[];
  @Input() filterItems!: IDesignSimpleSelectboxListItem[];
  @Input() selected!: IMapOfBoolean;

  @Output() toggleSelect: EventEmitter<IDesignSimpleSelectboxListItem> =
    new EventEmitter();

  listItems?: IDesignSimpleSelectboxListItem[];

  readonly FORM_ID = 'design-tree-view';

  formGroup!: FormGroup;

  get formData(): FormArray {
    return this.formGroup.get('formData') as FormArray;
  }

  constructor(private formBuilder: FormBuilder) {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['items']) {
      this.listItems = (this.items || []).slice();
      this.initForm();
    }

    if (
      simpleChanges['filterItems'] &&
      simpleChanges['filterItems'].currentValue
    ) {
      this.listItems = (this.filterItems || []).slice();
      this.initForm();
    }
  }

  private initForm() {
    this.formGroup = this.formBuilder.group({
      formData: new FormArray([]),
    });
    (this.listItems || []).forEach((item) =>
      this.formData.push(new FormControl(item))
    );
  }

  public toggleSelected(
    item: IDesignSimpleSelectboxListItem,
    event?: Event
  ): void {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    this.toggleSelect.emit(item);
  }
}
