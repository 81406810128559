@if (filters && filterValues) {
  @if (filterValues.priceFilter && (filterValues.priceFilter.minValue || filterValues.priceFilter.maxValue)) {
    <button type="button" class="g-btn-toggle" (click)="removePriceFilter.emit()">
      <span>
        От {{filterValues.priceFilter.minValue}}
        до {{filterValues.priceFilter.maxValue}} Р
      </span>
      <i class="icon icon-ic_close"></i>
    </button>
  }

  @if (filterValues.applyAllStock) {
    <button type="button" class="g-btn-toggle" (click)="removeApplyAllStockFilter.emit()">
      <span>На складе</span>
      <i class="icon icon-ic_close"></i>
    </button>
  }

  @for (prop of filters.characteristics; track prop.id) {
    @if (filterValues.typeFilters[prop.id] | countSelectedProperty : 0 : '>') {
      @if (filterValues.typeFilters[prop.id] | countSelectedProperty : MAX_COUNT_PROPERTY : '>=') {
        <button
          type="button"
          class="g-btn-toggle"
          (click)="removeFilter.emit(prop.id)"
        >
          <span>{{prop.alias}}</span>
          <i class="icon icon-ic_close"></i>
        </button>
      } @else {
        <design-simple-select-box
          class="select-box-selected"
          [items]="prop.typeElements || []"
          [selected]="filterValues.typeFilters[prop.id]"
          [isUseWrapperForSelectedList]="false"
          (toggle)="toggle(prop.id, $event, filterValues)"
          (clearAll)="removeFilter.emit(prop.id)"
        ></design-simple-select-box>
      }
    }
  }
}
