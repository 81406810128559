import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { PipesModule as CommonPipesModule } from '@inaripro-nx/common-ui';
import { ITextElement } from '../../../../interfaces/editor.interface';
import { PipesModule } from '../../../../pipes/pipes.module';
import { EditorElementTextMultilineComponent } from '../editor-element-text-multiline/editor-element-text-multiline.component';
import { EditorElementTextArcComponent } from '../editor-element-text-arc/editor-element-text-arc.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[data-painter-editor-element-text-content]',
  standalone: true,
  imports: [
    CommonPipesModule,
    PipesModule,
    EditorElementTextArcComponent,
    EditorElementTextMultilineComponent,
  ],
  templateUrl: './editor-element-text-content.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorElementTextContentComponent {
  @Input() el!: ITextElement;
  @Input() index!: number;
  @Input() inAction?: boolean;
}
