import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { IPublicProductForList } from '../../interfaces/catalog-product.interface';
import { CatalogProductCardComponent } from '../catalog-product-card/catalog-product-card.component';
import { IBannerImage } from '@inaripro-nx/common-ui';
import { BannerComponent } from '@inaripro-nx/design-ui';
import { IFiltersValues } from '../../interfaces';
import { CatalogProductListNotFoundComponent } from '../catalog-product-list-not-found/catalog-product-list-not-found.component';
import { AuthService } from '@inaripro-nx/auth';

@Component({
  selector: 'catalog-product-list-content',
  standalone: true,
  imports: [
    CommonModule,
    CatalogProductCardComponent,
    BannerComponent,
    CatalogProductListNotFoundComponent,
  ],
  templateUrl: './catalog-product-list-content.component.html',
  styleUrls: ['./catalog-product-list-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogProductListContentComponent {
  @Input() productList: IPublicProductForList[] | null = null;
  @Input() filterValues: IFiltersValues | null = null;
  @Input() range = 1;
  @Input() banners: IBannerImage[] | null = null;
  @Input() appliedFiltersTemplate: TemplateRef<unknown> | null = null;
  @Input() publicBaseUrl = '';
  @Input() selectedProductId?: number | null = null;

  @Output() resetFilterValues = new EventEmitter<void>();
  @Output() selectProduct = new EventEmitter<{
    product: IPublicProductForList;
    filterValues: IFiltersValues | null;
    range: number;
  }>();

  readonly user$ = this.authService.user$;

  constructor(private readonly authService: AuthService) {}
}
