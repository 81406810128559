import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RangeSliderWrapperComponent } from '../../../../../../../../../design-ui/src/lib/components/range-slider-wrapper/range-slider-wrapper.component';
import { DECIMAL_POINTS_STROKE } from '@inaripro-nx/painter';
import { IElement } from '../../../../interfaces/editor.interface';
import { ActionsElementRotateComponent } from '../actions-element-rotate/actions-element-rotate.component';
import { ActionsElementStrokeWidthComponent } from '../actions-element-stroke-width/actions-element-stroke-width.component';
import { ModalWindowStore } from '@inaripro-nx/design-ui';

@Component({
  selector: 'painter-actions-element-stroke',
  standalone: true,
  imports: [
    CommonModule,
    RangeSliderWrapperComponent,
    ActionsElementRotateComponent,
    ActionsElementStrokeWidthComponent,
  ],
  templateUrl: './actions-element-stroke.component.html',
  styleUrl: './actions-element-stroke.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsElementStrokeComponent {
  @Input() element!: IElement;
  @Input() sliderTemplate: TemplateRef<unknown> | null = null;
  @Input() minValue!: number;
  @Input() maxValue!: number;
  @Input() modalUid!: string;
  @Input() modificator!: 'stroke-text' | 'stroke-figure';
  @Input() prefix!: string;

  readonly DECIMAL_POINTS_STROKE = DECIMAL_POINTS_STROKE;

  constructor(private readonly modalWindowStore: ModalWindowStore) {}

  openModalStroke() {
    this.modalWindowStore.patch({ [this.modalUid]: true });
  }
}
