import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppShellConfig } from '@inaripro-nx/common-ui';
import { DesignUiModule, ModalWindowStore } from '@inaripro-nx/design-ui';
import { filter, map, startWith } from 'rxjs';
import { ProductStore } from '../../../../../../state/product/product.store';
import { ContentProductModalsComponent } from '../content-product-modals/content-product-modals.component';
import { MainService } from '../../../../../../services/main/main.service';

@Component({
  selector: 'painter-content-product-navigation',
  standalone: true,
  imports: [CommonModule, DesignUiModule, ContentProductModalsComponent],
  templateUrl: './content-product-navigation.component.html',
  styleUrls: ['./content-product-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentProductNavigationComponent {
  readonly MODAL_UID_PROPERTIES =
    '[ContentProductComponent] MODAL_UID_PROPERTIES';

  readonly MODAL_UID_DESCRIPTION =
    '[ContentProductComponent] MODAL_UID_DESCRIPTION';

  readonly MODAL_UID_GALLERY = '[ContentProductComponent] MODAL_UID_GALLERY';

  readonly product$ = this.productStore.product$;
  readonly galleryImages$ = this.productStore.galleryImages$;
  readonly currentImageId$ = this.productStore.currentImageId$;
  readonly propertiesInfo$ = this.productStore.propertiesInfo$;
  readonly productLink$ = this.productStore.product$.pipe(
    filter(Boolean),
    map((product) => `/catalog/product/${product?.id}/`),
    startWith('/catalog')
  );

  get config() {
    return this.mainService.config;
  }

  constructor(
    private readonly mainService: MainService,
    private readonly productStore: ProductStore,
    private readonly modalWindowStore: ModalWindowStore
  ) {}

  public openModal(modalUid: string): void {
    this.modalWindowStore.patch({ [modalUid]: true });
  }

  public closeModal(modalUid: string): void {
    this.modalWindowStore.patch({ [modalUid]: false });
  }
}
