<svg:rect
  [attr.x]="area.start?.x"
  [attr.y]="area.start?.y"
  [attr.width]="area.size?.x"
  [attr.height]="area.size?.y"
  style="
    fill: none;
    stroke-width: 1px;
    vector-effect: non-scaling-stroke;
  "
  [style.stroke]="colors[0]"
/>
<svg:rect
  [attr.x]="area.start?.x"
  [attr.y]="area.start?.y"
  [attr.width]="area.size?.x"
  [attr.height]="area.size?.y"
  style="
    fill: none;
    stroke-width: 1px;
    stroke-dasharray: 3 3;
    vector-effect: non-scaling-stroke;
  "
  [style.stroke]="colors[1]"
/>
<ng-content></ng-content>
