<painter-actions-element-stroke-width
  [element]="element"
  [minValue]="minValue"
  [maxValue]="maxValue"
  [sliderTemplate]="sliderTemplate"
  [modalUid]="modalUid"
  [modificator]="modificator"
  [prefix]="prefix"
>
  <ng-content></ng-content>
</painter-actions-element-stroke-width>
<ng-template
  #sliderTemplate
  let-minValue="minValue"
  let-maxValue="maxValue"
  let-value="value"
  let-changeValue="changeValue"
  let-stopChange="stopChange"
  let-reset="reset"
>
  <div class="wrapper">
    <design-range-slider-wrapper
      [label]="'Обводка'"
      [unitmeasurement]="''"
      [minValue]="minValue"
      [maxValue]="maxValue"
      [value]="value"
      [decimalPoints]="DECIMAL_POINTS_STROKE"
      [isShowInputValue]="false"
      (changeValue)="changeValue($event)"
      (stopChange)="stopChange()"
      (resetValue)="reset()"
    ></design-range-slider-wrapper>
    <div
      class="current-color"
      [ngStyle]="{ 'background-color': element.stroke || null }"
      (click)="openModalStroke()"
    ></div>
  </div>
</ng-template>
