<svg
  *ngIf="zone.size && zone.start"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  preserveAspectRatio="xMidYMid meet"
  [attr.viewBox]="zone.size | viewbox"
  [attr.width]="zone.sizeMM.x + 'mm'"
  [attr.height]="zone.sizeMM.y + 'mm'"
>
    <!-- Рабочая область редактора -->
    <defs *ngIf="fontFaces.length">
      <style
        *ngFor="let ff of fontFaces"
        [innerHTML]="ff"
      ></style>
    </defs>

    <defs>
      <clipPath id="mask1">
        <rect
          [attr.x]="0"
          [attr.y]="0"
          [attr.width]="zone.size.x"
          [attr.height]="zone.size.y"
        />
      </clipPath>
    </defs>

    <!-- Область фоновой заливки -->
    <g *ngIf="zone.hasColorPalette">
      <g *ngIf="zonePattern as pattern">
        <image
          x="0"
          y="0"
          [attr.width]="zone.size.x"
          [attr.height]="zone.size.y"
          [attr.xlink:href]="pattern.url"
          preserveAspectRatio="xMidYMid slice"
        ></image>
      </g>
    </g>

    <!-- Фон области нанесения -->
    <g
      data-painter-editor-zone-paint
      [zone]="zone"
      [zoneColor]="zoneColor"
      [zonePattern]="zonePattern"
      [isZoneExport]="true"
    ></g>

    <!-- Объекты дизайна -->
    <g clip-path="url(#mask1)">
      <g [attr.transform]="zone0Center | svgTranslate">
        <ng-container *ngFor="let el of elements; let i = index">
          <ng-container *ngIf="el.sideIndex === sideIndex">
            <g data-painter-editor-element-transform [el]="el">
              <g data-painter-editor-element-content [el]="el" [index]="i" [filter]="el.filterId && filters[el.filterId]"></g>
            </g>
          </ng-container>
        </ng-container>
      </g>
    </g>
</svg>
