import { Pipe, PipeTransform } from '@angular/core';

interface IXYb {
  x: boolean;
  y: boolean;
}
interface IXY {
  x: number;
  y: number;
}

@Pipe({
  name: 'svgFlip',
})
export class SvgFlipPipe implements PipeTransform {
  transform(el: { flip: IXYb; scale: IXY; size: IXY }): string {
    const { flip, scale, size } = el;

    const fScale = {
      x: (flip.x ? -1 : 1) * scale.x,
      y: (flip.y ? -1 : 1) * scale.y,
    };

    const fTranslate = {
      x: +flip.x * -size.x,
      y: +flip.y * -size.y,
    };

    return ` scale(${fScale.x} ${fScale.y}) translate(${fTranslate.x}, ${fTranslate.y})`;
  }
}
