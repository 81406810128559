import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'design-simple-selectbox-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './design-simple-selectbox-input.component.html',
  styleUrl: './design-simple-selectbox-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignSimpleSelectboxInputComponent implements OnChanges {
  @Input() placeholder = '';
  @Input() animatePlaceholder = false;
  @Input() count = 0;
  @Input() search = false;

  @Output() searchOut = new EventEmitter<string>();
  @Output() toggleOut = new EventEmitter<void>();

  @ViewChild('searchInput', { static: false })
  searchInput?: ElementRef<HTMLInputElement>;
  @ViewChild('icon', { static: false }) icon?: ElementRef<HTMLElement>;

  searchControl = new FormControl('');

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['search'] && simpleChanges['search'].currentValue) {
      this.cdr.detectChanges();
      this.searchInput?.nativeElement.focus();
    }
  }

  public toggle(event: MouseEvent | TouchEvent): void {
    event.preventDefault();
    this.toggleOut.emit();
  }

  public clear(): void {
    this.searchControl.setValue('');
  }

  public onInput(evt: Event) {
    this.searchOut.emit((evt.target as HTMLInputElement).value);
  }
}
