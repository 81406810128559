import { Pipe, PipeTransform } from '@angular/core';
import { IMapOfBoolean } from '@inaripro-nx/common-ui';

@Pipe({
  name: 'countSelectedProperty',
  standalone: true,
})
export class CountSelectedPropertyPipe implements PipeTransform {
  transform(
    value: IMapOfBoolean | undefined,
    count: number,
    operator: '>' | '<' | '>=' | '<=' | '==='
  ): boolean {
    let size = 0;
    const _value: IMapOfBoolean = value || {};
    Object.keys(_value).forEach((key) => {
      if (_value[key]) {
        size++;
      }
    });

    let result;
    switch (operator) {
      case '>':
        result = size > count;
        break;
      case '<':
        result = size < count;
        break;
      case '>=':
        result = size >= count;
        break;
      case '<=':
        result = size <= count;
        break;
      case '===':
        result = size === count;
        break;
    }

    return result;
  }
}
