import { IFilterMinMax, IMapNumberOfBoolean } from '@inaripro-nx/common-ui';
import { IProperty } from './catalog-product-properties.interface';
import { IPropertyFilter } from './catalog-product.interface';
import { ProductTypeEnum } from '../enums/catalog-product.enum';

export interface IPropertyTypeElementsMap {
  [id: string]: IMapNumberOfBoolean;
}

export interface IPropertyFilterMap {
  [name: string]: IPropertyFilter | null;
}

export interface IFiltersValues {
  typeFiltersSelectedAll: IMapNumberOfBoolean;
  typeFilters: IPropertyTypeElementsMap;
  valueFilters: IPropertyFilterMap;
  priceFilter: IFilterMinMax | null;
  productType: ProductTypeEnum | null;
  applyCityStock: boolean | null;
  applyAllStock: boolean | null;
}

export interface IPropertyForFilter extends IProperty {
  minValue: number;
  maxValue: number;
}

export interface IProductFiltersRequest {
  nomenclature: number;
  range: number | null;
  companies?: number[];
  userCityId: number | null;
  hasDesigner: boolean;
}

export interface IProductFilters {
  id: number;
  nomenclatureId: number;
  price: IFilterMinMax;
  characteristics: IPropertyForFilter[];
  hasCityStock: boolean;
  hasAllStocks: boolean;
}

export interface IPublicProductFilterCity {
  id: number;
  name: string;
  ext_id: number;
  fias_id: string;
  type: string;
  useTotal: number;
}

export interface IPublicProductFilterCompany {
  id: number;
  name: string;
  amount: number;
}
