<ng-container *ngIf="{ isDesktop: isDesktop$ | async } as state">
  <painter-main-desktop
    *ngIf="state.isDesktop; else mobileView"
  ></painter-main-desktop>
  <ng-template #mobileView>
    <ng-container *ngIf="{
      isShowRotateDevice: isShowRotateDevice$ | async
    } as subState">
      <div [ngClass]="{
         'show' : subState.isShowRotateDevice,
         'no-show': !subState.isShowRotateDevice
        }"
      >
        <painter-rotate-device></painter-rotate-device>
      </div>
      <div [ngClass]="{
         'show' : !subState.isShowRotateDevice,
         'no-show': subState.isShowRotateDevice
        }"
      >
        <painter-main-mobile></painter-main-mobile>
      </div>
    </ng-container>
  </ng-template>

  <div [hidden]="true">
    <ng-container #export></ng-container>
  </div>

  <pictures-modal-ai
    (save)="onSaveAI()"
  ></pictures-modal-ai>

  <pictures-modal-reach-file-limit
    #modalReachFileLimit
    [loginUrl]="loginUrl"
  ></pictures-modal-reach-file-limit>

  <painter-modal-template></painter-modal-template>
  <painter-modal-download-pdf></painter-modal-download-pdf>
  <painter-modal-save-changes></painter-modal-save-changes>
  <painter-modal-crop [isDesktop]="state.isDesktop!"></painter-modal-crop>
</ng-container>
