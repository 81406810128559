import { Injectable } from '@angular/core';
import { AuthService } from '@inaripro-nx/auth';
import { IProductTemplate } from '@inaripro-nx/painter';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  combineLatest,
  concatMap,
  debounceTime,
  filter,
  map,
  mergeMap,
  of,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { ProductTemplatesService } from '../../services/product-templates/product-templates.service';
import { ProductService } from '../../services/product/product.service';

export interface ProductTemplatesState extends EntityState<IProductTemplate> {
  currentId: number | null;
}

export const adapter = createEntityAdapter<IProductTemplate>({
  sortComparer: (a: IProductTemplate, b: IProductTemplate) => b.id - a.id,
});

const initialState: ProductTemplatesState = adapter.getInitialState({
  currentId: null,
});

const { selectAll, selectEntities } = adapter.getSelectors();

@Injectable()
export class ProductTemplatesStore extends ComponentStore<ProductTemplatesState> {
  collection$ = this.select(selectAll);
  dictionary$ = this.select(selectEntities);

  savedTemplate$: Subject<IProductTemplate | null> =
    new Subject<IProductTemplate | null>();

  errorCannotRemoveTemplate$: Subject<string | null> = new Subject<
    string | null
  >();

  isProductOwner$: Observable<boolean> = combineLatest([
    this.productService.product$.pipe(startWith(null)),
    this.authService.user$.pipe(startWith(null)),
  ]).pipe(
    map(
      ([product, user]) =>
        !!(
          product &&
          user?.companyId &&
          +user.companyId === product.company?.id
        )
    )
  );

  loadCollection = this.effect((trigger$) =>
    trigger$.pipe(
      switchMap(() =>
        combineLatest([
          this.productService.product$.pipe(startWith(null)),
          this.isProductOwner$.pipe(startWith(null)),
        ])
      ),
      debounceTime(0),
      filter(([product]) => !!product),
      switchMap(([product, isProductOwner]) =>
        product
          ? this.collectionService.all(product.id, !!isProductOwner).pipe(
              tap((collection) => {
                this.setState((state) => adapter.setAll(collection, state));
              }),
              catchError(() => EMPTY)
            )
          : of([])
      )
    )
  );

  addModel = this.effect((model$: Observable<Partial<IProductTemplate>>) =>
    model$.pipe(
      concatMap((newModel) =>
        this.collectionService.add(newModel).pipe(
          tap((model) => {
            this.setState((state) => adapter.addOne(model, state));
            this.savedTemplate$.next(model);
          }),
          catchError(() => {
            this.savedTemplate$.next(null);
            return EMPTY;
          })
        )
      )
    )
  );

  updateModel = this.effect(
    (
      model$: Observable<{
        template: IProductTemplate;
        triggerToSaved: boolean;
      }>
    ) =>
      model$.pipe(
        concatMap((updateModel) =>
          this.collectionService.update(updateModel.template).pipe(
            tap((model) => {
              this.errorCannotRemoveTemplate$.next(null);
              this.setState((state) =>
                adapter.updateOne({ id: model.id, changes: model }, state)
              );

              if (updateModel.triggerToSaved) {
                this.savedTemplate$.next(model);
              }
            }),
            catchError((err) => {
              try {
                if (err.error[0].productsWithTemplate) {
                  this.errorCannotRemoveTemplate$.next(
                    'Шаблон нельзя деактивировать пока он привязан к товару'
                  );
                }
              } catch (e) {
                //
              }

              this.setState((state) =>
                adapter.updateOne(
                  { id: updateModel.template.id, changes: {} },
                  state
                )
              );

              if (updateModel.triggerToSaved) {
                this.savedTemplate$.next(null);
              }

              return EMPTY;
            })
          )
        )
      )
  );

  deleteModel = this.effect((modelId$: Observable<number>) =>
    modelId$.pipe(
      tap((modelId) =>
        this.setState((state) =>
          adapter.updateOne(
            { id: modelId, changes: { markForDelete: true } },
            state
          )
        )
      ),
      mergeMap((modelId) =>
        this.collectionService.delete(modelId).pipe(
          tap(() => {
            this.errorCannotRemoveTemplate$.next(null);
            this.setState((state) =>
              adapter.removeOne(modelId, {
                ...state,
                currentId: state.currentId !== modelId ? state.currentId : null,
              })
            );
          }),
          catchError((err) => {
            try {
              if (err.error[0].productsWithTemplate) {
                this.errorCannotRemoveTemplate$.next(
                  'Шаблон нельзя удалить пока он привязан к товару'
                );
              }
            } catch (e) {
              //
            }

            this.setState((state) =>
              adapter.updateOne(
                { id: modelId, changes: { markForDelete: false } },
                state
              )
            );

            return EMPTY;
          })
        )
      )
    )
  );

  constructor(
    private collectionService: ProductTemplatesService,
    private productService: ProductService,
    private readonly authService: AuthService
  ) {
    super({ ...initialState });
  }

  init() {
    this.loadCollection();
  }
}
