<ng-container
  [ngTemplateOutlet]="sliderTemplate || defaultSliderTemplate"
  [ngTemplateOutletContext]="{
    minValue: minValue,
    maxValue: maxValue,
    value: elementScale,
    changeValue: changeValue.bind(this),
    stopChange: stopChange.bind(this),
    reset: reset.bind(this)
  }"
></ng-container>

<ng-template
  #defaultSliderTemplate
  let-minValue="minValue"
  let-maxValue="maxValue"
  let-value="value"
  let-changeValue="changeValue"
  let-stopChange="stopChange"
  let-reset="reset"
>
  <design-range-slider-wrapper
    [label]="'Размер'"
    [unitmeasurement]="'%'"
    [minValue]="minValue"
    [maxValue]="maxValue"
    [value]="value"
    [decimalPoints]="DECIMAL_POINTS_SCALE"
    (changeValue)="changeValue($event)"
    (stopChange)="stopChange()"
    (resetValue)="reset()"
  ></design-range-slider-wrapper>
</ng-template>
