import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import {
  ISortOption,
  ISortRequest,
  PAGINATION_ORDER_BY,
  PAGINATION_ORDER_DIRECTION,
} from '@inaripro-nx/common-ui';

@Component({
  selector: 'catalog-product-list-content-filter-sort',
  standalone: true,
  imports: [DesignUiModule],
  templateUrl: './catalog-product-list-content-filter-sort.component.html',
  styleUrl: './catalog-product-list-content-filter-sort.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogProductListContentFilterSortComponent implements OnChanges {
  @Input() sortRequest: ISortRequest | null = null;

  @Output() changeOption = new EventEmitter<ISortOption>();

  readonly options: ISortOption[] = [
    {
      id: 1,
      label: 'По умолчанию',
      value: PAGINATION_ORDER_BY.DEFAULT,
      direction: PAGINATION_ORDER_DIRECTION.DESC,
    },
    {
      id: 2,
      label: 'По популярности',
      value: PAGINATION_ORDER_BY.POPULARITY,
      direction: PAGINATION_ORDER_DIRECTION.DESC,
    },
    {
      id: 3,
      label: 'Цена по возрастанию',
      value: PAGINATION_ORDER_BY.PRICE,
      direction: PAGINATION_ORDER_DIRECTION.ASC,
    },
    {
      id: 4,
      label: 'Цена по убыванию',
      value: PAGINATION_ORDER_BY.PRICE,
      direction: PAGINATION_ORDER_DIRECTION.DESC,
    },
  ];

  selectedOption = this.options[0];

  ngOnChanges(changes: SimpleChanges) {
    if ('sortRequest' in changes && this.sortRequest) {
      this.selectedOption =
        this.options.find(
          (o) =>
            o.value === this.sortRequest?.order_by &&
            o.direction === this.sortRequest?.order_direction
        ) || this.options[0];
    }
  }

  onChange(id: number) {
    const option = this.options.find((o) => o.id === id);
    if (option) {
      this.selectedOption = option;
      this.changeOption.emit(option);
    }
  }
}
