import { Injectable } from '@angular/core';
import { AppShellConfig, AppShellLinkTypeEnum } from '@inaripro-nx/common-ui';
import { ComponentStore } from '@ngrx/component-store';
import { environment } from '../../../environments/environment';

export interface DesignerAppShellState {
  config: AppShellConfig;
}

const initialState: DesignerAppShellState = {
  config: {
    designerRoot: environment.designerRoot,
    publicUrl: environment.publicBaseUrl,
    publicLinkType: AppShellLinkTypeEnum.External,
    isProduction: environment.production,
  },
};

@Injectable()
export class DesignerAppShellStore extends ComponentStore<DesignerAppShellState> {
  readonly appShellConfig$ = this.select((state) => state.config);

  constructor() {
    super({ ...initialState });
  }
}
