import { Injectable } from '@angular/core';
import { IPricesRange } from '@inaripro-nx/catalog';
import { IOrderProductSource, OrderCartStore } from '@inaripro-nx/order';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  Observable,
  shareReplay,
} from 'rxjs';
import { HistoryStore } from '../../state/history/history.store';
import { ProductStore } from '../../state/product/product.store';

@Injectable({
  providedIn: 'root',
})
export class OrderDesignProductService implements IOrderProductSource {
  readonly updatingPrice$ = this.productStore.updatingPrice$;
  readonly publicPrice$ = this.productStore.price$;
  readonly countWithBasket$ = this.productStore.countWithBasket$;
  readonly orderCount$ = this.productStore.orderCount$;

  readonly priceRangeIndex$: Observable<number> = combineLatest([
    this.publicPrice$,
    this.countWithBasket$,
  ]).pipe(
    map(([publicPrice, countWithBasket]) => {
      if (!publicPrice) {
        return 0;
      }

      const index = (publicPrice.ranges || []).findIndex((r) => {
        return (
          (r.upper &&
            countWithBasket >= r.lower &&
            countWithBasket <= r.upper) ||
          (!r.upper && countWithBasket >= r.lower)
        );
      });

      return index > -1 ? index : 0;
    }),
    distinctUntilChanged(),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly priceRange$: Observable<IPricesRange> = combineLatest([
    this.publicPrice$,
    this.priceRangeIndex$,
  ]).pipe(
    debounceTime(0),
    map(([publicPrice, priceRangeIndex]) => {
      return (publicPrice?.ranges || [])[priceRangeIndex] || null;
    }),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly priceBaseValue$: Observable<number | null> = combineLatest([
    this.publicPrice$,
    this.priceRangeIndex$,
  ]).pipe(
    map(([publicPrice, priceRangeIndex]) => {
      return (publicPrice?.baseCost.vs || [])[priceRangeIndex] || null;
    }),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly priceDiscountValue$: Observable<number | null> = combineLatest([
    this.publicPrice$,
    this.priceRangeIndex$,
  ]).pipe(
    map(([publicPrice, priceRangeIndex]) => {
      return (publicPrice?.discountCost?.vs || [])[priceRangeIndex] || null;
    }),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly lotSize$: Observable<number | undefined> =
    this.productStore.product$.pipe(
      map((product) => product?.lotSize),
      shareReplay({ refCount: false, bufferSize: 1 })
    );

  readonly minRange$: Observable<number | undefined> =
    this.productStore.product$.pipe(
      map((product) => product?.minRange),
      shareReplay({ refCount: false, bufferSize: 1 })
    );

  readonly mergeProductStatus$ = this.orderStore.mergeProductStatus$;
  readonly isAddButtonDisabled$ = this.orderStore.isAddButtonDisabled$;

  readonly updatingProduct$ = merge(
    this.productStore.activeDesignProduct$.pipe(map(() => true)),
    this.productStore.zoneColors$.pipe(map(() => true)),
    this.productStore.zonePatterns$.pipe(map(() => true)),
    this.productStore.zoneTranslates$.pipe(map(() => true)),
    this.historyStore.elements$.pipe(map(() => true)),
    this.updatingPrice$
  );

  constructor(
    private readonly productStore: ProductStore,
    private readonly orderStore: OrderCartStore,
    private readonly historyStore: HistoryStore
  ) {}

  orderProduct() {
    this.productStore.orderProduct$.next();
  }

  setOrderCount(count: number) {
    this.productStore.setOrderCount(count);
  }
}
