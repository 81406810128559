@if (maxWidthModalStroke$ | async; as maxWidthModalStroke) {
  <design-colorpicker
    [modalTitle]="EColorTitle.COLOR_STROKE"
    [inColor]="element.stroke"
    (changeModel)="changeModelStroke($event)"
    (changeEnd)="changeEndStroke()"
    [eyeColor]="eyeDropperService.eyeColor$ | async"
    (startEye)="eyeDropperService.startEye($event)"
    [isDesktop]="isDesktop$ | async"
    [resizeSafari$]="actionsTextService.resizeSafari$"
    [modificator]="modificator"
    [prefix]="prefix"
    [useColors]="useColors$ | async"
    [colorDefault]="ELEMENT_STROKE_COLOR_DEFAULT"
    [withModal]="true"
    [modalUid]="modalUid"
    [maxWidthModal]="maxWidthModalStroke"
  >
    <ng-content></ng-content>
  </design-colorpicker>
}
