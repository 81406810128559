import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';
import {
  getDistanceBetweenTouches,
  isCtrl,
  preventDefault,
} from '@inaripro-nx/common-ui';
import {
  EMPTY,
  Subscription,
  combineLatest,
  distinctUntilChanged,
  exhaustMap,
  filter,
  fromEvent,
  map,
  merge,
  scan,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { CropStore, IXY, MAX_ZOOM, MIN_ZOOM } from '../store/crop';

const TOUCH_SENSITIVITY = 0.01;
const WHEEL_SENSITIVITY = 0.001;

@Directive({
  selector: '[cropModalWorkArea]',
  standalone: true,
})
export class CropModalWorkAreaDirective implements AfterViewInit, OnDestroy {
  @Input() cropModalWorkAreaContainer!: HTMLElement;

  @Output() dragStart = new EventEmitter<void>();
  @Output() dragEnd = new EventEmitter<void>();

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  private readonly pointerDown$ = fromEvent<PointerEvent>(
    this.elementRef.nativeElement,
    'pointerdown'
  ).pipe(tap(() => this.dragStart.emit()));

  private readonly pointerUp$ = fromEvent<PointerEvent>(
    this.document,
    'pointerup'
  ).pipe(
    withLatestFrom(this.cropStore.localOffset$),
    tap(([, position]) => {
      this.cropStore.setOffset(position);
      this.dragEnd.emit();
    })
  );

  private readonly pointerMove$ = fromEvent<PointerEvent>(
    this.document,
    'pointermove'
  ).pipe(filter((e) => e.isPrimary));

  private readonly dragging$ = this.pointerDown$.pipe(
    preventDefault(),
    withLatestFrom(this.cropStore.zoom$, this.cropStore.localOffset$),
    exhaustMap(([dragStartEvent, zoom, offset]) => {
      return this.pointerMove$.pipe(
        preventDefault(),
        map((mouseMoveEvent) => ({
          x: offset.x + (mouseMoveEvent.x - dragStartEvent.x) / zoom,
          y: offset.y + (mouseMoveEvent.y - dragStartEvent.y) / zoom,
        })),
        takeUntil(this.pointerUp$)
      );
    })
  );

  private readonly doubleTouchStart$ = fromEvent<TouchEvent>(
    this.elementRef.nativeElement,
    'touchstart'
  ).pipe(filter(({ touches }) => touches.length > 1));

  private readonly touchEnd$ = fromEvent<TouchEvent>(
    this.elementRef.nativeElement,
    'touchend'
  );

  private readonly touchMove$ = fromEvent<TouchEvent>(
    this.elementRef.nativeElement,
    'touchmove'
  );

  private readonly doubleTouchZoom$ = this.doubleTouchStart$.pipe(
    switchMap((startEvent) =>
      this.touchMove$.pipe(
        preventDefault(),
        scan(
          (prev, event) => {
            const distance = getDistanceBetweenTouches(event);
            return {
              event,
              distance,
              delta: (distance - prev.distance) * TOUCH_SENSITIVITY,
            };
          },
          {
            event: startEvent,
            distance: getDistanceBetweenTouches(startEvent),
            delta: 0,
          }
        ),
        map(({ delta }) => delta),
        takeUntil(this.touchEnd$)
      )
    )
  );

  private readonly wheel$ = fromEvent<WheelEvent>(
    this.elementRef.nativeElement,
    'wheel'
  ).pipe(filter((event) => isCtrl<WheelEvent>(event)));

  @HostListener('window:keydown', ['$event'])
  onKeyPress($event: KeyboardEvent) {
    if (
      $event.key === 'Escape' ||
      (isCtrl($event) && $event.key.toLowerCase() === 'z')
    ) {
      this.cropStore.removeGrab();
    }
  }

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly elementRef: ElementRef,
    private readonly cropStore: CropStore,
    private readonly renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    this.initChangePositionSubscription();
    this.initTransformSubscription();
    this.initZoomEventsSubscription();
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  private initChangePositionSubscription(): void {
    this.subs = this.cropStore.isGrabActive$
      .pipe(
        distinctUntilChanged(),
        switchMap((isActive) => (isActive ? this.dragging$ : EMPTY))
      )
      .subscribe((offset) => this.cropStore.setLocalOffset(offset));
  }

  private initTransformSubscription(): void {
    this.subs = this.cropStore.offset$
      .pipe(distinctUntilChanged())
      .subscribe((offset) => {
        this.cropStore.setLocalOffset(offset);
      });

    this.subs = combineLatest([
      this.cropStore.localOffset$,
      this.cropStore.zoom$,
    ]).subscribe(([offset, zoom]) =>
      this.setWorkAreaContainerStyle(zoom, offset)
    );
  }

  private initZoomEventsSubscription() {
    this.subs = merge(
      this.doubleTouchZoom$,
      this.wheel$.pipe(
        preventDefault<WheelEvent>(),
        map((wheel: WheelEvent) => -wheel.deltaY * WHEEL_SENSITIVITY)
      )
    )
      .pipe(withLatestFrom(this.cropStore.zoom$))
      .subscribe(([delta, stateZoom]) => {
        const zoom =
          delta > 0
            ? Math.min(stateZoom + delta, MAX_ZOOM)
            : Math.max(stateZoom + delta, MIN_ZOOM);

        this.cropStore.setZoom(zoom);
      });
  }

  private setWorkAreaContainerStyle(zoom: number, offset: IXY) {
    this.renderer.setStyle(
      this.cropModalWorkAreaContainer,
      'transform',
      `scale(${zoom}) translate(${offset.x}px, ${offset.y}px)`
    );
  }
}
