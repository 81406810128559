import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of, tap, BehaviorSubject } from 'rxjs';
import {
  ICatalogNomenclatureDictionaryItem,
  ICatalogNomenclatureListItem,
  ICatalogNomenclatureSeoListItem,
  ICategory,
  IMapOfCategory,
} from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class CatalogNomenclatureService {
  private readonly nomenclatureMapSubject$ =
    new BehaviorSubject<IMapOfCategory>({});
  readonly nomenclatureMap$ = this.nomenclatureMapSubject$.asObservable();

  constructor(private readonly httpClient: HttpClient) {}

  getNomenclature(id: number, cache = true): Observable<ICategory> {
    const nomenclatureMap = this.nomenclatureMapSubject$.value;

    if (cache && nomenclatureMap[id]) {
      return of(nomenclatureMap[id]);
    }

    return this.httpClient
      .get<ICategory>(`/catalog/public/nomenclature/${id}/`)
      .pipe(
        tap((data) => {
          if (data) {
            nomenclatureMap[id] = data;
            this.nomenclatureMapSubject$.next(nomenclatureMap);
          }
        })
      );
  }

  getNomenclatureDictionary(): Observable<
    ICatalogNomenclatureDictionaryItem[]
  > {
    return this.httpClient
      .get<{ data: ICatalogNomenclatureDictionaryItem[] }>(
        '/catalog/public/nomenclature/?list=true'
      )
      .pipe(map(({ data }) => data));
  }

  getNomenclatureList(): Observable<ICatalogNomenclatureListItem[]> {
    return this.httpClient
      .get<{ data: ICatalogNomenclatureListItem[] }>(
        '/catalog/public/nomenclature/tree/'
      )
      .pipe(map(({ data }) => data));
  }

  getNomenclatureSeoList(): Observable<ICatalogNomenclatureSeoListItem[]> {
    return this.httpClient.get<ICatalogNomenclatureSeoListItem[]>(
      '/catalog/public/nomenclature/4level'
    );
  }

  getNomenclatureNoLinkList(): Observable<ICatalogNomenclatureListItem[]> {
    return this.httpClient
      .get<{ data: ICatalogNomenclatureListItem[] }>(
        '/catalog/public/nomenclature/no_link_tree'
      )
      .pipe(map(({ data }) => data));
  }
}
