import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ITemplate } from '../../../../interfaces/templates.interface';

@Injectable({
  providedIn: 'root',
})
export class MyTemplatesService {
  templates$: BehaviorSubject<ITemplate[] | null> = new BehaviorSubject<
    ITemplate[] | null
  >(null);

  createMyTemplate?: (template: Partial<ITemplate>) => void;
  updateMyTemplate?: (data: {
    template: ITemplate;
    triggerToSaved: boolean;
  }) => void;
  removeMyTemplate?: (templateId: number) => void;
}
