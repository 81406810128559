import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'painter-modal-template-cover-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal-template-cover-item.component.html',
  styleUrls: ['./modal-template-cover-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalTemplateCoverItemComponent {
  @Input() cover: HTMLElement | null = null;
  @Input() isActive = false;
  @Output() selectCover = new EventEmitter<HTMLElement>();
}
