import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { IDesignSide } from '@inaripro-nx/catalog';
import {
  ELEMENT_COLOR_DEFAULT,
  ELEMENT_STROKE_COLOR_DEFAULT,
} from '@inaripro-nx/common-ui';
import { combineLatest, debounceTime, Subscription } from 'rxjs';
import { fitElementToActiveDesignSide } from '../../../../utils/calculate.utils';
import {
  IBaseElement,
  IElement,
  IFontStyle,
  IFontWeight,
  ISVGElement,
  ITextAnchor,
  ITextElement,
} from '../../interfaces/editor.interface';
import { EFontFamily } from '../../interfaces/fonts.interface';
import { EObject, EPage } from '../../interfaces/main.interface';
import { HistoryStore } from '../../state/history/history.store';
import { MainStore } from '../../state/main/main.store';
import { ProductStore } from '../../state/product/product.store';
import { ContentFiguresComponent } from './components/content-figures/content-figures.component';
import { ContentFillComponent } from './components/content-fill/content-fill.component';
import { ContentLayersComponent } from './components/content-layers/content-layers.component';
import { ContentPicturesComponent } from './components/content-pictures/content-pictures.component';
import { ContentProductComponent } from './components/content-product/content-product.component';
import { ContentQrcodesComponent } from './components/content-qrcodes/content-qrcodes.component';
import { ContentTemplatesComponent } from './components/content-templates/content-templates.component';
import { ContentTextComponent } from './components/content-text/content-text.component';
import { ISVGObject, ITextObject } from './interfaces/content.interface';
import { DEFAULT_VALUE_STROKE_WIDTH } from '../../../../constants/stroke.consts';

@Component({
  selector: 'painter-content',
  standalone: true,
  imports: [
    CommonModule,
    ContentProductComponent,
    ContentTemplatesComponent,
    ContentPicturesComponent,
    ContentTextComponent,
    ContentFiguresComponent,
    ContentQrcodesComponent,
    ContentLayersComponent,
    ContentFillComponent,
  ],
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentComponent implements OnInit, OnDestroy {
  readonly EPage = EPage;
  readonly EObject = EObject;

  page$ = this.mainStore.page$;
  object$ = this.mainStore.object$;
  activeDesignProduct$ = this.productStore.activeDesignProduct$;

  activeElementIndex$ = this.historyStore.activeElementIndex$;
  elements$ = this.historyStore.elements$;

  product$ = this.productStore.product$;

  activeDesignSide: IDesignSide | null = null;
  activeDesignSideIndex: number | null = null;

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  constructor(
    private mainStore: MainStore,
    private productStore: ProductStore,
    private historyStore: HistoryStore,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subs = combineLatest([
      this.productStore.activeDesignSide$,
      this.productStore.activeDesignSideIndex$,
    ])
      .pipe(debounceTime(0))
      .subscribe(([side, activeDesignSideIndex]) => {
        this.activeDesignSide = side;
        this.activeDesignSideIndex = activeDesignSideIndex;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  selectProduct() {
    this.productStore.selectProduct$.next(false);
  }

  isActiveObjects(page: EPage | null) {
    return page === EPage.design || page === EPage.objects;
  }

  private getBaseElement(data: ITextObject | ISVGObject): IBaseElement | null {
    const { bBox } = data;

    const updateCenter = { x: 0, y: 0 };
    const { width, height } = bBox;

    if (
      !width ||
      !height ||
      !this.activeDesignSide?.id ||
      this.activeDesignSideIndex === null
    ) {
      return null;
    }

    const element: IBaseElement = {
      typeIndex: null,
      sideIndex: this.activeDesignSideIndex,
      zone0Size: { ...(this.activeDesignSide.zones[0].size || { x: 1, y: 1 }) },
      translate: {
        x: updateCenter.x - width / 2,
        y: updateCenter.y - height / 2,
      },
      fill: ELEMENT_COLOR_DEFAULT,
      fillOpacity: null,
      stroke: ELEMENT_STROKE_COLOR_DEFAULT,
      strokeWidth: DEFAULT_VALUE_STROKE_WIDTH,
      strokeOpacity: null,
      rotate: 0,
      scale: { x: 1, y: 1 },
      size: { x: width, y: height },
      flip: { x: false, y: false },
      filterId: null,
    };

    return fitElementToActiveDesignSide(
      element,
      this.activeDesignSide,
      updateCenter,
      data
    );
  }

  private addElement(element: IElement) {
    this.historyStore.addElement({ element });
  }

  addTextObject(data: ITextObject) {
    const { type, text, textLength } = data;
    const baseElement = this.getBaseElement(data);

    if (!baseElement) {
      return;
    }

    const element: ITextElement = {
      ...baseElement,
      type,
      text,
      lineSpace: 1,
      letterSpacing: 0,
      textAnchor: ITextAnchor.middle,
      textTranslate: { x: baseElement.size.x / 2, y: 0 },
      textLength,
      fontStyle: IFontStyle.normal,
      fontWeight: IFontWeight.normal,
      fontFamily: EFontFamily.Arial,
      textArc: {
        angle: 0,
        width: 0,
        height: 0,
      },
    };

    this.addElement(element);
  }

  addSVGObject(data: ISVGObject) {
    const { type, text, svg, ext } = data;

    const baseElement = this.getBaseElement(data);

    if (!baseElement) {
      return;
    }

    const element: ISVGElement = {
      ...baseElement,
      type,
      svg,
      text,
      ext,
    };

    this.addElement(element);
  }

  setPage(page: EPage) {
    this.mainStore.setPage({ page });
  }
}
