@if (range) {
  <div class="title">
    <div class="helper-wrapper helper-wrapper--dashed">
      <design-helper-window
        class="helper"
        [title]="helperTitle"
        [uid]="'unitHelp'+uidStateHelper"
        [position]="'auto-bottom'"
        [size]="'xxs'"
      >
        <span class="state-popover__toggle">Ваш тираж&nbsp;(?)</span>
        <div class="state-popover-body__content" [innerHtml]="helperText"></div>
      </design-helper-window>
    </div>
  </div>

  <design-popup-select-box
    class="select"
    [activeOption]="selectedOption"
    [options]="options"
    (changeOption)="onChange($event.id)">
  </design-popup-select-box>
}

<design-modal-window [uid]="modalUid">
  <div modal-header>Укажите ваш тираж</div>
  <div modal-body>
    <div class="g-form-group">
      <input
        class="g-form-control"
        type="number"
        placeholder="Ваш тираж"
        [formControl]="rangeControl"
        [min]="minRange"
        [max]="maxRange"
        (keyup.enter)="saveRangeModal()"
      />
    </div>
  </div>
  <div modal-footer>
    <div class="g-form-button g-form-button--right">
      <button class="g-btn" (click)="saveRangeModal()">Принять</button>
      <button class="g-btn-link" (click)="closeRangeModal()">Отмена</button>
    </div>
  </div>
</design-modal-window>
