import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IArea } from '@inaripro-nx/catalog';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[data-painter-editor-rect-dashed]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './editor-rect-dashed.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorRectDashedComponent {
  @Input() area!: IArea;
  @Input() colors!: [string, string];
}
