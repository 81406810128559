import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IElement, IXY } from '../../interfaces/editor.interface';

@Injectable()
export class EditorService {
  rotate$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(
    null
  );

  scale$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(
    null
  );

  fill$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(
    null
  );

  translate$: BehaviorSubject<IXY | null> = new BehaviorSubject<IXY | null>(
    null
  );

  fillOpacity$: BehaviorSubject<number | null> = new BehaviorSubject<
    number | null
  >(null);

  stroke$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(
    null
  );

  strokeWidth$: BehaviorSubject<number | null> = new BehaviorSubject<
    number | null
  >(null);

  strokeOpacity$: BehaviorSubject<number | null> = new BehaviorSubject<
    number | null
  >(null);

  stopChange$: Subject<void> = new Subject();

  changeElement$: BehaviorSubject<IElement | null> =
    new BehaviorSubject<IElement | null>(null);

  rotate(rotate: number | null) {
    this.rotate$.next(rotate);
  }

  scale(scale: number | null) {
    this.scale$.next(scale);
  }

  fill(fill: string | null) {
    this.fill$.next(fill);
  }

  translate(translate: IXY) {
    this.translate$.next(translate);
  }

  fillOpacity(fillOpacity: number | null) {
    this.fillOpacity$.next(fillOpacity);
  }

  stroke(stroke: string | null) {
    this.stroke$.next(stroke);
  }

  strokeWidth(stroke: number | null) {
    this.strokeWidth$.next(stroke);
  }

  strokeOpacity(stroke: number | null) {
    this.strokeOpacity$.next(stroke);
  }

  changeElement(element: IElement) {
    this.changeElement$.next(element);
  }

  stopChange() {
    this.stopChange$.next();
  }
}
