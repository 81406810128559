<catalog-product-list-filter-wrapper
  [title]="'Наличие на складе'"
  [isApply]="!!filterValues.applyCityStock || !!filterValues.applyAllStock"
  (clearFilter)="clearFilter.emit($event)"
>
  <div class="g-form-group">
    <div class="g-checkbox g-checkbox--with-text" *ngIf="filters.hasCityStock">
      <input
        type="checkbox"
        id="apply_city_stock"
        [ngModel]="filterValues.applyCityStock"
        (ngModelChange)="changeApplyCityStock.emit($event)"
      >
      <label for="apply_city_stock" (click)="$event.stopPropagation()">Только в вашем городе</label>
    </div>
    <div class="g-checkbox g-checkbox--with-text" *ngIf="filters.hasAllStocks">
      <input
        type="checkbox"
        id="apply_all_stock"
        [ngModel]="filterValues.applyAllStock"
        (ngModelChange)="changeApplyAllStock.emit($event)"
      >
      <label for="apply_all_stock" (click)="$event.stopPropagation()">Только на складе</label>
    </div>
  </div>
</catalog-product-list-filter-wrapper>
