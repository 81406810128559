export interface IMapOfString {
  [name: string]: string;
}

export interface IMapOfBoolean {
  [name: string]: boolean;
}

export interface IMapNumberOfBoolean {
  [name: string]: boolean;
}

export interface IMapOfNumber {
  [name: string]: number;
}

export interface IMapOfNumberArray {
  [name: string]: number[];
}

export interface IMapNumberOfNumber {
  [name: number]: number;
}

export interface IMapOfStringArray {
  [name: string]: string[];
}
