<catalog-product-list-filter-wrapper
  [prop]="prop"
  [title]="prop.alias"
  [isApply]="!!filterValues.valueFilters[prop.id]"
  (clearFilter)="clearFilter.emit($event)"
>
  <design-range-inputs
    [range]="{minValue: prop.minValue, maxValue: prop.maxValue}"
    [values]="filterValues.valueFilters[prop.id] || {minValue: prop.minValue, maxValue: prop.maxValue}"
    (changeModel)="changePropertyFilter.emit($event)"
  ></design-range-inputs>
</catalog-product-list-filter-wrapper>
