import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import {
  IFiltersValues,
  IProductFilters,
  IPropertyForFilter,
} from '../../../../interfaces';
import { PipesModule } from '@inaripro-nx/common-ui';
import { CatalogProductListFilterWrapperComponent } from '../catalog-product-list-filter-wrapper/catalog-product-list-filter-wrapper.component';

@Component({
  selector: 'catalog-product-list-filter-buttons',
  standalone: true,
  imports: [
    CommonModule,
    DesignUiModule,
    CatalogProductListFilterWrapperComponent,
    PipesModule,
  ],
  templateUrl: './catalog-product-list-filter-buttons.component.html',
  styleUrls: ['./catalog-product-list-filter-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogProductListFilterButtonsComponent {
  @Input() filters!: IProductFilters;
  @Input() filterValues!: IFiltersValues;
  @Input() prop!: IPropertyForFilter;

  @Output() toggleType = new EventEmitter<number>();
  @Output() clearFilter = new EventEmitter<void>();
}
