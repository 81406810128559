import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesignUiModule, IDropdownOption } from '@inaripro-nx/design-ui';
import { ProductTypeEnum } from '../../../../enums/catalog-product.enum';
import { CATALOG_PRODUCT_TYPE } from '../../../../constants';
import { getProductTypeName } from '../../../../utils';

@Component({
  selector: 'catalog-product-list-content-filter-type',
  standalone: true,
  imports: [CommonModule, DesignUiModule],
  templateUrl: './catalog-product-list-content-filter-type.component.html',
  styleUrl: './catalog-product-list-content-filter-type.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogProductListContentFilterTypeComponent implements OnChanges {
  @Input() productType: ProductTypeEnum | null = null;
  @Input() existProductTypes: ProductTypeEnum[] | null = null;
  @Input() showAllProductTypeFilters = false;
  @Input() uidStateHelper = 'typeHelp';
  @Input() isMobileTypeButtons = false;

  @Output() selectType = new EventEmitter<ProductTypeEnum>();

  options: IDropdownOption[] = [];
  selectedOption?: IDropdownOption;

  readonly ProductTypeEnum = ProductTypeEnum;

  ngOnChanges(changes: SimpleChanges) {
    if (
      'productType' in changes ||
      'existProductTypes' in changes ||
      'showAllProductTypeFilters' in changes
    ) {
      this.options =
        this.showAllProductTypeFilters ||
        (this.existProductTypes || []).length > 1
          ? this.getProductTypeOptions(
              this.existProductTypes || [],
              this.showAllProductTypeFilters
            )
          : [];

      this.selectedOption = this.options.find(
        (o) => o.value === this.productType
      );
    }
  }

  onClickProductType(option: IDropdownOption) {
    this.selectType.emit(
      this.productType === option.value
        ? ProductTypeEnum.ALL
        : (option.value as ProductTypeEnum)
    );
  }

  onChange(id: number) {
    const option = this.options.find((r) => r.id === id);

    if (!option) {
      return;
    }

    this.onClickProductType(option);
  }

  private getProductTypeOptions(
    types: ProductTypeEnum[],
    showAll: boolean
  ): IDropdownOption[] {
    {
      return Object.keys(CATALOG_PRODUCT_TYPE)
        .map((key) => CATALOG_PRODUCT_TYPE[key as ProductTypeEnum])
        .filter(
          (value) =>
            showAll ||
            types.indexOf(value as ProductTypeEnum) > -1 ||
            value === ProductTypeEnum.ALL
        )
        .map((value, index) => {
          return {
            id: index + 1,
            label: getProductTypeName(value as ProductTypeEnum),
            value,
          };
        });
    }
  }
}
