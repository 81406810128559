import { Pipe, PipeTransform } from '@angular/core';
import { ICatalogNomenclatureTreeItem } from '../../interfaces/catalog.interface';

@Pipe({
  name: 'catalogTreeItemHasDesign',
  standalone: true,
})
export class CatalogTreeItemHasDesignPipe implements PipeTransform {
  transform(item: ICatalogNomenclatureTreeItem): boolean {
    return item.children.some(({ hasProductDesign }) => !!hasProductDesign);
  }
}
