import { Params } from '@angular/router';
import {
  IFiltersValues,
  IProductFilters,
} from '../interfaces/catalog-filter.interface';
import { isNullOrUndefined } from '@inaripro-nx/common-ui';

const PRODUCT_TYPES = ['BASE_PRODUCT', 'DESIGN', 'EXAMPLE', 'ALL'];

export class CatalogProductListUtils {
  static initFilterValues(): IFiltersValues {
    return {
      typeFiltersSelectedAll: {},
      typeFilters: {},
      valueFilters: {},
      priceFilter: null,
      productType: null,
      applyCityStock: null,
      applyAllStock: null,
    };
  }

  static getFilterValuesByQueryParams(
    queryParams: Params,
    filters: IProductFilters
  ): IFiltersValues {
    let filterValues = CatalogProductListUtils.initFilterValues();

    if (!filters) {
      return filterValues;
    }

    (filters.characteristics || []).forEach((prop) => {
      const propertyId = prop.id;

      if (!isNullOrUndefined(queryParams[`tf_${propertyId}`])) {
        if (queryParams[`tf_${propertyId}`] === '') {
          filterValues = CatalogProductListUtils.clearTypes(
            propertyId,
            filters,
            filterValues
          );
        } else {
          const elements: number[] = queryParams[`tf_${propertyId}`]
            .split(',')
            .map((val: string) => +val);

          (prop.typeElements || []).forEach((te) => {
            if (elements.indexOf(te.id) > -1) {
              filterValues = CatalogProductListUtils.toggleType(
                propertyId,
                te.id,
                filters,
                filterValues
              );
            }
          });
        }
      } else if (queryParams[`tv_${propertyId}`]) {
        const values: number[] = queryParams[`tv_${propertyId}`]
          .split('_')
          .map((val: string) => +val);

        const minValue = isNullOrUndefined(values[0]) ? null : values[0];
        const maxValue = isNullOrUndefined(values[1]) ? null : values[1];

        if (
          minValue !== null &&
          maxValue !== null &&
          minValue <= maxValue &&
          minValue >= prop.minValue &&
          maxValue <= prop.maxValue
        ) {
          filterValues.valueFilters[propertyId] = {
            characterId: propertyId,
            minValue,
            maxValue,
          };
        }
      }
    });

    if (queryParams['prices'] && filters.price) {
      const values: number[] = queryParams['prices']
        .split('_')
        .map((val: string) => +val || null);

      let minValue = isNullOrUndefined(values[0]) ? null : values[0];
      let maxValue = isNullOrUndefined(values[1]) ? null : values[1];

      const prop = filters.price;

      if (
        minValue !== null &&
        prop.minValue !== null &&
        prop.maxValue &&
        (minValue < prop.minValue || minValue > prop.maxValue)
      ) {
        minValue = null;
      }

      if (
        maxValue !== null &&
        prop.minValue !== null &&
        prop.maxValue !== null &&
        (maxValue < prop.minValue || maxValue > prop.maxValue)
      ) {
        maxValue = null;
      }

      if (minValue !== null && maxValue !== null && minValue > maxValue) {
        minValue = null;
        maxValue = null;
      }

      if (minValue !== null || maxValue !== null) {
        filterValues.priceFilter = {
          minValue,
          maxValue,
        };
      }
    }

    let productType = queryParams['productType'];

    if (PRODUCT_TYPES.indexOf(productType) < 0) {
      productType = null;
    }

    filterValues.productType = productType;

    if (filters.hasCityStock && queryParams['applyCityStock'] === 'true') {
      filterValues.applyCityStock = true;
    }

    if (filters.hasAllStocks && queryParams['applyAllStock'] === 'true') {
      filterValues.applyCityStock = null;
      filterValues.applyAllStock = true;
    }

    return filterValues;
  }

  static getQueryParamsByFilterValues(
    filters: IProductFilters,
    filterValues: IFiltersValues
  ): Params {
    const queryParams: Params = {};

    Object.keys(filterValues.typeFilters).forEach((propertyId) => {
      const property = filters.characteristics.find(
        (prop) => prop.id === +propertyId
      );

      const elements = Object.keys(filterValues.typeFilters[propertyId])
        .map((typeElementId) =>
          filterValues.typeFilters[propertyId][typeElementId]
            ? typeElementId
            : null
        )
        .filter((val) => val);

      if (
        property?.isRequired &&
        property?.typeElements &&
        elements.length === property.typeElements.length
      ) {
        queryParams[`tf_${propertyId}`] = '';
      } else if (elements.length) {
        queryParams[`tf_${propertyId}`] = elements.join(',');
      }
    });

    Object.keys(filterValues.valueFilters).forEach((propertyId) => {
      const minMax = filterValues.valueFilters[propertyId];

      if (minMax) {
        queryParams[
          `tv_${propertyId}`
        ] = `${minMax.minValue}_${minMax.maxValue}`;
      }
    });

    if (filterValues.priceFilter) {
      queryParams['prices'] = `${filterValues.priceFilter.minValue || ''}_${
        filterValues.priceFilter.maxValue || ''
      }`;
    }

    if (filterValues.productType) {
      queryParams['productType'] = filterValues.productType;
    }

    if (filters.hasCityStock && filterValues.applyCityStock) {
      queryParams['applyCityStock'] = true;
    }

    if (filters.hasAllStocks && filterValues.applyAllStock) {
      delete queryParams['applyCityStock'];
      queryParams['applyAllStock'] = true;
    }

    return queryParams;
  }

  static toggleType(
    propertyId: number,
    typeElementId: number,
    filters: IProductFilters | null,
    inputFilterValues: IFiltersValues
  ): IFiltersValues {
    const filterValues = CatalogProductListUtils.createTypeValues(
      propertyId,
      inputFilterValues
    );

    filterValues.typeFilters[propertyId][typeElementId] =
      !filterValues.typeFilters[propertyId][typeElementId];

    const length = Object.keys(
      filterValues.typeFilters[propertyId] || {}
    ).filter((key) => !!filterValues.typeFilters[propertyId][key]).length;

    const property = filters?.characteristics.find(
      (char) => char.id === propertyId
    );

    filterValues.typeFiltersSelectedAll[propertyId] =
      length === (property?.typeElements || []).length;

    return filterValues;
  }

  static clearTypes(
    propertyId: number,
    filters: IProductFilters | null,
    inputFilterValues: IFiltersValues
  ): IFiltersValues {
    const filterValues = CatalogProductListUtils.createTypeValues(
      propertyId,
      inputFilterValues
    );

    // const length = Object.keys(filterValues.typeFilters[propertyId] || {})
    //     .filter(key => !!filterValues.typeFilters[propertyId][key]).length;

    // const property = filters.characteristics.find(char => char.id === propertyId);

    // if (length === property.typeElements.length) {

    filterValues.typeFilters[propertyId] = {};
    filterValues.typeFiltersSelectedAll[propertyId] = false;

    // } else {
    //
    //     filterValues.typeFilters[propertyId] = property.typeElements.reduce((map, te) => {
    //         map[te.id] = true;
    //         return map;
    //     }, {});
    //
    //     filterValues.typeFiltersSelectedAll[propertyId] = true;
    // }

    return filterValues;
  }

  static createTypeValues(
    propertyId: number,
    inputFilterValues: IFiltersValues
  ): IFiltersValues {
    const filterValues: IFiltersValues = {
      ...inputFilterValues,
      typeFilters: {
        ...inputFilterValues.typeFilters,
      },
      typeFiltersSelectedAll: {
        ...inputFilterValues.typeFiltersSelectedAll,
      },
    };

    if (!filterValues.typeFilters[propertyId]) {
      filterValues.typeFilters[propertyId] = {};
    } else {
      filterValues.typeFilters[propertyId] = {
        ...filterValues.typeFilters[propertyId],
      };
    }

    return filterValues;
  }
}
