import {
  IPaginationOnlyRequest,
  IPaginationPages,
  IPaginationResponse,
  ISortRequest,
  PaginationOrderDirectionType,
} from '../interfaces';
import { PAGINATION_ORDER_DIRECTION } from '../constants';

export function revertOrderDirection(
  orderDirection: PaginationOrderDirectionType
) {
  if (orderDirection === PAGINATION_ORDER_DIRECTION.ASC) {
    return PAGINATION_ORDER_DIRECTION.DESC;
  } else {
    return PAGINATION_ORDER_DIRECTION.ASC;
  }
}

export function getPaginationUrlQueryString(request: IPaginationOnlyRequest) {
  if (!request) {
    return '';
  }

  return `&offset=${request.offset}&limit=${request.limit}`;
}

export function getSortUrlQueryString(request: ISortRequest) {
  if (!request) {
    return '';
  }

  return `&order_by=${request.order_by}&order_direction=${request.order_direction}`;
}

export function sortDirectionValidate(dir: PaginationOrderDirectionType) {
  if (
    dir === PAGINATION_ORDER_DIRECTION.ASC ||
    dir === PAGINATION_ORDER_DIRECTION.DESC
  ) {
    return dir;
  }

  return PAGINATION_ORDER_DIRECTION.ASC;
}

export function paginationCreatePageArray(
  instance: IPaginationResponse,
  currentPage: number,
  paginationRange: number,
  itemsPerPage?: number
): IPaginationPages[] {
  const totalItems = instance.totalItems;
  const perPage = itemsPerPage || instance.limit;

  // paginationRange could be a string if passed from attribute, so cast to number.
  paginationRange = +paginationRange;

  const pages: IPaginationPages[] = [];
  const totalPages = Math.ceil(totalItems / perPage);
  const halfWay = Math.ceil(paginationRange / 2);
  const isStart = currentPage <= halfWay;
  const isEnd = totalPages - halfWay < currentPage;
  const isMiddle = !isStart && !isEnd;
  const ellipsesNeeded = paginationRange < totalPages;

  let i = 1;

  while (i <= totalPages && i <= paginationRange) {
    let label: string;
    const pageNumber = paginationCalculatePageNumber(
      i,
      currentPage,
      paginationRange,
      totalPages
    );
    const openingEllipsesNeeded = i === 2 && (isMiddle || isEnd);
    const closingEllipsesNeeded =
      i === paginationRange - 1 && (isMiddle || isStart);

    if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded)) {
      label = '...';
    } else {
      label = pageNumber.toString();
    }
    pages.push({
      label,
      value: pageNumber,
    });
    i++;
  }

  return pages;
}

/**
 * Given the position in the sequence of pagination links [i],
 * figure out what page number corresponds to that position.
 */
export function paginationCalculatePageNumber(
  i: number,
  currentPage: number,
  paginationRange: number,
  totalPages: number
) {
  const halfWay = Math.ceil(paginationRange / 2);
  if (i === paginationRange) {
    return totalPages;
  } else if (i === 1) {
    return i;
  } else if (paginationRange < totalPages) {
    if (totalPages - halfWay < currentPage) {
      return totalPages - paginationRange + i;
    } else if (halfWay < currentPage) {
      return currentPage - halfWay + i;
    } else {
      return i;
    }
  } else {
    return i;
  }
}
