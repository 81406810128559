import { createAction, props } from '@ngrx/store';
import { ISortConfig, ISortRequest } from '../../interfaces';

export const setSortConfig = createAction(
  '[Sort] SET_CONFIG',
  props<{ uid: string; payload: ISortConfig }>()
);
export const setSortRequest = createAction(
  '[Sort] SET_REQUEST',
  props<{ uid: string; payload: ISortRequest }>()
);
