<design-range-slider-wrapper
  [unitmeasurement]="'%'"
  [minValue]="minValue"
  [maxValue]="maxValue"
  [color]="element.stroke || ELEMENT_STROKE_COLOR_DEFAULT"
  [isGradientRangeSlider]="true"
  [value]="element.strokeOpacity || 0"
  (changeValue)="changeValue($event)"
  (stopChange)="stopChange()"
  (resetValue)="reset()"
></design-range-slider-wrapper>
