<svg
  *ngIf="zone.size && zone.start"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  preserveAspectRatio="xMidYMid meet"
  [attr.viewBox]="zone.size | viewbox"
  [attr.width]="zone.sizeMM.x + 'mm'"
  [attr.height]="zone.sizeMM.y + 'mm'"
>
  <svg
    style="width: 100%; height: 100%"
    preserveAspectRatio="xMidYMid meet"
    [attr.viewBox]="zone.size | viewbox"
  >
    <!-- Рабочая область редактора -->
    <defs>
      <mask id="mask1">
        <rect x="0" y="0" width="100%" height="100%" fill="black" />
        <rect
          [attr.x]="0"
          [attr.y]="0"
          [attr.width]="zone.size.x"
          [attr.height]="zone.size.y"
          fill="white"
        />
      </mask>
    </defs>

    <ng-container *ngFor="let filterId of elementsFiltersId">
      <defs
        *ngIf="filterId && filters && filters[filterId] as filter"
        [innerHTML]="filter.html | safeHtml"
      ></defs>
    </ng-container>

    <!-- Область фоновой заливки -->
    <g *ngIf="zone.hasColorPalette">
      <g *ngIf="zonePattern as pattern">
        <pattern
          id="pattern"
          x="0"
          y="0"
          width="100%"
          height="100%"
          patternUnits="userSpaceOnUse"
        >
          <image
            x="0"
            y="0"
            width="100%"
            height="100%"
            [attr.xlink:href]="pattern.url"
            preserveAspectRatio="xMidYMid slice"
          ></image>
        </pattern>
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          fill="url(#pattern)"
          style="pointer-events: none"
        />
      </g>
    </g>

    <!-- Фон области нанесения -->
    <g
      data-painter-editor-zone-paint
      [zone]="zone"
      [zoneColor]="zoneColor"
      [zonePattern]="zonePattern"
      [isZoneExport]="true"
    ></g>

    <!-- Объекты дизайна -->
    <g mask="url(#mask1)">
      <g [attr.transform]="zone0Center | svgTranslate">
        <ng-container *ngFor="let el of elements; let i = index">
          <ng-container *ngIf="el.sideIndex === sideIndex">
            <g data-painter-editor-element-transform [el]="el">
              <g data-painter-editor-element-content [el]="el" [index]="i"></g>
            </g>
          </ng-container>
        </ng-container>
      </g>
    </g>

  </svg>
</svg>
