import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ContactLink, Links } from '@inaripro-nx/links';
import { HeaderLinkComponent } from '../header-link/header-link.component';

const MOSCOW_CITY_NAME = 'Москва';
const PITER_CITY_NAME = 'Санкт-Петербург';

@Component({
  selector: 'shell-header-menu-contacts',
  standalone: true,
  imports: [CommonModule, RouterModule, HeaderLinkComponent],
  templateUrl: './header-menu-contacts.component.html',
  styleUrls: ['./header-menu-contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMenuContactsComponent {
  @Input()
  set links(items: Links | null) {
    if (items) {
      const { moscowContacts, piterContacts, contacts } = items;

      this.moscowContacts = moscowContacts
        .map((item) => ({
          ...item,
          name: item.name.includes(',') ? item.name.split(',')[0] : item.name,
        }))
        .filter((item) => item.isHeader);

      this.piterContacts = piterContacts.map((item) => {
        return {
          ...item,
          name: item.name.includes(',') ? item.name.split(',')[0] : item.name,
        };
      });

      this.contacts = contacts;
    }
  }

  moscowContacts: ContactLink[] = [];
  piterContacts: ContactLink[] = [];
  contacts: ContactLink[] = [];
}
