<div class="main">
  <shell-header
    data-header
    class="main__header"
    [appShellConfig]="appShellConfig$ | async"
  ></shell-header>
  <div class="main__body">
    <router-outlet></router-outlet>
  </div>
</div>
