@if (formData && formData.controls.length) {
  <form [formGroup]="formGroup">
  <ul class="tree" formArrayName="formData">
    @for (tree of formData.controls; track tree.value; let i = $index) {
      <li
        class="tree__el"
        (click)="toggleSelected(tree.value, $event)"
      >
        <div class="tree__title">
            <span class="g-checkbox g-checkbox--with-text">
                <input
                  type="checkbox"
                  id="{{ FORM_ID }}-{{ tree.value.id }}"
                  [checked]="selected && selected[tree.value.id]"
                >
                <label
                  for="{{ FORM_ID }}-{{ tree.value.id }}"
                >
                    <span>{{tree.value.name}}</span>
                </label>
            </span>
        </div>
      </li>
    }
  </ul>
  </form>
}

