import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectValues',
})
export class ObjectValuesPipe implements PipeTransform {
  transform(value: unknown): unknown[] {
    if (value && this.isAnObject(value)) {
      return Object.keys(value).map((key) => value[key]);
    }

    return [];
  }

  private isAnObject(value: unknown): value is { [key: string]: unknown } {
    return Object.prototype.toString.call(value) === '[object Object]';
  }
}
