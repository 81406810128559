import { EFontFamily } from './fonts.interface';
import { FormControl } from '@angular/forms';
import { IFile } from '@inaripro-nx/design-ui';

export type IElements = IElement[];
export const ELEMENT_MIN_SCALE = 0.03;
export const ELEMENT_MAX_SCALE = 2;

export const DEFAULT_TEXT_SIZE = 40;

export interface IMapXY {
  [key: string]: IXY;
}

export interface IXY {
  x: number;
  y: number;
}

export interface IXYb {
  x: boolean;
  y: boolean;
}

export enum EElementType {
  figure = 'figure',
  image = 'image',
  qr = 'qr',
  text = 'text',
}

export enum EElementTypeName {
  figure = 'Фигура',
  image = 'Изображение',
  qr = 'QR code',
  text = 'Текст',
}

export type IElement = ITextElement | ISVGElement;

export enum ITextAnchor {
  start = 'start',
  middle = 'middle',
  end = 'end',
  stertch = 'stertch', // for apply textLength
}

export enum IFontStyle {
  normal = 'normal',
  italic = 'italic',
  oblique = 'oblique',
}

export enum IFontWeight {
  normal = 'normal',
  bold = 'bold',
  bolder = 'bolder',
  lighter = 'lighter',
}

export interface ITextElement extends IBaseElement {
  type: EElementType.text;
  text: string;
  lineSpace: number;
  letterSpacing?: number;
  textTranslate: IXY;
  textAnchor: ITextAnchor;
  textLength: number;
  fontStyle: IFontStyle;
  fontWeight: IFontWeight | number;
  fontFamily: EFontFamily;
  textArc?: {
    angle: number;
    width: number;
    height: number;
  };
}

export interface ISVGElement extends IBaseElement {
  type: EElementType.figure | EElementType.image | EElementType.qr;
  text: string | null;
  svg: string;
  ext?: string;
}

export interface IBaseElement {
  typeIndex: number | null;
  sideIndex: number;
  zone0Size: IXY;
  fill: string | null;
  fillOpacity: number | null;
  stroke: string | null;
  strokeWidth: number | null;
  strokeOpacity?: number | null;
  translate: IXY;
  rotate: number;
  scale: IXY;
  size: IXY;
  flip: IXYb;
  filterId: number | null;
}

export interface IZone {
  name: string;
  position: IXY;
  size: IXY;
}

export interface ISide {
  name: string;
  zones: IZone[];
  cover: string; // base64
  coverSize: IXY;
}

export interface IUpdateElement {
  index: number;
  element: IElement;
}

export interface ITemplateForm {
  id: FormControl<number | null>;
  name: FormControl<string>;
  cover: FormControl<HTMLElement | IFile | null>;
  showSuccessModal: FormControl<boolean>;
}
