import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { map, mergeMap } from 'rxjs';
import { RouterStateUrl } from '../../constants';
import { SORT_STORE_CONFIG, SortStoreConfig } from './sort.store.config';
import { filter } from 'rxjs/operators';
import { setSortConfig, setSortRequest } from './sort.actions';

@Injectable()
export class SortEffects {
  parseRouter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      map((action: RouterNavigationAction<RouterStateUrl>) => action.payload),
      filter(
        (payload) =>
          payload.routerState.data['module'] === this.storeConfig.moduleUid
      ),
      mergeMap((payload) => {
        const order_by =
          payload.routerState.queryParams['order_by'] ||
          this.storeConfig.config.defaultRequest.order_by;
        const order_direction =
          payload.routerState.queryParams['order_direction'] ||
          this.storeConfig.config.defaultRequest.order_direction;

        return [
          setSortConfig({
            uid: this.storeConfig.storeUid,
            payload: this.storeConfig.config,
          }),
          setSortRequest({
            uid: this.storeConfig.storeUid,
            payload: { order_by, order_direction },
          }),
        ];
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    @Inject(SORT_STORE_CONFIG) private readonly storeConfig: SortStoreConfig
  ) {}
}
