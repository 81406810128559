import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IDesignSide, IDesignZone } from '@inaripro-nx/catalog';
import { DesignUiModule, Tab } from '@inaripro-nx/design-ui';
import { Observable } from 'rxjs';
import { WindowToolsService } from '../../../../services/window-tools/window-tools.service';
import { ProductStore } from '../../../../state/product/product.store';
import { ContentFillColorComponent } from './components/content-fill-color/content-fill-color.component';
import { ContentFillPatternComponent } from './components/content-fill-pattern/content-fill-pattern.component';
import { ContentFillPropertyComponent } from './components/content-fill-property/content-fill-property.component';

@Component({
  selector: 'painter-content-fill',
  standalone: true,
  imports: [
    CommonModule,
    DesignUiModule,
    ContentFillColorComponent,
    ContentFillPatternComponent,
    ContentFillPropertyComponent,
  ],
  templateUrl: './content-fill.component.html',
  styleUrls: ['./content-fill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentFillComponent {
  readonly isDesktop$: Observable<boolean> = this.windowToolsService.isDesktop$;

  readonly activeDesignSide$ = this.productStore.activeDesignSide$;
  readonly activeDesignZone$ = this.productStore.activeDesignZone$;
  readonly activeDesignZonesWithColor$ =
    this.productStore.activeDesignZonesWithColor$;

  readonly COLOR_TAB_ID = 1;
  readonly PATTERN_TAB_ID = 2;
  readonly tabs: Tab[] = [
    {
      id: this.COLOR_TAB_ID,
      name: 'Цвет',
    },
    {
      id: this.PATTERN_TAB_ID,
      name: 'Текстура',
    },
  ];

  activeTab = this.tabs[0];

  constructor(
    private readonly productStore: ProductStore,
    private readonly windowToolsService: WindowToolsService
  ) {}

  public selectZone(side: IDesignSide, zone: unknown) {
    this.productStore.setActiveDesignZoneIndex(
      side.zones.indexOf(zone as IDesignZone)
    );
  }
}
