import { createAction, props } from '@ngrx/store';
import {
  IPaginationConfig,
  IPaginationOnlyRequest,
  IPaginationResponse,
} from '../../interfaces';

export const setPaginationConfig = createAction(
  '[Pagination] SET_CONFIG',
  props<{ uid: string; payload: IPaginationConfig }>()
);

export const setPaginationRequest = createAction(
  '[Pagination] SET_REQUEST',
  props<{ uid: string; payload: IPaginationOnlyRequest }>()
);

export const setPaginationResponse = createAction(
  '[Pagination] SET_RESPONSE',
  props<{ uid: string; payload: IPaginationResponse | null }>()
);
