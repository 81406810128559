import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Params } from '@angular/router';
import { ISelectedProductSource } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class SelectedProductService implements ISelectedProductSource {
  private readonly selectedProductIdSubject$ = new BehaviorSubject<
    number | null
  >(null);
  readonly selectedProductId$ = this.selectedProductIdSubject$.asObservable();

  private readonly paramsSubject$ = new BehaviorSubject<Params>({});
  readonly params$ = this.paramsSubject$.asObservable();

  setSelectedProductId(selectedProductId: number | null): void {
    this.selectedProductIdSubject$.next(selectedProductId);
  }

  setParams(params: Params): void {
    this.paramsSubject$.next(params);
  }
}
