import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IFilterMinMax } from '@inaripro-nx/common-ui';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash';

@Component({
  selector: 'design-range-inputs',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './design-range-inputs.component.html',
  styleUrl: './design-range-inputs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignRangeInputsComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Input() range: IFilterMinMax | null = null;
  @Input() values: IFilterMinMax | null = null;
  @Input() step = 1;

  @Output() changeModel: EventEmitter<IFilterMinMax> = new EventEmitter();

  readonly DEFAULT_FILTER: IFilterMinMax = {
    minValue: 0.01,
    maxValue: 10000000,
  };

  public formGroup: FormGroup = this.formBuilder.group({
    minValue: [this.values ? this.values.minValue : null],
    maxValue: [this.values ? this.values.maxValue : null],
  });

  private formSubscription?: Subscription;

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      'values' in simpleChanges &&
      !isEqual(this.values, this.formGroup.value)
    ) {
      this.setValues(this.values || { minValue: null, maxValue: null });
    }
  }

  ngOnInit() {
    this.formSubscription = this.formGroup.valueChanges
      .pipe(debounceTime(2000), distinctUntilChanged())
      .subscribe((values) => {
        this.updateFormValues(values);
      });
  }

  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
  }

  updateFormValues(values: IFilterMinMax) {
    const formValues = this.setValues(values);
    this.changeModel.emit(formValues);
  }

  private setValues(values: IFilterMinMax): IFilterMinMax {
    const range = this.range || this.DEFAULT_FILTER;
    const formValues: IFilterMinMax = {
      minValue: values.minValue !== null ? +values.minValue : null,
      maxValue: values.maxValue !== null ? +values.maxValue : null,
    };

    const setMin = (minValue: number | null) => {
      formValues.minValue = minValue;
      this.formGroup.patchValue({ minValue }, { emitEvent: false });
    };

    const setMax = (maxValue: number | null) => {
      formValues.maxValue = maxValue;
      this.formGroup.patchValue({ maxValue }, { emitEvent: false });
    };

    if (formValues.minValue === null) {
      setMin(formValues.minValue);
    } else {
      if (
        formValues.maxValue !== null &&
        formValues.minValue > formValues.maxValue
      ) {
        setMin(formValues.maxValue);
      } else if (range.minValue && formValues.minValue < range.minValue) {
        setMin(range.minValue);
      } else if (range.maxValue && formValues.minValue > range.maxValue) {
        setMin(range.maxValue);
      } else {
        setMin(formValues.minValue);
      }
    }

    if (formValues.maxValue === null) {
      setMax(formValues.maxValue);
    } else {
      if (
        formValues.minValue !== null &&
        formValues.minValue > formValues.maxValue
      ) {
        setMax(formValues.minValue);
      } else if (range.minValue && formValues.maxValue < range.minValue) {
        setMax(range.minValue);
      } else if (range.maxValue && formValues.maxValue > range.maxValue) {
        setMax(range.maxValue);
      } else {
        setMax(formValues.maxValue);
      }
    }

    return formValues;
  }
}
