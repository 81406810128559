import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { combineLatest, map, Observable, shareReplay, tap } from 'rxjs';
import { OrderDesignProductService } from '../../../../../../services/order-design-product/order-design-product.service';
import { ContentProductOrderInputComponent } from '../content-product-order-input/content-product-order-input.component';
import { ContentProductOrderPriceComponent } from '../content-product-order-price/content-product-order-price.component';

@Component({
  selector: 'painter-content-product-order',
  standalone: true,
  imports: [
    CommonModule,
    DesignUiModule,
    ContentProductOrderInputComponent,
    ContentProductOrderPriceComponent,
  ],
  templateUrl: './content-product-order.component.html',
  styleUrls: ['./content-product-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentProductOrderComponent {
  readonly countControl = new FormControl(0);

  readonly publicPrice$ = this.orderDesignProductService.publicPrice$;
  readonly orderCount$ = this.orderDesignProductService.orderCount$;
  readonly lotSize$ = this.orderDesignProductService.lotSize$;
  readonly minRange$ = this.orderDesignProductService.minRange$;

  readonly perCountPrice$ = combineLatest([
    this.orderDesignProductService.priceDiscountValue$,
    this.orderDesignProductService.priceBaseValue$,
  ]).pipe(
    map(
      ([priceDiscountValue, priceBaseValue]) =>
        priceDiscountValue || priceBaseValue || 0
    ),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly totalPrice$: Observable<number> = combineLatest([
    this.orderCount$,
    this.perCountPrice$,
  ]).pipe(
    tap(([orderCount]) =>
      this.countControl.setValue(orderCount, { emitEvent: false })
    ),
    map(([orderCount, perCountPrice]) => orderCount * perCountPrice),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly isDisable$ = combineLatest([
    this.orderDesignProductService.isAddButtonDisabled$,
    this.orderDesignProductService.updatingPrice$,
  ]).pipe(
    map(
      ([isAddButtonDisabled, updatingPrice]) =>
        isAddButtonDisabled || updatingPrice || false
    ),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly unit$ = this.orderDesignProductService.unit$;

  constructor(
    private readonly orderDesignProductService: OrderDesignProductService
  ) {}

  addProductToCart() {
    this.orderDesignProductService.addProductToCart();
  }

  setOrderCount(count: number) {
    this.orderDesignProductService.setOrderCount(count);
  }
}
