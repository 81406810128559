<div
  class="tabs tabs-object"
  *ngIf="{
    activeDesignSide: activeDesignSide$ | async,
    page: page$ | async,
    object: object$ | async
  } as state"
>
  <ng-container *ngIf="state.activeDesignSide">
    <div
      class="tabs-subrow"
      [class.tabs-subrow-objects]="
        state.page === EPage.objects && state.object !== EObject.templates
      "
      *ngIf="state.page === EPage.objects"
    >
      <div
        *ngIf="isFillTab$ | async"
        class="tab"
        [class.tab-active]="
          isActiveObjects(state.page) && state.object === EObject.fill
        "
        (click)="setObject(EObject.fill)"
      >
        <i class="icon icon-fill"></i>
        <span>Фон</span>
      </div>
      <div
        class="tab"
        [class.tab-active]="
          isActiveObjects(state.page) && state.object === EObject.pictures
        "
        (click)="setObject(EObject.pictures)"
      >
        <i class="icon icon-ic_picture"></i>
        <span>Картинки</span>
      </div>
      <div
        class="tab"
        [class.tab-active]="
          isActiveObjects(state.page) && state.object === EObject.text
        "
        (click)="setObject(EObject.text)"
      >
        <i class="icon icon-ic_letter-t"></i>
        <span>Текст</span>
      </div>
      <div
        class="tab"
        [class.tab-active]="
          isActiveObjects(state.page) && state.object === EObject.figures
        "
        (click)="setObject(EObject.figures)"
      >
        <i class="icon icon-ic_figures"></i>
        <span>Фигуры</span>
      </div>
      <div
        class="tab"
        [class.tab-active]="
          isActiveObjects(state.page) && state.object === EObject.qrcodes
        "
        (click)="setObject(EObject.qrcodes)"
      >
        <i class="icon icon-ic_qr-code"></i>
        <span>QR-код</span>
      </div>
    </div>
    <div class="tabs-row tabs-row__shadow">
      <div
        class="tab"
        [class.tab-downmenu-active]="state.page === EPage.product"
        (click)="setPage(EPage.product)"
      >
        <i class="icon icon-ic_product"></i>
        <span>Продукт</span>
      </div>
      <div
        class="tab"
        [class.tab-downmenu-active]="
          state.page === EPage.objects && state.object !== EObject.templates
        "
        (click)="setPage(EPage.objects); setObject(EObject.pictures)"
      >
        <i class="icon icon-ic_picture"></i>
        <span>Объекты</span>
      </div>
      <div
        class="tab"
        [class.tab-downmenu-active]="state.page === EPage.layers"
        (click)="setPage(EPage.layers)"
      >
        <i class="icon icon-ic_layers"></i>
        <span>Слои</span>
      </div>
      <div
        class="tab"
        [class.tab-downmenu-active]="state.page === EPage.design"
        (click)="setPage(EPage.design)"
      >
        <i class="icon icon-ic_pencil"></i>
        <span>Дизайн</span>
      </div>
      <div
        class="tab"
        [class.tab-downmenu-active]="
          isActiveObjects(state.page) && state.object === EObject.templates
        "
        (click)="setPage(EPage.objects); setObject(EObject.templates)"
      >
        <i class="icon icon-ic_figure-star"></i>
        <span>Шаблоны</span>
      </div>
    </div>
  </ng-container>
</div>
