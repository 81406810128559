import { Injectable } from '@angular/core';
import { CatalogNomenclatureStore, IPricesRange } from '@inaripro-nx/catalog';
import {
  IOrderProductSource,
  IProductDesignRequest,
  IProductRequest,
  OrderCartStore,
} from '@inaripro-nx/order';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  Observable,
  of,
  shareReplay,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs';
import { HistoryStore } from '../../state/history/history.store';
import { ProductStore } from '../../state/product/product.store';
import { ExportService } from '../export/export.service';
import { getOrderParams, getSumCountUid } from '../../../../utils';
import { TemplatesService } from '../templates/templates.service';
import { ShareService } from '@inaripro-nx/common-ui';

@Injectable({
  providedIn: 'root',
})
export class OrderDesignProductService implements IOrderProductSource {
  readonly updatingPrice$ = this.productStore.updatingPrice$;
  readonly publicPrice$ = this.productStore.price$;
  readonly countWithBasket$ = this.productStore.countWithBasket$;
  readonly orderCount$ = this.productStore.orderCount$;

  readonly priceRangeIndex$: Observable<number> = combineLatest([
    this.publicPrice$,
    this.countWithBasket$,
  ]).pipe(
    map(([publicPrice, countWithBasket]) => {
      if (!publicPrice) {
        return 0;
      }

      const index = (publicPrice.ranges || []).findIndex((r) => {
        return (
          (r.upper &&
            countWithBasket >= r.lower &&
            countWithBasket <= r.upper) ||
          (!r.upper && countWithBasket >= r.lower)
        );
      });

      return index > -1 ? index : 0;
    }),
    distinctUntilChanged(),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly priceRange$: Observable<IPricesRange> = combineLatest([
    this.publicPrice$,
    this.priceRangeIndex$,
  ]).pipe(
    debounceTime(0),
    map(([publicPrice, priceRangeIndex]) => {
      return (publicPrice?.ranges || [])[priceRangeIndex] || null;
    }),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly priceBaseValue$: Observable<number | null> = combineLatest([
    this.publicPrice$,
    this.priceRangeIndex$,
  ]).pipe(
    map(([publicPrice, priceRangeIndex]) => {
      return (publicPrice?.baseCost.vs || [])[priceRangeIndex] || null;
    }),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly priceDiscountValue$: Observable<number | null> = combineLatest([
    this.publicPrice$,
    this.priceRangeIndex$,
  ]).pipe(
    map(([publicPrice, priceRangeIndex]) => {
      return (publicPrice?.discountCost?.vs || [])[priceRangeIndex] || null;
    }),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  readonly lotSize$: Observable<number | undefined> =
    this.productStore.product$.pipe(
      map((product) => product?.lotSize),
      shareReplay({ refCount: false, bufferSize: 1 })
    );

  readonly minRange$: Observable<number | undefined> =
    this.productStore.product$.pipe(
      map((product) => product?.minRange),
      shareReplay({ refCount: false, bufferSize: 1 })
    );

  readonly mergeProductStatus$ = this.orderStore.mergeProductStatus$;
  readonly isAddButtonDisabled$ = this.orderStore.isAddButtonDisabled$;

  readonly updatingProduct$ = merge(
    this.productStore.activeDesignProduct$.pipe(map(() => true)),
    this.productStore.zoneColors$.pipe(map(() => true)),
    this.productStore.zonePatterns$.pipe(map(() => true)),
    this.productStore.zoneTranslates$.pipe(map(() => true)),
    this.historyStore.elements$.pipe(map(() => true)),
    this.updatingPrice$
  );

  readonly unit$ = this.catalogNomenclatureStore.treeItem$.pipe(
    map((category) => {
      return category ? category.unit : null;
    })
  );

  private readonly designerSource$ = this.productStore.designerSource$;

  constructor(
    private readonly productStore: ProductStore,
    private readonly orderStore: OrderCartStore,
    private readonly historyStore: HistoryStore,
    private readonly templatesService: TemplatesService,
    private readonly shareService: ShareService,
    private readonly exportService: ExportService,
    private readonly catalogNomenclatureStore: CatalogNomenclatureStore,
    private readonly orderCartStore: OrderCartStore
  ) {}

  addProductToCart() {
    const shareTemplate$ = this.historyStore.uuid$.pipe(
      switchMap((templateUid) => {
        return templateUid
          ? this.templatesService.createShareTemplate(templateUid)
          : of(null);
      })
    );

    return this.exportService
      .getProductSides()
      .pipe(
        take(1),
        withLatestFrom(
          this.productStore.orderCount$,
          this.productStore.product$,
          this.productStore.price$,
          this.designerSource$,
          this.catalogNomenclatureStore.listItems$,
          shareTemplate$
        ),
        map(
          ([
            sides,
            orderCount,
            product,
            price,
            designerSource,
            listItems,
            shareTemplate,
          ]) => {
            if (
              !product ||
              !designerSource ||
              !price ||
              !sides ||
              !orderCount
            ) {
              return null;
            }

            const sumCountUid = getSumCountUid(designerSource);
            const { fullColor, productId } = designerSource;
            const { uid: priceUid } = price;

            const { designElementsIds, orderElementsIds } =
              getOrderParams(designerSource);

            const design: IProductDesignRequest = {
              sides,
              fullColor,
            };

            if (shareTemplate?.uid) {
              design.templateUid = shareTemplate.uid;
              design.shareUrl = this.shareService.getHref(
                { templateUid: shareTemplate.uid },
                false
              );
            }

            const request: IProductRequest = {
              productId,
              priceUid,
              count: orderCount,
              designElementsIds,
              orderElementsIds,
              sumCountUid,
              design,
            };

            const linkId = (listItems || []).find(
              ({ dictionaryId }) =>
                dictionaryId === product.containerDictionaryId
            )?.id;

            if (linkId) {
              request.linkId = linkId;
            }

            return request;
          }
        )
      )
      .subscribe((request) => {
        if (request) {
          this.orderCartStore.addProductToCart(request);
        }
      });
  }

  setOrderCount(count: number) {
    this.productStore.setOrderCount(count);
  }
}
