import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import {
  IFiltersValues,
  IProductFilters,
  IPropertyForFilter,
} from '../../../../interfaces';
import { IFilterMinMax } from '@inaripro-nx/common-ui';
import { CatalogProductListFilterWrapperComponent } from '../catalog-product-list-filter-wrapper/catalog-product-list-filter-wrapper.component';

@Component({
  selector: 'catalog-product-list-filter-input',
  standalone: true,
  imports: [
    CommonModule,
    DesignUiModule,
    CatalogProductListFilterWrapperComponent,
  ],
  templateUrl: './catalog-product-list-filter-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogProductListFilterInputComponent {
  @Input() filters!: IProductFilters;
  @Input() filterValues!: IFiltersValues;
  @Input() prop!: IPropertyForFilter;

  @Output() changePropertyFilter = new EventEmitter<IFilterMinMax>();
  @Output() clearFilter = new EventEmitter<void>();
}
