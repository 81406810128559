import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ToolButtonComponent } from '@inaripro-nx/design-ui';

@Component({
  selector: 'painter-editor-history-controls',
  standalone: true,
  templateUrl: './editor-history-controls.component.html',
  styleUrls: ['./editor-history-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ToolButtonComponent],
})
export class EditorHistoryControlsComponent {
  @Input() hasPrev: boolean | null = null;
  @Input() hasNext: boolean | null = null;

  @Output() prevHistory = new EventEmitter<void>();
  @Output() nextHistory = new EventEmitter<void>();
}
