import {
  IFileResponse,
  IMatches,
  ImageSizeEnum,
  IFilterMinMax,
} from '@inaripro-nx/common-ui';
import {
  ProductCalcModeTypeEnum,
  ProductCardTypeEnum,
  ProductPriceTypeEnum,
  ProductTypeEnum,
} from '../enums/catalog-product.enum';
import {
  ICategoryIdentify,
  ICategoryProductPropertyExtended,
} from './catalog-nomenclature-category.interface';

// not for export
interface IXY {
  x: number;
  y: number;
}

export interface IProductForList {
  id: number;
  containerDictionaryId: number;
  name: string;
  isActive: boolean;
  createTime: string;
  logo?: IFileResponse[];
  crashed?: boolean;
  actionBestSeller?: boolean;
  actionIsNew?: boolean;
  onlinePayment: boolean;
  hasDesigner?: boolean;
}

export interface IProductAutoColor {
  baseProductId?: number;
  autoCreatePropertyId?: number;
}

export interface IPublicProductForList
  extends IProductForList,
    IProductAutoColor {
  productType: ProductTypeEnum;
  cardType: ProductCardTypeEnum;
  colors?: string[];
  company?: {
    id: number;
    name: string;
  };
  priceUid?: string;
  basePrice?: number;
  discountPrice?: number;
  minRange?: number;
  cardDescription?: string;
  actionIsDiscount?: boolean;
  stockCount?: {
    city: number;
    main: number;
    total: number;
  };
  brand?: string;
}

export interface IProductPropertyExtended
  extends ICategoryProductPropertyExtended {
  productProperty: IProductProperty;
}

export interface IProductPostListRequest {
  nomenclature: number;
  selectedTypeElementIds?: number[];
  characterFilters?: IPropertyFilter[];
  query?: string;
  companies?: number[];
  cities?: number[];
  sum?: IFilterMinMax;
  range?: number;
  productType?: ProductTypeEnum;
  baseProductId?: number;
  excludeProductId?: number;
  excludeCalculators?: boolean;
  cityStock?: boolean;
  allStocks?: boolean;
  userCityId?: number;
  strongSearch?: boolean;
  hasDesigner?: boolean;
}

export interface IPropertyFilter extends IFilterMinMax {
  characterId: number;
  valuesIsDefault?: boolean;
}

export interface IChildData {
  baseProductId?: number;
  priceComment?: string;
}

export interface IProduct extends IProductWithoutPrice {
  prices: IPrices;
}

interface IProductWithoutPrice
  extends IProductForList,
    IChildData,
    IProductAutoColor {
  productType: ProductTypeEnum;
  company: {
    id: number;
    name: string;
    canUseOnlinePayment: boolean;
    canUseDesigner: boolean;
  };
  shortDescription?: string;
  description: string;
  album: IFileResponse[];
  previews: IPublicAlbumThumbs[];
  video?: string[];
  previewPhotoId?: number[];
  productProperties: IProductProperty[];
  priceUnits?: IPriceUnit[];
  calculatorIds?: number[];
  maxRange: number;
  maxPrice: number;
  metaTitle: string; // maxlength 55
  metaDescription: string; // maxlength 130
  metaKeywords: string; // maxlength 250
  lotSize?: number;
  usefulInfoList?: IUsefulInfoList[];
  deliveryInfo?: string;
  deliveryParams: IProductDeliveryParams;
  designProducts?: IDesignProduct[];
  designFullColorPropertyId?: number;
}

export interface IPublicProductDesignSeo {
  title?: string;
  shortDescription?: string;
  description?: string;
  keywords?: string;
  index: boolean;
}

export interface IPublicProduct extends IProductWithoutPrice {
  containers: ICategoryIdentify[];
  minRange?: number;
  rating: number;
  numberOfVoices: number;
  actionIsDiscount?: boolean;
  productTypeHelpText?: string;
  stockBalancesCount: number;
  designSeo?: IPublicProductDesignSeo;
}

export interface IPublicProductWithPrice extends IPublicProduct {
  price: IPublicPrice;
}

export interface IDesignProduct {
  id: number;
  name: string;
  sides: IDesignSide[];
  isActive: boolean;
  properties?: IDesignProperty[];
  kitUids?: string[];
}

export enum EMockupType {
  down = 'down', // as default for all old sides
  up = 'up',
}

export interface IDesignSide {
  id?: number;
  name: string;
  image: IFileResponse;
  sizePX: IXY;
  sizeMM: IXY;
  zones: IDesignZone[];
  mockupType: EMockupType;
  isFullPrint: boolean; // always false for EMockupType.down
  hasColorPalette: boolean; // always false for EMockupType.down
}

export interface IDesignProperty {
  id: number;
  selectedElements?: number[];
}

export interface IArea {
  startPX: IXY; // Set value in %%
  startMM: IXY;
  sizePX: IXY; // Set value in %%
  sizeMM: IXY;
  start?: IXY; // Set on front in px
  size?: IXY; // Set on front in px
  center?: IXY; // Set on front in px
}

export interface IDesignZone extends IArea {
  id?: number;
  name: string;
  hasColorPalette: boolean;
  moveArea: IArea | null;
  isOnlyFilling: boolean; // Default = false
  colorProperty?: IDesignProperty;
  _translate?: IXY;
}

export interface IProductDeliveryParams {
  length: number;
  width: number;
  height: number;
  weight: number;
}

export interface IProductProperty {
  id: number;
  selectedElements?: number[];
  val?: string | number;
}

export interface IPriceGroupRange {
  id: number;
  lower: number;
  upper: number;
}

export interface IPriceGroupCell {
  rowId: number;
  rangeId: number;
  value: number;
}

export interface IPublicAlbumThumbs {
  id: number;
  originId: number;
  name: string;
  size: ImageSizeEnum;
  url: string;
}

export interface IUsefulInfoList {
  id: number;
  name: string;
  useCount: number;
  slug: string;
}

export interface IUsefulInfoList extends IMatches {
  id: number;
  name: string;
  useCount: number;
  slug: string;
}

export interface IUsefulInfo extends IUsefulInfoList {
  content: string;
  files: IFileResponse[];
  isActive: boolean;
  useProducts: number[];
  keywords?: string[];
  metaTitle?: string; // maxlength 55
  metaDescription?: string; // maxlength 130
  metaKeywords?: string; // maxlength 250
}

export interface IStockCityPosition {
  positionCount: number;
  positionName: string;
}

export interface IStockCityBalances {
  cityId: number;
  cityName: string;
  count: number;
  positions: IStockCityPosition[];
}

export interface IStockCities {
  id: number;
  allStocksPositions: IStockCityPosition[];
  byCity: IStockCityBalances[];
}

export interface IPublicPriceByUid {
  uid: string;
  baseCost: IPricesCost;
  discountCost?: IPricesCost;
}

export interface IPublicPrice extends IPublicPriceByUid {
  type: ProductPriceTypeEnum;
  ranges: IPricesRange[];
  photos: { [uid: string]: IPricesPhoto }; // default {}
  articles: { [uid: string]: string }; // default {}
  kits?: IPricesKit[]; // only for PRICE_TYPES.BY_KIT
  properties?: IPublicPricesProperty[]; // only for PRICE_TYPES.BY_PROPERTY
  propertiesOrder?: number[]; // Array of propertyId
  seoMinPrice?: number;
  seoMaxPrice?: number;
  disabledPriceUids?: string[];
}

export interface IPricesCost {
  uid: string;
  vs: number[];
  disabled?: boolean;
  _naturals?: number[];
  _crashed?: boolean;
}

export interface IPricesProperty {
  id: number;
  isPlusPrice: boolean;
}

export interface IPublicPricesProperty extends IPricesProperty {
  elementIds: number[];
}

export interface IPlusCosts {
  type: ProductCalcModeTypeEnum;
  costs: IPricesCost[];
}

export interface IPricesKit {
  uid: string;
  name: string;
  position: number;
}

export interface IPricesRange {
  lower: number;
  upper: number;
}

export interface IPricesPhoto {
  files: IFileResponse[];
  previewId: number | null;
}

export interface IPrices {
  type: ProductPriceTypeEnum;
  ranges: IPricesRange[];
  photos: { [uid: string]: IPricesPhoto }; // default {}
  articles: { [uid: string]: string }; // default {}
  baseCosts: IPricesCost[]; // default []
  categoryCosts: { [categoryId: string]: IPlusCosts }; // default {}
  kitsCounter: number;
  kits?: IPricesKit[]; // only for PRICE_TYPES.BY_KIT
  properties?: IPricesProperty[]; // only for PRICE_TYPES.BY_PROPERTY
  propertiesPlusCosts?: { [propertyId: string]: IPlusCosts }; // only for PRICE_TYPES.BY_PROPERTY
  propertiesOrder?: number[]; // Array of propertyId
}

export interface IPropertiesTitlesMap {
  [uid: string]: IPropertiesTitles;
}

export interface IPropertiesTitles {
  uid: string;
  _elementsNames?: string[];
  _elementsColors?: string[];
}

export interface IPriceUnit {
  shortName: string;
  comment: string | null;
  coefficient: number;
}
