import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IMapOfBoolean } from '@inaripro-nx/common-ui';
import { IDesignSimpleSelectboxListItem } from '../../../../interfaces';

@Component({
  selector: 'design-simple-selectbox-selected',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './design-simple-selectbox-selected.component.html',
  styleUrl: './design-simple-selectbox-selected.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignSimpleSelectboxSelectedComponent implements OnChanges {
  @Input() selected: IMapOfBoolean = {};
  @Input() items: IDesignSimpleSelectboxListItem[] = [];
  @Input() isUseWrapperForSelectedList = true;

  @Output() unSelect: EventEmitter<number> = new EventEmitter<number>();

  selectedItems: Array<IDesignSimpleSelectboxListItem> = [];

  constructor() {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['selected']) {
      const selected = { ...this.selected };
      const result: IDesignSimpleSelectboxListItem[] = [];

      Object.keys(selected).forEach((key) => {
        if (selected[key]) {
          const itemListElement = this.items.find((item) => item.id === +key);
          if (itemListElement) {
            result.push({
              id: +itemListElement.id,
              name: itemListElement.name,
            });
          }
        }
      });

      this.selectedItems = result;
    }
  }

  public unSelectClick(selectId: number): void {
    this.unSelect.emit(selectId);
  }
}
