import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IProductTemplate } from '@inaripro-nx/painter';
import { map, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductTemplatesService {
  API_URL = '/catalog/partner/goods/design_template/';

  constructor(private http: HttpClient) {}

  all(productId: number, isPartner = false) {
    const url = `/catalog/${
      isPartner ? 'partner' : 'public'
    }/goods/${productId}/design_template/`;

    return this.http
      .get<{ data: IProductTemplate[] }>(url)
      .pipe(map((response) => response.data));
  }

  add(model: Partial<IProductTemplate>) {
    return this.http.post<IProductTemplate>(
      `/catalog/partner/goods/${model.productId}/design_template/`,
      model
    );
  }

  update(model: IProductTemplate) {
    return this.http.put<IProductTemplate>(`${this.API_URL}${model.id}`, model);
  }

  delete(modelId: number) {
    //return throwError('Mistake');
    return this.http.delete(`${this.API_URL}${modelId}`);
  }

  getTemplate(templateId: number) {
    const url = `/catalog/public/goods/design_template/${templateId}`;
    return this.http.get<IProductTemplate>(url);
  }
}
