export function replaceUrl(window: Window, key: string, value?: string) {
  try {
    const title = window.document.title;

    const url = new URL(window.location.href);

    if (value !== undefined) {
      url.searchParams.set(key, value || '');
    } else {
      url.searchParams.delete(key);
    }

    window.history.replaceState(null, title, url.href);
  } catch (e) {}
}

export function fixUrl(url: string) {
  if (url && url.indexOf('http://') !== 0 && url.indexOf('https://') !== 0) {
    return `http://${url}`;
  }

  return url;
}
