import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CatalogNomenclatureDictionaryRecords,
  ICatalogNomenclatureTreeItem,
} from '@inaripro-nx/catalog';
import { Links } from '../../../../../../../links/src/lib/interfaces/links.interface';
import { DropdownDelayHoverDirective } from '../../directives/dropdown-delay-hover.directive';
import { HeaderLinkInfoComponent } from '../header-link-info/header-link-info.component';
import { HeaderLinkComponent } from '../header-link/header-link.component';
import { HeaderMenuContactsComponent } from '../header-menu-contacts/header-menu-contacts.component';

@Component({
  selector: 'shell-header-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    HeaderMenuContactsComponent,
    HeaderLinkComponent,
    HeaderLinkInfoComponent,
    DropdownDelayHoverDirective,
  ],
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMenuComponent implements OnChanges {
  @Input() dictionary: CatalogNomenclatureDictionaryRecords | null = null;
  @Input() treeItems: ICatalogNomenclatureTreeItem[] | null = null;
  @Input() links: Links | null = null;

  isNoAllContactsInHeader = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['links']?.currentValue) {
      this.isNoAllContactsInHeader =
        this.links?.contacts?.some((c) => !c.isHeader) ||
        this.links?.piterContacts?.some((c) => !c.isHeader) ||
        this.links?.moscowContacts?.some((c) => !c.isHeader) ||
        false;
    }
  }
}
