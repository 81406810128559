import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IPublicProductFilterCompany } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class CatalogCompanyService {
  constructor(private readonly httpClient: HttpClient) {}

  getCompaniesByNomenclatureIdAndCities(
    nomenclatureId: number,
    cities: number[],
    hasDesigner = false
  ): Observable<IPublicProductFilterCompany[]> {
    return this.httpClient
      .post<{ data: IPublicProductFilterCompany[] }>(
        '/catalog/public/companies',
        { nomenclatureId, cities, hasDesigner }
      )
      .pipe(map(({ data }) => data));
  }
}
