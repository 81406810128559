@if ({
  modalOpened: modalOpened$ | async,
  element: activeElement$ | async,
  index: activeElementIndex$ | async
}; as state) {
  @if (state.modalOpened && state.element !== null && state.index !== null) {
    <crop-modal
      [image]="state.element.type === EElementType.image ? state.element.url : null"
      [imageWidth]="(state.element | imageMask).originalImageWidth || state.element.size.x"
      [imageHeight]="(state.element | imageMask).originalImageHeight || state.element.size.y"
      [mask]="(state.element | imageMask).mask"
      [maskX]="(state.element | imageMask).x"
      [maskY]="(state.element | imageMask).y"
      [maskWidth]="(state.element | imageMask).width"
      [maskHeight]="(state.element | imageMask).height"
      [selectedFigureId]="(state.element | imageMask).selectedFigureId || (selectedFigureId$ | async)"
      [offsetX]="(state.element | imageMask).offsetX"
      [offsetY]="(state.element | imageMask).offsetY"
      [zoom]="(state.element | imageMask).zoom"
      [figures]="figures$ | async"
      [isDesktop]="isDesktop"
      (save)="cropImage($event, { index: state.index, element: state.element })"
    ></crop-modal>
  }
}
