<ng-container *ngIf="contacts.length > 0">

  <ul class="contacts-list">

    <ng-container *ngIf="moscowContacts.length > 0">
      <li class="contacts-list__item contacts-list__item-main-city" *ngFor="let contact of moscowContacts">
        <ng-container
          [ngTemplateOutlet]="contactLink"
          [ngTemplateOutletContext]="{ contact: contact }"
        ></ng-container>
      </li>
    </ng-container>


    <ng-container *ngIf="piterContacts.length > 0">
        <li class="contacts-list__item contacts-list__item-main-city" *ngFor="let contact of piterContacts">
          <ng-container
            [ngTemplateOutlet]="contactLink"
            [ngTemplateOutletContext]="{ contact: contact }"
          ></ng-container>
        </li>
    </ng-container>

    <li class="contacts-list__item" *ngFor="let contact of contacts">
      <ng-container
        [ngTemplateOutlet]="contactLink"
        [ngTemplateOutletContext]="{ contact: contact }"
      ></ng-container>
    </li>
  </ul>
</ng-container>

<ng-template #contactLink let-contact="contact">
  <shell-header-link
    *ngIf="contact?.isHeader"
    class="contacts-list__link"
    [link]="contact.slug ? '/contacts/' + contact.slug : null"
    [externalLink]="contact.externalLink ? contact.externalLink : null"
    [name]="contact.name + ' ' +(contact.phone || '')"
    [shortNotification]="contact?.shortNotification || ''"
  ></shell-header-link>
</ng-template>
