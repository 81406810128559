import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { EElementType } from '../../../../interfaces/editor.interface';
import { ProductStore } from '../../../../state/product/product.store';
import { ISVGObject } from '../../interfaces/content.interface';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  Subject,
  switchMap,
} from 'rxjs';
import { IFigure } from '@inaripro-nx/common-ui';

const bufferRows = 1;
const widthFigurePx = 80;
const heightFigurePx = 80;

@Component({
  selector: 'painter-content-figures',
  standalone: true,
  imports: [CommonModule, DesignUiModule, ReactiveFormsModule],
  templateUrl: './content-figures.component.html',
  styleUrls: ['./content-figures.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentFiguresComponent {
  @Output() selectObject: EventEmitter<ISVGObject> = new EventEmitter();

  @ViewChild('scrollContainer', { static: true }) scrollContainer!: ElementRef;
  @ViewChild('contentContainer', { static: true })
  contentContainer!: ElementRef;

  readonly control = new FormControl('');
  scrollEvent$: Subject<Event> = new Subject<Event>();

  beforeHeight = 0;
  afterHeight = 0;

  private readonly findFigures$ = this.productStore.figures$.pipe(
    filter((figures) => !!figures),
    switchMap((figures) => {
      return this.control.valueChanges.pipe(
        startWith(''),
        debounceTime(300),
        distinctUntilChanged(),
        map((searchString) =>
          (searchString || '')
            .toLowerCase()
            .split(' ')
            .filter((word) => !!word)
        ),
        map((words) => {
          if (!words.length) {
            return figures;
          } else {
            return (figures || []).filter((f) => {
              return words.some((word) => {
                return (
                  f.name.toLowerCase().indexOf(word) > -1 ||
                  (f.tags || '').toLowerCase().indexOf(word) > -1
                );
              });
            });
          }
        })
      );
    }),
    shareReplay(1)
  );

  readonly figures$ = this.findFigures$.pipe(
    switchMap((figures) =>
      this.scrollEvent$.pipe(
        startWith(null),
        debounceTime(200),
        map((event) =>
          event === null ? null : this.scrollContainer.nativeElement.scrollTop
        ),
        map((scrollTop) => {
          const { offsetHeight } = this.scrollContainer.nativeElement;
          const { offsetWidth } = this.contentContainer.nativeElement;

          const columns = Math.floor(offsetWidth / widthFigurePx);
          const rows = Math.ceil((figures || []).length / columns);

          const showRows = Math.ceil(offsetHeight / heightFigurePx);
          const scrolledRow = Math.floor(scrollTop / heightFigurePx);

          let startRow = scrolledRow - bufferRows;
          startRow = startRow < 0 ? 0 : startRow;

          const endRow = scrolledRow + showRows + bufferRows;

          this.beforeHeight = startRow > 0 ? startRow * heightFigurePx : 0;
          this.afterHeight =
            endRow < rows ? (rows - endRow) * heightFigurePx : 0;

          const startIndex = startRow * columns;
          const endIndex = endRow * columns;

          return (figures || []).slice(startIndex, endIndex);
        }),
        shareReplay(1)
      )
    )
  );

  readonly EElementType = EElementType;

  constructor(
    private productStore: ProductStore,
    private cdr: ChangeDetectorRef
  ) {}

  onSelectObject(data: {
    url: string;
    svg: string;
    bBox: { width: number; height: number };
  }) {
    this.selectObject.emit({
      type: EElementType.figure,
      text: null,
      ...data,
    });
  }

  trackByFigure(index: number, item: IFigure) {
    return item.id;
  }
}
