import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DOCUMENT } from '@angular/common';

interface IMask {
  mask: string | null;
}

interface IMaskData {
  x: number;
  y: number;
  width: number;
  height: number;
  originalImageWidth: number;
  originalImageHeight: number;
  selectedFigureId: number | null;
}

type MaskType = IMask & IMaskData;

@Pipe({
  name: 'imageMask',
})
export class ImageMaskPipe implements PipeTransform {
  static DEFAULT_MASK_DATA: MaskType = {
    mask: null,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    originalImageWidth: 0,
    originalImageHeight: 0,
    selectedFigureId: null,
  };

  static isMaskData(data: IMaskData | unknown): data is IMaskData {
    return (data as IMaskData).selectedFigureId !== undefined;
  }

  constructor(
    // It's document injection must stay on server for right render html
    @Inject(DOCUMENT) private document: Document
  ) {}

  transform<T extends { svg?: string }>(element?: T): MaskType {
    if (!element?.svg) {
      return ImageMaskPipe.DEFAULT_MASK_DATA;
    }

    const svgElement = this.document.createElementNS(
      'http://www.w3.org/2000/svg',
      'svg'
    );
    svgElement.innerHTML = element.svg;

    const mask = svgElement.getElementsByTagName('mask')[0];
    if (!mask) {
      return ImageMaskPipe.DEFAULT_MASK_DATA;
    }

    const isMaskData = ImageMaskPipe.isMaskData(mask.dataset);

    return {
      mask: mask.innerHTML,
      x: isMaskData ? Number(mask.dataset.x) : 0,
      y: isMaskData ? Number(mask.dataset.y) : 0,
      width: isMaskData ? Number(mask.dataset.width) : 0,
      height: isMaskData ? Number(mask.dataset.height) : 0,
      originalImageWidth: isMaskData
        ? Number(mask.dataset.originalImageWidth)
        : 0,
      originalImageHeight: isMaskData
        ? Number(mask.dataset.originalImageHeight)
        : 0,
      selectedFigureId: isMaskData
        ? Number(mask.dataset.selectedFigureId)
        : null,
    };
  }
}
