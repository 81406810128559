@if (isUseWrapperForSelectedList) {
  <div
    class="select-box-wrapper"
    [class.selectbox-wrapper--active]="isActive"
    designClickOutside
    (clickOutsideEvent)="hideDropdown()"
  >
    <design-simple-selectbox-input
      #selectBoxInput
      [placeholder]="placeholder"
      [animatePlaceholder]="animatePlaceholder"
      [count]="selectedItems | selectedItemsSize"
      [search]="isActive"
      (click)="showDropdown($event)"
      (searchOut)="onSearch($event)"
      (toggleOut)="toggleDropdown()"
    ></design-simple-selectbox-input>

    <design-simple-selectbox-dropdown
      [listItems]="listItems"
      [filterItems]="filterItems"
      [modificatorSimplebar]="modificatorSimplebar"
      [selectedItems]="selectedItems"
      [submitDisabled]="submitDisabled"
      [showSelectAll]="showSelectAll"
      [isActive]="isActive"
      (selectAllDropdown)="onSelectAll()"
      (clearAllDropdown)="onReset()"
      (changeModelDropdown)="onApply($event)"
      (toggleDropdown)="selectItem($event)"
    ></design-simple-selectbox-dropdown>
  </div>
}

<design-simple-selectbox-selected
  class="select-box-selected"
  [items]="listItems"
  [selected]="selectedItems"
  (unSelect)="unselected($event)"
  [isUseWrapperForSelectedList]="isUseWrapperForSelectedList"
></design-simple-selectbox-selected>
