import { CommonModule } from '@angular/common';
import { HttpEvent } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { IDesignProduct, IPublicPrice } from '@inaripro-nx/catalog';

import {
  IDesignerProduct,
  IDesignerSource,
  IPropertyInfo,
} from '../../interfaces';

import {
  AppShellConfig,
  IFigure,
  IFilter,
  IPattern,
  IPatternLibrary,
  IPicture,
  IPictureLibrary,
  IQRCode,
  isTouchDeviceUseMatchMedia,
  IWindowSize,
  StorageService,
  WINDOW_SIZE,
} from '@inaripro-nx/common-ui';
import { DesignUiModule, ModalWindowStore } from '@inaripro-nx/design-ui';
import {
  combineLatest,
  debounceTime,
  map,
  Observable,
  of,
  Subscription,
  tap,
  withLatestFrom,
} from 'rxjs';
import { ExportModule } from './components/export/export.module';
import { MainDesktopComponent } from './components/main-desktop/main-desktop.component';
import { MainMobileComponent } from './components/main-mobile/main-mobile.component';
import {
  ModalAiComponent,
  ModalReachFileLimitComponent,
  PicturesLibraryService,
  PicturesService,
} from '@inaripro-nx/pictures';
import { ModalDownloadPdfComponent } from './components/modal-download-pdf/modal-download-pdf.component';
import { ModalSaveChangesComponent } from './components/modal-save-changes/modal-save-changes.component';
import { ModalTemplateComponent } from './components/modal-template/modal-template.component';
import {
  EObject,
  EPage,
  getStorageNameProductElements,
  MODAL_UID_SAVE_CHANGES_REQUEST,
} from './interfaces/main.interface';
import {
  IProductTemplate,
  IShareResponse,
  IShareTemplate,
  ITemplate,
} from '../../interfaces';
import { ActionsService } from './services/actions/actions.service';
import { BrowserService } from './services/browser/browser.service';
import { EditorService } from './services/editor/editor.service';
import { ExportService } from './services/export/export.service';
import { MyTemplatesService } from './services/my-templates/my-templates.service';
import { OrderDesignProductService } from './services/order-design-product/order-design-product.service';
import { PatternsService } from './services/patterns/patterns.service';
import { ProductTemplatesService } from './services/product-templates/product-templates.service';
import { QrCodesService } from './services/qr-codes/qr-codes.service';
import { ShareTemplatesService } from './services/share-templates/share-templates.service';
import { TemplatesService } from './services/templates/templates.service';
import { WindowToolsService } from './services/window-tools/window-tools.service';
import { HistoryStore } from './state/history/history.store';
import { MainStore } from './state/main/main.store';
import { ProductStore } from './state/product/product.store';
import { HeaderService } from '@inaripro-nx/shell';
import { MainService } from './services/main/main.service';
import { ExportZoneModule } from './components/export-zone/export-zone.module';
import { RotateDeviceComponent } from './components/rotate-device/rotate-device.component';
import { EDeviceOrientation } from '@inaripro-nx/common-ui';
import { ModalCropComponent } from './components/modal-crop/modal-crop.component';

@Component({
  selector: 'painter-main',
  standalone: true,
  imports: [
    CommonModule,
    MainDesktopComponent,
    MainMobileComponent,
    DesignUiModule,
    ExportModule,
    ExportZoneModule,
    ModalAiComponent,
    ModalReachFileLimitComponent,
    ModalDownloadPdfComponent,
    ModalTemplateComponent,
    ModalSaveChangesComponent,
    RotateDeviceComponent,
    ModalCropComponent,
  ],
  providers: [EditorService, ActionsService, StorageService],
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Input() designerSource: IDesignerSource | null = null;
  @Input() product: IDesignerProduct | null = null;
  @Input() price: IPublicPrice | null = null;
  @Input() updatingPrice: boolean | null = false;
  @Input() activeDesignProduct: IDesignProduct | null = null;
  @Input() orderCount = 0;
  @Input() countInBasket = 0;
  @Input() propertiesInfo: IPropertyInfo[] | null = null;
  @Input() filters: IFilter[] | null = null;
  @Input() figures: IFigure[] | null = null;
  @Input() pictures: IPicture[] | null = null;
  @Input() picturesLibraries$!: Observable<IPictureLibrary[]>;
  @Input() picturesLibraryCurrentId$!: Observable<number>;
  @Input() picturesLibraryCurrentLibrary$!: Observable<IPictureLibrary | null>;
  @Input() picturesLibraryCurrentPictures$!: Observable<IPicture[] | null>;
  @Input() getLibraryPictures!: (id: number) => void;
  @Input() patternLibraries$!: Observable<IPatternLibrary[]>;
  @Input() patternCurrentId$!: Observable<number>;
  @Input() patternCurrentLibrary$!: Observable<IPatternLibrary | null>;
  @Input() patternCurrentPatterns$!: Observable<IPattern[] | null>;
  @Input() getPatterns!: (id: number) => void;
  @Input() qrCodes: IQRCode[] | null = null;
  @Input() myTemplates: ITemplate[] | null = null;
  @Input() isProductOwner: boolean | null = null;
  @Input() productTemplates: IProductTemplate[] | null = null;
  @Input() newQRCode$!: Observable<IQRCode>;
  @Input() savedTemplate$!: Observable<ITemplate | IProductTemplate | null>;
  @Input() uploadPicture!: (file: File) => Observable<HttpEvent<unknown>>;
  @Input() deletePicture!: (id: number) => void;
  @Input() addPicture!: (picture: IPicture) => void;
  @Input() createQRCode!: (qrCode: Partial<IQRCode>) => unknown;
  @Input() deleteQRCode!: (id: number) => unknown;
  @Input() createMyTemplate!: (template: Partial<ITemplate>) => void;
  @Input() updateMyTemplate!: (data: {
    template: ITemplate;
    triggerToSaved: boolean;
  }) => void;
  @Input() removeMyTemplate!: (template: number) => void;
  @Input() createProductTemplate!: (
    template: Partial<IProductTemplate>
  ) => void;
  @Input() updateProductTemplate!: (data: {
    template: IProductTemplate;
    triggerToSaved: boolean;
  }) => void;
  @Input() removeProductTemplate!: (templateId: number) => void;

  @Input() set config(config: AppShellConfig | null) {
    this.mainService.config = config;
  }

  @Input() createShareTemplate!: (
    model: IShareTemplate
  ) => Observable<IShareResponse>;
  @Input() getShareTemplate!: (uid: string) => Observable<IShareTemplate>;

  @Output() selectProduct: EventEmitter<void> = new EventEmitter();
  @Output() changeParams: EventEmitter<string | null> = new EventEmitter();

  @ViewChild('export', { read: ViewContainerRef, static: false })
  exportViewContainerRef: ViewContainerRef | null = null;

  @ViewChild('modalReachFileLimit')
  modalReachFileLimit?: ModalReachFileLimitComponent;

  isDesktop$: Observable<boolean> = this.windowToolsService.isDesktop$;
  deviceOrientation$: Observable<EDeviceOrientation> =
    this.windowToolsService.deviceOrientation$;

  private MAX_HEIGHT_FOR_CONDITION_ROTATE = 660;

  isShowRotateDevice$ = isTouchDeviceUseMatchMedia()
    ? combineLatest([this.windowSize$, this.deviceOrientation$]).pipe(
        debounceTime(0),
        map(([windowSize, deviceOrientation]) => {
          const { height } = windowSize;
          return (
            height < this.MAX_HEIGHT_FOR_CONDITION_ROTATE &&
            deviceOrientation === EDeviceOrientation.landscape
          );
        })
      )
    : of(false);

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  get loginUrl() {
    return `${
      this.mainService.config?.publicUrl
    }/login?returnUrl=${encodeURIComponent(window.location.href)}`;
  }

  constructor(
    private readonly mainService: MainService,
    private windowToolsService: WindowToolsService,
    private historyStore: HistoryStore,
    private productStore: ProductStore,
    private mainStore: MainStore,
    private picturesService: PicturesService,
    private picturesLibraryService: PicturesLibraryService,
    private patternsService: PatternsService,
    private qrCodesService: QrCodesService,
    private myTemplatesService: MyTemplatesService,
    private productTemplatesService: ProductTemplatesService,
    private templatesService: TemplatesService,
    private exportService: ExportService,
    private storageService: StorageService,
    private browserService: BrowserService,
    private readonly modalWindowStore: ModalWindowStore,
    private readonly orderDesignProductService: OrderDesignProductService,
    private readonly shareTemplatesService: ShareTemplatesService,
    private readonly headerService: HeaderService,
    @Inject(WINDOW_SIZE) private readonly windowSize$: Observable<IWindowSize>
  ) {
    mainService.initService();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['designerSource']) {
      this.productStore.setDesignerSource(this.designerSource);
      this.mainStore.setPage({ page: EPage.product });
    }

    if (changes['product']) {
      this.productStore.setProduct(this.product);
      this.mainStore.setPage({ page: EPage.product });
    }

    if (changes['price']) {
      this.productStore.setPrice(this.price);
    }

    if (changes['updatingPrice']) {
      this.productStore.setUpdatingPrice(this.updatingPrice || false);
    }

    if (changes['activeDesignProduct']) {
      this.productStore.setActiveDesignProduct(this.activeDesignProduct);
    }

    if (changes['orderCount']) {
      this.productStore.setOrderCount(this.orderCount);
    }

    if (changes['countInBasket']) {
      this.productStore.setCountInBasket(this.countInBasket);
    }

    if (changes['propertiesInfo']) {
      this.productStore.setPropertiesInfo(this.propertiesInfo);
    }

    if (changes['filters']) {
      const { filters } = this;
      this.productStore.setFilters({ filters });
    }

    if (changes['figures']) {
      const { figures } = this;
      this.productStore.setFigures({ figures });
    }

    if (changes['pictures']) {
      this.picturesService.pictures$.next(this.pictures || []);
    }

    if (changes['qrCodes']) {
      this.qrCodesService.qrCodes$.next(this.qrCodes);
    }

    if (changes['myTemplates'] || changes['product']) {
      const myTemplates = (this.myTemplates || []).filter(
        (t) => t.productId === this.product?.id
      );
      this.myTemplatesService.templates$.next(myTemplates);
    }

    if (changes['isProductOwner']) {
      this.productStore.isProductOwner$.next(this.isProductOwner);
    }

    if (changes['productTemplates']) {
      this.productTemplatesService.templates$.next(this.productTemplates);
    }

    if (changes['config']) {
      this.exportService.isProduction$.next(
        this.mainService.config?.isProduction
      );
    }
  }

  ngOnInit(): void {
    this.qrCodesService.newQRCode$ = this.newQRCode$;

    this.picturesService.uploadPicture = this.uploadPicture;
    this.picturesService.deletePicture = this.deletePicture;
    this.picturesService.addPicture = this.addPicture;

    this.qrCodesService.createQRCode = this.createQRCode;
    this.qrCodesService.deleteQRCode = this.deleteQRCode;

    this.picturesLibraryService.libraries$ = this.picturesLibraries$;
    this.picturesLibraryService.currentId$ = this.picturesLibraryCurrentId$;
    this.picturesLibraryService.currentLibrary$ =
      this.picturesLibraryCurrentLibrary$;
    this.picturesLibraryService.currentPictures$ =
      this.picturesLibraryCurrentPictures$;
    this.picturesLibraryService.getPictures = this.getLibraryPictures;

    this.patternsService.libraries$ = this.patternLibraries$;
    this.patternsService.currentId$ = this.patternCurrentId$;
    this.patternsService.currentLibrary$ = this.patternCurrentLibrary$;
    this.patternsService.currentPatterns$ = this.patternCurrentPatterns$;
    this.patternsService.getPatterns = this.getPatterns;

    this.myTemplatesService.createMyTemplate = this.createMyTemplate;
    this.myTemplatesService.updateMyTemplate = this.updateMyTemplate;
    this.myTemplatesService.removeMyTemplate = this.removeMyTemplate;

    this.productTemplatesService.createProductTemplate =
      this.createProductTemplate;
    this.productTemplatesService.updateProductTemplate =
      this.updateProductTemplate;
    this.productTemplatesService.removeProductTemplate =
      this.removeProductTemplate;

    this.templatesService.savedTemplate$ = this.savedTemplate$;

    this.shareTemplatesService.createShareTemplate = this.createShareTemplate;
    this.shareTemplatesService.getShareTemplate = this.getShareTemplate;

    this.subs = this.productStore.selectProduct$
      .pipe(
        withLatestFrom(this.historyStore.hasUnsavedChanges$),
        tap(([skipChecks, hasUnsavedChanges]) => {
          if (!skipChecks && hasUnsavedChanges) {
            this.modalWindowStore.patch({
              [MODAL_UID_SAVE_CHANGES_REQUEST]: true,
            });
            return;
          }

          this.selectProduct.emit();
        })
      )
      .subscribe();

    this.subs = this.productStore.changeParams$
      .pipe(tap((params) => this.changeParams.emit(params)))
      .subscribe();

    this.subs = this.savedTemplate$
      .pipe(
        tap((savedTemplate) =>
          this.productStore.setCurrentTemplate(savedTemplate)
        )
      )
      .subscribe();

    this.subs = this.savedTemplate$
      .pipe(
        withLatestFrom(this.productStore.product$),
        tap(([, product]) => {
          if (product) {
            this.storageService.local.removeItem(
              getStorageNameProductElements(product.id)
            );
          }
        })
      )
      .subscribe();

    this.subs = this.windowToolsService.isDesktop$.subscribe((value) => {
      if (value) {
        this.modalReachFileLimit?.closeModal();
      }
    });
  }

  ngAfterViewInit() {
    if (this.exportViewContainerRef) {
      this.exportService.init(this.exportViewContainerRef);
    }

    this.browserService.testHasBugGetScreenCTM();

    this.headerService.orderProductSource = this.orderDesignProductService;
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  onSaveAI() {
    this.mainStore.setObject({ object: EObject.pictures });
  }
}
