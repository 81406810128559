@if (dictionary && treeItems) {
<ul class="tree">
  @for (item of treeItemsSortedAndFiltered; track item.id) {
  <li class="tree__el">
    <div
      class="tree__title"
      [class.tree__title--active]="expandedMap[item.id] || selectedItemId === item.id"
    >
      <ng-container
        [ngTemplateOutlet]="treeItemTemplate || baseTreeItemTemplate"
        [ngTemplateOutletContext]="{ item: item, dictionary: dictionary }"
      ></ng-container>
    </div>

    @if (item.children && item.children.length) {
    <catalog-tree
      [hidden]="!expandedMap[item.id]"
      [treeItems]="item.children"
      [filterCatalogNomenclatureTreeItem]="filterCatalogNomenclatureTreeItem"
      [dictionary]="dictionary"
      [expandedMap]="expandedMap"
      [selectedItemId]="selectedItemId"
    ></catalog-tree>
    }
  </li>
  }
</ul>
}

<ng-template #baseTreeItemTemplate let-item="item" let-dictionary="dictionary">
  @if (item.children && !item.canAddProduct) {
    <span
      class="expand-btn"
      [class.expand-btn--expanded]="expandedMap[item.id]"
      (mousedown)="toggleExpanded($event, item)"
    ></span>
  }
  <a
    [class.expand]="expandedMap[item.id] && item.children && item.children.size"
    [routerLink]="item | catalogGroupLink"
  >
    @if (dictionary[item.dictionaryId].isPartner) {
      <span class="tree__icon--partners">
        <i class="icon icon-partners"></i>
      </span>
    }
    <span
      class="tree__name"
      [class.tree__name--partners]="dictionary[item.dictionaryId].isPartner"
    >
      {{ dictionary[item.dictionaryId].name }}
    </span>
  </a>
</ng-template>
