import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IDesignSide } from '@inaripro-nx/catalog';
import { PipesModule, WINDOW } from '@inaripro-nx/common-ui';
import {
  DesignUiModule,
  DropDownModule,
  SelectDirectionEnum,
} from '@inaripro-nx/design-ui';
import { debounceTime, filter, Subject, Subscription, tap } from 'rxjs';
import {
  DEFAULT_TEXT_SIZE,
  IElement,
  IFontStyle,
  IFontWeight,
  ITextAnchor,
  ITextArc,
  ITextChanges,
  ITextElement,
  IXYb,
} from '../../../../interfaces/editor.interface';
import { IFontFamily } from '../../../../interfaces/fonts.interface';
import { ActionsTextService } from '../../../../services/actions-text/actions-text.service';
import { ActionsElementFillOpacityComponent } from '../actions-element-fill-opacity/actions-element-fill-opacity.component';
import { ActionsElementFillComponent } from '../actions-element-fill/actions-element-fill.component';
import { ActionsElementTranslateComponent } from '../actions-element-translate/actions-element-translate.component';
import { ActionsTextFontSelectComponent } from './components/actions-text-font-select/actions-text-font-select.component';
import { ActionsTextModificationComponent } from './components/actions-text-modification/actions-text-modification.component';
import { ActionsTextRotateComponent } from './components/actions-text-rotate/actions-text-rotate.component';
import { ActionsTextScaleComponent } from './components/actions-text-scale/actions-text-scale.component';
import { EditorService } from '../../../../services/editor/editor.service';
import { EColorTitle } from '../../../../../../constants';
import { ActionsElementStrokeComponent } from '../actions-element-stroke/actions-element-stroke.component';
import { ActionsElementStrokeOpacityComponent } from '../actions-element-stroke-opacity/actions-element-stroke-opacity.component';
import {
  MAX_VALUE_TEXT_STROKE_WIDTH,
  MIN_VALUE_TEXT_STROKE_WIDTH,
  MODAL_STROKE_TEXT_UID,
} from '../../../../../../constants/stroke.consts';
import { EditorElementTextArcComponent } from '../../../editor/components/editor-element-text-arc/editor-element-text-arc.component';

enum ESubRow {
  trasformations = 'trasformations',
  text = 'text',
  font = 'font',
}

@Component({
  selector: 'painter-actions-text',
  standalone: true,
  templateUrl: './actions-text.component.html',
  styleUrls: ['./actions-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    PipesModule,
    DesignUiModule,
    DropDownModule,
    ActionsElementFillComponent,
    ActionsElementFillOpacityComponent,
    ActionsElementTranslateComponent,
    ActionsTextModificationComponent,
    ActionsTextScaleComponent,
    ActionsTextRotateComponent,
    ActionsTextFontSelectComponent,
    ActionsElementStrokeComponent,
    ActionsElementStrokeOpacityComponent,
    EditorElementTextArcComponent,
  ],
})
export class ActionsTextComponent implements OnInit, OnChanges, OnDestroy {
  @Input() element!: IElement;
  @Input() designSide: IDesignSide | null = null;
  @Input() isShowActionsSubmenu: boolean | null = false;
  @Input() isDesktop: boolean | null = false;

  @Output() updateElement: EventEmitter<ITextElement> = new EventEmitter();
  @Output() centerElement: EventEmitter<IXYb> = new EventEmitter();

  @ViewChild('svgGroupMultiLine')
  readonly svgGroupMultiLine!: ElementRef<SVGGraphicsElement>;

  @ViewChild('svgGroupSingleLine')
  readonly svgGroupSingleLine!: ElementRef<SVGGraphicsElement>;

  @ViewChild('svgGroupTextArc')
  readonly svgGroupTextArc!: EditorElementTextArcComponent;

  readonly ESubRow = ESubRow;
  readonly DEFAULT_TEXT_SIZE = DEFAULT_TEXT_SIZE;
  readonly DropdownDirection = SelectDirectionEnum;

  readonly MIN_VALUE_TEXT_STROKE_WIDTH = MIN_VALUE_TEXT_STROKE_WIDTH;
  readonly MAX_VALUE_TEXT_STROKE_WIDTH = MAX_VALUE_TEXT_STROKE_WIDTH;
  readonly MODAL_STROKE_TEXT_UID = MODAL_STROKE_TEXT_UID;

  get textElement(): ITextElement {
    return this.element as ITextElement;
  }

  subRow: ESubRow | null = ESubRow.trasformations;
  protected readonly EColorTitle = EColorTitle;

  inputEvent$: Subject<Event> = new Subject();
  textChanges: ITextChanges | null = null;

  private readonly isSafari =
    this.window.navigator.vendor?.indexOf('Apple') > -1;

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  constructor(
    @Inject(WINDOW) private readonly window: Window,
    private readonly cdr: ChangeDetectorRef,
    private readonly actionsTextService: ActionsTextService,
    private readonly editorService: EditorService
  ) {}

  ngOnInit(): void {
    this.inputTextSubscribe();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['element']) {
      if (this.element) {
        const {
          text,
          textAnchor,
          fontStyle,
          fontWeight,
          lineSpace,
          fontFamily,
          letterSpacing = 0,
          textArc = { angle: 0, width: 0, height: 0 },
        } = this.textElement;

        this.textChanges = {
          text,
          textAnchor,
          fontStyle,
          fontWeight,
          lineSpace,
          fontFamily,
          letterSpacing,
          textArc: { ...textArc },
        };
      }
    }
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  private inputTextSubscribe() {
    this.subs = this.inputEvent$
      .pipe(
        filter(() => !!this.textChanges),
        debounceTime(750),
        tap((event) => {
          if (!this.textChanges) {
            return;
          }

          const text = this.getInputText(event);
          // .trim()
          // .split('\n')
          // .map((s) => (s && !s.trim() ? '' : s))
          // .join('\n');

          this.textChanges = {
            ...this.textChanges,
            text,
          };

          this.updateTextArc();
        })
      )
      .subscribe();
  }

  private getInputText(event: Event) {
    return (event.target as HTMLTextAreaElement).value || '';
  }

  private updateTextArc(save = true) {
    if (!this.textChanges) {
      return;
    }

    this.cdr.detectChanges();

    const textBBox = this.getTextSingleLineBBox();
    const { width, height } = textBBox;

    this.textChanges = {
      ...this.textChanges,
      textArc: {
        ...this.textChanges.textArc,
        width,
        height,
      },
    };

    this.updateText(save);
  }

  private updateText(save = true) {
    if (
      !this.textChanges ||
      !this.svgGroupMultiLine ||
      !this.svgGroupSingleLine
    ) {
      return;
    }

    this.cdr.detectChanges();

    const {
      text,
      textAnchor,
      fontStyle,
      fontWeight,
      lineSpace,
      fontFamily,
      letterSpacing,
      textArc,
    } = this.textChanges;

    const hasAngle = !!textArc.angle;
    const size = this.getSize(hasAngle);
    const textTranslate = this.getUpdateTextTranslate(
      textAnchor,
      size,
      textArc
    );

    // After change textTranslate and before translate change
    if (!hasAngle) {
      size.x -= letterSpacing > 0 ? letterSpacing : 0;
    }

    const translate = this.getUpdateTranslate(textAnchor, size.x);
    const textLength = this.getUpdateTextLength();

    const element: ITextElement = {
      ...this.textElement,
      text,
      textAnchor,
      textLength,
      textTranslate,
      translate,
      fontStyle,
      fontWeight,
      lineSpace,
      fontFamily,
      size,
      letterSpacing,
      textArc,
    };

    if (save) {
      this.updateElement.emit(element);
    } else {
      this.editorService.changeElement(element);
    }
  }

  private getSize(hasAngle: boolean) {
    const textBBox = hasAngle
      ? this.getTextArcBBox()
      : this.getTextMultiLineBBox();

    const { width, height } = textBBox;
    return { x: width, y: height };
  }

  private getUpdateTextTranslate(
    textAnchor: ITextAnchor,
    size: { x: number; y: number },
    textArc: ITextArc
  ) {
    const textTranslate = { x: 0, y: 0 };

    if (textArc.angle) {
      textTranslate.x = (size.x - textArc.width) / 2;
      textTranslate.y = (textArc.angle > 0 ? 0 : 1) * (size.y - textArc.height);
    } else {
      if (textAnchor === ITextAnchor.middle) {
        textTranslate.x = size.x / 2;
      } else if (textAnchor === ITextAnchor.end) {
        textTranslate.x = size.x;
      }
    }

    return textTranslate;
  }

  private getUpdateTranslate(textAnchor: ITextAnchor, newSizeX: number) {
    const { size, scale } = this.textElement;

    const translate = { ...this.textElement.translate };

    if (textAnchor === ITextAnchor.middle) {
      translate.x += ((size.x - newSizeX) / 2) * scale.x;
    } else if (textAnchor === ITextAnchor.end) {
      translate.x += (size.x - newSizeX) * scale.x;
    }

    return translate;
  }

  private getUpdateTextLength() {
    if (!this.svgGroupMultiLine) {
      return 0;
    }

    let maxTextLength: number | null = null;

    this.svgGroupMultiLine.nativeElement
      .querySelectorAll('tspan')
      .forEach((tspan) => {
        const length = tspan.getComputedTextLength();

        if (maxTextLength === null || length > maxTextLength) {
          maxTextLength = length;
        }
      });

    return maxTextLength || 0;
  }

  public onChangeColor(fill: string) {
    const element: ITextElement = {
      ...this.textElement,
      fill,
    };

    this.updateElement.emit(element);
  }

  public changeTextAnchor(textAnchor: ITextAnchor) {
    if (!this.textChanges) {
      return;
    }

    this.textChanges = {
      ...this.textChanges,
      textAnchor,
    };

    this.updateText();
  }

  public toggleFontWeight() {
    if (!this.textChanges) {
      return;
    }

    const fontWeight =
      this.textChanges.fontWeight === IFontWeight.bold
        ? IFontWeight.normal
        : IFontWeight.bold;

    this.textChanges = {
      ...this.textChanges,
      fontWeight,
    };

    this.updateTextArc();
  }

  public toggleFontStyle() {
    if (!this.textChanges) {
      return;
    }

    const fontStyle =
      this.textChanges.fontStyle === IFontStyle.italic
        ? IFontStyle.normal
        : IFontStyle.italic;

    this.textChanges = {
      ...this.textChanges,
      fontStyle,
    };

    this.updateTextArc();
  }

  public changeLineSpace(data: unknown) {
    if (!this.textChanges) {
      return;
    }

    const lineSpace = (data as { lineSpace: number }).lineSpace;
    this.textChanges = {
      ...this.textChanges,
      lineSpace,
    };

    this.updateText();
  }

  changeFontFamily(fontFamily?: IFontFamily) {
    if (!this.textChanges || !fontFamily) {
      return;
    }

    this.textChanges = {
      ...this.textChanges,
      fontFamily: fontFamily.key,
    };

    this.updateTextArc();
  }

  changeLetterSpacing(letterSpacing: number) {
    if (!this.textChanges) {
      return;
    }

    this.textChanges = {
      ...this.textChanges,
      letterSpacing,
    };

    this.updateTextArc(!letterSpacing);
  }

  stopChangeLetterSpacing() {
    this.updateText();
  }

  changeTextArc(angle: number) {
    if (!this.textChanges) {
      return;
    }

    this.textChanges = {
      ...this.textChanges,
      textArc: {
        ...this.textChanges.textArc,
        angle,
      },
    };

    this.updateTextArc(!angle);
  }

  stopChangeTextArc() {
    this.updateText();
  }

  toggleSubRow(subRow: ESubRow) {
    this.subRow = subRow === this.subRow ? null : subRow;
  }

  public blurEmit(): void {
    if (this.isSafari) {
      this.actionsTextService.resizeSafari$.next(true);
    }
  }

  private getTextMultiLineBBox() {
    if (!this.svgGroupMultiLine || !this.svgGroupMultiLine.nativeElement) {
      return {
        width: 0,
        height: 0,
      };
    }

    return this.svgGroupMultiLine.nativeElement.getBBox();
  }

  private getTextSingleLineBBox() {
    if (!this.svgGroupSingleLine || !this.svgGroupSingleLine.nativeElement) {
      return {
        width: 0,
        height: 0,
      };
    }

    return this.svgGroupSingleLine.nativeElement.getBBox();
  }

  private getTextArcBBox() {
    if (!this.svgGroupTextArc || !this.svgGroupTextArc.elRef) {
      return {
        width: 0,
        height: 0,
      };
    }

    return this.svgGroupTextArc.elRef.nativeElement.getBBox();
  }
}
