import {
  DEFAULT_TEXT_SIZE,
  EElementType,
  IElements,
  ITextChanges,
  ITextElement,
  SVG_NS,
} from '../components/main/interfaces/editor.interface';
import { FONTS_FAMILIES } from '../components/main/interfaces/fonts.interface';

export function getTextStyledGroup(
  document: Document,
  el: ITextElement | ITextChanges
): {
  svg: SVGElement;
  svgG: SVGGraphicsElement;
  destroy: () => void;
} {
  const svgG = document.createElementNS(SVG_NS, 'g');

  svgG.setAttribute('font-style', el.fontStyle);
  svgG.setAttribute('font-weight', '' + el.fontWeight);
  svgG.setAttribute('font-family', `'${el.fontFamily}'`);
  svgG.setAttribute('font-size', '' + DEFAULT_TEXT_SIZE);

  if (el.letterSpacing) {
    svgG.setAttribute('letter-spacing', '' + el.letterSpacing);
  }

  const svg = document.createElementNS(SVG_NS, 'svg');
  svg.style.visibility = 'hidden';
  svg.style.position = 'absolute';

  svg.appendChild(svgG);
  document.body.appendChild(svg);

  return {
    svg,
    svgG,
    destroy: () => document.body.removeChild(svg),
  };
}

export function getFontFaces(
  hostUrl: string,
  anyElements: IElements,
  sideIndex: number | null
): string[] {
  const elements = anyElements.filter(
    (el) => el.sideIndex === sideIndex && el.type === EElementType.text
  );

  const FONT_URL = '/assets/fonts/css';

  const ffMap = FONTS_FAMILIES.filter((ff) => !!ff.fileName).reduce(
    (acc, ff) => {
      acc[ff.key] = `${hostUrl}${FONT_URL}/${ff.fileName}`;
      return acc;
    },
    {} as {
      [fontFamily: string]: string;
    }
  );

  return elements
    .map((el) => {
      const fontFamily = (el as ITextElement).fontFamily;
      const url = ffMap[fontFamily];

      if (url) {
        delete ffMap[fontFamily];
        return `@font-face {font-family: '${fontFamily}';src: url('${url}') format('woff');}`;
      }

      return null;
    })
    .filter((v) => !!v) as string[];
}
