import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Pipe({
  name: 'imageXLink',
})
export class ImageXlinkPipe implements PipeTransform {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  transform<T extends { svg?: string }>(element?: T): string | null {
    if (!element?.svg) {
      return null;
    }

    const svgElement = this.document.createElementNS(
      'http://www.w3.org/2000/svg',
      'svg'
    );
    svgElement.innerHTML = element.svg;

    const image = svgElement.getElementsByTagName('image')[0];

    return image
      ? image.getAttributeNS('http://www.w3.org/1999/xlink', 'href')
      : null;
  }
}
