<ng-container
  *ngIf="{
    activeDesignSide: activeDesignSide$ | async,
    activeDesignSideIndex: activeDesignSideIndex$ | async,
    activeDesignProduct: activeDesignProduct$ | async,
    user: user$ | async,
    userPictures: userPictures$ | async,
    isLimitFileReach: isLimitFileReach$ | async
  } as state"
>
  <ng-container *ngIf="state.activeDesignSide">
    <painter-editor-history-controls
      [hasPrev]="hasPrev$ | async"
      [hasNext]="hasNext$ | async"
      (prevHistory)="prevHistory()"
      (nextHistory)="nextHistory()"
    ></painter-editor-history-controls>

    <painter-editor-template-control></painter-editor-template-control>

    <painter-editor-zoom-controls></painter-editor-zoom-controls>

    <button
      type="button"
      class="btn-generate-image"
      [class.btn--noactive]="state.isLimitFileReach"
      [title]="
        state.isLimitFileReach
          ? state.user
            ? REACH_FILE_LIMIT_USER_TEXT
            : REACH_FILE_LIMIT_NO_USER_TEXT
          : null
      "
      (click)="
        state.isLimitFileReach ? openLimitErrorModal() : openGenerateForm()
      "
    >
      <span class="text text-desktop">AI-генератор</span>
      <span class="text text-mobile">AI</span>
    </button>

    <editor-share-links-controls></editor-share-links-controls>

    <painter-editor-sides-control
      class="design-sides"
      [activeDesignProduct]="state.activeDesignProduct"
      [activeDesignSideIndex]="state.activeDesignSideIndex"
      (selectSide)="selectSide.emit($event)"
      *ngIf="showSides$ | async"
    ></painter-editor-sides-control>
  </ng-container>
</ng-container>
