import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IPublicProductFilterCity } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class CatalogCityService {
  constructor(private readonly httpClient: HttpClient) {}

  getCitiesByNomenclatureId(
    id: number,
    hasDesigner = false
  ): Observable<IPublicProductFilterCity[]> {
    let url = `/catalog/public/cities/?nomenclature=${id}`;

    if (hasDesigner) {
      url += `&hasDesigner=1`;
    }

    return this.httpClient
      .get<{ data: IPublicProductFilterCity[] }>(url)
      .pipe(map(({ data }) => data));
  }
}
