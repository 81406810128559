import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { IElement } from '../../../../interfaces/editor.interface';
import { EditorService } from '../../../../services/editor/editor.service';
import { DECIMAL_POINTS_ROTATE } from '../../../../../../constants';

@Component({
  selector: 'painter-actions-element-rotate',
  standalone: true,
  imports: [CommonModule, DesignUiModule],
  templateUrl: './actions-element-rotate.component.html',
  styleUrls: ['./actions-element-rotate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsElementRotateComponent {
  @Input() element!: IElement;
  @Input() sliderTemplate: TemplateRef<unknown> | null = null;

  readonly minValue = -180;
  readonly maxValue = 180;

  readonly DECIMAL_POINTS_ROTATE = DECIMAL_POINTS_ROTATE;

  constructor(public editorService: EditorService) {}

  changeValue(event: number | null) {
    this.editorService.rotate(event);
  }

  stopChange() {
    this.editorService.stopChange();
  }

  reset(): void {
    const value = Math.round((this.minValue + this.maxValue) / 2);

    if (this.element.rotate === value) {
      return;
    }

    this.editorService.rotate(value);
    this.editorService.stopChange();
  }
}
