import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  EMockupType,
  IDesignProduct,
  IDesignSide,
  IDesignZone,
} from '@inaripro-nx/catalog';
import {
  BACKGROUND_COLOR_DEFAULT,
  decimalCeil,
  getSvgLength,
  getSvgSize,
  getSvgXYFromEvent,
  IFilter,
  IMapOfString,
  IPattern,
  isDoubleTouchEvent,
  isTouchEvent,
  IWindowSize,
  PipesModule as CommonPipesModule,
  WINDOW,
  WINDOW_SIZE,
} from '@inaripro-nx/common-ui';
import { ModalWindowStore } from '@inaripro-nx/design-ui';
import {
  catchError,
  combineLatest,
  combineLatestWith,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  finalize,
  map,
  Observable,
  shareReplay,
  Subscription,
  tap,
} from 'rxjs';
import { getElementMinScale } from '../../../../../../utils/calculate.utils';
import {
  EElementType,
  IElement,
  IElements,
  IMapXY,
  IUpdateElement,
  IXY,
} from '../../../../interfaces/editor.interface';
import {
  ELEMENT_FILL_MODAL_UID,
  EObject,
  EPage,
} from '../../../../interfaces/main.interface';
import { ElementRotateCenterPipe } from '../../../../pipes/element-rotate-center/element-rotate-center.pipe';
import { PipesModule } from '../../../../pipes/pipes.module';
import { BrowserService } from '../../../../services/browser/browser.service';
import { EditorService } from '../../../../services/editor/editor.service';
import { ExportService } from '../../../../services/export/export.service';
import { HistoryStore } from '../../../../state/history/history.store';
import { MainStore } from '../../../../state/main/main.store';
import { ProductStore } from '../../../../state/product/product.store';
import { WorkareaStore } from '../../../../state/workarea/workarea.store';
import { EditorElementContentComponent } from '../editor-element-content/editor-element-content.component';
import { EditorElementTransformComponent } from '../editor-element-transform/editor-element-transform.component';
import { EditorRectDashedComponent } from '../editor-rect-dashed/editor-rect-dashed.component';
import { NgArrayPipesModule } from 'ngx-pipes';
import { EditorZonePaintComponent } from '../editor-zone-paint/editor-zone-paint.component';
import { MODAL_CROP_UID } from '@inaripro-nx/crop';

enum EActionType {
  move = 'move',
  scale = 'scale',
  rotate = 'rotate',
  move_zone = 'move_zone',
}

interface IAction {
  type: EActionType;
  currentPointXY: IXY;
  needSave: boolean;
}

interface IActionResponse {
  element: IElement;
  applyChanges: boolean;
}

const defaultSizeActions: IXY = { x: 24, y: 24 };
const initFontSize = 12;

@Component({
  selector: 'painter-editor-draw',
  standalone: true,
  imports: [
    CommonModule,
    CommonPipesModule,
    PipesModule,
    EditorElementTransformComponent,
    EditorElementContentComponent,
    EditorRectDashedComponent,
    NgArrayPipesModule,
    EditorZonePaintComponent,
  ],
  templateUrl: './editor-draw.component.html',
  styleUrls: ['./editor-draw.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorDrawComponent implements OnInit, OnDestroy {
  @ViewChild('svgBase') svgBase!: ElementRef<SVGGraphicsElement>;

  elements$: Observable<IElements> = this.historyStore.elements$.pipe(
    map((elements) => JSON.parse(JSON.stringify(elements))),
    shareReplay({ refCount: false, bufferSize: 1 })
  );
  elementsFiltersId$ = this.historyStore.elementsFiltersId$;

  activeElementIndex$ = this.historyStore.activeElementIndex$;
  activeDesignSide$ = this.productStore.activeDesignSide$;
  activeDesignSideIndex$ = this.productStore.activeDesignSideIndex$;
  activeDesignProduct$ = this.productStore.activeDesignProduct$;

  readonly fullColor$ = this.productStore.fullColor$;
  readonly zoneColors$ = this.productStore.zoneColors$;
  readonly zonePatterns$ = this.productStore.zonePatterns$;
  readonly zoneTranslates$ = this.productStore.zoneTranslates$;
  readonly activeDesignZonesWithColor$ =
    this.productStore.activeDesignZonesWithColor$;

  readonly isFillModalOpened$: Observable<boolean> =
    this.modalWindowStore.modals$.pipe(
      map((modals) => modals[ELEMENT_FILL_MODAL_UID])
    );

  filters$: Observable<{ [id: number]: IFilter }> =
    this.productStore.filtersMap$;

  readonly elementsEditable$ = this.workareaStore.isGrabActive$.pipe(
    map((isGrabActive) => !isGrabActive)
  );

  elements!: IElements;
  filters: { [id: number]: IFilter } = {};
  fullColor: string | null = null;
  zoneColors: IMapOfString = {};
  zonePatterns: { [key: string]: IPattern } = {};
  fontSize = initFontSize;

  updateZone: IDesignZone | null = null;
  updateElement: IUpdateElement | null = null;
  activeDesignSide: IDesignSide | null = null;
  activeDesignProduct: IDesignProduct | null = null;

  zone0Center: IXY = { x: 0, y: 0 };

  private action: IAction | null = null;
  private rotate: number | null = null;
  private scale: number | null = null;
  private fill: string | null = null;
  private fillOpacity: number | null = null;

  readonly EActionType = EActionType;
  readonly EElementType = EElementType;
  readonly BACKGROUND_COLOR_DEFAULT = BACKGROUND_COLOR_DEFAULT;

  private zoom = 1; // not detect changes when change
  private zoneTranslates: IMapXY = {};

  sizeActions: IXY = { ...defaultSizeActions };

  get inAction(): boolean {
    return (
      !!this.action ||
      this.rotate !== null ||
      this.scale !== null ||
      this.fill !== null ||
      this.fillOpacity !== null
    );
  }

  readonly EMockupType = EMockupType;

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  private _downloadSubscription: Subscription | null = null;
  set downloadSubscription(sub: Subscription | null) {
    this._downloadSubscription?.unsubscribe();
    this._downloadSubscription = sub;
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(WINDOW_SIZE) private readonly windowSize$: Observable<IWindowSize>,
    private historyStore: HistoryStore,
    private productStore: ProductStore,
    public editorService: EditorService,
    private elementRotateCenterPipe: ElementRotateCenterPipe,
    private cdr: ChangeDetectorRef,
    private exportService: ExportService,
    private mainStore: MainStore,
    private modalWindowStore: ModalWindowStore,
    private readonly workareaStore: WorkareaStore,
    private browserService: BrowserService
  ) {}

  ngOnInit(): void {
    this.filtersSubscribe();
    this.fullColorSubscribe();
    this.zoneColorsSubscribe();
    this.zonePatternsSubscribe();
    this.activeDesignSideSubscribe();
    this.activeDesignProductSubscribe();
    this.activeElementSubscribe();
    this.scaleSubscribe();
    this.rotateSubscribe();
    this.changeElementSubscribe();
    this.fillSubscribe();
    this.fillOpacitySubscribe();
    this.strokeSubscribe();
    this.strokeWidthSubscribe();
    this.strokeOpacitySubscribe();
    this.stopChangeSubscribe();
    this.translateSubscribe();
    this.zoomSubscribe();
    this.zoneTranslatesSubscribe();
  }

  @HostListener('window:keydown', ['$event'])
  private onWindowKeydown(event: KeyboardEvent) {
    if (event.code === 'KeyQ' && (event.ctrlKey || event.metaKey)) {
      this.getTempPDF();
    }
  }

  private getTempPDF() {
    if (this._downloadSubscription) {
      this.window.alert('Экспорт уже запущен');
      return;
    }

    if (this.activeDesignProduct !== null) {
      const {
        activeDesignProduct: designProduct,
        elements,
        filters,
        fullColor,
        zoneColors,
        zonePatterns,
        zoneTranslates,
      } = this;

      this.downloadSubscription = this.exportService
        .getTempPDF({
          designProduct,
          elements,
          filters,
          fullColor,
          zoneColors,
          zonePatterns,
          zoneTranslates,
        })
        .pipe(
          catchError(() => EMPTY),
          finalize(() => (this.downloadSubscription = null))
        )
        .subscribe();
    }
  }

  private filtersSubscribe() {
    this.subs = this.filters$
      .pipe(
        tap((filters) => {
          this.filters = filters;
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  private fullColorSubscribe() {
    this.subs = this.fullColor$
      .pipe(
        tap((fullColor) => {
          this.fullColor = fullColor;
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  private zoneColorsSubscribe() {
    this.subs = this.zoneColors$
      .pipe(
        tap((zoneColors) => {
          this.zoneColors = zoneColors;
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  private zonePatternsSubscribe() {
    this.subs = this.zonePatterns$
      .pipe(
        tap((zonePatterns) => {
          this.zonePatterns = zonePatterns;
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  private calcFontSize() {
    this.fontSize = getSvgSize(this.svgBase.nativeElement, initFontSize, 2);
  }

  private activeDesignSideSubscribe() {
    this.subs = this.activeDesignSide$
      .pipe(
        tap((activeDesignSide) => {
          this.activeDesignSide = activeDesignSide;
          this.cdr.detectChanges();

          if (this.svgBase) {
            this.calcFontSize();
            this.cdr.detectChanges();
          }
        })
      )
      .subscribe();

    this.subs = combineLatest([
      this.activeDesignSide$,
      this.workareaStore.zoom$,
      this.windowSize$,
    ])
      .pipe(
        debounceTime(0),
        tap(([, zoom]) => {
          const sizeActions = this.browserService.fixScreenCTM(
            { ...defaultSizeActions },
            zoom,
            4
          );

          this.sizeActions = this.svgBase
            ? getSvgLength(this.svgBase.nativeElement, sizeActions, 4)
            : sizeActions;

          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  private activeDesignProductSubscribe() {
    this.subs = this.activeDesignProduct$
      .pipe(
        tap((activeDesignProduct) => {
          this.activeDesignProduct = activeDesignProduct;
        })
      )
      .subscribe();
  }

  private activeElementSubscribe() {
    this.subs = this.elements$
      .pipe(
        combineLatestWith(this.activeElementIndex$),
        debounceTime(0),
        tap(([elements, activeElementIndex]) => {
          this.elements = elements;
          this.updateElement = null;

          if (activeElementIndex !== null && this.elements) {
            const element = this.elements[activeElementIndex] || null;

            if (element) {
              this.updateElement = {
                index: activeElementIndex,
                element,
              };
            }
          }

          this.clearInAction();
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  private scaleSubscribe() {
    this.subs = this.editorService.scale$.subscribe((scale) => {
      this.scale = scale;

      if (!this.updateElement || scale === null) {
        this.cdr.detectChanges();
        return;
      }

      const { index, element } = this.updateElement;
      const { translate, size } = element;

      const dx = (size.x / 2) * (scale - element.scale.x);
      const dy = (size.y / 2) * (scale - element.scale.y);

      this.updateElement = {
        index,
        element: {
          ...element,
          scale: { x: scale, y: scale },
          translate: {
            x: translate.x - dx,
            y: translate.y - dy,
          },
        },
      };

      this.cdr.detectChanges();
    });
  }

  private rotateSubscribe() {
    this.subs = this.editorService.rotate$.subscribe((rotate) => {
      this.rotate = rotate;

      if (!this.updateElement || rotate === null) {
        this.cdr.detectChanges();
        return;
      }

      const { index, element } = this.updateElement;

      this.updateElement = {
        index,
        element: {
          ...element,
          rotate,
        },
      };

      this.cdr.detectChanges();
    });
  }

  private changeElementSubscribe() {
    this.subs = this.editorService.changeElement$.subscribe((element) => {
      if (!this.updateElement || element === null) {
        return;
      }

      const { index } = this.updateElement;

      this.updateElement = {
        index,
        element: {
          ...element,
        },
      };

      this.cdr.detectChanges();
    });
  }

  private fillSubscribe() {
    this.subs = this.editorService.fill$.subscribe((fill) => {
      this.fill = fill;

      if (!this.updateElement) {
        this.cdr.detectChanges();
        return;
      }

      const { index, element } = this.updateElement;

      this.updateElement = {
        index,
        element: {
          ...element,
          fill,
        },
      };

      this.cdr.detectChanges();
    });
  }

  private fillOpacitySubscribe() {
    this.subs = this.editorService.fillOpacity$
      .pipe(distinctUntilChanged())
      .subscribe((fillOpacity) => {
        this.fillOpacity = fillOpacity;

        if (!this.updateElement || fillOpacity === null) {
          this.cdr.detectChanges();
          return;
        }

        const { index, element } = this.updateElement;

        this.updateElement = {
          index,
          element: {
            ...element,
            fillOpacity,
          },
        };

        this.cdr.detectChanges();
      });
  }

  private translateSubscribe() {
    this.subs = this.editorService.translate$.subscribe((translate) => {
      if (!this.updateElement || translate === null) {
        this.cdr.detectChanges();
        return;
      }

      const { index, element } = this.updateElement;

      const updateElement = {
        index,
        element: {
          ...element,
          translate,
        },
      };

      this.historyStore.updateElement(updateElement);
      this.cdr.detectChanges();
    });
  }

  private zoomSubscribe() {
    this.subs = this.workareaStore.zoom$
      .pipe(tap((zoom) => (this.zoom = zoom)))
      .subscribe();
  }

  private zoneTranslatesSubscribe() {
    this.subs = this.zoneTranslates$
      .pipe(tap((zoneTranslates) => (this.zoneTranslates = zoneTranslates)))
      .subscribe();

    this.subs = combineLatest([this.activeDesignSide$, this.zoneTranslates$])
      .pipe(
        tap(([activeDesignSide, zoneTranslates]) => {
          const zone = activeDesignSide?.zones[0];
          this.zone0Center = {
            ...(zone?.center || { x: 0, y: 0 }),
          };

          if (zone) {
            const translate = zoneTranslates['' + zone.id];

            if (translate) {
              this.zone0Center.x += translate.x;
              this.zone0Center.y += translate.y;
            }
          }

          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  private strokeSubscribe() {
    this.subs = this.editorService.stroke$
      .pipe(distinctUntilChanged())
      .subscribe((stroke) => {
        if (!this.updateElement) {
          this.cdr.detectChanges();
          return;
        }

        const { index, element } = this.updateElement;

        this.updateElement = {
          index,
          element: {
            ...element,
            stroke,
          },
        };

        this.cdr.detectChanges();
      });
  }

  private strokeWidthSubscribe() {
    this.subs = this.editorService.strokeWidth$
      .pipe(distinctUntilChanged())
      .subscribe((strokeWidth) => {
        if (!this.updateElement) {
          this.cdr.detectChanges();
          return;
        }

        const { index, element } = this.updateElement;

        this.updateElement = {
          index,
          element: {
            ...element,
            strokeWidth,
          },
        };

        this.cdr.detectChanges();
      });
  }

  private strokeOpacitySubscribe() {
    this.subs = this.editorService.strokeOpacity$
      .pipe(distinctUntilChanged())
      .subscribe((strokeOpacity) => {
        if (!this.updateElement) {
          this.cdr.detectChanges();
          return;
        }

        const { index, element } = this.updateElement;

        this.updateElement = {
          index,
          element: {
            ...element,
            strokeOpacity,
          },
        };

        this.cdr.detectChanges();
      });
  }

  private stopChangeSubscribe() {
    this.subs = this.editorService.stopChange$.subscribe(() => {
      if (!this.updateElement) {
        return;
      }

      this.historyStore.updateElement(this.updateElement);
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
    this.downloadSubscription = null;
  }

  clearSelect() {
    this.historyStore.setActiveElementIndex({
      activeElementIndex: null,
    });
  }

  startAction(
    type: EActionType,
    event: MouseEvent | TouchEvent,
    activeDesignSide: IDesignSide | null,
    index?: number
  ) {
    event.preventDefault();

    if (type === EActionType.move_zone) {
      const zone =
        (index !== undefined && activeDesignSide?.zones[index]) || null;
      this.updateZone = zone ? { ...zone } : null;

      if (this.updateZone) {
        const translate = (this.zoneTranslates || {})['' + this.updateZone.id];

        if (translate) {
          this.updateZone._translate = translate;
        }
      }
    } else if (index !== undefined) {
      this.historyStore.setActiveElementIndex({
        activeElementIndex: index,
      });
    }

    if (!this.svgBase || !activeDesignSide) {
      return;
    }

    this.action = {
      type,
      currentPointXY: this.browserService.fixScreenCTM(
        getSvgXYFromEvent(this.svgBase.nativeElement, event),
        this.zoom
      ),
      needSave: false,
    };

    const minScale = decimalCeil(
      getElementMinScale(this.updateElement?.element, activeDesignSide) -
        Number.EPSILON,
      2
    );

    const moveHandler = (event: MouseEvent | TouchEvent) => {
      if (!this.action || !this.svgBase) {
        return;
      }

      const svgXY = this.browserService.fixScreenCTM(
        getSvgXYFromEvent(this.svgBase.nativeElement, event),
        this.zoom
      );

      const { currentPointXY } = this.action;

      if (this.action.type === EActionType.move_zone) {
        if (isDoubleTouchEvent(event) || !this.updateZone) {
          return;
        }

        this.updateZone = this.moveZone(currentPointXY, this.updateZone, svgXY);

        if (
          this.updateZone.id === this.activeDesignSide?.zones[0]?.id &&
          this.updateZone._translate &&
          this.updateZone.center
        ) {
          this.zone0Center = {
            x: this.updateZone.center.x + this.updateZone._translate.x,
            y: this.updateZone.center.y + this.updateZone._translate.y,
          };
        }

        this.action.needSave = true;
        this.action.currentPointXY = svgXY;
        this.cdr.detectChanges();
        return;
      }

      if (!this.updateElement) {
        return;
      }

      const { index, element } = this.updateElement;
      let responseAction: IActionResponse = {
        element,
        applyChanges: false,
      };

      if (this.action.type === EActionType.move && !isDoubleTouchEvent(event)) {
        responseAction = this.moveAction(currentPointXY, element, svgXY);
      } else if (this.action.type === EActionType.scale) {
        responseAction = this.scaleAction(
          currentPointXY,
          element,
          svgXY,
          event,
          minScale
        );
      } else if (this.action.type === EActionType.rotate) {
        responseAction = this.rotateAction(
          currentPointXY,
          element,
          svgXY,
          event
        );
      }

      if (responseAction.applyChanges) {
        this.action.needSave = true;
        this.action.currentPointXY = svgXY;
        this.updateElement = {
          index,
          element: responseAction.element,
        };
      }

      this.cdr.detectChanges();
    };

    this.mainStore.setHideAction({ hideAction: true });

    if (isTouchEvent(event)) {
      const touchFinishHandle = () => {
        this.endAction();
        this.cdr.detectChanges();

        this.window.removeEventListener('touchmove', moveHandler);
        this.window.removeEventListener('touchend', touchFinishHandle);
        this.window.removeEventListener('touchcancel', touchFinishHandle);
      };

      this.window.addEventListener('touchmove', moveHandler);
      this.window.addEventListener('touchend', touchFinishHandle);
      this.window.addEventListener('touchcancel', touchFinishHandle);

      return;
    }

    const mouseUpHandle = () => {
      this.endAction();
      this.cdr.detectChanges();

      this.window.removeEventListener('mousemove', moveHandler);
      this.window.removeEventListener('mouseup', mouseUpHandle);
    };

    this.window.addEventListener('mousemove', moveHandler);
    this.window.addEventListener('mouseup', mouseUpHandle);
  }

  private endAction() {
    this.mainStore.setHideAction({ hideAction: false });

    if (!this.action) {
      return;
    }

    const { type, needSave } = this.action;
    this.action = null;

    if (needSave) {
      if (type === EActionType.move_zone) {
        if (this.updateZone && this.updateZone._translate) {
          const { id, _translate } = this.updateZone;

          this.productStore.setZoneTranslate({
            key: '' + id,
            translate: _translate,
          });

          this.updateZone = null;
        }
      } else {
        if (this.updateElement) {
          this.historyStore.updateElement(this.updateElement);
        }
      }
    }
  }

  private moveZone(
    currentPointXY: IXY,
    zone: IDesignZone,
    svgXY: IXY
  ): IDesignZone {
    if (
      !zone.start ||
      !zone.size ||
      !zone.moveArea ||
      !zone.moveArea.start ||
      !zone.moveArea.size
    ) {
      return zone;
    }

    const dx = svgXY.x - currentPointXY.x;
    const dy = svgXY.y - currentPointXY.y;

    const { x, y } = zone._translate || { x: 0, y: 0 };
    const _translate = { x: x + dx, y: y + dy };

    if (_translate.x + zone.start.x < zone.moveArea.start.x) {
      _translate.x = x;
    }

    if (_translate.y + zone.start.y < zone.moveArea.start.y) {
      _translate.y = y;
    }

    if (
      _translate.x + zone.start.x + zone.size.x >
      zone.moveArea.start.x + zone.moveArea.size.x
    ) {
      _translate.x = x;
    }

    if (
      _translate.y + zone.start.y + zone.size.y >
      zone.moveArea.start.y + zone.moveArea.size.y
    ) {
      _translate.y = y;
    }

    return {
      ...zone,
      _translate,
    };
  }

  private moveAction(
    currentPointXY: IXY,
    element: IElement,
    svgXY: IXY
  ): IActionResponse {
    const dx = svgXY.x - currentPointXY.x;
    const dy = svgXY.y - currentPointXY.y;

    const { x, y } = element.translate;

    return {
      applyChanges: true,
      element: {
        ...element,
        translate: { x: x + dx, y: y + dy },
      },
    };
  }

  scaleToDefault() {
    if (!this.updateElement) {
      return;
    }

    const { scale, size, translate } = this.updateElement.element;
    const { x, y } = scale;

    if (x === 1 && y === 1) {
      return;
    }

    const newScale: IXY = { x: 1, y: 1 };

    const translateDx = (size.x / 2) * (newScale.x - scale.x);
    const translateDy = (size.y / 2) * (newScale.y - scale.y);

    const { index, element } = this.updateElement;

    const updateElement = {
      index,
      element: {
        ...element,
        scale: newScale,
        translate: {
          x: translate.x - translateDx,
          y: translate.y - translateDy,
        },
      },
    };

    this.historyStore.updateElement(updateElement);
  }

  private scaleAction(
    currentPointXY: IXY,
    element: IElement,
    svgXY: IXY,
    event: MouseEvent | TouchEvent,
    minScale: number
  ): IActionResponse {
    if (!this.activeDesignSide) {
      return {
        applyChanges: false,
        element,
      };
    }

    const isCtrl = event.ctrlKey || event.metaKey;

    const { translate, size, rotate, scale, type } = element;

    // calculate

    const angle = rotate < 0 ? rotate + 360 : rotate;
    const radians = (angle * Math.PI) / 180;
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);

    let coeffXY = 1;
    const angle45 = angle + 45;
    coeffXY = (angle45 > 90 && angle45 < 180) || angle45 > 270 ? -1 : 1;

    const dx = svgXY.x - currentPointXY.x;
    const dy = svgXY.y - currentPointXY.y;

    const { x, y } = scale;
    let newScale: IXY = { x, y };

    const deltaX = (dx: number, dy: number) => {
      const scaleDx = (dx * 2) / size.x;
      const scaleDy = (dy * 2) / size.y;

      return cos * scaleDx + sin * scaleDy;
    };

    const deltaY = (dx: number, dy: number) => {
      const scaleDx = (dx * 2) / size.x;
      const scaleDy = (dy * 2) / size.y;

      return cos * scaleDy - sin * scaleDx;
    };

    const getFreeScale = (x: number, y: number, dx: number, dy: number) => {
      return {
        x: x + deltaX(dx, dy),
        y: y + deltaY(dx, dy),
      };
    };

    const getProportionalScale = (
      x: number,
      y: number,
      dx: number,
      dy: number
    ) => {
      if (Math.abs(dx) > Math.abs(dy)) {
        return {
          x: x + deltaX(dx, coeffXY * (dx / size.x) * size.y),
          y: y + deltaX(dx, coeffXY * (dx / size.x) * size.y),
        };
      } else {
        return {
          x: x + deltaY(coeffXY * (dy / size.y) * size.x, dy),
          y: y + deltaY(coeffXY * (dy / size.y) * size.x, dy),
        };
      }
    };

    const getActionResponse = (newScale: IXY): IActionResponse => {
      const newSize: IXY = {
        x: size.x * newScale.x,
        y: size.y * newScale.y,
      };

      const props: IXY = {
        x: newSize.x / (this.activeDesignSide as IDesignSide).sizePX.x,
        y: newSize.y / (this.activeDesignSide as IDesignSide).sizePX.y,
      };

      if (
        (type === EElementType.text ? newScale.x : props.x) < minScale ||
        (type === EElementType.text ? newScale.y : props.y) < minScale
      ) {
        return {
          applyChanges: false,
          element,
        };
      }

      const translateDx = (size.x / 2) * (newScale.x - scale.x);
      const translateDy = (size.y / 2) * (newScale.y - scale.y);

      return {
        applyChanges: true,
        element: {
          ...element,
          scale: newScale,
          translate: {
            x: translate.x - translateDx,
            y: translate.y - translateDy,
          },
        },
      };
    };

    newScale = isCtrl
      ? getFreeScale(x, y, dx, dy)
      : getProportionalScale(x, y, dx, dy);

    return getActionResponse(newScale);
  }

  rotateToDefault() {
    if (!this.updateElement || this.updateElement.element.rotate === 0) {
      return;
    }

    const { index, element } = this.updateElement;

    const updateElement = {
      index,
      element: {
        ...element,
        rotate: 0,
      },
    };

    this.historyStore.updateElement(updateElement);
  }

  private rotateAction(
    currentPointXY: IXY,
    element: IElement,
    svgXY: IXY,
    event: MouseEvent | TouchEvent
  ): IActionResponse {
    const isShift = event.shiftKey;

    const { translate } = element;

    const rotateCenter: IXY = this.elementRotateCenterPipe.transform(element);

    const currentAngle = Math.atan2(
      currentPointXY.y - rotateCenter.y - translate.y - this.zone0Center.y,
      currentPointXY.x - rotateCenter.x - translate.x - this.zone0Center.x
    );

    const angleNew = Math.atan2(
      svgXY.y - rotateCenter.y - translate.y - this.zone0Center.y,
      svgXY.x - rotateCenter.x - translate.x - this.zone0Center.x
    );

    const changeAngle = ((angleNew - currentAngle) * 180) / Math.PI;

    if (isShift && Math.abs(changeAngle) < 5) {
      return {
        applyChanges: false,
        element,
      };
    }

    let rotate = element.rotate + changeAngle;

    if (isShift) {
      rotate = Math.round(rotate / 5) * 5;
    }

    if (rotate <= -180) {
      rotate += 360;
    } else if (rotate >= 180) {
      rotate -= 360;
    }

    return {
      applyChanges: true,
      element: {
        ...element,
        rotate,
      },
    };
  }

  clearInAction() {
    this.rotate = null;
    this.scale = null;
    this.fill = null;
    this.fillOpacity = null;
  }

  removeElement(event: MouseEvent) {
    if (!this.updateElement) {
      return;
    }

    event.stopPropagation();
    this.historyStore.removeElement({ index: this.updateElement.index });
  }

  public selectZone(side: IDesignSide, zone: IDesignZone) {
    this.productStore.setActiveDesignZoneIndex(side.zones.indexOf(zone));
    this.mainStore.setPage({ page: EPage.product });
    this.mainStore.setObject({ object: EObject.fill });
  }

  onEditorDrawTouchStart(event: TouchEvent) {
    const { touches } = event;
    if (touches.length === 1) {
      event.stopPropagation();
    }
  }

  openCropModal() {
    this.modalWindowStore.patch({
      [MODAL_CROP_UID]: true,
    });
  }
}
