import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IDesignSide } from '@inaripro-nx/catalog';
import { DesignUiModule } from '@inaripro-nx/design-ui';
import { IElement } from '../../../../interfaces/editor.interface';
import { ActionsElementFillOpacityComponent } from '../actions-element-fill-opacity/actions-element-fill-opacity.component';
import { ActionsElementFillComponent } from '../actions-element-fill/actions-element-fill.component';
import { ActionsElementFlipComponent } from '../actions-element-flip/actions-element-flip.component';
import { ActionsElementRotateComponent } from '../actions-element-rotate/actions-element-rotate.component';
import { ActionsElementScaleComponent } from '../actions-element-scale/actions-element-scale.component';
import { ActionsElementTranslateComponent } from '../actions-element-translate/actions-element-translate.component';
import { EColorTitle } from '../../../../../../constants';
import { ActionsElementStrokeWidthComponent } from '../actions-element-stroke-width/actions-element-stroke-width.component';
import { ActionsElementStrokeOpacityComponent } from '../actions-element-stroke-opacity/actions-element-stroke-opacity.component';
import { ActionsElementStrokeComponent } from '../actions-element-stroke/actions-element-stroke.component';
import {
  MAX_VALUE_FIGURE_STROKE_WIDTH,
  MIN_VALUE_FIGURE_STROKE_WIDTH,
  MODAL_STROKE_FIGURE_UID,
} from '../../../../../../constants/stroke.consts';

enum ESubRow {
  trasformations = 'trasformations',
  color = 'color',
}

@Component({
  selector: 'painter-actions-figures',
  standalone: true,
  imports: [
    CommonModule,
    DesignUiModule,
    ActionsElementRotateComponent,
    ActionsElementScaleComponent,
    ActionsElementTranslateComponent,
    ActionsElementFillComponent,
    ActionsElementFillOpacityComponent,
    ActionsElementFlipComponent,
    ActionsElementStrokeWidthComponent,
    ActionsElementStrokeOpacityComponent,
    ActionsElementStrokeComponent,
  ],
  templateUrl: './actions-figures.component.html',
  styleUrls: ['./actions-figures.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsFiguresComponent {
  @Input() element!: IElement;
  @Input() designSide: IDesignSide | null = null;
  @Input() isShowActionsSubmenu: boolean | null = false;
  @Input() isDesktop: boolean | null = false;

  @Output() updateElement: EventEmitter<IElement> = new EventEmitter();

  readonly ESubRow = ESubRow;
  readonly EColorTitle = EColorTitle;

  readonly MIN_VALUE_FIGURE_STROKE_WIDTH = MIN_VALUE_FIGURE_STROKE_WIDTH;
  readonly MAX_VALUE_FIGURE_STROKE_WIDTH = MAX_VALUE_FIGURE_STROKE_WIDTH;
  readonly MODAL_STROKE_FIGURE_UID = MODAL_STROKE_FIGURE_UID;

  subRow: ESubRow | null = ESubRow.trasformations;

  toggleSubRow(subRow: ESubRow) {
    this.subRow = subRow === this.subRow ? null : subRow;
  }
}
