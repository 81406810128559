import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  Subscription,
  debounceTime,
  map,
  tap,
  distinctUntilChanged,
} from 'rxjs';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  selector: 'design-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangeSliderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() minValue = 0;
  @Input() maxValue = 100;
  @Input() value = 0;
  @Input() label = '';

  @Output() stopChange: EventEmitter<void> = new EventEmitter();
  @Output() changeValue: EventEmitter<number> = new EventEmitter();

  currentValue: number = this.getValueInRange(
    this.value,
    this.minValue,
    this.maxValue
  );

  sliderControl = new FormControl<number>(this.currentValue);

  private sub!: Subscription;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      this.currentValue = this.getValueInRange(
        this.value,
        this.minValue,
        this.maxValue
      );
      this.sliderControl.setValue(this.currentValue, { emitEvent: false });
    }
  }

  ngOnInit(): void {
    this.sub = this.sliderControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        map((value) =>
          this.getValueInRange(value, this.minValue, this.maxValue)
        ),
        tap((value) => {
          this.currentValue = value;
          this.cdr.detectChanges();
        }),
        tap((value) => this.update(value))
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  stopChangeEvent(event: Event): void {
    event.stopPropagation();
    this.stopChange.emit();
  }

  update(value: number): void {
    this.changeValue.emit(value);
  }

  private getValueInRange(value: number | null, min: number, max: number) {
    if (value === null) {
      return min;
    }

    return Math.min(Math.max(value, min), max);
  }
}
