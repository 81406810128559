import {
  EFilterPropertyKey,
  IDesignerSource,
} from '../interfaces/designer.interface';
import { sortAsNumber } from '@inaripro-nx/common-ui';

export function getOrderParams(designerSource: IDesignerSource): {
  designElementsIds: number[];
  orderElementsIds: number[];
} {
  const designElementsIds = (designerSource.selected || [])
    .filter((id) => {
      const el = designerSource.allElementsMap[id];
      return el && el.propertyId !== EFilterPropertyKey.kit && el.inDesign;
    })
    .map((id) => +id);

  const orderElementsIds = (designerSource.selected || [])
    .filter((id) => {
      const el = designerSource.allElementsMap[id];
      return el && el.propertyId !== EFilterPropertyKey.kit && el.inOrder;
    })
    .map((id) => +id);

  return { designElementsIds, orderElementsIds };
}

export function getSumCountUid(designerSource: IDesignerSource | null): string {
  if (!designerSource) {
    return '';
  }

  return sortAsNumber(
    (designerSource.selected || []).filter((id) => {
      const el = designerSource.allElementsMap[id];
      const prop = el ? designerSource.propertiesMap[el.propertyId] : null;
      return prop?.isSeparateProductCount;
    })
  ).join('.');
}
