<svg:g *ngIf="zone.start && zone.size && (zoneColor || zonePattern)">
  <g *ngIf="zoneColor">
    <rect
      [attr.x]="isZoneExport ? 0 : zone.start.x"
      [attr.y]="isZoneExport ? 0 : zone.start.y"
      [attr.width]="zone.size.x"
      [attr.height]="zone.size.y"
      [attr.fill]="zoneColor"
    />
  </g>

  <g *ngIf="zonePattern">
    <pattern
      id="{{ 'pattern-' + zone.id }}"
      [attr.x]="isZoneExport ? 0 : zone.start.x"
      [attr.y]="isZoneExport ? 0 : zone.start.y"
      [attr.width]="zone.size.x"
      [attr.height]="zone.size.y"
      patternUnits="userSpaceOnUse"
    >
      <image
        x="0"
        y="0"
        [attr.width]="zone.size.x"
        [attr.height]="zone.size.y"
        [attr.xlink:href]="zonePattern.url"
        preserveAspectRatio="xMidYMid slice"
      ></image>
    </pattern>
    <rect
      [attr.x]="isZoneExport ? 0 : zone.start.x"
      [attr.y]="isZoneExport ? 0 : zone.start.y"
      [attr.width]="zone.size.x"
      [attr.height]="zone.size.y"
      [attr.fill]="'url(#pattern-' + zone.id + ')'"
    />
  </g>
</svg:g>
