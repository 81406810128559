<div
  #leftArrow
  class="left-arrow"
  [class.left-arrow--show]="isShowArrows && isShowLeftArrow"
>
  <ng-content select="[left-arrow]"></ng-content>
</div>
<div
  #invisibleHorizontallyScroll
  class="invisible-horizontally-scroll"
>
  <ng-content select="[slider]"></ng-content>
</div>

<div
  #rightArrow
  class="right-arrow"
  [class.right-arrow--show]="isShowArrows && isShowRightArrow"
>
  <ng-content select="[right-arrow]"></ng-content>
</div>

