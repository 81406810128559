import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EMockupType } from '@inaripro-nx/catalog';
import { BACKGROUND_COLOR_DEFAULT, PipesModule } from '@inaripro-nx/common-ui';
import { DesignUiModule, IFile } from '@inaripro-nx/design-ui';
import { SimplebarAngularModule } from 'simplebar-angular';
import { ITemplateForm } from '../../interfaces/editor.interface';
import { ProductStore } from '../../state/product/product.store';
import { EditorElementContentComponent } from '../editor/components/editor-element-content/editor-element-content.component';
import { EditorElementTransformComponent } from '../editor/components/editor-element-transform/editor-element-transform.component';
import { ModalTemplateCoverItemComponent } from './components/modal-template-cover-item/modal-template-cover-item.component';
import { Subscription } from 'rxjs';
import { HistoryStore } from '../../state/history/history.store';
import { TemplateFormService } from '../../services/template-form/template-form.service';
import { NgArrayPipesModule } from 'ngx-pipes';
import { EditorZonePaintComponent } from '../editor/components/editor-zone-paint/editor-zone-paint.component';

@Component({
  selector: 'painter-modal-template',
  standalone: true,
  imports: [
    CommonModule,
    PipesModule,
    DesignUiModule,
    SimplebarAngularModule,
    EditorElementContentComponent,
    EditorElementTransformComponent,
    ModalTemplateCoverItemComponent,
    NgArrayPipesModule,
    EditorZonePaintComponent,
  ],
  templateUrl: './modal-template.component.html',
  styleUrls: ['./modal-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalTemplateComponent implements OnInit, OnDestroy {
  uploadedFile: IFile | null = null;

  readonly elements$ = this.historyStore.elements$;
  readonly elementsFiltersId$ = this.historyStore.elementsFiltersId$;
  readonly filters$ = this.productStore.filtersMap$;
  readonly zoneColors$ = this.productStore.zoneColors$;
  readonly zonePatterns$ = this.productStore.zonePatterns$;
  readonly zoneTranslates$ = this.productStore.zoneTranslates$;
  readonly templateForm$ = this.templateFormService.templateForm$;
  readonly isSending$ = this.templateFormService.isSending$;
  readonly sides$ = this.templateFormService.sides$;

  readonly BACKGROUND_COLOR_DEFAULT = BACKGROUND_COLOR_DEFAULT;
  readonly EMockupType = EMockupType;

  private _subs: Subscription[] = [];
  set subs(sub: Subscription) {
    this._subs.push(sub);
  }

  constructor(
    private readonly historyStore: HistoryStore,
    private readonly productStore: ProductStore,
    private readonly cdr: ChangeDetectorRef,
    private readonly templateFormService: TemplateFormService
  ) {}

  ngOnInit() {
    this.subs = this.templateForm$.subscribe((tf) => {
      this.initCover(tf);
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s.unsubscribe());
  }

  selectCover(templateForm: FormGroup<ITemplateForm>, coverItem: HTMLElement) {
    templateForm?.controls['cover'].setValue(coverItem);
  }

  onFileUploaded(templateForm: FormGroup<ITemplateForm>, file: IFile) {
    this.uploadedFile = file;
    this.resetCover(templateForm);
    this.cdr.markForCheck();
  }

  saveTemplate(templateForm: FormGroup<ITemplateForm>) {
    this.templateFormService.saveTemplate(templateForm);
  }

  onCloseModal() {
    this.templateFormService.clearForm();
  }

  private initCover(templateForm: FormGroup<ITemplateForm> | null) {
    const cover = templateForm && templateForm.controls['cover'].value;
    if (cover && this.coverIsFile(cover)) {
      this.uploadedFile = cover;
      this.resetCover(templateForm);
    } else {
      this.uploadedFile = null;
    }
  }

  private resetCover(templateForm: FormGroup<ITemplateForm>) {
    templateForm?.controls['cover'].reset();
  }

  private coverIsFile(cover: HTMLElement | IFile): cover is IFile {
    return (
      (cover as IFile).id !== undefined &&
      (cover as IFile).name !== undefined &&
      (cover as IFile).url !== undefined
    );
  }
}
