<svg:g
  [attr.transform]="
    (el.translate | svgTranslate) +
    ({
      angle: el.rotate,
      point: { size: el.size, scale: el.scale } | elementRotateCenter
    } | svgRotate) +
    (el.scale | svgScale)
  "
  [attr.fill]="el.fill || 'transparent'"
  [attr.fill-opacity]="el.fillOpacity !== null ? 100 - el.fillOpacity + '%' : null"
  [attr.stroke]="el.stroke || null"
  [attr.stroke-width]="el.strokeWidth"
  [attr.stroke-opacity]="el.strokeOpacity === null || el.strokeOpacity === undefined ? null : 100 - el.strokeOpacity + '%'"
  stroke-linecap="round"
  stroke-linejoin="round"
  (mousedown)="onMouseDown($event)"
  (touchstart)="onTouchStart($event)"
  [attr.filter]="el.filterId && 'url(#filter_' + el.filterId + ')'"
>
  <ng-content></ng-content>
</svg:g>
