import { InjectionToken } from '@angular/core';
import { ISortConfig } from '../../interfaces';

export interface SortStoreConfig {
  storeUid: string;
  moduleUid: string;
  config: ISortConfig;
}

export const SORT_STORE_CONFIG = new InjectionToken<SortStoreConfig>(
  'sort.store.config'
);
