import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CropModalComponent } from '@inaripro-nx/crop';
import { IElement, ISVGElement } from '../../../../interfaces/editor.interface';
import { ActionsButtonComponent } from '../actions-button/actions-button.component';
import { PipesModule } from '@inaripro-nx/common-ui';

@Component({
  selector: 'painter-actions-element-crop',
  standalone: true,
  templateUrl: './actions-element-crop.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ActionsButtonComponent,
    CropModalComponent,
    PipesModule,
  ],
})
export class ActionsElementCropComponent implements OnChanges {
  @Input() element!: IElement;
  @Input() title = 'Обрезка';
  @Input() titleEdit = 'Заменить изображение';
  @Input() disabled = false;

  @Output() openCropModal = new EventEmitter<void>();

  svgElement: ISVGElement = this.element as ISVGElement;

  ngOnChanges(changes: SimpleChanges) {
    if ('element' in changes) {
      this.svgElement = this.element as ISVGElement;
    }
  }
}
