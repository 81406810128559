<design-tool-button [active]="isGrabActive$ | async" (click)="toggleGrab()">
  <i class="icon icon-ic_grab"></i>
</design-tool-button>

<div class="zoom-buttons">
  <design-tool-button
    [disabled]="zoomOutDisabled$ | async"
    (click)="zoomOut()"
  >
    <i class="icon icon-ic_minus"></i>
  </design-tool-button>

  <design-tool-button
    [disabled]="zoomInDisabled$ | async"
    (click)="zoomIn()"
  >
    <i class="icon icon-ic_plus"></i>
  </design-tool-button>
</div>

<design-tool-button
  [active]="isFocusActive$ | async"
  (click)="toggleFocus()"
>
  <i class="icon icon-ic_fullscreen"></i>
</design-tool-button>

<div
  designAdDropDown
  [dropDownAlignElement]="zoomValue"
  [dropDownItems]="zoomOptions"
  [dropDownLabelActive]="(zoom$ | async | percentNoSpace) || ''"
  [dropDownOpenTo]="'down'"
  [dropDownAlign]="'left'"
  [dropDownClass]="'drop-down-zoom-value'"
  (selectItem)="selectZoom($event.item)"
  (toggleDropDown)="zoomSelectActive = $event"
>
  <div class="zoom-value" #zoomValue>
    <design-tool-button
      class="justify-content-center"
      [selected]="zoomSelectActive"
    >
      <span>{{ zoom$ | async | percentNoSpace}}</span>
      <i class="icon icon-ic_arrow-down"></i>
    </design-tool-button>
  </div>
</div>
