import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  DesignUiModule,
  IDesignSimpleSelectboxListItem,
} from '@inaripro-nx/design-ui';
import { CatalogProductListFilterWrapperComponent } from '../catalog-product-list-filter-wrapper/catalog-product-list-filter-wrapper.component';

@Component({
  selector: 'catalog-product-list-filter-select-box',
  standalone: true,
  imports: [
    CommonModule,
    DesignUiModule,
    CatalogProductListFilterWrapperComponent,
  ],
  templateUrl: './catalog-product-list-filter-select-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogProductListFilterSelectBoxComponent {
  @Input() title = '';
  @Input() items: IDesignSimpleSelectboxListItem[] = [];
  @Input() selected: number[] = [];
  @Input() submitDisabled = false;

  @Output() toggle = new EventEmitter<number>();
  @Output() selectAll = new EventEmitter<void>();
  @Output() clearAll = new EventEmitter<void>();
  @Output() clearFilter = new EventEmitter<void>();
  @Output() applyFilterValues = new EventEmitter<void>();
}
