import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { PipesModule as CommonPipesModule } from '@inaripro-nx/common-ui';
import {
  EPropertyType,
  ICatalogNomenclatureTreeItem,
  ICategory,
  IFiltersValues,
  IProductFilters,
  IPropertyFilter,
  IPropertyForFilter,
  IPropertyTypeElement,
  IPublicProductFilterCity,
  IPublicProductFilterCompany,
} from '../../interfaces';
import { IFilterMinMax, IPaginationResponse } from '@inaripro-nx/common-ui';
import { CatalogProductListFilterButtonsComponent } from './components/catalog-product-list-filter-buttons/catalog-product-list-filter-buttons.component';
import { CatalogProductListFilterCheckboxComponent } from './components/catalog-product-list-filter-checkbox/catalog-product-list-filter-checkbox.component';
import { CatalogProductListFilterPriceComponent } from './components/catalog-product-list-filter-price/catalog-product-list-filter-price.component';
import { CatalogProductListFilterInputComponent } from './components/catalog-product-list-filter-input/catalog-product-list-filter-input.component';
import { CatalogProductListFilterSelectBoxComponent } from './components/catalog-product-list-filter-select-box/catalog-product-list-filter-select-box.component';
import { CatalogProductListFilterStockComponent } from './components/catalog-product-list-filter-stock/catalog-product-list-filter-stock.component';
import { CatalogGroupLinkPipe } from '../../pipes';

@Component({
  selector: 'catalog-product-list-filter',
  standalone: true,
  imports: [
    RouterModule,
    CommonPipesModule,
    CatalogProductListFilterButtonsComponent,
    CatalogProductListFilterCheckboxComponent,
    CatalogProductListFilterPriceComponent,
    CatalogProductListFilterInputComponent,
    CatalogProductListFilterSelectBoxComponent,
    CatalogProductListFilterStockComponent,
    CatalogGroupLinkPipe,
  ],
  templateUrl: './catalog-product-list-filter.component.html',
  styleUrl: './catalog-product-list-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogProductListFilterComponent {
  @Input() resetItem: ICatalogNomenclatureTreeItem | null = null;

  @Input() filters: IProductFilters | null = null;
  @Input() filterValues: IFiltersValues | null = null;

  @Input() cities: IPublicProductFilterCity[] | null = null;
  @Input() companies: IPublicProductFilterCompany[] | null = null;
  @Input() selectedCities: number[] | null = null;
  @Input() selectedCompanies: number[] | null = null;

  @Input() companyId?: number;
  @Input() nomenclatureBase: ICategory | null = null;

  @Input() submitDisabled = false;
  @Input() paginationResponse: IPaginationResponse | null = null;

  @Output() filterValuesSetPrice = new EventEmitter<IFilterMinMax>();
  @Output() filterValuesSetApplyCityStock = new EventEmitter<boolean>();
  @Output() filterValuesSetApplyAllStock = new EventEmitter<boolean>();
  @Output() deleteFilterValues = new EventEmitter<number>();
  @Output() filterValuesSetMinMax = new EventEmitter<IPropertyFilter>();
  @Output() filterValuesToggleType = new EventEmitter<{
    toggleElement: IPropertyTypeElement;
    filterValues: IFiltersValues;
  }>();
  @Output() filterValuesSelectTypes = new EventEmitter<number>();
  @Output() filterValuesClearTypes = new EventEmitter<number>();
  @Output() setSelectedCities = new EventEmitter<number[]>();
  @Output() setSelectedCompanies = new EventEmitter<number[]>();
  @Output() resetFilterValues = new EventEmitter<void>();
  @Output() applyFilterValues =
    new EventEmitter<ICatalogNomenclatureTreeItem | null>();

  readonly PROPERTY_TYPES = EPropertyType;

  changePrice(priceFilter: IFilterMinMax) {
    this.filterValuesSetPrice.emit(priceFilter);
  }

  changeApplyCityStock(applyCityStock: boolean) {
    this.filterValuesSetApplyCityStock.emit(applyCityStock);
  }

  changeApplyAllStock(applyAllStock: boolean) {
    this.filterValuesSetApplyAllStock.emit(applyAllStock);
  }

  changePropertyFilter(property: IPropertyForFilter, values: IFilterMinMax) {
    if (
      property.maxValue === values.maxValue &&
      property.minValue === values.minValue
    ) {
      this.deleteFilterValues.emit(property.id);
    } else {
      this.filterValuesSetMinMax.emit({ characterId: property.id, ...values });
    }
  }

  toggleType(
    propertyId: number,
    elementId: number,
    filterValues: IFiltersValues
  ) {
    this.filterValuesToggleType.emit({
      toggleElement: { propertyId, elementId },
      filterValues,
    });
  }

  selectTypes(propertyId: number) {
    this.filterValuesSelectTypes.emit(propertyId);
  }

  toggleCity(id: number) {
    let ids: number[];
    const index = (this.selectedCities || []).indexOf(id);

    if (index > -1) {
      ids = (this.selectedCities || []).slice();
      ids.splice(index, 1);
    } else {
      ids = (this.selectedCities || []).concat(id);
    }

    this.setSelectedCities.emit(ids);
  }

  selectCities() {
    const ids = (this.cities || []).map((item) => item.id);
    this.setSelectedCities.emit(ids);
  }

  toggleCompany(id: number) {
    let ids: number[];
    const index = (this.selectedCompanies || []).indexOf(id);

    if (index > -1) {
      ids = (this.selectedCompanies || []).slice();
      ids.splice(index, 1);
    } else {
      ids = (this.selectedCompanies || []).concat(id);
    }

    this.setSelectedCompanies.emit(ids);
  }

  selectCompanies() {
    const ids = (this.companies || []).map((item) => item.id);
    this.setSelectedCompanies.emit(ids);
  }

  clearPriceFilter() {
    this.changePrice({
      minValue: null,
      maxValue: null,
    });
    this.apply();
  }

  clearStock() {
    this.changeApplyCityStock(false);
    this.changeApplyAllStock(false);
    this.apply();
  }

  clearPropertyFilter(propertyId: number) {
    this.deleteFilterValues.emit(propertyId);
    this.apply();
  }

  clearTypes(propertyId: number) {
    this.filterValuesClearTypes.emit(propertyId);
    this.apply();
  }

  clearCities() {
    this.setSelectedCities.emit([]);
    this.apply();
  }

  clearCompanies() {
    this.setSelectedCompanies.emit([]);
    this.apply();
  }

  apply() {
    this.applyFilterValues.emit(this.resetItem);
  }
}
