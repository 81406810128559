import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { AuthService } from '@inaripro-nx/auth';
import { ModalWindowStore } from '@inaripro-nx/design-ui';
import { combineLatest, filter, map, Observable } from 'rxjs';
import {
  MAX_FILE_IN_LIBRARY_AUTH_USER,
  MAX_FILE_IN_LIBRARY_NOAUTH_USER,
  REACH_FILE_LIMIT_NO_USER_TEXT,
  REACH_FILE_LIMIT_USER_TEXT,
} from '../../../../../../constants';
import { PicturesService } from '../../../../services/pictures/pictures.service';
import { WindowToolsService } from '../../../../services/window-tools/window-tools.service';
import { HistoryStore } from '../../../../state/history/history.store';
import { ProductStore } from '../../../../state/product/product.store';
import { MODAL_AI_UID } from '../../../modal-ai/modal-ai.component';
import { MODAL_REACH_FILE_LIMIT } from '../../../modal-reach-file-limit/modal-reach-file-limit.component';
import { EditorButtonComponent } from '../editor-button/editor-button.component';
import { EditorHistoryControlsComponent } from '../editor-history-controls/editor-history-controls.component';
import { EditorProductSidesComponent } from '../editor-product-sides/editor-product-sides.component';
import { EditorSidesControlComponent } from '../editor-sides-control/editor-sides-control.component';
import { EditorTemplateControlComponent } from '../editor-template-control/editor-template-control.component';
import { EditorZoomControlsComponent } from '../editor-zoom-controls/editor-zoom-controls.component';
import { EditorShareLinksControlComponent } from '../editor-share-links-controls/editor-share-links-controls.component';
import { ContentComponent } from '../../../content/content.component';

@Component({
  selector: 'painter-editor-controls',
  standalone: true,
  templateUrl: './editor-controls.component.html',
  styleUrls: ['./editor-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    EditorHistoryControlsComponent,
    EditorZoomControlsComponent,
    EditorButtonComponent,
    EditorProductSidesComponent,
    EditorSidesControlComponent,
    EditorTemplateControlComponent,
    EditorShareLinksControlComponent,
    ContentComponent,
  ],
})
export class EditorControlsComponent {
  @Output() selectSide = new EventEmitter<number>();

  readonly MAX_FILE_IN_LIBRARY_AUTH_USER = MAX_FILE_IN_LIBRARY_AUTH_USER;
  readonly MAX_FILE_IN_LIBRARY_NOAUTH_USER = MAX_FILE_IN_LIBRARY_NOAUTH_USER;

  readonly REACH_FILE_LIMIT_USER_TEXT = REACH_FILE_LIMIT_USER_TEXT;
  readonly REACH_FILE_LIMIT_NO_USER_TEXT = REACH_FILE_LIMIT_NO_USER_TEXT;

  hasPrev$ = this.historyStore.hasPrev$;
  hasNext$ = this.historyStore.hasNext$;

  activeDesignSide$ = this.productStore.activeDesignSide$;
  activeDesignProduct$ = this.productStore.activeDesignProduct$;
  activeDesignSideIndex$ = this.productStore.activeDesignSideIndex$;

  readonly userPictures$ = this.picturesService.userPictures$;
  readonly user$ = this.authService.user$;
  readonly isLimitFileReach$ = combineLatest([
    this.userPictures$,
    this.user$,
  ]).pipe(
    map(
      ([userPictures, user]) =>
        userPictures!.length >=
        (user ? MAX_FILE_IN_LIBRARY_AUTH_USER : MAX_FILE_IN_LIBRARY_NOAUTH_USER)
    )
  );

  showSides$: Observable<boolean> = this.activeDesignProduct$.pipe(
    filter(Boolean),
    map((activeDesignProduct) => activeDesignProduct.sides.length > 1)
  );

  constructor(
    private historyStore: HistoryStore,
    private productStore: ProductStore,
    private readonly modalWindowStore: ModalWindowStore,
    private readonly authService: AuthService,
    private readonly picturesService: PicturesService,
    private windowToolsService: WindowToolsService
  ) {}

  prevHistory() {
    this.historyStore.prevHistory();
  }

  nextHistory() {
    this.historyStore.nextHistory();
  }

  openGenerateForm() {
    this.modalWindowStore.patch({ [MODAL_AI_UID]: true });
  }

  openLimitErrorModal() {
    if (this.windowToolsService.isDesktop$.value) {
      return;
    }

    this.modalWindowStore.patch({ [MODAL_REACH_FILE_LIMIT]: true });
  }
}
