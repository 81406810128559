import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
} from '@angular/core';
import { IDesignSide } from '@inaripro-nx/catalog';
import { IWindowSize, WINDOW_SIZE } from '@inaripro-nx/common-ui';
import { ClickOutsideDirective } from '@inaripro-nx/design-ui';
import { combineLatest, map, Observable, shareReplay } from 'rxjs';
import { EObject, EPage } from '../../interfaces/main.interface';
import { MainStore } from '../../state/main/main.store';
import { ProductStore } from '../../state/product/product.store';

const MIN_TAB_HEIGHT = 60;
const MORE_TAB_HEIGHT = 50;

@Component({
  selector: 'painter-desktop-tabs',
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective],
  templateUrl: './desktop-tabs.component.html',
  styleUrls: ['./desktop-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopTabsComponent {
  moreGroupActive = false;

  readonly tabs$ = combineLatest([
    this.mainStore.page$,
    this.mainStore.object$,
    this.productStore.activeDesignSide$,
    this.windowSize$,
  ]).pipe(
    map(([page, object, activeDesignSide]) =>
      this.getTabs(page, object, activeDesignSide)
    ),
    shareReplay({ refCount: false, bufferSize: 1 })
  );

  constructor(
    private readonly mainStore: MainStore,
    private readonly productStore: ProductStore,
    private readonly elementRef: ElementRef,
    @Inject(WINDOW_SIZE) private readonly windowSize$: Observable<IWindowSize>
  ) {}

  private getTabs(
    page: EPage,
    object: EObject | null,
    activeDesignSide: IDesignSide | null
  ) {
    const tabs = [
      {
        id: 1,
        name: 'Продукт',
        icon: 'icon-ic_product',
        isActive: page === EPage.product,
        isVisible: true,
        onClick: () => this.setPage(EPage.product),
      },
      {
        id: 2,
        name: 'Фон',
        icon: 'icon-fill',
        isActive: this.isActiveObjects(page) && object === EObject.fill,
        isVisible:
          activeDesignSide?.hasColorPalette ||
          activeDesignSide?.zones.some(
            (z) => z.hasColorPalette || z.colorProperty
          ),
        onClick: () => this.setObject(EObject.fill),
      },
      {
        id: 3,
        name: 'Картинки',
        icon: 'icon-ic_picture',
        isActive: this.isActiveObjects(page) && object === EObject.pictures,
        isVisible: true,
        onClick: () => this.setObject(EObject.pictures),
      },
      {
        id: 4,
        name: 'Текст',
        icon: 'icon-ic_letter-t',
        isActive: this.isActiveObjects(page) && object === EObject.text,
        isVisible: true,
        onClick: () => this.setObject(EObject.text),
      },
      {
        id: 5,
        name: 'Фигуры',
        icon: 'icon-ic_figures',
        isActive: this.isActiveObjects(page) && object === EObject.figures,
        isVisible: true,
        onClick: () => this.setObject(EObject.figures),
      },
      {
        id: 6,
        name: 'QR-код',
        icon: 'icon-ic_qr-code',
        isActive: this.isActiveObjects(page) && object === EObject.qrcodes,
        isVisible: true,
        onClick: () => this.setObject(EObject.qrcodes),
      },
      {
        id: 7,
        name: 'Шаблоны',
        icon: 'icon-ic_figure-star',
        isActive: this.isActiveObjects(page) && object === EObject.templates,
        isVisible: true,
        onClick: () => this.setObject(EObject.templates),
      },
      {
        id: 8,
        name: 'Слои',
        icon: 'icon-ic_layers',
        isActive: page === EPage.layers,
        isVisible: true,
        onClick: () => this.setPage(EPage.layers),
      },
    ].filter((tab) => !!tab.isVisible);

    const slicedTabsLength = Math.floor(
      (this.elementRef.nativeElement.clientHeight - MORE_TAB_HEIGHT) /
        MIN_TAB_HEIGHT
    );
    const isMoreButton =
      tabs.length >
      Math.floor(this.elementRef.nativeElement.clientHeight / MIN_TAB_HEIGHT);

    return {
      main: isMoreButton ? tabs.slice(0, slicedTabsLength) : tabs,
      more: isMoreButton ? tabs.slice(slicedTabsLength, tabs.length) : [],
    };
  }

  private isActiveObjects(page: EPage | null) {
    return page === EPage.design || page === EPage.objects;
  }

  private setPage(page: EPage) {
    this.mainStore.setPage({ page });
  }

  private setObject(object: EObject) {
    this.mainStore.setObject({ object });
  }
}
